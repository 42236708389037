import { ChangeDetectorRef, Component, OnInit, ViewChild, computed, inject, signal, viewChild } from '@angular/core';
import { DynamicTableComponent } from '../../dynamic-table/dynamic-table.component';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ActivatedRoute, ParamMap, RouterModule } from '@angular/router';
import { ReportService } from '../../shared/service/report.service';
import { map, Observable, of, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { DynamicData, ServerSideResponse } from '../../shared/model/types';
import { SidenavService } from '../../shared/service/sidenav.service';
import { MatCardModule } from '@angular/material/card';
import { SpecificCriteriaComponent } from '../specific-critera/specific-criteria.component';
import { ReportDatesComponent } from '../report-dates/report-dates.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportDatesService } from '../../shared/service/report-dates.service';
import { CommonModule } from '@angular/common';
import { AppService } from '../../shared/service/app.service';
import { ModelReportService } from '../../shared/service/model-report.service';
import { AuthService } from '../../shared/service/auth.service';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../../dialog/success-dialog/success-dialog.component';

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatButtonToggleModule, MatDialogModule, FormsModule, ReactiveFormsModule, TranslateModule, DynamicTableComponent, MatCardModule, SpecificCriteriaComponent, ReportDatesComponent, MatSelectModule, NgxMatSelectSearchModule, MatIconModule, RouterModule],
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss'
})
export class ReportComponent implements OnInit {

  private sidenavService = inject(SidenavService);
  private route = inject(ActivatedRoute);
  private reportService = inject(ReportService);
  private reportDatesService = inject(ReportDatesService);
  private appService = inject(AppService);
  private modelReportService = inject(ModelReportService);
  private authService = inject(AuthService);
  private dialog = inject(MatDialog);

  private reportColumns = this.reportService.reportColumns;
  private reportController = this.reportService.reportController;
  private reportCode = this.reportService.reportCode;
  private reportType = this.reportService.reportType;

  columnsToDisplay = signal<string[]>([]);

  stickyLeftColumnsCount: number = 0;
  stickyRightColumnsCount: number = 0;
  stickyLeftColumns: string[] = [];
  stickyRightColumns: string[] = [];
  showSpecificCriteria: boolean = false;

  // ReportCode: string | null = "";
  ReportName: string | null = "";
  subReportCode: string | null = "";
  idReportTemplateUrl: string | null = "";

  exportTypeControl = new FormControl('RAW');
  exportFormatControl = new FormControl('EXCELOPENXML');
  reportModelControl = new FormControl<DynamicData>({});
  reportModel = signal<DynamicData[]>([]);

  public filterControl: FormControl = new FormControl();
  public filteredList: ReplaySubject<DynamicData[]> = new ReplaySubject<DynamicData[]>(1);
  private _onDestroy = new Subject<void>();

  items = computed(() => {
    this.filteredList.next(this.reportModel());

    this.filterControl.valueChanges.pipe(
      takeUntil(this._onDestroy),
      map(search => this.filterItems(this.reportModel(), search))
    ).subscribe(filtered => {
      this.filteredList.next(filtered);
    });
    return this.filteredList;
  });

  analysisDate = computed(() => {
    if (this.reportDatesService.showDateRange) {
      return this.reportDatesService.endDate();
    }
    else {
      return this.reportDatesService.analysisDate();
    }
  });

  startDate = computed(() => {
    if (this.reportDatesService.showDateRange) {
      return this.reportDatesService.startDate();
    }
    else {
      return this.reportDatesService.analysisDate();
    }
  });


  ngOnInit(): void {
    this.sidenavService.showSidenav = true;
    this.sidenavService.showBuildingsList.set(true);

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.reportModelControl.reset();
      const paramReportCode = params.get('reportcode'); // Report code with or without _RAW
      this.reportCode.set(paramReportCode?.includes('RAW') ? paramReportCode?.substring(0, paramReportCode.length - 4) : paramReportCode); // Get the report code without RAW
      this.subReportCode = params.get('subreportcode');
      this.idReportTemplateUrl = params.get('idreporttemplate');

      //Get report model based on report code
      this.modelReportService.getReportTemplates(paramReportCode, this.authService.getLoggedUser()?.UserName!).subscribe(result => {
        this.reportModel.set(result.data);
        if (this.idReportTemplateUrl) {
          const model = this.reportModel().find(el => el["id_report_template"] === Number(this.idReportTemplateUrl))
          this.reportModelControl.patchValue(model!);
        }
      });

      this.initReport();

      //Change export type status according to selected model
      this.reportModelControl.valueChanges.subscribe(selectedModel => {
        if (selectedModel && selectedModel["report_code"]) {
          if (selectedModel["report_code"].includes('RAW')) {
            this.exportTypeControl.setValue('RAW');
            this.exportTypeControl.disable();
          } else {
            this.exportTypeControl.setValue('DETAILED');
            this.exportTypeControl.disable();
          }
          //Get the columns of the report model
          this.modelReportService
            .getReportTemplateData(selectedModel["report_code"], selectedModel["id_report_template"], '1')
            .subscribe((result) => {
              this.columnsToDisplay.set(result.data.map(col => col["COLUMN_NAME"]));
            });
        } else {
          this.exportTypeControl.enable();
          //this.initReport();
          return;
        }
      });
    }
    );
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private filterItems(items: DynamicData[], search: string): DynamicData[] {
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(item => item["template_name"].toLowerCase().includes(search));
  }

  getReportData = (): Observable<ServerSideResponse> => {
    this.reportService.reportController = this.reportController;
    this.reportService.reportCode = this.reportCode;
    this.reportService.reportColumns = this.reportColumns;
    this.reportService.reportType = this.reportType;

    if (this.reportController() != "") {
      return this.reportService.reportData$;
    }
    else {
      const response: ServerSideResponse = {
        draw: 0,
        recordsTotal: 0,
        recordsFiltered: 0,
        data: [],
        ColSums: {},
        dataType: ""
      };
      return of(response);
    }

  }

  exportReport() {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      data: {
        message:
          'lblReportLaunch',
      }
    });
    const reportTemplateId = Number(this.reportModelControl.value?.['id_report_template']) || null;
    this.reportService.getReportExport(this.reportCode(), this.ReportName, this.reportController(), this.exportTypeControl.value, this.exportFormatControl.value, reportTemplateId)
      .subscribe(
        (data: Blob) => this.downloadFile(data)
      );
  }

  downloadFile(data: Blob) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = (this.reportModelControl.value?.['template_name'] ? this.reportModelControl.value?.['template_name'] : this.ReportName) + (this.exportFormatControl.value === "EXCELOPENXML" ? '.xlsx' : '.pdf');
    link.click();
  }


  initReport(): void {
    switch (this.reportCode()) {
      case "TS_BUILDING": {
        this.reportController.set("TenancyScheduleBuilding");
        this.ReportName = this.appService.getTranslation("lblTSBuildingReport");
        this.reportColumns.set([
          {
            data: 'EXTERNAL_FUND_NAME',
            name: 'EXTERNAL_FUND_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MAIN_MANAGER_NAME',
            name: 'MAIN_MANAGER_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PORTFOLIO_CODE',
            name: 'PORTFOLIO_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PORTFOLIO_LABEL',
            name: 'PORTFOLIO_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ASSET_MANAGER',
            name: 'ASSET_MANAGER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SITE_CODE',
            name: 'SITE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SITE_NAME',
            name: 'SITE_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'FEE_NAME',
            name: 'FEE_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'AGENCY',
            name: 'AGENCY',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PROPERTY_MANAGER',
            name: 'PROPERTY_MANAGER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BU_MANAGER',
            name: 'BU_MANAGER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'START_MANAGEMENT_DATE',
            name: 'START_MANAGEMENT_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'END_MANAGEMENT_DATE',
            name: 'END_MANAGEMENT_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SERVICE_CODE',
            name: 'SERVICE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SERVICE_LABEL',
            name: 'SERVICE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OWNER_CODE',
            name: 'OWNER_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OWNER_NAME',
            name: 'OWNER_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_NAME',
            name: 'BUILDING_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_TOTAL_AREA_SQM',
            name: 'BUILDING_TOTAL_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_ADDRESS',
            name: 'BUILDING_ADDRESS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_POSTAL_CODE',
            name: 'BUILDING_POSTAL_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_CITY',
            name: 'BUILDING_CITY',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_COUNTRY',
            name: 'BUILDING_COUNTRY',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_TYPE_CODE',
            name: 'BUILDING_TYPE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_TYPE_LABEL',
            name: 'BUILDING_TYPE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_NATURE_CODE',
            name: 'BUILDING_NATURE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_NATURE_LABEL',
            name: 'BUILDING_NATURE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GEOGRAPHICAL_ZONE',
            name: 'GEOGRAPHICAL_ZONE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_BUILDINGS',
            name: 'NUMBER_OF_BUILDINGS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MAX_NUMBER_OF_LEVELS',
            name: 'MAX_NUMBER_OF_LEVELS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_TENANTS',
            name: 'NUMBER_OF_TENANTS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_LEASES',
            name: 'NUMBER_OF_LEASES',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_UNITS',
            name: 'NUMBER_OF_UNITS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_PARKING_SPACES',
            name: 'NUMBER_OF_PARKING_SPACES',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_OCCUPIED_UNITS',
            name: 'NUMBER_OF_OCCUPIED_UNITS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_VACANT_UNITS',
            name: 'NUMBER_OF_VACANT_UNITS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'UNITS_TOTAL_AREA_SQM',
            name: 'UNITS_TOTAL_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OCCUPIED_AREA_SQM',
            name: 'OCCUPIED_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'VACANT_AREA_SQM',
            name: 'VACANT_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GROSS_HEADLINE_RENT',
            name: 'GROSS_HEADLINE_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GROSS_HEADLINE_RENT_M2',
            name: 'GROSS_HEADLINE_RENT_M2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RRE_TOTAL_AREA_SQM',
            name: 'RRE_TOTAL_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RRE_OCCUPIED_AREA_SQM',
            name: 'RRE_OCCUPIED_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RRE_VACANT_AREA_SQM',
            name: 'RRE_VACANT_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RRE_GROSS_HEADLINE_RENT',
            name: 'RRE_GROSS_HEADLINE_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RRE_GROSS_HEADLINE_RENT_M2',
            name: 'RRE_GROSS_HEADLINE_RENT_M2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RRE_ABATEMENT',
            name: 'RRE_ABATEMENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ERE_TOTAL_AREA_SQM',
            name: 'ERE_TOTAL_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ERE_OCCUPIED_AREA_SQM',
            name: 'ERE_OCCUPIED_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ERE_VACANT_AREA_SQM',
            name: 'ERE_VACANT_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ERE_GROSS_HEADLINE_RENT',
            name: 'ERE_GROSS_HEADLINE_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ERE_GROSS_HEADLINE_RENT_M2',
            name: 'ERE_GROSS_HEADLINE_RENT_M2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ERE_ABATEMENT',
            name: 'ERE_ABATEMENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OTHER_TOTAL_AREA_SQM',
            name: 'OTHER_TOTAL_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OTHER_OCCUPIED_AREA_SQM',
            name: 'OTHER_OCCUPIED_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OTHER_VACANT_AREA_SQM',
            name: 'OTHER_VACANT_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'IMPORT_DATE',
            name: 'IMPORT_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ANALYSIS_DATE',
            name: 'ANALYSIS_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "BUILDING_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "NUMBER_OF_BUILDINGS",
          "MAX_NUMBER_OF_LEVELS",
          "NUMBER_OF_TENANTS",
          "NUMBER_OF_LEASES",
          "NUMBER_OF_UNITS",
          "NUMBER_OF_PARKING_SPACES",
          "NUMBER_OF_OCCUPIED_UNITS",
          "NUMBER_OF_VACANT_UNITS",
          "UNITS_TOTAL_AREA_SQM",
          "OCCUPIED_AREA_SQM",
          "VACANT_AREA_SQM",
          "GROSS_HEADLINE_RENT",
          "GROSS_HEADLINE_RENT_M2",
          "RRE_TOTAL_AREA_SQM",
          "RRE_OCCUPIED_AREA_SQM",
          "RRE_VACANT_AREA_SQM",
          "RRE_GROSS_HEADLINE_RENT",
          "RRE_GROSS_HEADLINE_RENT_M2",
          "RRE_ABATEMENT",
          "ERE_TOTAL_AREA_SQM",
          "ERE_OCCUPIED_AREA_SQM",
          "ERE_VACANT_AREA_SQM",
          "ERE_GROSS_HEADLINE_RENT",
          "ERE_GROSS_HEADLINE_RENT_M2",
          "ERE_ABATEMENT",
          "OTHER_TOTAL_AREA_SQM",
          "OTHER_OCCUPIED_AREA_SQM",
          "OTHER_VACANT_AREA_SQM"
        ]);
        this.stickyLeftColumnsCount = 1;
        this.stickyLeftColumns = ["BUILDING_CODE"];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "TS_LEASE": {
        this.reportController.set("TenancySchedule");
        this.ReportName = this.appService.getTranslation("lblTenancySchedule");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'TENANT_NAME',
            name: 'TENANT_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PORTFOLIO_LABEL',
            name: 'PORTFOLIO_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'EXTERNAL_FUND_NAME',
            name: 'EXTERNAL_FUND_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OWNER_CODE',
            name: 'OWNER_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OWNER_NAME',
            name: 'OWNER_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ASSET_MANAGER',
            name: 'ASSET_MANAGER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SITE_NAME',
            name: 'SITE_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'FEE_NAME',
            name: 'FEE_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'AGENCY',
            name: 'AGENCY',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PROPERTY_MANAGER',
            name: 'PROPERTY_MANAGER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BU_MANAGER',
            name: 'BU_MANAGER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'START_MANAGEMENT_DATE',
            name: 'START_MANAGEMENT_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'END_MANAGEMENT_DATE',
            name: 'END_MANAGEMENT_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SERVICE_LABEL',
            name: 'SERVICE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_NAME',
            name: 'BUILDING_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_TOTAL_AREA_SQM',
            name: 'BUILDING_TOTAL_AREA_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_NATURE_LABEL',
            name: 'BUILDING_NATURE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GEOGRAPHICAL_ZONE',
            name: 'GEOGRAPHICAL_ZONE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_ADDRESS',
            name: 'BUILDING_ADDRESS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_POSTAL_CODE',
            name: 'BUILDING_POSTAL_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_CITY',
            name: 'BUILDING_CITY',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_COUNTRY',
            name: 'BUILDING_COUNTRY',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEGAL_PERSON',
            name: 'LEGAL_PERSON',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'HOLDING_COMPANY',
            name: 'HOLDING_COMPANY',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MAIN_MANAGER_NAME',
            name: 'MAIN_MANAGER_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'TRADE_NAME',
            name: 'TRADE_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_NATURE_CODE',
            name: 'BUILDING_NATURE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BUILDING_TYPE_LABEL',
            name: 'BUILDING_TYPE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'TENANT_CODE',
            name: 'TENANT_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SIRET_NUMBER',
            name: 'SIRET_NUMBER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'TRADE_REGISTER_NUMBER',
            name: 'TRADE_REGISTER_NUMBER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'EU_VAT_IDENTIFICATION_NUMBER',
            name: 'EU_VAT_IDENTIFICATION_NUMBER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'TENANT_INDUSTRY',
            name: 'TENANT_INDUSTRY',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INSURANCE_POLICY_NUMBER',
            name: 'INSURANCE_POLICY_NUMBER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_NATURE_LABEL',
            name: 'LEASE_NATURE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_TYPE_LABEL',
            name: 'LEASE_TYPE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'IS_GREEN_LEASE',
            name: 'IS_GREEN_LEASE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'REPAIR_CLAUSE',
            name: 'REPAIR_CLAUSE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'REFURBISHMENT_CLAUSE',
            name: 'REFURBISHMENT_CLAUSE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STATUS_LEASE',
            name: 'STATUS_LEASE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_LEASE_RENEWALS',
            name: 'NUMBER_OF_LEASE_RENEWALS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'AMENDMENT_TYPE',
            name: 'AMENDMENT_TYPE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'AMENDMENT_DATE',
            name: 'AMENDMENT_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'AMENDMENT_DESCRIPTION',
            name: 'AMENDMENT_DESCRIPTION',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'IS_LEASE_RENEWED',
            name: 'IS_LEASE_RENEWED',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'IS_HOLDOVER_LEASE',
            name: 'IS_HOLDOVER_LEASE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INVOICING_FREQUENCY_LABEL',
            name: 'INVOICING_FREQUENCY_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENTAL_TERM_LABEL',
            name: 'RENTAL_TERM_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_SIGNED_DATE',
            name: 'LEASE_SIGNED_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_START_DATE',
            name: 'LEASE_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'TENANT_ARRIVAL_DATE',
            name: 'TENANT_ARRIVAL_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NOTICE_PERIOD',
            name: 'NOTICE_PERIOD',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_BREAK_DATE_1',
            name: 'LEASE_BREAK_DATE_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_BREAK_DATE_2',
            name: 'LEASE_BREAK_DATE_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_BREAK_DATE_3',
            name: 'LEASE_BREAK_DATE_3',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_BREAK_PERIOD_1',
            name: 'LEASE_BREAK_PERIOD_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_BREAK_PERIOD_2',
            name: 'LEASE_BREAK_PERIOD_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_BREAK_PERIOD_3',
            name: 'LEASE_BREAK_PERIOD_3',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NEXT_RISK_DATE',
            name: 'NEXT_RISK_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NEXT_LEASE_BREAK_DATE',
            name: 'NEXT_LEASE_BREAK_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_END_DATE',
            name: 'LEASE_END_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NOTICE_DATE',
            name: 'NOTICE_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LANDLORD_NOTICE_DATE',
            name: 'LANDLORD_NOTICE_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NOTICE_TYPE_LABEL',
            name: 'NOTICE_TYPE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'TENANT_EXIT_DATE',
            name: 'TENANT_EXIT_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_DURATION',
            name: 'LEASE_DURATION',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_COMMITMENT_TERM',
            name: 'LEASE_COMMITMENT_TERM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'DURATION_TO_BREAK_DATE',
            name: 'DURATION_TO_BREAK_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'DURATION_TO_LEASE_END',
            name: 'DURATION_TO_LEASE_END',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'REMAINING_TERM_TO_THE_LEASE_TERMINATION',
            name: 'REMAINING_TERM_TO_THE_LEASE_TERMINATION',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_REVIEW_FREQUENCY',
            name: 'RENT_REVIEW_FREQUENCY',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_REVIEW_LEVERAGE',
            name: 'RENT_REVIEW_LEVERAGE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INDEX_FLOOR',
            name: 'INDEX_FLOOR',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INDEXATION_CAP',
            name: 'INDEXATION_CAP',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_INDEX',
            name: 'INITIAL_INDEX',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CURRENT_INDEX_TYPE_LABEL',
            name: 'CURRENT_INDEX_TYPE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_INDEX_VALUE',
            name: 'INITIAL_INDEX_VALUE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_INDEX_DATE',
            name: 'INITIAL_INDEX_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CURRENT_INDEX_VALUE',
            name: 'CURRENT_INDEX_VALUE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CURRENT_INDEX_DATE',
            name: 'CURRENT_INDEX_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LAST_RENT_REVIEW_DATE',
            name: 'LAST_RENT_REVIEW_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NEXT_INDEX_DATE',
            name: 'NEXT_INDEX_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NEXT_REVISION_DATE',
            name: 'NEXT_REVISION_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_FLOORS',
            name: 'LEASE_FLOORS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MAIN_UNIT',
            name: 'MAIN_UNIT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MAIN_UNIT_TYPE_LABEL',
            name: 'MAIN_UNIT_TYPE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_ROOMS',
            name: 'NUMBER_OF_ROOMS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MAIN_UNIT_BUILDING',
            name: 'MAIN_UNIT_BUILDING',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MAIN_UNIT_STAIRCASE',
            name: 'MAIN_UNIT_STAIRCASE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MAIN_UNIT_FLOOR',
            name: 'MAIN_UNIT_FLOOR',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'DOOR_NUMBER',
            name: 'DOOR_NUMBER',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_UNITS',
            name: 'LEASE_UNITS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CONDOMINIUM_UNIT_CODE',
            name: 'CONDOMINIUM_UNIT_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENTED_AREA',
            name: 'RENTED_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'VACANT_AREA',
            name: 'VACANT_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PERCENT_TOTAL_PROPERTY_AREA',
            name: 'PERCENT_TOTAL_PROPERTY_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OFFICE_AREA',
            name: 'OFFICE_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RESIDENTIAL_AREA',
            name: 'RESIDENTIAL_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RETAIL_AREA',
            name: 'RETAIL_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'WORKSHOP_AREA',
            name: 'WORKSHOP_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ARCHIVE_AREA',
            name: 'ARCHIVE_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STORAGE_AREA',
            name: 'STORAGE_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INDUSTRIAL_AREA',
            name: 'INDUSTRIAL_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MISCELLANEOUS_AREA',
            name: 'MISCELLANEOUS_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_PARKING_PLACES',
            name: 'NUMBER_OF_PARKING_PLACES',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PRIVATE_UNIT_AREA',
            name: 'PRIVATE_UNIT_AREA',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'COMMON_AREA_SHARE',
            name: 'COMMON_AREA_SHARE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'THOUSANDS',
            name: 'THOUSANDS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_SIMPLE_CAR_PARKS',
            name: 'NUMBER_OF_SIMPLE_CAR_PARKS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NUMBER_OF_DOUBLE_CAR_PARKS',
            name: 'NUMBER_OF_DOUBLE_CAR_PARKS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_ANNUAL_RENT_AMOUNT',
            name: 'INITIAL_ANNUAL_RENT_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_ANNUAL_RENT_AMOUNT_M2',
            name: 'INITIAL_ANNUAL_RENT_AMOUNT_M2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CURRENT_ANNUAL_RENT_AMOUNT',
            name: 'CURRENT_ANNUAL_RENT_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ANNUAL_RENT_AMOUNT_M2',
            name: 'ANNUAL_RENT_AMOUNT_M2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CURRENT_MONTHLY_RENT_AMOUNT',
            name: 'CURRENT_MONTHLY_RENT_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PERCENT_TOTAL_RENT_AMOUNT',
            name: 'PERCENT_TOTAL_RENT_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_UPLIFT',
            name: 'RENT_UPLIFT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BASE_RENT',
            name: 'BASE_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PERCENTAGE_RENT_Y_N',
            name: 'PERCENTAGE_RENT_Y_N',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PERCENTAGE_RENT',
            name: 'PERCENTAGE_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'TOTAL_RENT',
            name: 'TOTAL_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'TOTAL_RENT_M2',
            name: 'TOTAL_RENT_M2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_AMOUNT_1',
            name: 'RENT_FREE_AMOUNT_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_1_START_DATE',
            name: 'RENT_FREE_1_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_1_END_DATE',
            name: 'RENT_FREE_1_END_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_1_PERIOD',
            name: 'RENT_FREE_1_PERIOD',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_AMOUNT_2',
            name: 'RENT_FREE_AMOUNT_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_2_START_DATE',
            name: 'RENT_FREE_2_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_2_END_DATE',
            name: 'RENT_FREE_2_END_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_2_PERIOD',
            name: 'RENT_FREE_2_PERIOD',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_AMOUNT_3',
            name: 'RENT_FREE_AMOUNT_3',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_3_START_DATE',
            name: 'RENT_FREE_3_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_3_END_DATE',
            name: 'RENT_FREE_3_END_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_3_PERIOD',
            name: 'RENT_FREE_3_PERIOD',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ABATEMENT_AMOUNT_TOTAL',
            name: 'ABATEMENT_AMOUNT_TOTAL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ABATEMENT_MONTHS_TOTAL',
            name: 'ABATEMENT_MONTHS_TOTAL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ABATEMENT_AMOUNT_LEFT',
            name: 'ABATEMENT_AMOUNT_LEFT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ABATEMENT_MONTHS_LEFT',
            name: 'ABATEMENT_MONTHS_LEFT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_NB_MONTH',
            name: 'RENT_FREE_NB_MONTH',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_LEFT_NB_MONTH',
            name: 'RENT_FREE_LEFT_NB_MONTH',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_TOTAL_AMOUNT',
            name: 'RENT_FREE_TOTAL_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_LEFT_AMOUNT',
            name: 'RENT_FREE_LEFT_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_FREE_END_DATE',
            name: 'RENT_FREE_END_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEPPED_RENT',
            name: 'STEPPED_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEPPED_RENT_1',
            name: 'STEPPED_RENT_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEP_1_START_DATE',
            name: 'STEP_1_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_STEP_RENT_1_OF_GHL_RENT',
            name: 'PC_STEP_RENT_1_OF_GHL_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEPPED_RENT_2',
            name: 'STEPPED_RENT_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEP_2_START_DATE',
            name: 'STEP_2_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_STEP_RENT_2_OF_GHL_RENT',
            name: 'PC_STEP_RENT_2_OF_GHL_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEPPED_RENT_3',
            name: 'STEPPED_RENT_3',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEP_3_START_DATE',
            name: 'STEP_3_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_STEP_RENT_3_OF_GHL_RENT',
            name: 'PC_STEP_RENT_3_OF_GHL_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEPPED_RENT_4',
            name: 'STEPPED_RENT_4',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEP_4_START_DATE',
            name: 'STEP_4_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_STEP_RENT_4_OF_GHL_RENT',
            name: 'PC_STEP_RENT_4_OF_GHL_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEPPED_RENT_5',
            name: 'STEPPED_RENT_5',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEP_5_START_DATE',
            name: 'STEP_5_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_STEP_RENT_5_OF_GHL_RENT',
            name: 'PC_STEP_RENT_5_OF_GHL_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEPPED_RENT_6',
            name: 'STEPPED_RENT_6',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'STEP_6_START_DATE',
            name: 'STEP_6_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_STEP_RENT_6_OF_GHL_RENT',
            name: 'PC_STEP_RENT_6_OF_GHL_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ADDITIONAL_RENT',
            name: 'ADDITIONAL_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ADDITIONAL_RENT_START_DATE',
            name: 'ADDITIONAL_RENT_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ADDITIONAL_RENT_END_DATE',
            name: 'ADDITIONAL_RENT_END_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ADDITIONAL_RENT_PERIOD',
            name: 'ADDITIONAL_RENT_PERIOD',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ADDITIONAL_RENT_INDEXATION_MODE',
            name: 'ADDITIONAL_RENT_INDEXATION_MODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'EFFECTIVE_RENT',
            name: 'EFFECTIVE_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ECONOMIC_RENT',
            name: 'ECONOMIC_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ECONOMIC_RENT_PER_SQM',
            name: 'ECONOMIC_RENT_PER_SQM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ECONOMIC_RENT_BY_HEADLINE_RENT',
            name: 'ECONOMIC_RENT_BY_HEADLINE_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INCENTIVE_RATE',
            name: 'INCENTIVE_RATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'EXTRAORDINARY_RENT',
            name: 'EXTRAORDINARY_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'EXTRAORDINARY_RENT_START_DATE',
            name: 'EXTRAORDINARY_RENT_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'EXTRAORDINARY_RENT_END_DATE',
            name: 'EXTRAORDINARY_RENT_END_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'EXTRAORDINARY_RENT_PERIOD',
            name: 'EXTRAORDINARY_RENT_PERIOD',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'IS_RENT_CATCH_UP',
            name: 'IS_RENT_CATCH_UP',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CATCH_UP_AMOUNT',
            name: 'CATCH_UP_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CATCH_UP_START_DATE',
            name: 'CATCH_UP_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CATCH_UP_END_DATE',
            name: 'CATCH_UP_END_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CATCH_UP_PERIOD',
            name: 'CATCH_UP_PERIOD',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_RECEIVABLE_YEAR_TO_DATE',
            name: 'RENT_RECEIVABLE_YEAR_TO_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_RECEIVABLE_CUR_BIL_TERM',
            name: 'RENT_RECEIVABLE_CUR_BIL_TERM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'TAX_EXEMPTION',
            name: 'TAX_EXEMPTION',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SERVICE_CHARGES',
            name: 'SERVICE_CHARGES',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SERVICE_CHARGES_M2',
            name: 'SERVICE_CHARGES_M2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'IS_SERVICES_CHARGES_CAPPED',
            name: 'IS_SERVICES_CHARGES_CAPPED',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SERVICE_CHARGES_CAPS',
            name: 'SERVICE_CHARGES_CAPS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SERVICE_CHARGES_CAPS_M2',
            name: 'SERVICE_CHARGES_CAPS_M2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'IS_OPERATING_EXPENSES_REBILLED',
            name: 'IS_OPERATING_EXPENSES_REBILLED',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'AMOUNT_OF_RECOVERABLE_WORKS',
            name: 'AMOUNT_OF_RECOVERABLE_WORKS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MAJOR_REFURBISHMENT_AMOUNT',
            name: 'MAJOR_REFURBISHMENT_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PROPERTY_TAX_MANAGEMENT_FEES',
            name: 'PROPERTY_TAX_MANAGEMENT_FEES',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_PROPERTY_TAX_MGMT_FEES',
            name: 'PC_PROPERTY_TAX_MGMT_FEES',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MGMT_FEES_OF_GARB_COLLECT_TAX',
            name: 'MGMT_FEES_OF_GARB_COLLECT_TAX',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_MGMT_FEES_GARB_COLLECT_TAX',
            name: 'PC_MGMT_FEES_GARB_COLLECT_TAX',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_DEPOSIT_VALUE',
            name: 'INITIAL_DEPOSIT_VALUE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MAIN_DEPOSIT_VALUE',
            name: 'MAIN_DEPOSIT_VALUE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BANK_GUARANTEE_AMOUNT',
            name: 'BANK_GUARANTEE_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BANK_GUARANTEE_START_DATE',
            name: 'BANK_GUARANTEE_START_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BANK_GUARANTEE_END_DATE',
            name: 'BANK_GUARANTEE_END_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_TYPE',
            name: 'GUARANTEE_TYPE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_GUARANTEE_1',
            name: 'INITIAL_GUARANTEE_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CURRENT_GUARANTEE_1',
            name: 'CURRENT_GUARANTEE_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_TYPE_1',
            name: 'GUARANTEE_TYPE_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_BANK_1',
            name: 'GUARANTEE_BANK_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_START_DATE_1',
            name: 'GUARANTEE_START_DATE_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_END_DATE_1',
            name: 'GUARANTEE_END_DATE_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_GUARANTEE_2',
            name: 'INITIAL_GUARANTEE_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CURRENT_GUARANTEE_2',
            name: 'CURRENT_GUARANTEE_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_TYPE_2',
            name: 'GUARANTEE_TYPE_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_BANK_2',
            name: 'GUARANTEE_BANK_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_START_DATE_2',
            name: 'GUARANTEE_START_DATE_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_END_DATE_2',
            name: 'GUARANTEE_END_DATE_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_GUARANTEE_3',
            name: 'INITIAL_GUARANTEE_3',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CURRENT_GUARANTEE_3',
            name: 'CURRENT_GUARANTEE_3',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_TYPE_3',
            name: 'GUARANTEE_TYPE_3',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_BANK_3',
            name: 'GUARANTEE_BANK_3',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_START_DATE_3',
            name: 'GUARANTEE_START_DATE_3',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GUARANTEE_END_DATE_3',
            name: 'GUARANTEE_END_DATE_3',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_EQUIPMENT_GUARANTEE',
            name: 'INITIAL_EQUIPMENT_GUARANTEE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CURRENT_EQUIPMENT_GUARANTEE',
            name: 'CURRENT_EQUIPMENT_GUARANTEE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INITIAL_WORKING_CAPITAL',
            name: 'INITIAL_WORKING_CAPITAL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CURRENT_WORKING_CAPITAL',
            name: 'CURRENT_WORKING_CAPITAL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ENTRANCE_FEES',
            name: 'ENTRANCE_FEES',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_RECOVERABLE_PROPERTY_TAX',
            name: 'PC_RECOVERABLE_PROPERTY_TAX',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_RECOVERABLE_OFFICE_TAX',
            name: 'PC_RECOVERABLE_OFFICE_TAX',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_RECOVERABLE_INSURANCE',
            name: 'PC_RECOVERABLE_INSURANCE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_RECOV_PROP_MANAGT_FEES_TEN',
            name: 'PC_RECOV_PROP_MANAGT_FEES_TEN',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_RECOV_PROP_MANAGT_FEES_ADM',
            name: 'PC_RECOV_PROP_MANAGT_FEES_ADM',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_RECOVERABLE_606',
            name: 'PC_RECOVERABLE_606',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PC_RECOVERABLE_REFURB_EXCL_606',
            name: 'PC_RECOVERABLE_REFURB_EXCL_606',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RECOVERABLE_GARB_COLLECT_TAX',
            name: 'RECOVERABLE_GARB_COLLECT_TAX',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RECOVERABLE_MARKETING_FEES',
            name: 'RECOVERABLE_MARKETING_FEES',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PROPERTY_TAX_AMOUNT',
            name: 'PROPERTY_TAX_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'GARBAGE_COLLECTION_TAX_AMOUNT',
            name: 'GARBAGE_COLLECTION_TAX_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OFFICE_TAX_AMOUNT',
            name: 'OFFICE_TAX_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INSURANCE_PREMIMS_AMOUNT',
            name: 'INSURANCE_PREMIMS_AMOUNT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BREAKPOINT_AMOUNT_1',
            name: 'BREAKPOINT_AMOUNT_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BREAKPOINT_RATE_1',
            name: 'BREAKPOINT_RATE_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BREAKPOINT_AMOUNT_2',
            name: 'BREAKPOINT_AMOUNT_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'BREAKPOINT_RATE_2',
            name: 'BREAKPOINT_RATE_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ANNUAL_SALES_CERT_Y_MINUS_1',
            name: 'ANNUAL_SALES_CERT_Y_MINUS_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ANNUAL_SALES_CERT_Y_MINUS_2',
            name: 'ANNUAL_SALES_CERT_Y_MINUS_2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'YOY_ANNUAL_SALES_GROWTH',
            name: 'YOY_ANNUAL_SALES_GROWTH',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SPACE_PRODUCTIVITY',
            name: 'SPACE_PRODUCTIVITY',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'RENT_TO_SALES_RATIO',
            name: 'RENT_TO_SALES_RATIO',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'OCCUPANCY_COST_RATIO',
            name: 'OCCUPANCY_COST_RATIO',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MONTHLY_SALES_VOLUME',
            name: 'MONTHLY_SALES_VOLUME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MONTHLY_SALES_VOLUME_M_MINUS_1',
            name: 'MONTHLY_SALES_VOLUME_M_MINUS_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MONTH_ON_MONTH_SALES_GROWTH',
            name: 'MONTH_ON_MONTH_SALES_GROWTH',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MONTHLY_SALES_VOLUME_Y_MINUS_1',
            name: 'MONTHLY_SALES_VOLUME_Y_MINUS_1',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'YOY_MONTHLY_SALES_GROWTH',
            name: 'YOY_MONTHLY_SALES_GROWTH',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'YEAR_TO_DATE_SALES_VOLUME',
            name: 'YEAR_TO_DATE_SALES_VOLUME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MONTH_ROLLING_SALES_VOLUME',
            name: 'MONTH_ROLLING_SALES_VOLUME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'ON_LITIGATION',
            name: 'ON_LITIGATION',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PREVIOUS_TENANT_CODE',
            name: 'PREVIOUS_TENANT_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PREVIOUS_TENANT_NAME',
            name: 'PREVIOUS_TENANT_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PREVIOUS_TENANT_MOVE_OUT_DATE',
            name: 'PREVIOUS_TENANT_MOVE_OUT_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PREVIOUS_LEASE_HEADLINE_RENT',
            name: 'PREVIOUS_LEASE_HEADLINE_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'PREVIOUS_LEASE_HEADLINE_RENT_M2',
            name: 'PREVIOUS_LEASE_HEADLINE_RENT_M2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NEXT_TENANT_CODE',
            name: 'NEXT_TENANT_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NEXT_TENANT_NAME',
            name: 'NEXT_TENANT_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NEXT_TENANT_MOVE_IN_DATE',
            name: 'NEXT_TENANT_MOVE_IN_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NEXT_LEASE_HEADLINE_RENT',
            name: 'NEXT_LEASE_HEADLINE_RENT',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'NEXT_LEASE_HEADLINE_RENT_M2',
            name: 'NEXT_LEASE_HEADLINE_RENT_M2',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'LEASE_COMMENTS',
            name: 'LEASE_COMMENTS',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'EXTERNAL_BUILDING_NATURE_LABEL',
            name: 'EXTERNAL_BUILDING_NATURE_LABEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CUSTOMER_GROUP_CODE',
            name: 'CUSTOMER_GROUP_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CUSTOMER_GROUP_NAME',
            name: 'CUSTOMER_GROUP_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CONTACT_NAME',
            name: 'CONTACT_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CONTACT_FIRSTNAME',
            name: 'CONTACT_FIRSTNAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CONTACT_SERVICE',
            name: 'CONTACT_SERVICE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'CONTACT_FUNCTION',
            name: 'CONTACT_FUNCTION',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'MANAGER_NAME',
            name: 'MANAGER_NAME',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'FEE_CENTER_CODE',
            name: 'FEE_CENTER_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SITE_CODE',
            name: 'SITE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'HOUSING_COMPLEX',
            name: 'HOUSING_COMPLEX',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'SERVICE_CODE',
            name: 'SERVICE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INSURANCE_BEGIN_DATE',
            name: 'INSURANCE_BEGIN_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INSURANCE_END_DATE',
            name: 'INSURANCE_END_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INSURANCE_PERIOD',
            name: 'INSURANCE_PERIOD',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INSURANCE_DUNNING_DATE',
            name: 'INSURANCE_DUNNING_DATE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
          {
            data: 'INSURANCE_DUNNING_LEVEL',
            name: 'INSURANCE_DUNNING_LEVEL',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          },
        ]);
        this.columnsToDisplay.set([
          "LEASE_CODE",
          "TENANT_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "LEGAL_PERSON",
          "HOLDING_COMPANY",
          "TRADE_NAME",
          "SIRET_NUMBER",
          "TRADE_REGISTER_NUMBER",
          "EU_VAT_IDENTIFICATION_NUMBER",
          "TENANT_INDUSTRY",
          "INSURANCE_POLICY_NUMBER",
          "LEASE_NATURE_LABEL",
          "LEASE_TYPE_LABEL",
          "IS_GREEN_LEASE",
          "REPAIR_CLAUSE",
          "REFURBISHMENT_CLAUSE",
          "STATUS_LEASE",
          "NUMBER_OF_LEASE_RENEWALS",
          "AMENDMENT_TYPE",
          "AMENDMENT_DATE",
          "AMENDMENT_DESCRIPTION",
          "IS_LEASE_RENEWED",
          "IS_HOLDOVER_LEASE",
          "INVOICING_FREQUENCY_LABEL",
          "RENTAL_TERM_LABEL",
          "LEASE_SIGNED_DATE",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "NOTICE_PERIOD",
          "LEASE_BREAK_DATE_1",
          "LEASE_BREAK_DATE_2",
          "LEASE_BREAK_DATE_3",
          "LEASE_BREAK_PERIOD_1",
          "LEASE_BREAK_PERIOD_2",
          "LEASE_BREAK_PERIOD_3",
          "NEXT_RISK_DATE",
          "NEXT_LEASE_BREAK_DATE",
          "LEASE_END_DATE",
          "NOTICE_DATE",
          "LANDLORD_NOTICE_DATE",
          "NOTICE_TYPE_LABEL",
          "TENANT_EXIT_DATE",
          "LEASE_DURATION",
          "LEASE_COMMITMENT_TERM",
          "DURATION_TO_BREAK_DATE",
          "DURATION_TO_LEASE_END",
          "REMAINING_TERM_TO_THE_TERMINATION",
          "RENT_REVIEW_FREQUENCY",
          "RENT_REVIEW_LEVERAGE",
          "INDEX_FLOOR",
          "INDEXATION_CAP",
          "INITIAL_INDEX",
          "INITIAL_INDEX_VALUE",
          "INITIAL_INDEX_DATE",
          "CURRENT_INDEX_TYPE_LABEL",
          "CURRENT_INDEX_VALUE",
          "CURRENT_INDEX_DATE",
          "LAST_RENT_REVIEW_DATE",
          "NEXT_INDEX_DATE",
          "NEXT_REVISION_DATE",
          "LEASE_UNITS",
          "LEASE_FLOORS",
          "MAIN_UNIT",
          "CONDOMINIUM_UNIT_CODE",
          "MAIN_UNIT_TYPE_LABEL",
          "NUMBER_OF_ROOMS",
          "MAIN_UNIT_BUILDING",
          "MAIN_UNIT_STAIRCASE",
          "MAIN_UNIT_FLOOR",
          "DOOR_NUMBER",
          "RENTED_AREA",
          "VACANT_AREA",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "OFFICE_AREA",
          "RESIDENTIAL_AREA",
          "RETAIL_AREA",
          "WORKSHOP_AREA",
          "ARCHIVE_AREA",
          "STORAGE_AREA",
          "INDUSTRIAL_AREA",
          "MISCELLANEOUS_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "COMMON_AREA_SHARE",
          "THOUSANDS",
          "NUMBER_OF_SIMPLE_CAR_PARKS",
          "NUMBER_OF_DOUBLE_CAR_PARKS",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "INITIAL_ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_ANNUAL_RENT_AMOUNT",
          "ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "PERCENT_TOTAL_RENT_AMOUNT",
          "RENT_UPLIFT",
          "BASE_RENT",
          "PERCENTAGE_RENT_Y_N",
          "PERCENTAGE_RENT",
          "TOTAL_RENT",
          "TOTAL_RENT_M2",
          "RENT_FREE_AMOUNT_1",
          "RENT_FREE_1_START_DATE",
          "RENT_FREE_1_END_DATE",
          "RENT_FREE_1_PERIOD",
          "RENT_FREE_AMOUNT_2",
          "RENT_FREE_2_START_DATE",
          "RENT_FREE_2_END_DATE",
          "RENT_FREE_2_PERIOD",
          "RENT_FREE_AMOUNT_3",
          "RENT_FREE_3_START_DATE",
          "RENT_FREE_3_END_DATE",
          "RENT_FREE_3_PERIOD",
          "RENT_FREE_NB_MONTH",
          "RENT_FREE_LEFT_NB_MONTH",
          "RENT_FREE_TOTAL_AMOUNT",
          "RENT_FREE_LEFT_AMOUNT",
          "RENT_FREE_END_DATE",
          "ABATEMENT_AMOUNT_TOTAL",
          "ABATEMENT_MONTHS_TOTAL",
          "ABATEMENT_AMOUNT_LEFT",
          "ABATEMENT_MONTHS_LEFT",
          "STEPPED_RENT",
          "STEPPED_RENT_1",
          "STEP_1_START_DATE",
          "PC_STEP_RENT_1_OF_GHL_RENT",
          "STEPPED_RENT_2",
          "STEP_2_START_DATE",
          "PC_STEP_RENT_2_OF_GHL_RENT",
          "STEPPED_RENT_3",
          "STEP_3_START_DATE",
          "PC_STEP_RENT_3_OF_GHL_RENT",
          "STEPPED_RENT_4",
          "STEP_4_START_DATE",
          "PC_STEP_RENT_4_OF_GHL_RENT",
          "STEPPED_RENT_5",
          "STEP_5_START_DATE",
          "PC_STEP_RENT_5_OF_GHL_RENT",
          "STEPPED_RENT_6",
          "STEP_6_START_DATE",
          "PC_STEP_RENT_6_OF_GHL_RENT",
          "ADDITIONAL_RENT",
          "ADDITIONAL_RENT_START_DATE",
          "ADDITIONAL_RENT_END_DATE",
          "ADDITIONAL_RENT_PERIOD",
          "ADDITIONAL_RENT_INDEXATION_MODE",
          "EFFECTIVE_RENT",
          "ECONOMIC_RENT",
          "ECONOMIC_RENT_PER_SQM",
          "ECONOMIC_RENT_BY_HEADLINE_RENT",
          "INCENTIVE_RATE",
          "EXTRAORDINARY_RENT",
          "EXTRAORDINARY_RENT_START_DATE",
          "EXTRAORDINARY_RENT_END_DATE",
          "EXTRAORDINARY_RENT_PERIOD",
          "IS_RENT_CATCH_UP",
          "CATCH_UP_AMOUNT",
          "CATCH_UP_START_DATE",
          "CATCH_UP_END_DATE",
          "CATCH_UP_PERIOD",
          "RENT_RECEIVABLE_YEAR_TO_DATE",
          "RENT_RECEIVABLE_CUL_BIL_TERM",
          "TAX_EXEMPTION",
          "SERVICE_CHARGES",
          "SERVICE_CHARGES_M2",
          "IS_SERVICES_CHARGES_CAPPED",
          "SERVICE_CHARGES_CAPS",
          "SERVICE_CHARGES_CAPS_M2",
          "IS_OPERATING_EXPENSES_REBILLED",
          "PC_RECOVERABLE_PROPERTY_TAX",
          "RECOVERABLE_GARB_COLLECT_TAX",
          "PC_RECOVERABLE_OFFICE_TAX",
          "PC_RECOVERABLE_INSURANCE",
          "PC_RECOV_PROP_MANAGT_FEES_TEN",
          "PC_RECOV_PROP_MANAGT_FEES_ADM",
          "PC_RECOVERABLE_606",
          "PC_RECOVERABLE_REFURB_EXCL_606",
          "RECOVERABLE_MARKETING_FEES",
          "PROPERTY_TAX_AMOUNT",
          "GARBAGE_COLLECTION_TAX_AMOUNT",
          "MAJOR_REFURBISHMENT_AMOUNT",
          "OFFICE_TAX_AMOUNT",
          "INSURANCE_PREMIMS_AMOUNT",
          "AMOUNT_OF_RECOVERABLE_WORKS",
          "PROPERTY_TAX_MANAGEMENT_FEES",
          "PC_PROPERTY_TAX_MGMT_FEES",
          "MGMT_FEES_OF_GARB_COLLECT_TAX",
          "PC_MGMT_FEES_GARB_COLLECT_TAX",
          "INITIAL_DEPOSIT_VALUE",
          "MAIN_DEPOSIT_VALUE",
          "BANK_GUARANTEE_AMOUNT",
          "BANK_GUARANTEE_START_DATE",
          "BANK_GUARANTEE_END_DATE",
          "GUARANTEE_TYPE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "BREAKPOINT_AMOUNT_1",
          "ENTRANCE_FEES",
          "BREAKPOINT_RATE_1",
          "BREAKPOINT_AMOUNT_2",
          "BREAKPOINT_RATE_2",
          "ANNUAL_SALES_CERT_Y_MINUS_1",
          "ANNUAL_SALES_CERT_Y_MINUS_2",
          "YOY_ANNUAL_SALES_GROWTH",
          "SPACE_PRODUCTIVITY",
          "RENT_TO_SALES_RATIO",
          "OCCUPANCY_COST_RATIO",
          "MONTHLY_SALES_VOLUME",
          "MONTHLY_SALES_VOLUME_M_MINUS_1",
          "MONTH_ON_MONTH_SALES_GROWTH",
          "MONTHLY_SALES_VOLUME_Y_MINUS_1",
          "YOY_MONTHLY_SALES_GROWTH",
          "YEAR_TO_DATE_SALES_VOLUME",
          "MONTH_ROLLING_SALES_VOLUME",
          "ON_LITIGATION",
          "PREVIOUS_TENANT_CODE",
          "PREVIOUS_TENANT_NAME",
          "PREVIOUS_TENANT_MOVE_OUT_DATE",
          "PREVIOUS_LEASE_HEADLINE_RENT",
          "PREVIOUS_LEASE_HEADLINE_RENT_M2",
          "NEXT_TENANT_CODE",
          "NEXT_TENANT_NAME",
          "NEXT_TENANT_MOVE_IN_DATE",
          "NEXT_LEASE_HEADLINE_RENT",
          "NEXT_LEASE_HEADLINE_RENT_M2",
          "LEASE_COMMENTS",
          "INSURANCE_BEGIN_DATE",
          "INSURANCE_END_DATE",
          "INSURANCE_PERIOD",
          "INSURANCE_DUNNING_DATE",
          "INSURANCE_DUNNING_LEVEL"
        ]);
        this.stickyLeftColumnsCount = 3;
        this.stickyLeftColumns = [
          "LEASE_CODE",
          "TENANT_CODE",
          "TENANT_NAME"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "TS_MIXED": {
        this.reportController.set("TenancyScheduleMixed");
        this.ReportName = this.appService.getTranslation("lblTSMixedReport");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "LEASE_CODE",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "INSURANCE_POLICY_NUMBER",
          "TENANT_CODE",
          "TENANT_NAME",
          "LEGAL_PERSON",
          "HOLDING_COMPANY",
          "TRADE_NAME",
          "SIRET_NUMBER",
          "TRADE_REGISTER_NUMBER",
          "EU_VAT_IDENTIFICATION_NUMBER",
          "TENANT_INDUSTRY",
          "LEASE_NATURE_LABEL",
          "LEASE_TYPE_LABEL",
          "IS_GREEN_LEASE",
          "REPAIR_CLAUSE",
          "REFURBISHMENT_CLAUSE",
          "STATUS_LEASE",
          "NUMBER_OF_LEASE_RENEWALS",
          "AMENDMENT_TYPE",
          "AMENDMENT_DATE",
          "AMENDMENT_DESCRIPTION",
          "IS_LEASE_RENEWED",
          "IS_HOLDOVER_LEASE",
          "INVOICING_FREQUENCY_LABEL",
          "RENTAL_TERM_LABEL",
          "LEASE_SIGNED_DATE",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "NOTICE_PERIOD",
          "LEASE_BREAK_DATE_1",
          "LEASE_BREAK_DATE_2",
          "LEASE_BREAK_DATE_3",
          "LEASE_BREAK_PERIOD_1",
          "LEASE_BREAK_PERIOD_2",
          "LEASE_BREAK_PERIOD_3",
          "NEXT_RISK_DATE",
          "NEXT_LEASE_BREAK_DATE",
          "LEASE_END_DATE",
          "NOTICE_DATE",
          "LANDLORD_NOTICE_DATE",
          "NOTICE_TYPE_LABEL",
          "TENANT_EXIT_DATE",
          "LEASE_DURATION",
          "LEASE_COMMITMENT_TERM",
          "DURATION_TO_BREAK_DATE",
          "DURATION_TO_LEASE_END",
          "REMAINING_TERM_TO_THE_LEASE_TERMINATION",
          "VACANCY_START_DATE",
          "RENT_REVIEW_FREQUENCY",
          "RENT_REVIEW_LEVERAGE",
          "INDEX_FLOOR",
          "INDEXATION_CAP",
          "INITIAL_INDEX",
          "INITIAL_INDEX_VALUE",
          "INITIAL_INDEX_DATE",
          "CURRENT_INDEX_TYPE_LABEL",
          "CURRENT_INDEX_VALUE",
          "CURRENT_INDEX_DATE",
          "LAST_RENT_REVIEW_DATE",
          "NEXT_INDEX_DATE",
          "NEXT_REVISION_DATE",
          "LEASE_UNITS",
          "LEASE_FLOORS",
          "MAIN_UNIT",
          "CONDOMINIUM_UNIT_CODE",
          "MAIN_UNIT_TYPE_LABEL",
          "NUMBER_OF_ROOMS",
          "MAIN_UNIT_BUILDING",
          "MAIN_UNIT_STAIRCASE",
          "MAIN_UNIT_FLOOR",
          "DOOR_NUMBER",
          "RENTED_AREA",
          "VACANT_AREA",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "OFFICE_AREA",
          "RESIDENTIAL_AREA",
          "RETAIL_AREA",
          "WORKSHOP_AREA",
          "ARCHIVE_AREA",
          "STORAGE_AREA",
          "INDUSTRIAL_AREA",
          "MISCELLANEOUS_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "PRIVATE_UNIT_AREA",
          "COMMON_AREA_SHARE",
          "THOUSANDS",
          "NUMBER_OF_SIMPLE_CAR_PARKS",
          "NUMBER_OF_DOUBLE_CAR_PARKS",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "INITIAL_ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_ANNUAL_RENT_AMOUNT",
          "ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "PERCENT_TOTAL_RENT_AMOUNT",
          "RENT_UPLIFT",
          "BASE_RENT",
          "PERCENTAGE_RENT_Y_N",
          "PERCENTAGE_RENT",
          "TOTAL_RENT",
          "TOTAL_RENT_M2",
          "CURRENT_MARKET_VALUE",
          "RENT_FREE_AMOUNT_1",
          "RENT_FREE_1_START_DATE",
          "RENT_FREE_1_END_DATE",
          "RENT_FREE_1_PERIOD",
          "RENT_FREE_AMOUNT_2",
          "RENT_FREE_2_START_DATE",
          "RENT_FREE_2_END_DATE",
          "RENT_FREE_2_PERIOD",
          "RENT_FREE_AMOUNT_3",
          "RENT_FREE_3_START_DATE",
          "RENT_FREE_3_END_DATE",
          "RENT_FREE_3_PERIOD",
          "RENT_FREE_NB_MONTH",
          "RENT_FREE_LEFT_NB_MONTH",
          "RENT_FREE_TOTAL_AMOUNT",
          "RENT_FREE_LEFT_AMOUNT",
          "RENT_FREE_END_DATE",
          "ABATEMENT_AMOUNT_TOTAL",
          "ABATEMENT_MONTHS_TOTAL",
          "ABATEMENT_AMOUNT_LEFT",
          "ABATEMENT_MONTHS_LEFT",
          "STEPPED_RENT",
          "STEPPED_RENT_1",
          "STEP_1_START_DATE",
          "PC_STEP_RENT_1_OF_GHL_RENT",
          "STEPPED_RENT_2",
          "STEP_2_START_DATE",
          "PC_STEP_RENT_2_OF_GHL_RENT",
          "STEPPED_RENT_3",
          "STEP_3_START_DATE",
          "PC_STEP_RENT_3_OF_GHL_RENT",
          "STEPPED_RENT_4",
          "STEP_4_START_DATE",
          "PC_STEP_RENT_4_OF_GHL_RENT",
          "STEPPED_RENT_5",
          "STEP_5_START_DATE",
          "PC_STEP_RENT_5_OF_GHL_RENT",
          "STEPPED_RENT_6",
          "STEP_6_START_DATE",
          "PC_STEP_RENT_6_OF_GHL_RENT",
          "ADDITIONAL_RENT",
          "ADDITIONAL_RENT_START_DATE",
          "ADDITIONAL_RENT_END_DATE",
          "ADDITIONAL_RENT_PERIOD",
          "ADDITIONAL_RENT_INDEXATION_MODE",
          "EFFECTIVE_RENT",
          "ECONOMIC_RENT",
          "ECONOMIC_RENT_PER_SQM",
          "ECONOMIC_RENT_BY_HEADLINE_RENT",
          "INCENTIVE_RATE",
          "EXTRAORDINARY_RENT",
          "EXTRAORDINARY_RENT_START_DATE",
          "EXTRAORDINARY_RENT_END_DATE",
          "EXTRAORDINARY_RENT_PERIOD",
          "IS_RENT_CATCH_UP",
          "CATCH_UP_AMOUNT",
          "CATCH_UP_START_DATE",
          "CATCH_UP_END_DATE",
          "CATCH_UP_PERIOD",
          "RENT_RECEIVABLE_YEAR_TO_DATE",
          "RENT_RECEIVABLE_CUR_BIL_TERM",
          "TAX_EXEMPTION",
          "SERVICE_CHARGES",
          "SERVICE_CHARGES_M2",
          "IS_SERVICES_CHARGES_CAPPED",
          "SERVICE_CHARGES_CAPS",
          "SERVICE_CHARGES_CAPS_M2",
          "IS_OPERATING_EXPENSES_REBILLED",
          "PC_RECOVERABLE_PROPERTY_TAX",
          "RECOVERABLE_GARB_COLLECT_TAX",
          "PC_RECOVERABLE_OFFICE_TAX",
          "PC_RECOVERABLE_INSURANCE",
          "PC_RECOV_PROP_MANAGT_FEES_TEN",
          "PC_RECOV_PROP_MANAGT_FEES_ADM",
          "PC_RECOVERABLE_606",
          "PC_RECOVERABLE_REFURB_EXCL_606",
          "RECOVERABLE_MARKETING_FEES",
          "PROPERTY_TAX_AMOUNT",
          "GARBAGE_COLLECTION_TAX_AMOUNT",
          "OFFICE_TAX_AMOUNT",
          "INSURANCE_PREMIMS_AMOUNT",
          "AMOUNT_OF_RECOVERABLE_WORKS",
          "MAJOR_REFURBISHMENT_AMOUNT",
          "PROPERTY_TAX_MANAGEMENT_FEES",
          "PC_PROPERTY_TAX_MGMT_FEES",
          "MGMT_FEES_OF_GARB_COLLECT_TAX",
          "PC_MGMT_FEES_GARB_COLLECT_TAX",
          "INITIAL_DEPOSIT_VALUE",
          "MAIN_DEPOSIT_VALUE",
          "BANK_GUARANTEE_AMOUNT",
          "BANK_GUARANTEE_START_DATE",
          "BANK_GUARANTEE_END_DATE",
          "GUARANTEE_TYPE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "ENTRANCE_FEES",
          "BREAKPOINT_AMOUNT_1",
          "BREAKPOINT_RATE_1",
          "BREAKPOINT_AMOUNT_2",
          "BREAKPOINT_RATE_2",
          "ANNUAL_SALES_CERT_Y_MINUS_1",
          "ANNUAL_SALES_CERT_Y_MINUS_2",
          "YOY_ANNUAL_SALES_GROWTH",
          "SPACE_PRODUCTIVITY",
          "RENT_TO_SALES_RATIO",
          "OCCUPANCY_COST_RATIO",
          "MONTHLY_SALES_VOLUME",
          "MONTHLY_SALES_VOLUME_M_MINUS_1",
          "MONTH_ON_MONTH_SALES_GROWTH",
          "MONTHLY_SALES_VOLUME_Y_MINUS_1",
          "YOY_MONTHLY_SALES_GROWTH",
          "YEAR_TO_DATE_SALES_VOLUME",
          "MONTH_ROLLING_SALES_VOLUME",
          "ON_LITIGATION",
          "PREVIOUS_TENANT_CODE",
          "PREVIOUS_TENANT_NAME",
          "PREVIOUS_TENANT_MOVE_OUT_DATE",
          "PREVIOUS_LEASE_HEADLINE_RENT",
          "PREVIOUS_LEASE_HEADLINE_RENT_M2",
          "NEXT_TENANT_CODE",
          "NEXT_TENANT_NAME",
          "NEXT_TENANT_MOVE_IN_DATE",
          "NEXT_LEASE_HEADLINE_RENT",
          "NEXT_LEASE_HEADLINE_RENT_M2",
          "LEASE_COMMENTS",
          "INSURANCE_BEGIN_DATE",
          "INSURANCE_END_DATE",
          "INSURANCE_PERIOD",
          "INSURANCE_DUNNING_DATE",
          "INSURANCE_DUNNING_LEVEL"
        ]);
        this.stickyLeftColumnsCount = 3;
        this.stickyLeftColumns = [
          "BUILDING_CODE",
          "LEASE_CODE"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "TS_UNITTYPE": {
        this.reportController.set("TenancyScheduleUnitType");
        this.ReportName = this.appService.getTranslation("lblTSUnitTypeReport");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "LEASE_CODE",
          "UNIT_TYPE_LABEL",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TENANT_CODE",
          "TENANT_NAME",
          "LEGAL_PERSON",
          "HOLDING_COMPANY",
          "TRADE_NAME",
          "SIRET_NUMBER",
          "TRADE_REGISTER_NUMBER",
          "EU_VAT_IDENTIFICATION_NUMBER",
          "TENANT_INDUSTRY",
          "INSURANCE_POLICY_NUMBER",
          "LEASE_NATURE_LABEL",
          "LEASE_TYPE_LABEL",
          "IS_GREEN_LEASE",
          "REPAIR_CLAUSE",
          "REFURBISHMENT_CLAUSE",
          "STATUS_LEASE",
          "NUMBER_OF_LEASE_RENEWALS",
          "AMENDMENT_TYPE",
          "AMENDMENT_DATE",
          "AMENDMENT_DESCRIPTION",
          "IS_LEASE_RENEWED",
          "IS_HOLDOVER_LEASE",
          "INVOICING_FREQUENCY_LABEL",
          "RENTAL_TERM_LABEL",
          "LEASE_SIGNED_DATE",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "NOTICE_PERIOD",
          "LEASE_BREAK_DATE_1",
          "LEASE_BREAK_DATE_2",
          "LEASE_BREAK_DATE_3",
          "LEASE_BREAK_PERIOD_1",
          "LEASE_BREAK_PERIOD_2",
          "LEASE_BREAK_PERIOD_3",
          "NEXT_RISK_DATE",
          "NEXT_LEASE_BREAK_DATE",
          "LEASE_END_DATE",
          "NOTICE_DATE",
          "LANDLORD_NOTICE_DATE",
          "NOTICE_TYPE_LABEL",
          "TENANT_EXIT_DATE",
          "LEASE_DURATION",
          "LEASE_COMMITMENT_TERM",
          "DURATION_TO_BREAK_DATE",
          "DURATION_TO_LEASE_END",
          "REMAINING_TERM_TO_THE_LEASE_TERMINATION",
          "RENT_REVIEW_FREQUENCY",
          "RENT_REVIEW_LEVERAGE",
          "INDEX_FLOOR",
          "INDEXATION_CAP",
          "INITIAL_INDEX",
          "INITIAL_INDEX_VALUE",
          "INITIAL_INDEX_DATE",
          "CURRENT_INDEX_TYPE_LABEL",
          "CURRENT_INDEX_VALUE",
          "CURRENT_INDEX_DATE",
          "LAST_RENT_REVIEW_DATE",
          "NEXT_INDEX_DATE",
          "NEXT_REVISION_DATE",
          "LEASE_UNITS",
          "LEASE_FLOORS",
          "MAIN_UNIT",
          "CONDOMINIUM_UNIT_CODE",
          "NUMBER_OF_ROOMS",
          "MAIN_UNIT_BUILDING",
          "MAIN_UNIT_STAIRCASE",
          "MAIN_UNIT_FLOOR",
          "DOOR_NUMBER",
          "RENTED_AREA",
          "VACANT_AREA",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "PRIVATE_UNIT_AREA",
          "COMMON_AREA_SHARE",
          "THOUSANDS",
          "NUMBER_OF_SIMPLE_CAR_PARKS",
          "NUMBER_OF_DOUBLE_CAR_PARKS",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "INITIAL_ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_ANNUAL_RENT_AMOUNT",
          "ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "PERCENT_TOTAL_RENT_AMOUNT",
          "RENT_UPLIFT",
          "BASE_RENT",
          "PERCENTAGE_RENT_Y_N",
          "PERCENTAGE_RENT",
          "TOTAL_RENT",
          "TOTAL_RENT_M2",
          "RENT_FREE_AMOUNT_1",
          "RENT_FREE_1_START_DATE",
          "RENT_FREE_1_END_DATE",
          "RENT_FREE_1_PERIOD",
          "RENT_FREE_AMOUNT_2",
          "RENT_FREE_2_START_DATE",
          "RENT_FREE_2_END_DATE",
          "RENT_FREE_2_PERIOD",
          "RENT_FREE_AMOUNT_3",
          "RENT_FREE_3_START_DATE",
          "RENT_FREE_3_END_DATE",
          "RENT_FREE_3_PERIOD",
          "RENT_FREE_NB_MONTH",
          "RENT_FREE_LEFT_NB_MONTH",
          "RENT_FREE_TOTAL_AMOUNT",
          "RENT_FREE_LEFT_AMOUNT",
          "RENT_FREE_END_DATE",
          "ABATEMENT_AMOUNT_TOTAL",
          "ABATEMENT_MONTHS_TOTAL",
          "ABATEMENT_AMOUNT_LEFT",
          "ABATEMENT_MONTHS_LEFT",
          "STEPPED_RENT",
          "STEPPED_RENT_1",
          "STEP_1_START_DATE",
          "PC_STEP_RENT_1_OF_GHL_RENT",
          "STEPPED_RENT_2",
          "STEP_2_START_DATE",
          "PC_STEP_RENT_2_OF_GHL_RENT",
          "STEPPED_RENT_3",
          "STEP_3_START_DATE",
          "PC_STEP_RENT_3_OF_GHL_RENT",
          "STEPPED_RENT_4",
          "STEP_4_START_DATE",
          "PC_STEP_RENT_4_OF_GHL_RENT",
          "STEPPED_RENT_5",
          "STEP_5_START_DATE",
          "PC_STEP_RENT_5_OF_GHL_RENT",
          "STEPPED_RENT_6",
          "STEP_6_START_DATE",
          "PC_STEP_RENT_6_OF_GHL_RENT",
          "ADDITIONAL_RENT",
          "ADDITIONAL_RENT_START_DATE",
          "ADDITIONAL_RENT_END_DATE",
          "ADDITIONAL_RENT_PERIOD",
          "ADDITIONAL_RENT_INDEXATION_MODE",
          "EFFECTIVE_RENT",
          "ECONOMIC_RENT",
          "ECONOMIC_RENT_PER_SQM",
          "ECONOMIC_RENT_BY_HEADLINE_RENT",
          "INCENTIVE_RATE",
          "EXTRAORDINARY_RENT",
          "EXTRAORDINARY_RENT_START_DATE",
          "EXTRAORDINARY_RENT_END_DATE",
          "EXTRAORDINARY_RENT_PERIOD",
          "IS_RENT_CATCH_UP",
          "CATCH_UP_AMOUNT",
          "CATCH_UP_START_DATE",
          "CATCH_UP_END_DATE",
          "CATCH_UP_PERIOD",
          "RENT_RECEIVABLE_YEAR_TO_DATE",
          "RENT_RECEIVABLE_CUR_BIL_TERM",
          "TAX_EXEMPTION",
          "SERVICE_CHARGES",
          "SERVICE_CHARGES_M2",
          "IS_SERVICES_CHARGES_CAPPED",
          "SERVICE_CHARGES_CAPS",
          "SERVICE_CHARGES_CAPS_M2",
          "IS_OPERATING_EXPENSES_REBILLED",
          "PC_RECOVERABLE_PROPERTY_TAX",
          "RECOVERABLE_GARB_COLLECT_TAX",
          "PC_RECOVERABLE_OFFICE_TAX",
          "PC_RECOVERABLE_INSURANCE",
          "PC_RECOV_PROP_MANAGT_FEES_TEN",
          "PC_RECOV_PROP_MANAGT_FEES_ADM",
          "PC_RECOVERABLE_606",
          "PC_RECOVERABLE_REFURB_EXCL_606",
          "RECOVERABLE_MARKETING_FEES",
          "PROPERTY_TAX_AMOUNT",
          "GARBAGE_COLLECTION_TAX_AMOUNT",
          "OFFICE_TAX_AMOUNT",
          "INSURANCE_PREMIMS_AMOUNT",
          "AMOUNT_OF_RECOVERABLE_WORKS",
          "MAJOR_REFURBISHMENT_AMOUNT",
          "PROPERTY_TAX_MANAGEMENT_FEES",
          "PC_PROPERTY_TAX_MGMT_FEES",
          "PC_MGMT_FEES_GARB_COLLECT_TAX",
          "INITIAL_DEPOSIT_VALUE",
          "MAIN_DEPOSIT_VALUE",
          "BANK_GUARANTEE_AMOUNT",
          "BANK_GUARANTEE_START_DATE",
          "BANK_GUARANTEE_END_DATE",
          "GUARANTEE_TYPE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "ENTRANCE_FEES",
          "BREAKPOINT_AMOUNT_1",
          "BREAKPOINT_RATE_1",
          "BREAKPOINT_AMOUNT_2",
          "BREAKPOINT_RATE_2",
          "ANNUAL_SALES_CERT_Y_MINUS_1",
          "ANNUAL_SALES_CERT_Y_MINUS_2",
          "YOY_ANNUAL_SALES_GROWTH",
          "SPACE_PRODUCTIVITY",
          "RENT_TO_SALES_RATIO",
          "OCCUPANCY_COST_RATIO",
          "MONTHLY_SALES_VOLUME",
          "MONTHLY_SALES_VOLUME_M_MINUS_1",
          "MONTH_ON_MONTH_SALES_GROWTH",
          "MONTHLY_SALES_VOLUME_Y_MINUS_1",
          "YOY_MONTHLY_SALES_GROWTH",
          "YEAR_TO_DATE_SALES_VOLUME",
          "MONTH_ROLLING_SALES_VOLUME",
          "ON_LITIGATION",
          "PREVIOUS_TENANT_CODE",
          "PREVIOUS_TENANT_NAME",
          "PREVIOUS_TENANT_MOVE_OUT_DATE",
          "PREVIOUS_LEASE_HEADLINE_RENT",
          "PREVIOUS_LEASE_HEADLINE_RENT_M2",
          "NEXT_TENANT_CODE",
          "NEXT_TENANT_NAME",
          "NEXT_TENANT_MOVE_IN_DATE",
          "NEXT_LEASE_HEADLINE_RENT",
          "NEXT_LEASE_HEADLINE_RENT_M2",
          "LEASE_COMMENTS",
          "INSURANCE_BEGIN_DATE",
          "INSURANCE_END_DATE",
          "INSURANCE_PERIOD",
          "INSURANCE_DUNNING_DATE",
          "INSURANCE_DUNNING_LEVEL"
        ]);
        this.stickyLeftColumnsCount = 3;
        this.stickyLeftColumns = [
          "BUILDING_CODE",
          "LEASE_CODE",
          "UNIT_TYPE_LABEL"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "TS_UNIT": {
        this.reportController.set("TenancyScheduleUnit");
        this.ReportName = this.appService.getTranslation("lblTSUnitReport");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "UNIT_NUMBER",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "MAIN_UNIT",
          "IS_MAIN_UNIT",
          "CONDOMINIUM_UNIT_CODE",
          "UNIT_TYPE_LABEL",
          "NUMBER_OF_ROOMS",
          "BUILDING",
          "STAIRCASE",
          "FLOOR",
          "DOOR_NUMBER",
          "TENANT_CODE",
          "TENANT_NAME",
          "LEGAL_PERSON",
          "HOLDING_COMPANY",
          "TRADE_NAME",
          "SIRET_NUMBER",
          "TRADE_REGISTER_NUMBER",
          "EU_VAT_IDENTIFICATION_NUMBER",
          "TENANT_INDUSTRY",
          "INSURANCE_POLICY_NUMBER",
          "LEASE_CODE",
          "LEASE_NATURE_LABEL",
          "LEASE_TYPE_LABEL",
          "IS_GREEN_LEASE",
          "REPAIR_CLAUSE",
          "REFURBISHMENT_CLAUSE",
          "STATUS_LEASE",
          "NUMBER_OF_LEASE_RENEWALS",
          "AMENDMENT_TYPE",
          "AMENDMENT_DATE",
          "AMENDMENT_DESCRIPTION",
          "IS_LEASE_RENEWED",
          "IS_HOLDOVER_LEASE",
          "INVOICING_FREQUENCY_LABEL",
          "RENTAL_TERM_LABEL",
          "LEASE_SIGNED_DATE",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "NOTICE_PERIOD",
          "LEASE_BREAK_DATE_1",
          "LEASE_BREAK_DATE_2",
          "LEASE_BREAK_DATE_3",
          "LEASE_BREAK_PERIOD_1",
          "LEASE_BREAK_PERIOD_2",
          "LEASE_BREAK_PERIOD_3",
          "NEXT_RISK_DATE",
          "NEXT_LEASE_BREAK_DATE",
          "LEASE_END_DATE",
          "NOTICE_DATE",
          "LANDLORD_NOTICE_DATE",
          "NOTICE_TYPE_LABEL",
          "TENANT_EXIT_DATE",
          "LEASE_DURATION",
          "LEASE_COMMITMENT_TERM",
          "DURATION_TO_BREAK_DATE",
          "DURATION_TO_LEASE_END",
          "REMAINING_TERM_TO_THE_LEASE_TERMINATION",
          "VACANCY_START_DATE",
          "CURRENT_INDEX_TYPE_LABEL",
          "INITIAL_INDEX_VALUE",
          "RENT_REVIEW_FREQUENCY",
          "RENT_REVIEW_LEVERAGE",
          "INDEX_FLOOR",
          "INDEXATION_CAP",
          "INITIAL_INDEX",
          "INITIAL_INDEX_DATE",
          "LAST_RENT_REVIEW_DATE",
          "CURRENT_INDEX_VALUE",
          "CURRENT_INDEX_DATE",
          "NEXT_INDEX_DATE",
          "NEXT_REVISION_DATE",
          "UNIT_AREA_SQM",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "PRIVATE_UNIT_AREA",
          "COMMON_AREA_SHARE",
          "THOUSANDS",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "INITIAL_ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_ANNUAL_RENT_AMOUNT",
          "ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "PERCENT_TOTAL_RENT_AMOUNT",
          "RENT_UPLIFT",
          "BASE_RENT",
          "PERCENTAGE_RENT_Y_N",
          "PERCENTAGE_RENT",
          "TOTAL_RENT",
          "TOTAL_RENT_M2",
          "CURRENT_MARKET_VALUE",
          "RENT_FREE_AMOUNT_1",
          "RENT_FREE_1_START_DATE",
          "RENT_FREE_1_END_DATE",
          "RENT_FREE_1_PERIOD",
          "RENT_FREE_AMOUNT_2",
          "RENT_FREE_2_START_DATE",
          "RENT_FREE_2_END_DATE",
          "RENT_FREE_2_PERIOD",
          "RENT_FREE_AMOUNT_3",
          "RENT_FREE_3_START_DATE",
          "RENT_FREE_3_END_DATE",
          "RENT_FREE_3_PERIOD",
          "RENT_FREE_NB_MONTH",
          "RENT_FREE_LEFT_NB_MONTH",
          "RENT_FREE_TOTAL_AMOUNT",
          "RENT_FREE_LEFT_AMOUNT",
          "RENT_FREE_END_DATE",
          "ABATEMENT_AMOUNT_TOTAL",
          "ABATEMENT_MONTHS_TOTAL",
          "ABATEMENT_AMOUNT_LEFT",
          "ABATEMENT_MONTHS_LEFT",
          "STEPPED_RENT",
          "STEPPED_RENT_1",
          "STEP_1_START_DATE",
          "PC_STEP_RENT_1_OF_GHL_RENT",
          "STEPPED_RENT_2",
          "STEP_2_START_DATE",
          "PC_STEP_RENT_2_OF_GHL_RENT",
          "STEPPED_RENT_3",
          "STEP_3_START_DATE",
          "PC_STEP_RENT_3_OF_GHL_RENT",
          "STEPPED_RENT_4",
          "STEP_4_START_DATE",
          "PC_STEP_RENT_4_OF_GHL_RENT",
          "STEPPED_RENT_5",
          "STEP_5_START_DATE",
          "PC_STEP_RENT_5_OF_GHL_RENT",
          "STEPPED_RENT_6",
          "STEP_6_START_DATE",
          "PC_STEP_RENT_6_OF_GHL_RENT",
          "ADDITIONAL_RENT",
          "ADDITIONAL_RENT_START_DATE",
          "ADDITIONAL_RENT_END_DATE",
          "ADDITIONAL_RENT_PERIOD",
          "ADDITIONAL_RENT_INDEXATION_MODE",
          "EFFECTIVE_RENT",
          "ECONOMIC_RENT",
          "ECONOMIC_RENT_PER_SQM",
          "ECONOMIC_RENT_BY_HEADLINE_RENT",
          "INCENTIVE_RATE",
          "EXTRAORDINARY_RENT",
          "EXTRAORDINARY_RENT_START_DATE",
          "EXTRAORDINARY_RENT_END_DATE",
          "EXTRAORDINARY_RENT_PERIOD",
          "IS_RENT_CATCH_UP",
          "CATCH_UP_AMOUNT",
          "CATCH_UP_START_DATE",
          "CATCH_UP_END_DATE",
          "CATCH_UP_PERIOD",
          "RENT_RECEIVABLE_YEAR_TO_DATE",
          "RENT_RECEIVABLE_CUR_BIL_TERM",
          "TAX_EXEMPTION",
          "SERVICE_CHARGES",
          "SERVICE_CHARGES_M2",
          "IS_SERVICES_CHARGES_CAPPED",
          "SERVICE_CHARGES_CAPS",
          "SERVICE_CHARGES_CAPS_M2",
          "IS_OPERATING_EXPENSES_REBILLED",
          "PC_RECOVERABLE_PROPERTY_TAX",
          "RECOVERABLE_GARB_COLLECT_TAX",
          "PC_RECOVERABLE_OFFICE_TAX",
          "PC_RECOVERABLE_INSURANCE",
          "PC_RECOV_PROP_MANAGT_FEES_TEN",
          "PC_RECOV_PROP_MANAGT_FEES_ADM",
          "PC_RECOVERABLE_606",
          "PC_RECOVERABLE_REFURB_EXCL_606",
          "RECOVERABLE_MARKETING_FEES",
          "PROPERTY_TAX_AMOUNT",
          "GARBAGE_COLLECTION_TAX_AMOUNT",
          "OFFICE_TAX_AMOUNT",
          "INSURANCE_PREMIMS_AMOUNT",
          "AMOUNT_OF_RECOVERABLE_WORKS",
          "MAJOR_REFURBISHMENT_AMOUNT",
          "PROPERTY_TAX_MANAGEMENT_FEES",
          "PC_PROPERTY_TAX_MGMT_FEES",
          "MGMT_FEES_OF_GARB_COLLECT_TAX",
          "PC_MGMT_FEES_GARB_COLLECT_TAX",
          "INITIAL_DEPOSIT_VALUE",
          "MAIN_DEPOSIT_VALUE",
          "BANK_GUARANTEE_AMOUNT",
          "BANK_GUARANTEE_START_DATE",
          "BANK_GUARANTEE_END_DATE",
          "GUARANTEE_TYPE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "ENTRANCE_FEES",
          "BREAKPOINT_AMOUNT_1",
          "BREAKPOINT_RATE_1",
          "BREAKPOINT_AMOUNT_2",
          "BREAKPOINT_RATE_2",
          "ANNUAL_SALES_CERT_Y_MINUS_1",
          "ANNUAL_SALES_CERT_Y_MINUS_2",
          "YOY_ANNUAL_SALES_GROWTH",
          "SPACE_PRODUCTIVITY",
          "RENT_TO_SALES_RATIO",
          "OCCUPANCY_COST_RATIO",
          "MONTHLY_SALES_VOLUME",
          "MONTHLY_SALES_VOLUME_M_MINUS_1",
          "MONTH_ON_MONTH_SALES_GROWTH",
          "MONTHLY_SALES_VOLUME_Y_MINUS_1",
          "YOY_MONTHLY_SALES_GROWTH",
          "YEAR_TO_DATE_SALES_VOLUME",
          "MONTH_ROLLING_SALES_VOLUME",
          "ON_LITIGATION",
          "PREVIOUS_TENANT_CODE",
          "PREVIOUS_TENANT_NAME",
          "PREVIOUS_TENANT_MOVE_OUT_DATE",
          "PREVIOUS_LEASE_HEADLINE_RENT",
          "PREVIOUS_LEASE_HEADLINE_RENT_M2",
          "NEXT_TENANT_CODE",
          "NEXT_TENANT_NAME",
          "NEXT_TENANT_MOVE_IN_DATE",
          "NEXT_LEASE_HEADLINE_RENT",
          "NEXT_LEASE_HEADLINE_RENT_M2",
          "LEASE_COMMENTS",
          "INSURANCE_BEGIN_DATE",
          "INSURANCE_END_DATE",
          "INSURANCE_PERIOD",
          "INSURANCE_DUNNING_DATE",
          "INSURANCE_DUNNING_LEVEL"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "BUILDING_CODE",
          "UNIT_NUMBER"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "TS_UNIT_DETAILED": {
        this.reportController.set("TenancyScheduleUnitDetailed");
        this.ReportName = this.appService.getTranslation("lblTSUnitDetailedReport");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "UNIT_NUMBER",
          "EXTERNAL_FUND_NAME",
          "OWNER_CODE",
          "OWNER_NAME",
          "BUILDING_NAME",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "MAIN_MANAGER_NAME",
          "MAIN_UNIT",
          "IS_MAIN_UNIT",
          "CONDOMINIUM_UNIT_CODE",
          "UNIT_TYPE_LABEL",
          "NUMBER_OF_ROOMS",
          "BUILDING",
          "STAIRCASE",
          "FLOOR",
          "DOOR_NUMBER",
          "TENANT_CODE",
          "TENANT_NAME",
          "LEGAL_PERSON",
          "HOLDING_COMPANY",
          "TRADE_NAME",
          "SIRET_NUMBER",
          "TRADE_REGISTER_NUMBER",
          "EU_VAT_IDENTIFICATION_NUMBER",
          "TENANT_INDUSTRY",
          "INSURANCE_POLICY_NUMBER",
          "LEASE_CODE",
          "LEASE_NATURE_LABEL",
          "LEASE_TYPE_LABEL",
          "IS_GREEN_LEASE",
          "REPAIR_CLAUSE",
          "REFURBISHMENT_CLAUSE",
          "STATUS_LEASE",
          "NUMBER_OF_LEASE_RENEWALS",
          "AMENDMENT_TYPE",
          "AMENDMENT_DATE",
          "AMENDMENT_DESCRIPTION",
          "IS_LEASE_RENEWED",
          "IS_HOLDOVER_LEASE",
          "INVOICING_FREQUENCY_LABEL",
          "RENTAL_TERM_LABEL",
          "LEASE_SIGNED_DATE",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "NOTICE_PERIOD",
          "LEASE_BREAK_DATE_1",
          "LEASE_BREAK_DATE_2",
          "LEASE_BREAK_DATE_3",
          "LEASE_BREAK_PERIOD_1",
          "LEASE_BREAK_PERIOD_2",
          "LEASE_BREAK_PERIOD_3",
          "NEXT_RISK_DATE",
          "NEXT_LEASE_BREAK_DATE",
          "LEASE_END_DATE",
          "NOTICE_DATE",
          "LANDLORD_NOTICE_DATE",
          "NOTICE_TYPE_LABEL",
          "TENANT_EXIT_DATE",
          "LEASE_DURATION",
          "LEASE_COMMITMENT_TERM",
          "DURATION_TO_BREAK_DATE",
          "DURATION_TO_LEASE_END",
          "REMAINING_TERM_TO_THE_LEASE_TERMINATION",
          "VACANCY_START_DATE",
          "CURRENT_INDEX_TYPE_LABEL",
          "INITIAL_INDEX_VALUE",
          "RENT_REVIEW_FREQUENCY",
          "RENT_REVIEW_LEVERAGE",
          "INDEX_FLOOR",
          "INDEXATION_CAP",
          "INITIAL_INDEX",
          "INITIAL_INDEX_DATE",
          "LAST_RENT_REVIEW_DATE",
          "CURRENT_INDEX_VALUE",
          "CURRENT_INDEX_DATE",
          "NEXT_INDEX_DATE",
          "NEXT_REVISION_DATE",
          "UNIT_AREA_SQM",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "PRIVATE_UNIT_AREA",
          "COMMON_AREA_SHARE",
          "THOUSANDS",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "INITIAL_ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_ANNUAL_RENT_AMOUNT",
          "ANNUAL_RENT_AMOUNT_M2",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "PERCENT_TOTAL_RENT_AMOUNT",
          "RENT_UPLIFT",
          "BASE_RENT",
          "PERCENTAGE_RENT_Y_N",
          "PERCENTAGE_RENT",
          "TOTAL_RENT",
          "TOTAL_RENT_M2",
          "CURRENT_MARKET_VALUE",
          "RENT_FREE_AMOUNT_1",
          "RENT_FREE_1_START_DATE",
          "RENT_FREE_1_END_DATE",
          "RENT_FREE_1_PERIOD",
          "RENT_FREE_AMOUNT_2",
          "RENT_FREE_2_START_DATE",
          "RENT_FREE_2_END_DATE",
          "RENT_FREE_2_PERIOD",
          "RENT_FREE_AMOUNT_3",
          "RENT_FREE_3_START_DATE",
          "RENT_FREE_3_END_DATE",
          "RENT_FREE_3_PERIOD",
          "RENT_FREE_NB_MONTH",
          "RENT_FREE_LEFT_NB_MONTH",
          "RENT_FREE_TOTAL_AMOUNT",
          "RENT_FREE_LEFT_AMOUNT",
          "RENT_FREE_END_DATE",
          "ABATEMENT_AMOUNT_TOTAL",
          "ABATEMENT_MONTHS_TOTAL",
          "ABATEMENT_AMOUNT_LEFT",
          "ABATEMENT_MONTHS_LEFT",
          "STEPPED_RENT",
          "STEPPED_RENT_1",
          "STEP_1_START_DATE",
          "PC_STEP_RENT_1_OF_GHL_RENT",
          "STEPPED_RENT_2",
          "STEP_2_START_DATE",
          "PC_STEP_RENT_2_OF_GHL_RENT",
          "STEPPED_RENT_3",
          "STEP_3_START_DATE",
          "PC_STEP_RENT_3_OF_GHL_RENT",
          "STEPPED_RENT_4",
          "STEP_4_START_DATE",
          "PC_STEP_RENT_4_OF_GHL_RENT",
          "STEPPED_RENT_5",
          "STEP_5_START_DATE",
          "PC_STEP_RENT_5_OF_GHL_RENT",
          "STEPPED_RENT_6",
          "STEP_6_START_DATE",
          "PC_STEP_RENT_6_OF_GHL_RENT",
          "ADDITIONAL_RENT",
          "ADDITIONAL_RENT_START_DATE",
          "ADDITIONAL_RENT_END_DATE",
          "ADDITIONAL_RENT_PERIOD",
          "ADDITIONAL_RENT_INDEXATION_MODE",
          "EFFECTIVE_RENT",
          "ECONOMIC_RENT",
          "ECONOMIC_RENT_PER_SQM",
          "ECONOMIC_RENT_BY_HEADLINE_RENT",
          "INCENTIVE_RATE",
          "EXTRAORDINARY_RENT",
          "EXTRAORDINARY_RENT_START_DATE",
          "EXTRAORDINARY_RENT_END_DATE",
          "EXTRAORDINARY_RENT_PERIOD",
          "IS_RENT_CATCH_UP",
          "CATCH_UP_AMOUNT",
          "CATCH_UP_START_DATE",
          "CATCH_UP_END_DATE",
          "CATCH_UP_PERIOD",
          "RENT_RECEIVABLE_YEAR_TO_DATE",
          "RENT_RECEIVABLE_CUR_BIL_TERM",
          "TAX_EXEMPTION",
          "SERVICE_CHARGES",
          "SERVICE_CHARGES_M2",
          "IS_SERVICES_CHARGES_CAPPED",
          "SERVICE_CHARGES_CAPS",
          "SERVICE_CHARGES_CAPS_M2",
          "IS_OPERATING_EXPENSES_REBILLED",
          "PC_RECOVERABLE_PROPERTY_TAX",
          "RECOVERABLE_GARB_COLLECT_TAX",
          "PC_RECOVERABLE_OFFICE_TAX",
          "PC_RECOVERABLE_INSURANCE",
          "PC_RECOV_PROP_MANAGT_FEES_TEN",
          "PC_RECOV_PROP_MANAGT_FEES_ADM",
          "PC_RECOVERABLE_606",
          "PC_RECOVERABLE_REFURB_EXCL_606",
          "RECOVERABLE_MARKETING_FEES",
          "PROPERTY_TAX_AMOUNT",
          "GARBAGE_COLLECTION_TAX_AMOUNT",
          "OFFICE_TAX_AMOUNT",
          "INSURANCE_PREMIMS_AMOUNT",
          "AMOUNT_OF_RECOVERABLE_WORKS",
          "MAJOR_REFURBISHMENT_AMOUNT",
          "PROPERTY_TAX_MANAGEMENT_FEES",
          "PC_PROPERTY_TAX_MGMT_FEES",
          "MGMT_FEES_OF_GARB_COLLECT_TAX",
          "PC_MGMT_FEES_GARB_COLLECT_TAX",
          "INITIAL_DEPOSIT_VALUE",
          "MAIN_DEPOSIT_VALUE",
          "BANK_GUARANTEE_AMOUNT",
          "BANK_GUARANTEE_START_DATE",
          "BANK_GUARANTEE_END_DATE",
          "GUARANTEE_TYPE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "ENTRANCE_FEES",
          "BREAKPOINT_AMOUNT_1",
          "BREAKPOINT_RATE_1",
          "BREAKPOINT_AMOUNT_2",
          "BREAKPOINT_RATE_2",
          "ANNUAL_SALES_CERT_Y_MINUS_1",
          "ANNUAL_SALES_CERT_Y_MINUS_2",
          "YOY_ANNUAL_SALES_GROWTH",
          "SPACE_PRODUCTIVITY",
          "RENT_TO_SALES_RATIO",
          "OCCUPANCY_COST_RATIO",
          "MONTHLY_SALES_VOLUME",
          "MONTHLY_SALES_VOLUME_M_MINUS_1",
          "MONTH_ON_MONTH_SALES_GROWTH",
          "MONTHLY_SALES_VOLUME_Y_MINUS_1",
          "YOY_MONTHLY_SALES_GROWTH",
          "YEAR_TO_DATE_SALES_VOLUME",
          "MONTH_ROLLING_SALES_VOLUME",
          "ON_LITIGATION",
          "PREVIOUS_TENANT_CODE",
          "PREVIOUS_TENANT_NAME",
          "PREVIOUS_TENANT_MOVE_OUT_DATE",
          "PREVIOUS_LEASE_HEADLINE_RENT",
          "PREVIOUS_LEASE_HEADLINE_RENT_M2",
          "NEXT_TENANT_CODE",
          "NEXT_TENANT_NAME",
          "NEXT_TENANT_MOVE_IN_DATE",
          "NEXT_LEASE_HEADLINE_RENT",
          "NEXT_LEASE_HEADLINE_RENT_M2",
          "LEASE_COMMENTS",
          "INSURANCE_BEGIN_DATE",
          "INSURANCE_END_DATE",
          "INSURANCE_PERIOD",
          "INSURANCE_DUNNING_DATE",
          "INSURANCE_DUNNING_LEVEL"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "BUILDING_CODE",
          "UNIT_NUMBER"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = false;

        break;
      }
      case "OUT_LEA_REPORT": {
        this.reportController.set("OutstandingPayments");
        this.ReportName = this.appService.getTranslation("lblOUTReport");
        this.reportType.set("BY_LEASE");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "TENANT_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TRADE_NAME",
          "LEASE_CODE",
          "LEASE_TYPE_LABEL",
          "INVOICING_FREQUENCY_LABEL",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "LEASE_END_DATE",
          "TENANT_EXIT_DATE",
          "UNPAID_INVOICED_INCL_TAX_AMOUNT",
          "INVOICES_NOT_DUE_INC_TAX_AMOUNT",
          "PARTIAL_PAYMENT_INCL_TAX_AMOUNT",
          "OUTSTAND_PAY_INC_TAX_AMOUNT",
          "BALANCE_INCL_TAX_AMOUNT",
          "PAYMENT_PERIOD_LESS_0",
          "PAYMENT_PERIOD_1",
          "PAYMENT_PERIOD_2",
          "PAYMENT_PERIOD_3",
          "PAYMENT_PERIOD_4",
          "PAYMENT_PERIOD_5",
          "PAYMENT_PERIOD_6",
          "OVERPAYMENTS_INC_TAX_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "IS_IN_LITIGATION",
          "LITIGATION_SITUATION_LABEL",
          "LITIGATION_TYPE_LABEL",
          "LITIGATION_STATUS_LABEL",
          "LITIGATION_INFORMATION",
          "LITIGATION_AMOUNT",
          "TENANT_PRESENCE",
          "REPORT_COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "TENANT_CODE",
          "TENANT_NAME"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "OUT_LEA_RCI_REP": {
        this.reportController.set("OutstandingPayments");
        this.ReportName = this.appService.getTranslation("lblOUTLEARCIReport");
        this.reportType.set("BY_RCI");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "TENANT_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TRADE_NAME",
          "LEASE_CODE",
          "LEASE_TYPE_LABEL",
          "INVOICING_FREQUENCY_LABEL",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "LEASE_END_DATE",
          "TENANT_EXIT_DATE",
          "REGULAR_CHARGES_ITEM_CODE",
          "REGULAR_CHARGES_ITEM_LABEL",
          "UNPAID_INVOICED_INCL_TAX_AMOUNT",
          "INVOICES_NOT_DUE_INC_TAX_AMOUNT",
          "PARTIAL_PAYMENT_INCL_TAX_AMOUNT",
          "OUTSTAND_PAY_INC_TAX_AMOUNT",
          "BALANCE_INCL_TAX_AMOUNT",
          "PAYMENT_PERIOD_LESS_0",
          "PAYMENT_PERIOD_1",
          "PAYMENT_PERIOD_2",
          "PAYMENT_PERIOD_3",
          "PAYMENT_PERIOD_4",
          "PAYMENT_PERIOD_5",
          "PAYMENT_PERIOD_6",
          "OVERPAYMENTS_INC_TAX_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "IS_IN_LITIGATION",
          "LITIGATION_TYPE_LABEL",
          "LITIGATION_STATUS_LABEL",
          "LITIGATION_INFORMATION",
          "LITIGATION_AMOUNT",
          "TENANT_PRESENCE",
          "REPORT_COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "TENANT_CODE",
          "TENANT_NAME"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "OUT_INV_REPORT": {
        this.reportController.set("OutstandPayInvoice");
        this.ReportName = this.appService.getTranslation("lblOUTINVReport");
        this.reportType.set("BY_INVOICE");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "TENANT_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TRADE_NAME",
          "LEASE_CODE",
          "LEASE_TYPE_LABEL",
          "INVOICING_FREQUENCY_LABEL",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "LEASE_END_DATE",
          "TENANT_EXIT_DATE",
          "DOCUMENT_NUMBER",
          "ACCOUNTING_DATE_INVOICE",
          "DUE_DATE",
          "ACCOUNTING_DATE_PAYMENT",
          "UNPAID_INVOICED_INCL_TAX_AMOUNT",
          "INVOICES_NOT_DUE_INC_TAX_AMOUNT",
          "PARTIAL_PAYMENT_INCL_TAX_AMOUNT",
          "OUTSTAND_PAY_INC_TAX_AMOUNT",
          "BALANCE_INCL_TAX_AMOUNT",
          "PAYMENT_PERIOD_LESS_0",
          "PAYMENT_PERIOD_1",
          "PAYMENT_PERIOD_2",
          "PAYMENT_PERIOD_3",
          "PAYMENT_PERIOD_4",
          "PAYMENT_PERIOD_5",
          "PAYMENT_PERIOD_6",
          "OVERPAYMENTS_INC_TAX_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "IS_IN_LITIGATION",
          "LITIGATION_TYPE_LABEL",
          "LITIGATION_STATUS_LABEL",
          "LITIGATION_INFORMATION",
          "LITIGATION_AMOUNT",
          "TENANT_PRESENCE",
          "REPORT_COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "TENANT_CODE",
          "TENANT_NAME"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "OUT_INV_RCI_REP": {
        this.reportController.set("OutstandPayInvoiveBillItem");
        this.ReportName = this.appService.getTranslation("lblOUTINVRCIReport");
        this.reportType.set("BY_INVOICE_RCI");
        this.reportColumns.set([
          {
            data: 'LEASE_CODE',
            name: 'LEASE_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "TENANT_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TRADE_NAME",
          "LEASE_CODE",
          "LEASE_TYPE_LABEL",
          "INVOICING_FREQUENCY_LABEL",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "LEASE_END_DATE",
          "TENANT_EXIT_DATE",
          "DOCUMENT_NUMBER",
          "ACCOUNTING_DATE_INVOICE",
          "DUE_DATE",
          "ACCOUNTING_DATE_PAYMENT",
          "REGULAR_CHARGES_ITEM_CODE",
          "REGULAR_CHARGES_ITEM_LABEL",
          "UNPAID_INVOICED_INCL_TAX_AMOUNT",
          "INVOICES_NOT_DUE_INC_TAX_AMOUNT",
          "PARTIAL_PAYMENT_INCL_TAX_AMOUNT",
          "OUTSTAND_PAY_INC_TAX_AMOUNT",
          "BALANCE_INCL_TAX_AMOUNT",
          "PAYMENT_PERIOD_LESS_0",
          "PAYMENT_PERIOD_1",
          "PAYMENT_PERIOD_2",
          "PAYMENT_PERIOD_3",
          "PAYMENT_PERIOD_4",
          "PAYMENT_PERIOD_5",
          "PAYMENT_PERIOD_6",
          "OVERPAYMENTS_INC_TAX_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "IS_IN_LITIGATION",
          "LITIGATION_TYPE_LABEL",
          "LITIGATION_STATUS_LABEL",
          "LITIGATION_INFORMATION",
          "LITIGATION_AMOUNT",
          "TENANT_PRESENCE",
          "REPORT_COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "TENANT_CODE",
          "TENANT_NAME"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "BUM_REPORT": {
        this.reportController.set("BudgetMonitoring");
        this.ReportName = this.appService.getTranslation("lblBUMReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "BUILDING_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "LEVEL4_BUDGET_SECTION",
          "RECOVERABLE",
          "LEVEL3_BUDGET_SECTION",
          "LEVEL2_BUDGET_SECTION",
          "LEVEL1_BUDGET_SECTION",
          "LEVEL1_BUDGET_SECTION_CODE",
          "BUDGET_INITIAL_N_M1",
          "LANDED_N_M1",
          "REALISED_N_M1",
          "REALISED_N_TODATE_M1",
          "REALISED_ACCOUNTING_N_M1",
          "DIFF_N_M1",
          "COMMENT_N_M1",
          "BUDGET_INITIAL_N",
          "LANDED_N",
          "BUDGET_TODATE_N",
          "REALISED_TODATE_N",
          "VARIATION_N",
          "VARIATION_RATE_N",
          "VARIATION_TODATE_N",
          "VARIATION_RATE_TODATE_N",
          "VARIATION_BUDGET",
          "VARIATION_BUDGET_RATE",
          "REALISED_N",
          "COMMENT_N",
          "BUDGET_INITIAL_N_P1",
          "COMMENT_N_P1",
          "BUDGET_T1_N",
          "REALISED_T1_N",
          "VARIATION_T1_N",
          "VARIATION_RATE_T1_N",
          "BUDGET_T2_N",
          "REALISED_T2_N",
          "VARIATION_T2_N",
          "VARIATION_RATE_T2_N",
          "BUDGET_T3_N",
          "REALISED_T3_N",
          "VARIATION_T3_N",
          "VARIATION_RATE_T3_N",
          "BUDGET_T4_N",
          "REALISED_T4_N",
          "VARIATION_T4_N",
          "VARIATION_RATE_T4_N",
          "BUDGET_01_N",
          "REALISED_01_N",
          "VARIATION_01_N",
          "VARIATION_RATE_01_N",
          "BUDGET_02_N",
          "REALISED_02_N",
          "VARIATION_02_N",
          "VARIATION_RATE_02_N",
          "BUDGET_03_N",
          "REALISED_03_N",
          "VARIATION_03_N",
          "VARIATION_RATE_03_N",
          "BUDGET_04_N",
          "REALISED_04_N",
          "VARIATION_04_N",
          "VARIATION_RATE_04_N",
          "BUDGET_05_N",
          "REALISED_05_N",
          "VARIATION_05_N",
          "VARIATION_RATE_05_N",
          "BUDGET_06_N",
          "REALISED_06_N",
          "VARIATION_06_N",
          "VARIATION_RATE_06_N",
          "BUDGET_07_N",
          "REALISED_07_N",
          "VARIATION_07_N",
          "VARIATION_RATE_07_N",
          "BUDGET_08_N",
          "REALISED_08_N",
          "VARIATION_08_N",
          "VARIATION_RATE_08_N",
          "BUDGET_09_N",
          "REALISED_09_N",
          "VARIATION_09_N",
          "VARIATION_RATE_09_N",
          "BUDGET_10_N",
          "REALISED_10_N",
          "VARIATION_10_N",
          "VARIATION_RATE_10_N",
          "BUDGET_11_N",
          "REALISED_11_N",
          "VARIATION_11_N",
          "VARIATION_RATE_11_N",
          "BUDGET_12_N",
          "REALISED_12_N",
          "VARIATION_12_N",
          "VARIATION_RATE_12_N",
          "COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "BUILDING_CODE",
          "BUILDING_NAME"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "GEC_REPORT": {
        this.reportController.set("GeneralExpenses");
        this.ReportName = this.appService.getTranslation("lblGECReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "PROVIDER_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_CODE",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "GEOGRAPHICAL_ZONE",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "BUILDING_TYPE_LABEL",
          "SERVICE_LABEL",
          "BUILDING_NAME",
          "BUILDING_SURFACE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "PROVIDER_CODE",
          "PROVIDER_TYPE_CODE",
          "PROVIDER_TYPE_NAME",
          "FEE_TYPE_CODE",
          "FEE_TYPE_NAME",
          "CONTRACT_COMMAND_CODE",
          "CONTRACT_COMMAND_NAME",
          "CONTRACT_TYPE_CODE",
          "PERIOD",
          "INVOICING_DATE",
          "ACCOUNTING_DATE",
          "INVOICE_NUMBER",
          "INVOICE",
          "CURRENCY",
          "BEFORE_TAX_AMOUNT_TOTAL",
          "VAT_AMOUNT_TOTAL",
          "INCLUDING_TAX_AMOUNT_TOTAL",
          "PAYMENT_AMOUNT",
          "OUTSTAND_PAYMENT_INC_TAX_AMOUNT",
          "PAYMENT_MODE",
          "PAYMENT_DATE",
          "BANK_JOURNAL",
          "BUDGET_CODE",
          "BUDGET_TYPE",
          "BUDGET_YEAR",
          "BUDGET_BEGIN_DATE",
          "BUDGET_END_DATE",
          "BUDGET_NAME",
          "WORK_CAMPAIGN_CODE",
          "WORK_CAMPAIGN_LABEL",
          "JOURNAL_CODE",
          "ENTRY",
          "EXTRANET_REF",
          "CHRONO_GED",
          "RECOVERABLE",
          "BUDGET_LINE_CODE",
          "BUDGET_LINE",
          "LEVEL2_BUDGET_SECTION_CODE",
          "LEVEL2_BUDGET_SECTION",
          "LEVEL3_BUDGET_SECTION_CODE",
          "LEVEL3_BUDGET_SECTION",
          "OPERATION_NATURE_CODE",
          "OPERATION_NATURE_LABEL",
          "REPARTITION_CODE",
          "REPARTITION_LABEL",
          "ACCOUNT_NUMBER",
          "ACCOUNT_NUMBER_LABEL",
          "BEFORE_TAX_AMOUNT",
          "VAT_AMOUNT",
          "INCLUDING_TAX_AMOUNT",
          "COMMENT"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "BUILDING_CODE",
          "PROVIDER_NAME"
        ];
        this.reportDatesService.showDateRange = true;
        this.showSpecificCriteria = true;

        break;
      }
      case "GEP_REPORT": {
        this.reportController.set("GeneralExpenses");
        this.ReportName = this.appService.getTranslation("lblGEPReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "BUILDING_CODE",
          "PROVIDER_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_CODE",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "GEOGRAPHICAL_ZONE",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "BUILDING_TYPE_LABEL",
          "SERVICE_LABEL",
          "BUILDING_NAME",
          "BUILDING_SURFACE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "PROVIDER_CODE",
          "PROVIDER_TYPE_CODE",
          "PROVIDER_TYPE_NAME",
          "FEE_TYPE_CODE",
          "FEE_TYPE_NAME",
          "CONTRACT_COMMAND_CODE",
          "CONTRACT_COMMAND_NAME",
          "CONTRACT_TYPE_CODE",
          "PERIOD",
          "INVOICING_DATE",
          "ACCOUNTING_DATE",
          "INVOICE_NUMBER",
          "INVOICE",
          "CURRENCY",
          "BEFORE_TAX_AMOUNT_TOTAL",
          "VAT_AMOUNT_TOTAL",
          "INCLUDING_TAX_AMOUNT_TOTAL",
          "PAYMENT_AMOUNT",
          "OUTSTAND_PAYMENT_INC_TAX_AMOUNT",
          "PAYMENT_MODE",
          "PAYMENT_DATE",
          "BANK_JOURNAL",
          "BUDGET_CODE",
          "BUDGET_TYPE",
          "BUDGET_YEAR",
          "BUDGET_BEGIN_DATE",
          "BUDGET_END_DATE",
          "BUDGET_NAME",
          "WORK_CAMPAIGN_CODE",
          "WORK_CAMPAIGN_LABEL",
          "JOURNAL_CODE",
          "ENTRY",
          "EXTRANET_REF",
          "CHRONO_GED",
          "RECOVERABLE",
          "BUDGET_LINE_CODE",
          "BUDGET_LINE",
          "LEVEL2_BUDGET_SECTION_CODE",
          "LEVEL2_BUDGET_SECTION",
          "LEVEL3_BUDGET_SECTION_CODE",
          "LEVEL3_BUDGET_SECTION",
          "OPERATION_NATURE_CODE",
          "OPERATION_NATURE_LABEL",
          "REPARTITION_CODE",
          "REPARTITION_LABEL",
          "ACCOUNT_NUMBER",
          "ACCOUNT_NUMBER_LABEL",
          "BEFORE_TAX_AMOUNT",
          "VAT_AMOUNT",
          "INCLUDING_TAX_AMOUNT",
          "COMMENT"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "BUILDING_CODE",
          "PROVIDER_NAME"
        ];
        this.reportDatesService.showDateRange = true;
        this.showSpecificCriteria = true;

        break;
      }
      case "SDG_REPORT": {
        this.reportController.set("SecurityDepositsGuarantees");
        this.ReportName = this.appService.getTranslation("lblSDGReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "LEASE_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TENANT_ARRIVAL_DATE",
          "TENANT_EXIT_DATE",
          "IS_IN_LITIGATION",
          "INITIAL_ANNUAL_RENT_AMOUNT",
          "SECURIT_DEPOSIT_IN_MONTH",
          "INITIAL_DEPOSIT_VALUE",
          "ANNUAL_RENT_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "SECURITY_DEPOSIT_CALLS",
          "SECURITY_DEPOSIT_REFUNDS",
          "SECURITY_DEPOSIT_RECEIVABLE",
          "LAST_INDEXATION_DATE",
          "INITIAL_GUARANTEE_1",
          "CURRENT_GUARANTEE_1",
          "GUARANTEE_TYPE_1",
          "GUARANTEE_BANK_1",
          "GUARANTEE_START_DATE_1",
          "GUARANTEE_END_DATE_1",
          "INITIAL_GUARANTEE_2",
          "CURRENT_GUARANTEE_2",
          "GUARANTEE_TYPE_2",
          "GUARANTEE_BANK_2",
          "GUARANTEE_START_DATE_2",
          "GUARANTEE_END_DATE_2",
          "INITIAL_GUARANTEE_3",
          "CURRENT_GUARANTEE_3",
          "GUARANTEE_TYPE_3",
          "GUARANTEE_BANK_3",
          "GUARANTEE_START_DATE_3",
          "GUARANTEE_END_DATE_3",
          "INITIAL_EQUIPMENT_GUARANTEE",
          "CURRENT_EQUIPMENT_GUARANTEE",
          "EQUIPMENT_GUARANTEE_CALLS",
          "EQUIPMENT_GUARANTEE_REFUNDS",
          "EQUIPMENT_GUARANTEE_RECEIVABLE",
          "INITIAL_WORKING_CAPITAL",
          "CURRENT_WORKING_CAPITAL",
          "WORKING_CAPITAL_CALLS",
          "WORKING_CAPITAL_REFUNDS",
          "WORKING_CAPITAL_RECEIVABLE",
          "COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "LEASE_CODE",
          "TENANT_NAME"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "OPM_REPORT": {
        this.reportController.set("OutstandPayMonitoring");
        this.ReportName = this.appService.getTranslation("lblOPMReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "LEASE_CODE",
          "TENANT_NAME",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "ADDRESS1",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "TENANT_CODE",
          "TRADE_NAME",
          "LEASE_TYPE_LABEL",
          "LEASE_START_DATE",
          "TENANT_ARRIVAL_DATE",
          "LEASE_END_DATE",
          "TENANT_EXIT_DATE",
          "CURRENT_MONTHLY_RENT_AMOUNT",
          "LEASE_PAYMENT_MODE_LABEL",
          "IS_DIRECT_DEBIT",
          "INVOICING_FREQUENCY_LABEL",
          "ACCOUNTING_BALANCE_START_AMOUNT",
          "PAYMENT_DURING_PERIOD_INC_TAX_AMNT",
          "BEFORE_PAYMENTS_INC_TAX_AMNT",
          "INVOICE_DURING_PERIOD_INC_TAX_AMNT",
          "PAYMENT_INCL_TAX_AMOUNT",
          "OUTSTAND_PAY_INC_TAX_AMOUNT",
          "OUTSTAND_RATE",
          "OUTSTAND_PAY_TERM_EQUIV",
          "ADVANCE_PAYMENTS_INC_TAX_AMNT",
          "ACCOUNTING_BALANCE_END_AMOUNT",
          "MAIN_DEPOSIT_VALUE",
          "IS_IN_LITIGATION",
          "LITIGATION_SITUATION_LABEL",
          "LITIGATION_TYPE_LABEL",
          "LITIGATION_STATUS_LABEL",
          "LITIGATION_INFORMATION",
          "LITIGATION_AMOUNT",
          "TENANT_PRESENCE",
          "REPORT_COMMENTS"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "LEASE_CODE",
          "TENANT_NAME",
        ];
        this.reportDatesService.showDateRange = true;
        this.showSpecificCriteria = true;

        break;
      }
      case "VAU_REPORT": {
        this.reportController.set("VacantUnits");
        this.ReportName = this.appService.getTranslation("lblVAUReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "UNIT_NUMBER",
          "UNIT_TYPE_LABEL",
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE_LABEL",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "BUILDING_POSTAL_CODE",
          "BUILDING_CITY",
          "BUILDING_COUNTRY",
          "BUILDING_TYPE_LABEL",
          "NUMBER_OF_ROOMS",
          "BUILDING",
          "STAIRCASE",
          "FLOOR",
          "VACANCY_TYPE",
          "VACANCY_START_DATE",
          "VACANCY_DURATION",
          "UNIT_AREA_SQM",
          "PERCENT_TOTAL_PROPERTY_AREA",
          "NUMBER_OF_PARKING_PLACES",
          "CURRENT_MARKET_VALUE",
          "FINANCIAL_VACANCY",
          "PRO_RATED_FINANCIAL_VACANCY",
          "LAST_TENANT_NAME",
          "LAST_TENANT_TRADE_NAME",
          "LAST_LEASE_RENT_AMOUNT",
          "LAST_LEASE_RENT_AMOUNT_M2"
        ]);
        this.stickyLeftColumnsCount = 2;
        this.stickyLeftColumns = [
          "UNIT_NUMBER",
          "UNIT_TYPE_LABEL"
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "VAC_PHY_REPORT": {
        this.ReportName = this.appService.getTranslation("lblVACPhyReport");
        this.route.paramMap.subscribe((params: ParamMap) => {
          switch (this.subReportCode) {
            case "SQM": {
              this.reportController.set("VacancyPhysical");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "PORTFOLIO_LABEL",
                "EXTERNAL_FUND_NAME",
                "OWNER_NAME",
                "ASSET_MANAGER",
                "SITE_NAME",
                "FEE_NAME",
                "AGENCY",
                "PROPERTY_MANAGER",
                "BU_MANAGER",
                "START_MANAGEMENT_DATE",
                "END_MANAGEMENT_DATE",
                "SERVICE_LABEL",
                "BUILDING_TOTAL_AREA_SQM",
                "BUILDING_NATURE",
                "GEOGRAPHICAL_ZONE",
                "BUILDING_ADDRESS",
                "POSTAL_CODE",
                "CITY",
                "COUNTRY",
                "BUILDING_TYPE_LABEL",
                "BUILDING_TOTAL_AREA",
                "VACANT_AREA_MINUS_11_MONTHS",
                "VACANT_AREA_MINUS_10_MONTHS",
                "VACANT_AREA_MINUS_09_MONTHS",
                "VACANT_AREA_MINUS_08_MONTHS",
                "VACANT_AREA_MINUS_07_MONTHS",
                "VACANT_AREA_MINUS_06_MONTHS",
                "VACANT_AREA_MINUS_05_MONTHS",
                "VACANT_AREA_MINUS_04_MONTHS",
                "VACANT_AREA_MINUS_03_MONTHS",
                "VACANT_AREA_MINUS_02_MONTHS",
                "VACANT_AREA_MINUS_01_MONTHS",
                "VACANT_AREA_CURRENT_MONTH",
                "AVERAGE_VACANCY_RATE"
              ]);
              this.stickyLeftColumnsCount = 2;
              this.stickyLeftColumns = [
                "BUILDING_CODE",
                "BUILDING_NAME"
              ];
              break;
            }
            case "PERCENT": {
              this.reportController.set("VacancyPhysicalPercent");
              this.ReportName = this.appService.getTranslation("lblVACPhyReport");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TYPE_LABEL",
                "BUILDING_TOTAL_AREA",
                "VACANCY_RATE_MINUS_11_MONTHS",
                "VACANCY_RATE_MINUS_10_MONTHS",
                "VACANCY_RATE_MINUS_09_MONTHS",
                "VACANCY_RATE_MINUS_08_MONTHS",
                "VACANCY_RATE_MINUS_07_MONTHS",
                "VACANCY_RATE_MINUS_06_MONTHS",
                "VACANCY_RATE_MINUS_05_MONTHS",
                "VACANCY_RATE_MINUS_04_MONTHS",
                "VACANCY_RATE_MINUS_03_MONTHS",
                "VACANCY_RATE_MINUS_02_MONTHS",
                "VACANCY_RATE_MINUS_01_MONTHS",
                "VACANCY_RATE_CURRENT_MONTH",
                "AVERAGE_VACANCY_RATE"
              ]);
              this.stickyLeftColumnsCount = 2;
              this.stickyLeftColumns = [
                "BUILDING_CODE",
                "BUILDING_NAME"
              ];
              break;
            }
            case "FINANCIAL": {
              this.reportController.set("VacancyPhysicalFin");
              this.ReportName = this.appService.getTranslation("lblVACPhyReport");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TYPE_LABEL",
                "BUILDING_TOTAL_AREA",
                "FIN_VAC_MINUS_11_MONTH",
                "FIN_VAC_MINUS_10_MONTH",
                "FIN_VAC_MINUS_09_MONTH",
                "FIN_VAC_MINUS_08_MONTH",
                "FIN_VAC_MINUS_07_MONTH",
                "FIN_VAC_MINUS_06_MONTH",
                "FIN_VAC_MINUS_05_MONTH",
                "FIN_VAC_MINUS_04_MONTH",
                "FIN_VAC_MINUS_03_MONTH",
                "FIN_VAC_MINUS_02_MONTH",
                "FIN_VAC_MINUS_01_MONTH",
                "FIN_VAC_CURRENT_MONTH",
                "ANNUAL_FINANCIAL_VACANCY",
              ]);
              this.stickyLeftColumnsCount = 2;
              this.stickyLeftColumns = [
                "BUILDING_CODE",
                "BUILDING_NAME"
              ];
              break;
            }

          }
          this.reportDatesService.showDateRange = false;
          this.showSpecificCriteria = true;
        });
        break;
      }
      case "VAC_ANA_REPORT": {
        this.reportController.set("VacancyAnalysis");
        this.ReportName = this.appService.getTranslation("lblVACAnaReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "PORTFOLIO_LABEL",
          "EXTERNAL_FUND_NAME",
          "OWNER_NAME",
          "ASSET_MANAGER",
          "SITE_NAME",
          "FEE_NAME",
          "AGENCY",
          "PROPERTY_MANAGER",
          "BU_MANAGER",
          "START_MANAGEMENT_DATE",
          "END_MANAGEMENT_DATE",
          "SERVICE_LABEL",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_TOTAL_AREA_SQM",
          "BUILDING_NATURE",
          "GEOGRAPHICAL_ZONE",
          "BUILDING_ADDRESS",
          "POSTAL_CODE",
          "CITY",
          "COUNTRY",
          "BUILDING_TYPE_LABEL",
          "BUILDING_TOTAL_AREA",
          "RENTED_AREA",
          "PHYSICAL_OCCUPATION_RATE",
          "VACANT_AREA",
          "PHYSICAL_VACANCY_RATE",
          "VACANT_AREA_INF_2MONTHS",
          "VACANT_AREA_2_4_MONTHS",
          "VACANT_AREA_SUP_4MONTHS",
          "FINANCIAL_VACANCY",
          "FINANCIAL_VACANCY_PER_SQM"
        ]);
        this.stickyLeftColumnsCount = 0;
        this.stickyLeftColumns = [];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "EVP_REPORT": {
        this.reportController.set("PortfolioEvolution");
        this.ReportName = this.appService.getTranslation("lblEVPReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_TYPE_LABEL',
            name: 'BUILDING_TYPE_LABEL',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'BUILDING_NATURE_LABEL',
            name: 'BUILDING_NATURE_LABEL',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'EVOLUTION_YEAR',
            name: 'EVOLUTION_YEAR',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'BUILDINGS_COUNT',
            name: 'BUILDINGS_COUNT',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'UNITS_COUNT',
            name: 'UNITS_COUNT',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'ASSETS_AREA',
            name: 'ASSETS_AREA',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'ASSETS_VALUE',
            name: 'ASSETS_VALUE',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'GROSS_HEADLINE_RENT',
            name: 'GROSS_HEADLINE_RENT',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
          {
            data: 'NET_EFFECTIVE_RENT',
            name: 'NET_EFFECTIVE_RENT',
            orderable: true,
            search: {
              regex: false,
              value: '',
            },
            searchable: true,
          },
        ]);
        this.columnsToDisplay.set([
          "BUILDING_TYPE_LABEL",
          "BUILDING_NATURE_LABEL",
          "EVOLUTION_YEAR",
          "BUILDINGS_COUNT",
          "UNITS_COUNT",
          "ASSETS_AREA",
          "ASSETS_VALUE",
          "GROSS_HEADLINE_RENT",
          "NET_EFFECTIVE_RENT"
        ]);
        this.stickyLeftColumnsCount = 3;
        this.stickyLeftColumns = [
          "BUILDING_TYPE_LABEL",
          "BUILDING_NATURE_LABEL",
          "EVOLUTION_YEAR",
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
      case "YIM_REPORT": {
        this.ReportName = this.appService.getTranslation("lblYIMReport");
        this.route.paramMap.subscribe((params: ParamMap) => {
          switch (this.subReportCode) {
            case "YMG": {
              this.reportController.set("YieldMonitoringGross");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TOTAL_AREA_SQM",
                "NET_MARKET_VALUE",
                "GROSS_MARKET_VALUE",
                "LAST_VALUATION_DATE",
                "GROSS_HEADLINE_RENT",
                "GROSS_EFFECTIVE_RENT",
                "GROSS_POTENTIAL_RENT",
                "FAIR_MARKET_RENT",
                "HEADLINE_GROSS_INITIAL_YIELD",
                "EFFECTIVE_GROSS_INITIAL_YIELD",
                "POTENTIAL_GROSS_INITIAL_YIELD",
                "GROSS_REVERSIONARY_YIELD",
                "GROSS_REVERS_POTENT_OCCUP_AREA",
                "GROSS_REVERS_POTENT_TOTAL_PROP"
              ]);
              this.stickyLeftColumnsCount = 2;
              this.stickyLeftColumns = [
                "BUILDING_CODE",
                "BUILDING_NAME",
              ];
              break;
            }
            case "YMN": {
              this.reportController.set("YieldMonitoringNet");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TOTAL_AREA_SQM",
                "NET_MARKET_VALUE",
                "GROSS_MARKET_VALUE",
                "LAST_VALUATION_DATE",
                "NET_HEADLINE_RENT",
                "NET_EFFECTIVE_RENT",
                "NET_POTENTIAL_RENT",
                "NET_MARKET_RENT",
                "HEADLINE_NET_INITIAL_YIELD",
                "EFFECTIVE_NET_INITIAL_YIELD",
                "POTENTIAL_NET_INITIAL_YIELD",
                "NET_REVERSIONARY_YIELD",
                "GROSS_REVERS_POTENT_OCCUP_AREA",
                "GROSS_REVERS_POTENT_TOTAL_PROP"

              ]);
              this.stickyLeftColumnsCount = 2;
              this.stickyLeftColumns = [
                "BUILDING_CODE",
                "BUILDING_NAME",
              ];
              break;
            }
            case "YMCG": {
              this.reportController.set("YieldMonitoringCapiGross");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TOTAL_AREA_SQM",
                "NET_MARKET_VALUE",
                "GROSS_MARKET_VALUE",
                "LAST_VALUATION_DATE",
                "GROSS_HEADLINE_RENT",
                "GROSS_EFFECTIVE_RENT",
                "GROSS_POTENTIAL_RENT",
                "FAIR_MARKET_RENT",
                "HEADLINE_GROSS_CAPITAL_RATE",
                "EFFECTIVE_GROSS_CAPITAL_RATE",
                "POTENTIAL_GROSS_CAPITAL_RATE",
                "GROSS_REVERS_CAPITAL_RATE",
                "GROSS_REVERS_POTENT_OCCUP_AREA",
                "GROSS_REVERS_POTENT_TOTAL_PROP"
              ]);
              this.stickyLeftColumnsCount = 2;
              this.stickyLeftColumns = [
                "BUILDING_CODE",
                "BUILDING_NAME",
              ];
              break;
            }

            case "YMCN": {
              this.reportController.set("YieldMonitoringCapiNet");
              this.reportColumns.set([
                {
                  data: 'BUILDING_CODE',
                  name: 'BUILDING_CODE',
                  searchable: true,
                  orderable: true,
                  search: {
                    value: '',
                    regex: false,
                  },
                }
              ]);
              this.columnsToDisplay.set([
                "BUILDING_CODE",
                "BUILDING_NAME",
                "BUILDING_TOTAL_AREA_SQM",
                "NET_MARKET_VALUE",
                "GROSS_MARKET_VALUE",
                "LAST_VALUATION_DATE",
                "GROSS_HEADLINE_RENT",
                "GROSS_EFFECTIVE_RENT",
                "GROSS_POTENTIAL_RENT",
                "FAIR_MARKET_RENT",
                "HEADLINE_GROSS_CAPITAL_RATE",
                "EFFECTIVE_GROSS_CAPITAL_RATE",
                "POTENTIAL_GROSS_CAPITAL_RATE",
                "GROSS_REVERS_CAPITAL_RATE",
                "GROSS_REVERS_POTENT_OCCUP_AREA",
                "GROSS_REVERS_POTENT_TOTAL_PROP"
              ]);
              this.stickyLeftColumnsCount = 2;
              this.stickyLeftColumns = [
                "BUILDING_CODE",
                "BUILDING_NAME",
              ];
              break;
            }
          }
        });

        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;
        break;
      }
      case "PVA_REPORT": {
        this.reportController.set("PropertyValuation");
        this.ReportName = this.appService.getTranslation("lblPVAReport");
        this.reportColumns.set([
          {
            data: 'BUILDING_CODE',
            name: 'BUILDING_CODE',
            searchable: true,
            orderable: true,
            search: {
              value: '',
              regex: false,
            },
          }
        ]);
        this.columnsToDisplay.set([
          "OWNER_CODE",
          "OWNER_NAME",
          "BUILDING_CODE",
          "BUILDING_NAME",
          "BUILDING_NATURE_LABEL",
          "MARKET_VALUE_EXCL_DUTIES_VAT",
          "LAST_VALUATION_DATE",
          "GROSS_BOOK_VALUE",
          "TOTAL_ACCUMULATED_DEPRECIATION",
          "NET_BOOK_VALUE_BEFORE_RESERVE",
          "LOSS_BEFORE_RESERVE",
          "IMPAIRMENT_RATIO",
          "DEPRECIATION_PROVISION_AMOUNT",
          "IMPAIRMENT_CHARGE",
          "NET_BOOK_VALUE_AFTER_IMPAIR",
          "VALUATION_MARKET_RENT",
          "PC_EXPECTED_YIELD",
          "VALUE_IN_USE",
          "RECOVERABLE_AMOUNT",
          "IMPAIR_LOSS_CALC",
          "ALLOWANCE_IMPAIR_LOSS_CALC",
          "IMPAIRMENT_LOSS",
          "ALLOWANCE_IMPAIR_LOSS_USER",
          "ADJUSTED_NET_BOOK_VALUE",
          "UNREALIZED_GAINS_AND_LOSSES"
        ]);
        this.stickyLeftColumnsCount = 4;
        this.stickyLeftColumns = [
          "OWNER_CODE",
          "OWNER_NAME",
          "BUILDING_CODE",
          "BUILDING_NAME",
        ];
        this.reportDatesService.showDateRange = false;
        this.showSpecificCriteria = true;

        break;
      }
    }
  }

}
