﻿{
  "01-BALANCE-ALL_REG_CHARGES": "Zahlungsbilanz Saldo - Alle Rechnungsposten (€)",
  "02-BALANCE-RENTS": "Zahlungsbilanz Saldo - Miete (€)",
  "03-BALANCE-SERVICE_CHARGES": "Zahlungsbilanz Saldo - Nebenkostenvorauszahlung (€)",
  "04-BALANCE-OTHER_EXPENSES": "Zahlungsbilanz Saldo - Sonstige Bewirtschaftungskosten (€)",
  "05-BALANCE-OTHER_REG_CHARGES": "Zahlungsbilanz Saldo - Sonstige Rechnungsposten (€)",
  "11-COLLECT-ALL_REG_CHARGES": "Bezahlt - Alle Rechnungsposten (€)",
  "12-COLLECT-RENTS": "Bezahlt - Miete (€)",
  "13-COLLECT-SERVICE_CHARGES": "Bezahlt - Nebenkostenvorauszahlung (€)",
  "14-COLLECT-OTHER_EXPENSES": "Bezahlt - Sonstige Bewirtschaftungskosten (€)",
  "15-COLLECT-OTHER_REG_CHARGES": "Bezahlt - Sonstige Rechnungsposten (€)",
  "21-RATE-ALL_REG_CHARGES": "Bezahlt gegen Zahlungsbilanz Saldo - Alle Rechnungsposten (%)",
  "22-RATE-RENTS": "Bezahlt gegen Zahlungsbilanz Saldo - Miete (%)",
  "23-RATE-SERVICE_CHARGES": "Bezahlt gegen Zahlungsbilanz Saldo - Nebenkostenvorauszahlung (%)",
  "24-RATE-OTHER_EXPENSES": "Bezahlt gegen Zahlungsbilanz Saldo - Sonstige Bewirtschaftungskosten (%)",
  "25-RATE-OTHER_REG_CHARGES": "Bezahlt gegen Zahlungsbilanz Saldo - Sonstige Rechnungsposten (%)",
  "ABATEMENT 1 END DATE": "Mietminderung Jahr 1 - Enddatum",
  "ABATEMENT 1 PERIOD": "Mietminderung Jahr 1 (Monate)",
  "ABATEMENT 1 START DATE": "Mietminderung Jahr 1 - Beginndatum",
  "ABATEMENT 2 END DATE": "Mietminderung Jahr 2 - Enddatum",
  "ABATEMENT 2 PERIOD": "Mietminderung Jahr 2 (Monate)",
  "ABATEMENT 2 START DATE": "Mietminderung Jahr 2 - Beginndatum",
  "ABATEMENT 3 END DATE": "Mietminderung Jahr 3 - Enddatum",
  "ABATEMENT 3 PERIOD": "Mietminderung Jahr 3 (Monate)",
  "ABATEMENT 3 START DATE": "Mietminderung Jahr 3 - Beginndatum",
  "ABATEMENT AMOUNT": "Mietminderung - Betrag (€)",
  "ABATEMENT AMOUNT 1": "Mietminderung - Betrag für Jahr 1 (€)",
  "ABATEMENT AMOUNT 2": "Mietminderung - Betrag für Jahr 2 (€)",
  "ABATEMENT AMOUNT 3": "Mietminderung - Betrag für Jahr 3 (€)",
  "ABATEMENT AMOUNT LEFT": "Verbleibender Minderungsbetrag (€)",
  "ABATEMENT AMOUNT TOTAL": "Kürzungsbetrag insgesamt (€)",
  "ABATEMENT END DATE": "Enddatum der Mietminderung",
  "ABATEMENT LEFT AMOUNT": "(de) Remaining abatement (€)",
  "ABATEMENT LEFT NB MONTH": "(de) Rent free left month",
  "ABATEMENT MONTHS LEFT": "Verbleibende Minderungsmonate",
  "ABATEMENT MONTHS TOTAL": "Kürzungsmonate insgesamt",
  "ABATEMENT NB MONTH": "(de) Rent free nb month",
  "ABATEMENT PERIOD": "Mietminderung 2 (J-M-T)",
  "ABATEMENT START DATE": "Beginndatum der Mietminderung",
  "ABATEMENT TOTAL AMOUNT": "(de) Rent free total amount (€)",
  "ABATEMENT_1_END_DATE": "Mietminderung Jahr 1 - Enddatum",
  "ABATEMENT_1_PERIOD": "Mietminderung Jahr 1 (Monate)",
  "ABATEMENT_1_START_DATE": "Mietminderung Jahr 1 - Beginndatum",
  "ABATEMENT_2_END_DATE": "Mietminderung Jahr 2 - Enddatum",
  "ABATEMENT_2_PERIOD": "Mietminderung Jahr 2 (Monate)",
  "ABATEMENT_2_START_DATE": "Mietminderung Jahr 2 - Beginndatum",
  "ABATEMENT_3_END_DATE": "Mietminderung Jahr 3 - Enddatum",
  "ABATEMENT_3_PERIOD": "Mietminderung Jahr 3 (Monate)",
  "ABATEMENT_3_START_DATE": "Mietminderung Jahr 3 - Beginndatum",
  "ABATEMENT_AMOUNT": "Mietminderung - Betrag (€)",
  "ABATEMENT_AMOUNT_1": "Mietminderung - Betrag für Jahr 1 (€)",
  "ABATEMENT_AMOUNT_2": "Mietminderung - Betrag für Jahr 2 (€)",
  "ABATEMENT_AMOUNT_3": "Mietminderung - Betrag für Jahr 3 (€)",
  "ABATEMENT_AMOUNT_LEFT": "Verbleibender Minderungsbetrag (€)",
  "ABATEMENT_AMOUNT_TOTAL": "Kürzungsbetrag insgesamt (€)",
  "ABATEMENT_END_DATE": "Enddatum der Mietminderung",
  "ABATEMENT_LEFT_AMOUNT": "(de) Remaining abatement (€)",
  "ABATEMENT_LEFT_NB_MONTH": "(de) Rent free left month",
  "ABATEMENT_MONTHS_LEFT": "Verbleibende Minderungsmonate",
  "ABATEMENT_MONTHS_TOTAL": "Kürzungsmonate insgesamt",
  "ABATEMENT_NB_MONTH": "(de) Rent free nb month",
  "ABATEMENT_START_DATE": "Beginndatum der Mietminderung",
  "ABATEMENT_TOTAL_AMOUNT": "(de) Rent free total amount (€)",
  "ACCOUNT LABEL": "Konto-Bezeichnung",
  "ACCOUNT NUMBER": "Konto-Nummer",
  "ACCOUNT NUMBER 1": "Nummer des Buchhaltungskontos",
  "ACCOUNT NUMBER LABEL": "Konto-Bezeichnung",
  "ACCOUNT NUMBER LABEL 1": "Buchhaltungskonto Etikett",
  "ACCOUNT_LABEL": "Konto-Bezeichnung",
  "ACCOUNT_NUMBER": "Konto-Nummer",
  "ACCOUNT_NUMBER_1": "Nummer des Buchhaltungskontos",
  "ACCOUNT_NUMBER_LABEL": "Konto-Bezeichnung",
  "ACCOUNT_NUMBER_LABEL_1": "Buchhaltungskonto Etikett",
  "ACCOUNTING ACCOUNT CUSTOMER": "(de) Account",
  "ACCOUNTING BALANCE END AMOUNT": "Buchhaltungssaldo (€) zu Ende der Periode",
  "ACCOUNTING BALANCE START AMOUNT": "Buchhaltungssaldo (€) zu Beginn der Periode",
  "ACCOUNTING DATE": "(de) Accounting Date",
  "ACCOUNTING DATE INVOICE": "Rechnungsdatum",
  "ACCOUNTING DATE OUTSTANDING PAYMENTS": "Stichtag offene Zahlungen",
  "ACCOUNTING DATE PAYMENT": "Zahlungsdatum",
  "ACCOUNTING_ACCOUNT_CUSTOMER": "(de) Account",
  "ACCOUNTING_BALANCE_END_AMOUNT": "Buchhaltungssaldo (€) zu Ende der Periode DD/MM/YYYY",
  "ACCOUNTING_BALANCE_START_AMOUNT": "Buchhaltungssaldo (€) zu Beginn der Periode DD/MM/YYYY",
  "ACCOUNTING_DATE": "(de) Accounting Date",
  "ACCOUNTING_DATE_INVOICE": "Rechnungsdatum",
  "ACCOUNTING_DATE_PAYMENT": "Zahlungsdatum",
  "ACCOUNTING_STREAM": "Buchhaltung",
  "ACCOUNTS TYPE": "Kontotyp",
  "ACTIVE_AS_OF_THE_END_DATE": "Aktiv zum Enddatum",
  "ACTIVE_AS_OF_THE_REPORT_DATE": "Aktiv zum Zeitpunkt",
  "ACTIVE_AS_OF_THE_START_DATE": "Aktiv zum Beginndatum",
  "ACTUAL SALE DATE": "Ist-Verkaufsdatum",
  "ACTUAL_SALE_DATE": "Ist-Verkaufsdatum",
  "ACTUAL_SALE_DATE_mismatch": "Ist-Verkaufsdatum ungültig",
  "ADDITIONAL RENT": "Sonstige Miete (€)",
  "ADDITIONAL RENT END DATE": "Sonstige Miete Enddatum",
  "ADDITIONAL RENT INDEXATION MODE": "Indizierungsmodus der sonstige Miete",
  "ADDITIONAL RENT PERIOD": "Dauer der sonstige Miete (Monaten)",
  "ADDITIONAL RENT START DATE": "Startdatum der sonstige Miete",
  "ADDITIONAL_FIELD": "Daten Thema",
  "ADDITIONAL_FIELD_END_DATE": "Enddatum",
  "ADDITIONAL_FIELD_START_DATE": "Startdatum",
  "ADDITIONAL_FIELD_TYPE": "Datenebene",
  "ADDITIONAL_FIELD_VALUE": "Daten",
  "ADDITIONAL_RENT": "Sonstige Miete (€)",
  "ADDITIONAL_RENT_END_DATE": "Enddatum der sonstige Miete",
  "ADDITIONAL_RENT_INDEXATION_MODE": "Indizierungsmodus der sonstige Miete",
  "ADDITIONAL_RENT_PERIOD": "Dauer der sonstige Miete (Monaten)",
  "ADDITIONAL_RENT_START_DATE": "Startdatum der sonstige Miete",
  "ADDRESS": "Adresse",
  "ADDRESS1": "Adresse",
  "ADDRESS2": "Andere Adresse",
  "ADJUSTED NET BOOK VALUE": "Netto-Buchwert nach benutzerdefinierter außerplanmäßigen Abschreibung (€)",
  "ADJUSTED_NET_BOOK_VALUE": "Netto-Buchwert nach benutzerdefinierter außerplanmäßigen Abschreibung (€)",
  "ADRESSE_1": "Adresse",
  "ADRESSE_2": "Adresse (suite)",
  "ADVANCE PAYMENTS INC TAX AMNT": "Überzahlungen (€)",
  "ADVANCE_ON_CURRENT_ACCOUNT": "Kontokorrentkredit (€)",
  "ADVANCE_PAYMENTS_INC_TAX_AMNT": "Überzahlungen (€)",
  "AGENCY": "(de) Agency",
  "AGING SCHEDULE": "Fälligkeitsliste",
  "AGING SCHEDULE AS AT": "Fälligkeitsliste zum",
  "ALERT_LEASE_EXPIRING_OTHER_UNIT": "",
  "ALERT_LEASE_UNIT": "Monat(e)",
  "ALERT_PROPERTY_VALUE_VARIATION_UNIT": "%",
  "ALERT_RENT_VARIATION_UNIT": "%",
  "ALERT_THRESHOLD": "Warnschwelle",
  "ALERT_VACANCY_RATE_UNIT": "%",
  "ALERT_VACANT_UNIT_UNIT": "Monat(e)",
  "AlhambraUserGuide": "Alhambra - Benutzerhandbuch.pdf",
  "ALL_AGENCY": "-- Alle Agentüre --",
  "ALL_ASSET_MANAGER": "-- Alle Asset Managers --",
  "ALL_BUSINESS_UNIT": "-- Alle Geschäftseinheiten --",
  "ALL_CLIENTS": "-- Alle Kunden --",
  "ALL_EXTERNAL_FUND": "-- Alle Fonds --",
  "ALL_FEE_CENTER": "-- Alle Gebührenstellen --",
  "ALL_LEASES": "Alle Mietverträge",
  "ALL_MANAGER": "-- Alle Property Managers --",
  "ALL_PORTFOLIOS": "-- Alle Portfolios --",
  "ALL_USERS": "-- Alle Benutzer --",
  "AllAuditAction": "Alle Veränderungen",
  "ALLOCATION_CODE": "(de) Allocation Code",
  "ALLOCATION_LABEL": "(de) Allocation Label",
  "ALLOWANCE IMPAIR LOSS CALC": "Kalkulierter Wertminderungsaufwand (+) oder Wertaufholung (-) (€)",
  "ALLOWANCE IMPAIR LOSS USER": "Benutzerdefinierter Wertminderungsaufwand (+) oder Wertaufholung (-) (€)",
  "ALLOWANCE_IMPAIR_LOSS_CALC": "Kalkulierter Wertminderungsaufwand (+) oder Wertaufholung (-) (€)",
  "ALLOWANCE_IMPAIR_LOSS_USER": "Benutzerdefinierter Wertminderungsaufwand (+) oder Wertaufholung (-) (€)",
  "AMENDMENT DATE": "(de) Amendment Date",
  "AMENDMENT DESCRIPTION": "(de) Amendment Description",
  "AMENDMENT TYPE": "(de) Amendment Type",
  "AMENDMENT_DATE": "Änderungsdatum",
  "AMENDMENT_DESCRIPTION": "Beschreibung der Änderung",
  "AMENDMENT_TYPE": "Änderungstyp",
  "AMOUNT": "Betrag (€)",
  "AMOUNT OF RECOVERABLE WORKS": "Betrag der Grossreparaturen (€)",
  "AMOUNT PERIOD 1": "<= 30 tage",
  "AMOUNT PERIOD 2": "31-60 tage",
  "AMOUNT PERIOD 3": "61-90 tage",
  "AMOUNT PERIOD 4": "91-180 tage",
  "AMOUNT PERIOD 5": "180-365 tage",
  "AMOUNT PERIOD 6": ">365 tage",
  "AMOUNT_OF_RECOVERABLE_WORKS": "Betrag der Grossreparaturen (€)",
  "AMOUNT_PERIOD_1": "<= 30 tage",
  "AMOUNT_PERIOD_2": "31-60 tage",
  "AMOUNT_PERIOD_3": "61-90 tage",
  "AMOUNT_PERIOD_4": "91-180 tage",
  "AMOUNT_PERIOD_5": "180-365 tage",
  "AMOUNT_PERIOD_6": ">365 tage",
  "AMOUNTS": "Beträge",
  "ANALYSIS DATE": "Zeitpunkt:",
  "ANALYSIS_DATE": "Zeitpunkt",
  "ANNEE_DEBUT": "Année début chantier",
  "ANNEE_DEBUT_PPAT": "Année début PPAT",
  "ANNEE_FIN": "Année fin chantier",
  "ANNEE_FIN_PPAT": "Année fin PPAT",
  "ANNEE_FUTURE": "Année",
  "ANNUAL AMOUNT": "Jährlicher Betrag (€/Jahr)",
  "ANNUAL FINANCIAL VACANCY": "Leerstandsmiete (€/Jahr)",
  "ANNUAL M2 AMOUNT": "Jährlicher Betrag pro m² (€/m²/Jahr)",
  "ANNUAL RENT AMOUNT": "Vertragsmiete (VM) (€/Jahr)",
  "ANNUAL RENT AMOUNT M2": "Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "ANNUAL SALES CERT Y MINUS 1": "Gemeldeter jährlicher Umsatz (€/Jahr)",
  "ANNUAL SALES CERT Y MINUS 2": "Gemeldeter jährlicher Umsatz (€/Jahr)",
  "ANNUAL_AMOUNT": "Jährlicher Betrag",
  "ANNUAL_AMOUNT_PER_UNIT": "(de) Annual Amount Per Unit",
  "ANNUAL_FINANCIAL_VACANCY": "Leerstandsmiete (€/Jahr)",
  "ANNUAL_RENT_AMOUNT": "Vertragsmiete (VM) (€/Jahr)",
  "ANNUAL_RENT_AMOUNT_M2": "Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "ANNUAL_RENT_AMOUNT_PER_SQM": "Anfangsmiete pro m² (€/m²/Jahr)",
  "ANNUAL_SALES_CERT_Y_MINUS_1": "Gemeldeter jährlicher Umsatz YYYY-1 (€/Jahr)",
  "ANNUAL_SALES_CERT_Y_MINUS_2": "Gemeldeter jährlicher Umsatz YYYY-2 (€/Jahr)",
  "APERIODIC": "Aperiodic",
  "APRIL_AMOUNT": "April (€)",
  "ARBITRAGE": "Verkäufe erstellen",
  "ARBITRAGE SALE PRICE": "Verkaufspreis (€)",
  "ARBITRAGE TOTAL VALUE": "Gesamter Kaufpreis des Objekts (€)",
  "ARBITRAGE TYPE": "Verkaufstyp",
  "ARBITRAGE_SALE_PRICE": "Verkaufspreis (€)",
  "ARBITRAGE_SALE_PRICE_mismatch": "Verkaufspreis ungültig",
  "ARBITRAGE_SALE_PRICE_WOU": "Verkaufspreis",
  "ARBITRAGE_STATUS": "Verkaufsstatus",
  "ARCHIVE AREA": "Archivfläche (m²)",
  "ARCHIVE_AREA": "Archivfläche (m²)",
  "AREA FOR SALE SQM": "Fläche zum Verkauf (m²)",
  "AREA UNIT": "Flächeneinheit",
  "AREA_FOR_SALE_SQM": "Fläche zum Verkauf (m²)",
  "AREA_FOR_SALE_SQM_mismatch": "Fläche zum Verkauf (m²) ungültig",
  "AREA_FOR_SALE_SQM_required": "Fläche zum Verkauf (m²) erforderlich",
  "AREA_SQM": "Mietfläche (m²)",
  "AREA_UNIT": "Flächeneinheit",
  "AREA_UNIT_CODE": "Flächeneinheitcode",
  "AREAS PERCENT": "% der Gesamtfläche des Portfolios",
  "AREAS_PERCENT": "% der Gesamtfläche des Portfolios",
  "ASSET MANAGER": "Vermögensverwalter",
  "ASSET_MANAGER": "Vermögensverwalter",
  "ASSET_MANAGER_NAME": "Vermögensverwalter",
  "ASSETS AREA": "Gesamte vermietbare Fläche (m²)",
  "ASSETS VALUE": "Netto-Verkehrswert (€)",
  "ASSETS_AREA": "Gesamte vermietbare Fläche (m²)",
  "ASSETS_UNDER_CONSTRUCTION": "Anlagen im Bau",
  "ASSETS_VALUE": "Netto-Verkehrswert (€)",
  "AUDIT ACTION LABEL": "Veränderung",
  "AUDIT TRAIL": "Prüfprotokoll",
  "AUDIT_ACTION_LABEL": "Veränderung",
  "AUGUST_AMOUNT": "August (€)",
  "AVERAGE PHYSICAL VACANCY RATE": "Durchschnittliche physische Leerstandsquote (%)",
  "AVERAGE VACANCY RATE": "Durchschnittliche physische Leerstandsquote (%)",
  "AVERAGE_VACANCY_RATE": "Durchschnittliche physische Leerstandsquote (%)",
  "BALANCE": "Saldo",
  "BALANCE BEFORE OVERPAYMENTS": "Zahlungsbilanz Saldo zum Periodenende exkl. Vorauszahlungen (€)",
  "BALANCE DATE": "Abschlussstichtag",
  "BALANCE END AMNT": "Zahlungsbilanz Saldo zum Periodenende inkl. Vorauszahlungen (€)",
  "BALANCE END AMOUNT": "Zahlungsbilanz Saldo (€) zum",
  "BALANCE INCL TAX AMOUNT": "Saldo (€)",
  "BALANCE START AMNT": "Zahlungsbilanz Saldo zum Periodenanfang (€)",
  "BALANCE START AMOUNT": "Zahlungsbilanz Saldo (€) zum",
  "BALANCE_BEFORE_OVERPAYMENTS": "Zahlungsbilanz Saldo zum Periodenende exkl. Vorauszahlungen (€)",
  "BALANCE_DATE": "Abschlussstichtag",
  "BALANCE_END_AMNT": "Zahlungsbilanz Saldo zum Periodenende (€)",
  "BALANCE_END_AMOUNT": "Zahlungsbilanz Saldo zum DD/MM/YYYY (€)",
  "BALANCE_INCL_TAX_AMOUNT": "Saldo (€)",
  "BALANCE_OUT_END_AMOUNT": "Zahlungsbilanz Saldo zum DD/MM/YYYY inkl. Vorauszahlungen (€)",
  "BALANCE_OUT_START_AMOUNT": "Zahlungsbilanz Saldo zum DD/MM/YYYY (€)",
  "BALANCE_START_AMNT": "Zahlungsbilanz Saldo zum Periodenanfang (€)",
  "BALANCE_START_AMOUNT": "Zahlungsbilanz Saldo zum DD/MM/YYYY (€)",
  "BANK GUARANTEE AMOUNT": "Mietkaution (€)",
  "BANK GUARANTEE END DATE": "Ablauf der Mietkaution",
  "BANK GUARANTEE START DATE": "Mietkaution-Beginn-Datum",
  "BANK JOURNAL": "(de) Paid by Bank Journal",
  "BANK_GUARANTEE_AMOUNT": "Mietkaution (€)",
  "BANK_GUARANTEE_END_DATE": "Ablauf der Mietkaution",
  "BANK_GUARANTEE_START_DATE": "Mietkaution-Beginn-Datum",
  "BANK_JOURNAL": "(de) Paid by Bank Journal",
  "BASE RENT": "Mindest- oder Sockelmiete (€/Jahr)",
  "BASE_RENT": "Mindest- oder Sockelmiete (€/Jahr)",
  "BATIMENT_CODE_DESTINATAIRE": "Batiment code emetteur",
  "BATIMENT_CODE_EMETTEUR": "Code bâtiment",
  "BEFORE PAYMENTS INC TAX AMNT": "Nachzahlungen (€)",
  "BEFORE PAYMENTS INC TAX AMOUNT": "Nachzahlungen (€)",
  "BEFORE TAX AMOUNT": "Rechnungsbetrag ohne Steuern",
  "BEFORE TAX AMOUNT TOTAL": "Betrag ohne Steuern",
  "BEFORE TAX AMOUNT TOTAL 1": "Betrag ohne Steuern",
  "BEFORE_PAYMENTS_INC_TAX_AMNT": "Nachzahlungen (€)",
  "BEFORE_PAYMENTS_INC_TAX_AMOUNT": "Nachzahlungen (€)",
  "BEFORE_TAX_AMOUNT": "Rechnungsbetrag ohne Steuern",
  "BEFORE_TAX_AMOUNT_TOTAL": "Betrag ohne Steuern",
  "BEFORE_TAX_AMOUNT_TOTAL_1": "Betrag ohne Steuern",
  "bfColumnType": "Typ der Säule",
  "BILLING_ITEMS": "Rechnungsposten",
  "BOR": "Rand",
  "BREAK_OPTION": "Sonderkündigungsrecht",
  "BREAKPOINT AMOUNT 1": "Grenzumsatz 1 (€/Jahr)",
  "BREAKPOINT AMOUNT 2": "Grenzumsatz 2 (€/Jahr)",
  "BREAKPOINT RATE 1": "Prozentsatz bei Prozentstaffel 1",
  "BREAKPOINT RATE 2": "Prozentsatz bei Prozentstaffel 2",
  "BREAKPOINT_AMOUNT_1": "Grenzumsatz 1 (€/Jahr)",
  "BREAKPOINT_AMOUNT_2": "Grenzumsatz 2 (€/Jahr)",
  "BREAKPOINT_RATE_1": "Prozentsatz bei Prozentstaffel 1",
  "BREAKPOINT_RATE_2": "Prozentsatz bei Prozentstaffel 2",
  "btnAdditionalFieldDownload": "Importvorlage downloaden",
  "btnBackAccount": "Zurück zu meinem Konto",
  "btnBackSearch": "Zurück zum Suchergebnis",
  "btnBrowse": "Durchsuchen",
  "btnBuildingPurchasing": "Laufende Erwerbe",
  "btnCancel": "Abbrechen",
  "btnCancelModalPopup": "Schließen",
  "btnClearCache": "Caches löschen",
  "btnClose": "Schließen",
  "btnCreate": "Schaffen",
  "btnDelete": "Streichen",
  "btnDeletePreviousFundUpdate": "Die ausgewählte Aktualisierung löschen",
  "btnDeletePurchase": "Den Erwerb streichen",
  "btnDeleteSale": "Den Verkauf streichen",
  "btnDeleteTranscodingType": "Löschen Sie den Daten Typ",
  "btnDeleteTranscodingTypeConfirmation": "Sind Sie sicher, dass Sie diese Zuordnung löschen wollen?",
  "btnDeleteTranscodingTypeConfirmationResult": "Sind Sie sicher, Das Mapping ist gelöscht worden.",
  "btnDownloadDoc": "Benutzerhandbuch",
  "btnErvDownload": "Vorlagedatei für Marktmiete herunterladen",
  "btnExport": "Exportieren",
  "btnFileTypeNotAllowed": "Dateityp nicht erlaubt",
  "btnFundDates": "Datumsangaben",
  "btnFundOwnershipDownload": "Vorlagedatei für Anlegerdaten herunterladen",
  "btnGeneralInformation": "Stammdaten",
  "btnGenerate": "Datei erzeugen",
  "btnGenerateFile": "Datei erzeugen",
  "btnImport": "(de) Impoort",
  "btnImportDownload": "Vorlagedatei herunterladen",
  "btnInsert": "Einfügen",
  "btnLaunch": "Schnittstelle starten",
  "btnLaunchInterface": "Schnittstelle starten",
  "btnLaunchReport": "Bericht herunterladen",
  "btnLeaseRepositoryDownload": "Vorlagedatei für Mietvertragsdaten herunterladen",
  "btnNetBookValueDownload": "Netto-Buchwerte hochladen",
  "btnNewBuilding": "Neues Objekt",
  "btnNewInterfaceLowCode": "Neue Schnittstelle mit niedrigem Code",
  "btnNewInterfaceTs": "Neuer Mieterliste-Datenstrom",
  "btnNewPurchase": "Neuer Erwerb",
  "btnNewUser": "Neuer Benutzer",
  "btnNext": "Weiter",
  "btnNormalReload": "Vollständig neu laden",
  "btnOwnership": "Investoren",
  "btnPartialReload": "Teilweise neu laden",
  "btnPurchasingHistory": "Überblick der Investitionsvolumen",
  "btnRedirect": "Zurück zur Login-Seite",
  "btnRepositoryBuildingExport": "Objekt-Repositorium Export",
  "btnRepositoryFundExport": "Fonds-Repositorium Export",
  "btnReset": "Einstellungen zurücksetzen",
  "btnReturnListInterface": "Zurück zur Liste der Schnittstellen",
  "btnSalesHistory": "Abgeschlossene Verkäufe",
  "btnSave": "Speichern",
  "btnSearch1": "Suche",
  "btnSelectColumns": "Kolonne",
  "btnSelectLogo": "Personalisierung",
  "btnSelectReportLogo": "Logo ändern",
  "btnSendGED": "Meine Rechnungen sehen",
  "btnSubmit": "Einreichen",
  "btnTotalReload": "Gesamte neu laden",
  "btnUnlock": "Entsperren",
  "btnUpdate": "Aktualisieren",
  "btnUpdateLocation": "Geo-Koordinaten umrechnen",
  "btnUpdatePurchase": "Den Erwerb aktualisieren",
  "btnUpload": "Datei importieren",
  "btnValidatePdd": "Die Abschreibungen bestätigen",
  "btnValidatePurchase": "Den Erwerb bestätigen",
  "btnValidateSale": "Den Verkauf bestätigen",
  "btnValuationDownload": "Bewertung-Daten hochladen",
  "btnYieldDownload": "Zielrenditen hochladen",
  "btSelectBuildingImage": "Bild ändern",
  "btStreetView": "Straßenperspektive",
  "BU MANAGER": "(de) BU Manager",
  "BU_MANAGER": "(de) BU Manager",
  "BUDGET 01 N": "Januar Budget",
  "BUDGET 02 N": "Februar Budget",
  "BUDGET 03 N": "März Budget",
  "BUDGET 04 N": "April Budget",
  "BUDGET 05 N": "Mai Budget",
  "BUDGET 06 N": "Juni Budget",
  "BUDGET 07 N": "Juli Budget",
  "BUDGET 08 N": "August Budget",
  "BUDGET 09 N": "September Budget",
  "BUDGET 10 N": "Oktober Budget",
  "BUDGET 11 N": "November Budget",
  "BUDGET 12 N": "Dezember Budget",
  "BUDGET AMOUNT BT": "Plan (€/Jahr)",
  "BUDGET BEGIN DATE": "(de) Budget Start Date",
  "BUDGET CODE": "(de) Budget Code",
  "BUDGET END DATE": "(de) Budget End Date",
  "BUDGET INITIAL N": "(de) Initial budget",
  "BUDGET INITIAL N M1": "(de) Initial budget",
  "BUDGET INITIAL N P1": "(de) Initial budget",
  "BUDGET LINE": "Kundenkontonummer",
  "BUDGET LINE 1": "Etikett der Haushaltslinie",
  "BUDGET LINE CODE": "Haushaltsliniecode",
  "BUDGET MONITORING": "Soll-Ist-Vergleich Bilanz",
  "BUDGET MONITORING AS AT": "Soll-Ist-Vergleich Bilanz zum",
  "BUDGET NAME": "(de) Budget Label",
  "BUDGET T1 N": "Budget V1",
  "BUDGET T2 N": "Budget V2",
  "BUDGET T3 N": "Budget V3",
  "BUDGET T4 N": "Budget V4",
  "BUDGET TODATE N": "(de) Budget as of",
  "BUDGET TYPE": "(de) Budget Type",
  "BUDGET YEAR": "(de) Budget Year",
  "BUDGET_01_N": "Januar Budget YYYY",
  "BUDGET_02_N": "Februar Budget YYYY",
  "BUDGET_03_N": "März Budget YYYY",
  "BUDGET_04_N": "April Budget YYYY",
  "BUDGET_05_N": "Mai Budget YYYY",
  "BUDGET_06_N": "Juni Budget YYYY",
  "BUDGET_07_N": "Juli Budget YYYY",
  "BUDGET_08_N": "August Budget YYYY",
  "BUDGET_09_N": "September Budget YYYY",
  "BUDGET_10_N": "Oktober Budget YYYY",
  "BUDGET_11_N": "November Budget YYYY",
  "BUDGET_12_N": "Dezember Budget YYYY",
  "BUDGET_BEGIN_DATE": "(de) Budget Start Date",
  "BUDGET_CODE": "(de) Budget Code",
  "BUDGET_END_DATE": "(de) Budget End Date",
  "BUDGET_INITIAL_N": "(de) Initial budget YYYY",
  "BUDGET_INITIAL_N_M1": "(de) Initial budget YYYY-1",
  "BUDGET_INITIAL_N_P1": "(de) Initial budget YYYY+1",
  "BUDGET_LINE": "Kundenkontonummer",
  "BUDGET_LINE_1": "Etikett der Haushaltslinie",
  "BUDGET_LINE_CODE": "Haushaltsliniecode",
  "BUDGET_NAME": "(de) Budget Label",
  "BUDGET_T1_N": "Budget V1 YYYY",
  "BUDGET_T2_N": "Budget V2 YYYY",
  "BUDGET_T3_N": "Budget V3 YYYY",
  "BUDGET_T4_N": "Budget V4 YYYY",
  "BUDGET_TODATE_N": "(de) Budget as of DDMMYYYY",
  "BUDGET_TYPE": "(de) Budget Type",
  "BUDGET_YEAR": "(de) Budget Year",
  "BUDGETED N": "Plan (€/Jahr)",
  "BUDGETED_N": "Plan YYYY (€/Jahr)",
  "BUILDING": "(de) Building",
  "BUILDING ADDRESS": "Adresse",
  "BUILDING CITY": "Stadt",
  "BUILDING CODE": "Objekt-Nummer",
  "BUILDING COUNTRY": "Land",
  "BUILDING DELIVERY DATE": "Übergabedatum",
  "BUILDING DESCRIPTION": "Objekt-Überblick",
  "BUILDING MAIN USE": "Objekt-Nutzungsart",
  "BUILDING NAME": "Objekt-Name",
  "BUILDING NATURE": "Objekt-Nutzungsart",
  "BUILDING NATURE LABEL": "Objekt-Nutzungsart",
  "BUILDING NATURE LABEL TOTAL": "Gesamt",
  "BUILDING NATURE LABEL TOTAL1": "Gesaùt",
  "BUILDING NUMBER": "Gebäude-Nummer",
  "BUILDING OTHER NATURES": "Andere Objekt-Nutzungsarte",
  "BUILDING OTHER USES": "Andere Nutzung(en)",
  "BUILDING POSTAL CODE": "Postleitzahl",
  "BUILDING PRICE": "Kaufpreis des Objekts (€)",
  "BUILDING PURCHASE DATE": "Erwerbsdatum",
  "BUILDING SUMMARY": "Objekt-Überblick",
  "BUILDING SURFACE": "Gebäudefläche",
  "BUILDING TENANCY SCHEDULE": "Mieterliste nach Objekt zum",
  "BUILDING TENANCY SCHEDULE SHEET": "Mieterliste nach Objekt",
  "BUILDING TOTAL": "Gesamt",
  "BUILDING TOTAL AREA": "Gesamte vermietbare Fläche (m²)",
  "BUILDING TOTAL AREA SQM": "Gebäudefläche (m²)",
  "BUILDING TOTAL AREA SQM AT DATE": "Gesamte vermietbare Fläche (m²) zum",
  "BUILDING TOTAL VALUE": "Netto-Verkehrswert (€)",
  "BUILDING TYPE LABEL": "Objekttyp",
  "BUILDING_ACCUMULATED_DEPRECIATION": "Kumulierte Abschreibung - Gebäude",
  "BUILDING_ADDRESS": "Adresse",
  "BUILDING_ADDRESS1": "Adresse",
  "BUILDING_ADDRESS1_required": "Adresse erforderlich",
  "BUILDING_ADDRESS2": "Andere Adresse",
  "BUILDING_CITY": "Stadt",
  "BUILDING_CITY_required": "Objekt-Stadt erforderlich",
  "BUILDING_CODE": "Objekt-Nummer",
  "BUILDING_CODE_required": "Objekt-Nummer erforderlich",
  "BUILDING_COUNTRY": "Land",
  "BUILDING_COUNTRY_required": "Land erforderlich",
  "BUILDING_DELIVERY_DATE": "Übergabedatum",
  "BUILDING_LATITUDE": "Breitengrad",
  "BUILDING_LONGITUDE": "Längengrad",
  "BUILDING_NAME": "Objekt-Name",
  "BUILDING_NAME_required": "Objekt-Name erforderlich",
  "BUILDING_NATURE": "Objekt-Nutzungsart",
  "BUILDING_NATURE_CODE": "Code Objekt-Nutzungsart",
  "BUILDING_NATURE_LABEL": "Objekt-Nutzungsart",
  "BUILDING_POSTAL_CODE": "Postleitzahl",
  "BUILDING_POSTAL_CODE_required": "Postleitzahl erforderlich",
  "BUILDING_PRICE": "Kaufpreis des Objekts (€)",
  "BUILDING_PRICE_mismatch": "Kaufpreis des Objekts ungültig",
  "BUILDING_PRICE_WOU": "Kaufpreis des Objekts",
  "BUILDING_PURCHASE_DATE": "Erwerbsdatum",
  "BUILDING_SEARCH": "Ein Objekt zum Erwerb suchen",
  "BUILDING_SURFACE": "Gebäudefläche",
  "BUILDING_TOTAL_AREA": "Gesamte vermietbare Fläche (m²)",
  "BUILDING_TOTAL_AREA_SQM": "Gebäudefläche (m²)",
  "BUILDING_TOTAL_AREA_SQM_AT_DATE": "Gesamte vermietbare Fläche (m²) zum DD/MM/YYYY",
  "BUILDING_TOTAL_AREA_SQM_mismatch": "Gesamte vermietbare Fläche ungültig",
  "BUILDING_TOTAL_AREA_SQM_WOU": "Gesamte vermietbare Fläche",
  "BUILDING_TOTAL_VALUE": "Netto-Verkehrswert (€)",
  "BUILDING_TOTAL_VALUE_mismatch": "Gesamter Kaufpreis des Objekts ungültig",
  "BUILDING_TOTAL_VALUE_required": "Gesamter Kaufpreis des Objekts erforderlich",
  "BUILDING_TOTAL_VALUE_SQM": "Gesamter Kaufpreis pro m² (€/m²)",
  "BUILDING_TOTAL_VALUE_WOU": "Gesamter Kaufpreis des Objekts",
  "BUILDING_TYPE_CODE": "(de) Property Class Code",
  "BUILDING_TYPE_LABEL": "Objekttyp",
  "BUILDING_UNIQUE_CODE": "Objekt-Nummer",
  "BUILDING_VALUE": "Gebäudewert",
  "BUILDING_VALUE_VARIATION_RATE": "Steigerungsquote",
  "BUILDINGS COUNT": "Anzahl von Immobilien",
  "BUILDINGS PERCENT": "% der gesamten Anzahl von Objekten",
  "BUILDINGS_COUNT": "Anzahl von Objekten",
  "BUILDINGS_PERCENT": "% der gesamten Anzahl von Objekten",
  "BUSINESS_UNITS": "Geschäftseinheiten",
  "BUYER NAME": "Erwerber-Name",
  "BUYER_NAME": "Erwerber-Name",
  "BVARIATION RATE 01 N": "(de) Variation rate 01/",
  "BVARIATION_RATE_01_N": "(de) Variation rate 01/YYYY",
  "BY BUILDING NATURE": "Nach Objekt-Nutzungsart",
  "BY BUILDING NATURE SUMED": "Nach Objekt-Nutzungsart kumuliert",
  "BY FUND NAME": "(de) By Fund Entity Name",
  "BY FUND NAME SUMED": "(de) By Fund Entity Name Running Total",
  "BY INVOICE": "(de) By Invoice",
  "BY INVOICE SUMED": "(de) By Invoice Running Total",
  "BY INVOICING ITEM": "Nach Rechnungsposten",
  "BY INVOICING ITEM SUMED": "Nach Rechnungsposten kumuliert",
  "BY LEASE": "Nach Mietvertrag",
  "BY LEASE NATURE": "Nach Mietvertragstyp",
  "BY LEASE SUMED": "Nach Mietvertrag kumuliert",
  "BY OWNER": "(de) By Owner",
  "BY OWNER SUMED": "(de) By Owner Running Total",
  "BY PORTFOLIO": "(de) By Portfolio",
  "BY PORTFOLIO SUMED": "(de) By Portfolio Running Total",
  "BY PROPERTY": "Nach Objekt",
  "BY PROPERTY SUMED": "Nach Objekt kumuliert",
  "BY YEAR": "Nach Jahr",
  "CANCELLED_AT": "(de) Cancelled at",
  "CAPITAL": "Gezeichnetes Kapital (€)",
  "CAPITAL CALLS": "Abgerufenes Eigenkapital (€)",
  "CAPITAL EXPENSES": "Investitionskosten (InvK) (€/year)",
  "CAPITAL GAIN REINVESTMENT": "Wiederanlage (€)",
  "CAPITAL GROWTH": "Wertänderung absolut (€)",
  "CAPITAL GROWTH BREAKDOWN": "Aufteilung der Wertänderung nach Objekt",
  "CAPITAL GROWTH RATE": "Wertänderung relativ (%)",
  "CAPITAL RECEIPTS": "Weitere Erträge (€/Jahr)",
  "CAPITAL RETURN": "Wertänderungsrendite (WÄR)",
  "CAPITAL RETURN FUND": "Wertänderungsrendite (WÄR)",
  "CAPITAL STRUCTURE": "GuV + Kapitalstruktur",
  "CAPITAL_CALLS": "Abgerufenes Eigenkapital (€)",
  "CAPITAL_EXPENSES": "Investitionskosten (InvK) YYYY-1 (€/year)",
  "CAPITAL_GAIN_REINVESTMENT": "Wiederanlage (€)",
  "CAPITAL_GROWTH": "Wertänderung absolut (€)",
  "CAPITAL_GROWTH_RATE": "Wertänderung relativ (%)",
  "CAPITAL_RECEIPTS": "Weitere Erträge YYYY-1 (€/Jahr)",
  "CAPITAL_RETURN": "Wertänderungsrendite (WÄR)",
  "CASH FLOW": "Cash-Flow",
  "CASH FLOW BREAKDOWN": "Aufteilung des Objekt-Cashflows",
  "CASH ON CASH": "Cash-on-Cash",
  "CASH ON CASH RETURN": "Cash-on-Cash Rendite",
  "CASH_FLOW": "Objekt-Cashflow (€)",
  "CATCH UP AMOUNT": "(de) Catch-up Amount (€)",
  "CATCH UP END DATE": "(de) Catch-up End Date",
  "CATCH UP PERIOD": "(de) Catch-up Period (months)",
  "CATCH UP START DATE": "(de) Catch-up Start Date",
  "CATCH_UP_AMOUNT": "(de) Catch-up Amount (€)",
  "CATCH_UP_END_DATE": "(de) Catch-up End Date",
  "CATCH_UP_PERIOD": "(de) Catch-up Period (months)",
  "CATCH_UP_START_DATE": "(de) Catch-up Start Date",
  "cbActivation": "Schnittstelle Aktivierung",
  "cbErvDownload": "Die Datei automatisch mit den Daten zur aktuellsten Wertstellung generieren",
  "cbFundOwnershipDownload": "Die Datei automatisch mit den Daten zum aktuellsten Aktualisierungsdatum generieren",
  "cbLeaseRepositoryDownload": "Vorlagedatei für Mietvertragsdaten herunterladen",
  "cbNetBookValueDownload": "Die Datei automatisch mit den Daten zum aktuellsten Abschlussstichtag generieren",
  "cbValuationDownload": "Die Datei automatisch mit den Daten zum aktuellsten Bewertungsstichtag generieren",
  "cbYieldDownload": "Die Datei automatisch mit den Daten zum aktuellsten Bewertungsstichtag generieren",
  "CHANTIER_CODE_DESTINATAIRE": "Chantier code destinatire",
  "CHANTIER_CODE_EMETTEUR": "Chantier code emetteur",
  "CHANTIER_EMETTEUR_PREALABLE": "Chantier emetteur préalable",
  "CHARTS": "Diagramme",
  "CHARTS BY AREA": "Diagramme Nach Mietfläche",
  "CHARTS BY BUILDING NATURE": "Diagramme nach Objekt-Nutzungsart",
  "CHARTS BY LEASE": "Diagramme Nach Mietvertrag",
  "CHARTS BY PROPERTY TYPE": "Diagramme nach Immobilientyp",
  "CHARTS BY UNIT": "Diagramme Nach Mieteinheit",
  "CHRONO GED": "(de) Chrono",
  "CHRONO_GED": "(de) Chrono",
  "CITY": "Stadt",
  "CLASSIFICATION_ELLIGIBLE_PINEL": "Classification élligible PINEL",
  "CLASSIFICATION_ICMS_EQUIPEMENT": "Code équipement ICMS",
  "CLEAR_FILTERS": "Filter löschen",
  "CLIENT ACCOUNT NUMBER": "Kundenkontonummer",
  "CLIENT ACCOUNT NUMBER LABEL": "Bezeichnung des Kundenkontos",
  "CLIENT_ACCOUNT_NUMBER": "Kundenkontonummer",
  "CLIENT_ACCOUNT_NUMBER_LABEL": "Bezeichnung des Kundenkontos",
  "CODE_EQUIPEMENT_CLASSIFICATION_SYPEMI": "Code équipement SYPEMI",
  "CODE_POSTAL": "Code postal",
  "COLLECTION MONITORING": "Überwachung der Ist-Mieteinnahmen",
  "COLLECTION MONITORING BREAKDOWN": "Verteilung der Ist-Mieteinnahmen nach Laufzeit",
  "COLLECTION MONITORING BREAKDOWN BY BUILDING": "(de) Breakdown of Rental Income by Collection Period and by Property",
  "COLLECTION MONITORING BREAKDOWN BY PORTFOLIO": "(de) Breakdown of Rental Income by Collection Period and by Portfolio",
  "COLLECTION MONITORING SUM": "Kumulierte Verteilung der Ist-Mieteinnahmen nach Laufzeit",
  "COLLECTION RATE": "Inkassorate",
  "COLLECTION_RATE": "Inkassorate",
  "COLOR_BACK": "Background color (de)",
  "COLOR_FONT": "Font color (de)",
  "COLUMN NAME": "Spalte-Name",
  "COLUMN_NAME": "Spalte-Name",
  "COMMENT": "[DE] Comments",
  "COMMENT N": "(de) Comment",
  "COMMENT N M1": "(de) Comment",
  "COMMENT N P1": "(de) Comment",
  "COMMENT_N": "(de) Comment YYYY",
  "COMMENT_N_M1": "(de) Comment YYYY-1",
  "COMMENT_N_P1": "(de) Comment YYYY+1",
  "COMMENTAIRE": "Commentaire",
  "COMMENTAIRE_VALIDATION": "Commentaire validation",
  "COMMENTS": "(de) Comments",
  "COMMERCIAL LEASE SCHEDULE": "Nächstmögliche Kündigungstermine",
  "COMMITMENT": "Zugesagtes Eigenkapital (€)",
  "COMMITMENT AGREEMENT DATE": "Datum der Kaufverpflichtung",
  "COMMITMENT_AGREEMENT_DATE": "Datum der Kaufverpflichtung",
  "COMMITMENT_AGREEMENT_DATE_mismatch": "Datum der Kaufverpflichtung ungültig",
  "COMMON AREA SHARE": "(de) Common Area Share (m²)",
  "COMMON_AREA_SHARE": "(de) Common Area Share (m²)",
  "COMPANY REGISTRATION NUMBER": "Handels- und Gesellschaftsregister-Nummer",
  "COMPANY_REGISTRATION_NUMBER": "Handels- und Gesellschaftsregister-Nummer",
  "Compilation Failed": "Kompilierung fehlgeschlagen",
  "CONDOMINIUM UNIT CODE": "(de) Condominium Unit Code",
  "CONDOMINIUM_UNIT_CODE": "(de) Condominium Unit Code",
  "Conflict": "Konflikt",
  "CONTACT_FIRSTNAME": "(de) First name",
  "CONTACT_FUNCTION": "(de) Function",
  "CONTACT_NAME": "(de) Name",
  "CONTACT_SERVICE": "(de) Service",
  "CONTRACT COMMAND CODE": "(de) Contrat or Order",
  "CONTRACT COMMAND NAME": "(de) Contrat or Order Label",
  "CONTRACT TYPE CODE": "(de) Contract Type",
  "CONTRACT_COMMAND_CODE": "(de) Contrat or Order",
  "CONTRACT_COMMAND_NAME": "(de) Contrat or Order Label",
  "CONTRACT_TYPE_CODE": "(de) Contract Type",
  "CONTRIBUTION TO PORTFOLIO TOTAL RETURN": "Objektbeitrag zum Portfolioreturn",
  "CONTROL DATE": "Änderung ab",
  "CONTROL_DATE": "Änderung ab",
  "COST PRICE AMOUNT": "Anschaffungspreis (€)",
  "COST_PRICE_AMOUNT": "Anschaffungspreis (€)",
  "COST_PRICE_AMOUNT_mismatch": "Anschaffungspreis ungültig",
  "COST_PRICE_AMOUNT_WOU": "Anschaffungspreis",
  "COUNTRY": "Land",
  "Creation": "Erstellung",
  "CREATION DATE": "Erstellungsdatum",
  "CREDIT": "(de) Credit",
  "CURRENCY": "Währung",
  "CURRENCY PER SQM PER YEAR": "€/m²/J",
  "CURRENCY PER YEAR": "€/J",
  "CURRENT ANNUAL RENT AMOUNT": "Vertragsmiete (VM) (€/Jahr)",
  "CURRENT EQUIPMENT GUARANTEE": "(de) Current equipment guarantee (€)",
  "CURRENT GUARANTEE 1": "(de) Current guarantee 1 (€)",
  "CURRENT GUARANTEE 2": "(de) Current guarantee 2 (€)",
  "CURRENT GUARANTEE 3": "(de) Current guarantee 3 (€)",
  "CURRENT INDEX DATE": "Datum letzte Indexanpassung",
  "CURRENT INDEX TYPE LABEL": "Indextyp",
  "CURRENT INDEX VALUE": "Letzter Indexstand",
  "CURRENT LEASE SUMMARY": "Überblick des aktuellen Mietvertrags",
  "CURRENT MARKET VALUE": "Marktmiete pro m² (€/m²/Jahr)",
  "CURRENT MONTHLY RENT AMOUNT": "Monatliche Vertragsmiete (VM) (€/Monat)",
  "CURRENT REAL ESTATE DISPOSITION": "Laufende Verkäufe zum",
  "CURRENT REAL ESTATE PURCHASE": "Laufende Erwerbe zum",
  "CURRENT RENT AMOUNT PER SQM": "Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "CURRENT WORKING CAPITAL": "(de) Current working capital (€)",
  "CURRENT_ANNUAL_RENT_AMOUNT": "Vertragsmiete (VM) (€/Jahr)",
  "CURRENT_EQUIPMENT_GUARANTEE": "(de) Current equipment guarantee (€)",
  "CURRENT_GUARANTEE_1": "(de) Current guarantee 1 (€)",
  "CURRENT_GUARANTEE_2": "(de) Current guarantee 2 (€)",
  "CURRENT_GUARANTEE_3": "(de) Current guarantee 3 (€)",
  "CURRENT_INDEX_DATE": "Datum letzte Indexanpassung",
  "CURRENT_INDEX_TYPE_LABEL": "(de) Current Index Type",
  "CURRENT_INDEX_VALUE": "Letzter Indexstand",
  "CURRENT_MARKET_VALUE": "Marktmiete pro m² (€/m²/Jahr)",
  "CURRENT_MONTHLY_RENT_AMOUNT": "Monatliche Vertragsmiete (VM) (€/Monat)",
  "CURRENT_RENT_AMOUNT_PER_SQM": "Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "CURRENT_WORKING_CAPITAL": "(de) Current working capital (€)",
  "CUSTOMER_GROUP_CODE": "(de) Portoflio Code",
  "CUSTOMER_GROUP_NAME": "(de) Portoflio Name",
  "CUT_OFF_DATE": "Abschlussstichtag",
  "DATE OF CONSTRUCTION": "Baujahr",
  "DATE OF DATA": "Objekt-Datenblatt zum",
  "DATE OF PURCHASE": "Erwerbsdatum",
  "DATE_DEBUT_TRAVAUX_LIEE_AU_STATUT": "Date début travaux",
  "DATE_ENVOI": "Date d'import",
  "DATE_RECEPTION_TRAVAUX_LIEE_AU_STATUT": "Date réception travaux",
  "DateFormat": "dd.MM.yyyy",
  "DE_PORTFOLIO_LABEL": "(de) Portfolio Name (German)",
  "DE_SECTOR_LABEL": "(de) Market Geography (German)",
  "DE1": "Cell (de)",
  "DEBIT": "(de) Debit",
  "DECEMBER_AMOUNT": "Dezember (€)",
  "Deletion": "Löschung",
  "DELIVERY ACTUAL DATE": "Ist-Übergabedatum",
  "DELIVERY ESTIMATED DATE": "Soll-Übergabedatum",
  "DELIVERY_ACTUAL_DATE": "Ist-Übergabedatum",
  "DELIVERY_ACTUAL_DATE_mismatch": "Ist-Übergabedatum ungültig",
  "DELIVERY_ESTIMATED_DATE": "Soll-Übergabedatum",
  "DELIVERY_ESTIMATED_DATE_mismatch": "Soll-Übergabedatum ungültig",
  "DEPRECIATION": "Abschreibungen (€)",
  "DEPRECIATION PROVISION AMOUNT": "Zuvor erfasster Wertminderungsaufwand (€)",
  "DEPRECIATION_PROVISION_AMOUNT": "Zuvor erfasster Wertminderungsaufwand (€)",
  "DESCRIPTIF_DE_LA_NATURE_DU_CHANTIER": "Descriptif de la Nature du chantier",
  "DESCRIPTION_DE_LA_ZONE_DU_CHANTIER": "Description de la Zone du chantier",
  "DESTINATAIRE": "Destinataire",
  "DESTINATION_CODE": "(de) Destination Code",
  "DESTINATION_LABEL": "(de) Destination Label",
  "DETAILLED AGING SCHEDULE": "Ausführliche Fälligkeitsliste",
  "DETENTION STRUCTURE": "Fondsstruktur",
  "DIFF BUDGET N REAL N MINUS 1": "Plan vs. Ist Abweichung absolut (€/Jahr)",
  "DIFF BUDGETED N REALISED N": "Plan vs. Ist Abweichung absolut (€/Jahr)",
  "DIFF N M1": "(de) Difference Realised / Accounting",
  "DIFF N M1 01": "(de) Difference Realised",
  "DIFF N M1 02": "(de) /Accounting",
  "DIFF REALISED N BUDGETED N": "Plan vs. Ist Abweichung absolut (€/Jahr)",
  "DIFF REALISED N N MINUS 1": "Ist vs. Ist Abweichung absolut (€/Jahr)",
  "DIFF_BUDGET_N_REAL_N_MINUS_1": "Plan YYYY vs. Ist YYYY-1 Abweichung absolut (€/Jahr)",
  "DIFF_BUDGETED_N_REALISED_N": "Plan YYYY vs. Ist YYYY Abweichung absolut (€/Jahr)",
  "DIFF_N_M1": "(de) Difference realised YYYY-1 / Accounting YYYY-1",
  "DIFF_REALISED_N_BUDGETED_N": "Verfügbare Budget (€/Jahr)",
  "DIFF_REALISED_N_N_MINUS_1": "Ist YYYY vs. Ist YYYY-1 Abweichung absolut (€/Jahr)",
  "DISPLAY NAME": "Beschreibung",
  "DISPLAY_NAME": "Beschreibung",
  "DISPLAY_THE_LEASE_THAT_ARE": "Mietverträge anzeigen:",
  "DISPLAY_THE_PROP_THAT_ARE": "Objekte anzeigen:",
  "DISPOSITION HISTORY €": "Überblick der Verkäufe von Immobilien nach Immobilientyp und Netto-Marktwert",
  "DISPOSITION HISTORY M2": "Überblick der Verkäufe von Immobilien nach Immobilientyp und Gesamtfläche",
  "DISPOSITION HISTORY RECORD": "Überblick der Verkäufe von Immobilien",
  "DISPOSITION VALUE": "Verkaufswert (€)",
  "DISPOSITION VALUE SQM": "Verkaufswert pro m² (€/m²)",
  "DISTRIBUTION VALORIZATION": "Ausschüttung + Leistung",
  "DIVIDEND": "Schlussdividenden (€)",
  "DIVIDEND ADVANCE": "Zwischendividenden (€)",
  "DIVIDEND PER SHARE": "Dividende pro Anteil",
  "DIVIDEND PROFIT DISTRIBUTIONS": "Ausschüttungen (€)",
  "DIVIDEND_ADVANCE": "Zwischendividenden (€)",
  "DIVIDEND_PER_SHARE": "Dividende pro Anteil",
  "DOCUMENT NUMBER": "Belegnummer",
  "DOCUMENT_DATE": "(de) Document Date",
  "DOCUMENT_LINE_NUMBER": "(de) Document line number",
  "DOCUMENT_NUMBER": "Belegnummer",
  "DOCUMENT_TYPE_LABEL": "(de) Document Type",
  "DOOR NUMBER": "Türnummer",
  "DOOR_NUMBER": "Türnummer",
  "DOTATION PDDI": "Weitere Abschreibung (€)",
  "DOTATION_PDDI": "Weitere Abschreibung (€)",
  "DOUBLE NET RENT": "Doppel-Nettomiete (€)",
  "DOUBLE NET RENT WOU": "Doppel-Nettomiete",
  "DUE DATE": "Fälligkeitsdatum",
  "DUE INVOICES BALANCE END AMOUNT": "Endssaldo fälliger Rechnungen (€) zum",
  "DUE INVOICES BALANCE START AMOUNT": "Anfangssaldo fälliger Rechnungen (€) zum",
  "DUE OUTSTAND PAY INC TAX AMOUNT": "Ausstehende Zahlungen (€)",
  "DUE_DATE": "Fälligkeitsdatum",
  "DUE_INVOICES_BALANCE_END_AMOUNT": "Endssaldo fälliger Rechnungen (€) zum DD/MM/YYYY",
  "DUE_INVOICES_BALANCE_START_AMOUNT": "Anfangssaldo fälliger Rechnungen (€) zum DD/MM/YYYY",
  "DUE_OUTSTAND_PAY_INC_TAX_AMOUNT": "Ausstehende Zahlungen (€)",
  "DURATION": "Restmietvertragslaufzeit (nächstmöglicher Kündigungstermin) (J-M-T)",
  "DURATION TO BREAK DATE": "Restmietvertragslaufzeit (nächstmöglicher Kündigungstermin) (J-M-T)",
  "DURATION TO LEASE END": "Restmietvertragslaufzeit (Mietvertragsablauf) (J-M-T)",
  "DURATION_TO_BREAK_DATE": "Restmietvertragslaufzeit (nächstmöglicher Kündigungstermin) (J-M-T)",
  "DURATION_TO_LEASE_END": "Restmietvertragslaufzeit (Mietvertragsablauf) (J-M-T)",
  "EARNINGS BEFORE INTEREST": "Betriebsergebnis (€)",
  "EARNINGS_BEFORE_INTEREST": "Betriebsergebnis (€)",
  "EBIT": "Netto-Mieteinnahmen (NME)",
  "ECONOMIC RENT": "Effektivmiete über die Laufzeit verteilt (€/Jahr)",
  "ECONOMIC RENT BY HEADLINE RENT": "% Effektivmiete",
  "ECONOMIC RENT BY SQM": "Effektivmiete über die Laufzeit verteilt pro m² (€/m²/Jahr)",
  "ECONOMIC_RENT": "Effektivmiete über die Laufzeit verteilt (€/Jahr)",
  "ECONOMIC_RENT_BY_HEADLINE_RENT": "% Effektivmiete",
  "ECONOMIC_RENT_PER_SQM": "Effektivmiete über die Laufzeit verteilt pro m² (€/m²/Jahr)",
  "EDITION TIME": "Bearbarbeitung am:",
  "EFFECTIVE GROSS CAPITAL RATE": "Brutto-Kapitalisierungszinssatz auf effektive Mietniveau",
  "EFFECTIVE GROSS INITIAL YIELD": "Brutto-Anfangsrendite auf Basis EM",
  "EFFECTIVE NET CAPITAL RATE": "Netto-Kapitalisierungszinssatz auf effektive Mietniveau",
  "EFFECTIVE NET INITIAL YIELD": "Netto-Anfangsrendite auf Basis NME",
  "EFFECTIVE RENT": "Effektivmiete (EM) (€/Jahr)",
  "EFFECTIVE_GROSS_CAPITAL_RATE": "Brutto-Kapitalisierungszinssatz auf effektive Mietniveau",
  "EFFECTIVE_GROSS_INITIAL_YIELD": "Brutto-Anfangsrendite auf Basis EM",
  "EFFECTIVE_NET_CAPITAL_RATE": "Netto-Kapitalisierungszinssatz auf effektive Mietniveau",
  "EFFECTIVE_NET_INITIAL_YIELD": "Netto-Anfangsrendite auf Basis NME",
  "EFFECTIVE_RENT": "Effektivmiete (EM) (€/Jahr)",
  "emailGreetingsToUser": "Lieber Nutzer,",
  "emailLinkCodeExpiration": "Der Link und der temporäres Codiert werden 15 Minuten lang gültig bleiben, nach diese Frist müssen Sie eine neue Passwort wieder anfragen.",
  "emailLinkExpiration": "Der Link wird 15 Minuten lang gültig bleiben, nach diese Frist müssen Sie eine neue Passwort wieder anfragen.",
  "emailPwdRecoveryIntro": "Sie haben auf der Website von Alhambra ein neues Passwort angefordert",
  "emailPwdRecoveryLinkClick": "Bitte klicken Sie auf den untenstehenden Link, um Ihr Passwort zurückzusetzen.",
  "emailPwdRecoveryObject": "Alhambra - Passwort-Wiederherstellung",
  "emailPwdRecoveryProvideSMSCode": "und geben Sie das untenstehende SMS Code an",
  "emailPwdRecoveryProvideTempPwd": "und geben Sie das  untenstehende temporäre Codiert an",
  "emailRegards": "Herzliche Grüsse",
  "emailWebAlhambraTeamSignature": "Alhambra Team.",
  "EMETTEUR": "Emetteur",
  "EN_PORTFOLIO_LABEL": "(de) Portfolio Name (English)",
  "EN_SECTOR_LABEL": "(de) Market Geography (English)",
  "END MANAGEMENT DATE": "Betriebsende",
  "END_DATE": "(de) End Date",
  "END_MANAGEMENT_DATE": "Betriebsende",
  "END_MANAGEMENT_DATE_mismatch": "Betriebsende ungültig",
  "END_OF_LEASE": "Mietvertragsablauf",
  "ENTERPRISE ABATEMENT": "Mietfreie Zeit - Betrag (€)",
  "ENTERPRISE ANNUAL AMOUNT": "Jährlicher Betrag (€/Jahr)",
  "ENTERPRISE GROSS HEADLINE RENT": "Vertragsmiete (VM) (€/Jahr)",
  "ENTERPRISE GROSS HEADLINE RENT M2": "Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "ENTERPRISE MONTHLY AMOUNT": "Monatlicher Betrag (€/Monat)",
  "ENTERPRISE REAL ESTATE": "Gewerbeimmobilie",
  "ENTERPRISE RENTED AREA": "Vermietete Fläche (m²)",
  "ENTERPRISE TOTAL AREA": "Gesamte vermietbare Fläche (m²)",
  "ENTERPRISE VACANT AREA": "Leerstandsfläche (m²)",
  "ENTRANCE FEES": "(de) Entrance Fees (€)",
  "ENTRANCE_FEES": "(de) Entrance Fees (€)",
  "ENTREPRISE MONTHLY M2 AMOUNT": "Monatlicher Betrag pro m² (€/m²/Monat)",
  "ENTRY": "(de) Reference Number",
  "ENTRY_AMOUNT": "(de) Entry Amount",
  "ENTRY_DIRECTION_CODE": "(de) Entry Direction code",
  "ENTRY_LABEL": "(de) Entry Label",
  "EQUIPMENT GUARANTEE CALLS": "(de) Equipment guarantee calls (€)",
  "EQUIPMENT GUARANTEE RECEIVABLE": "(de) Equipment guarantee receivable (€)",
  "EQUIPMENT GUARANTEE REFUNDS": "(de) Equipment guarantee refund (€)",
  "EQUIPMENT_GUARANTEE_CALLS": "(de) Equipment guarantee calls (€)",
  "EQUIPMENT_GUARANTEE_RECEIVABLE": "(de) Equipment guarantee receivable (€)",
  "EQUIPMENT_GUARANTEE_REFUNDS": "(de) Equipment guarantee refund (€)",
  "EQUITY": "Eigenkapital (€)",
  "EQUITY FINANCING": "Finanzierung",
  "ERE ABATEMENT": "Gewerbeimmobilie - Mietfreie Zeit - Betrag (€)",
  "ERE GROSS HEADLINE RENT": "Gewerbeimmobilie - Vertragsmiete (VM) (€/Jahr)",
  "ERE GROSS HEADLINE RENT M2": "Gewerbeimmobilie - Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "ERE RENTED AREA": "Gewerbeimmobilie - Vermietete Fläche (m²)",
  "ERE TOTAL AREA": "Gewerbeimmobilie - Gesamtfläche (m²)",
  "ERE VACANT AREA": "Gewerbeimmobilie - Leerstandsfläche (m²)",
  "ERE_ABATEMENT": "Gewerbeimmobilie - Mietfreie Zeit (€)",
  "ERE_GROSS_HEADLINE_RENT": "Gewerbeimmobilie - Vertragsmiete (VM) (€/Jahr)",
  "ERE_GROSS_HEADLINE_RENT_M2": "Gewerbeimmobilie - Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "ERE_OCCUPIED_AREA_SQM": "Gewerbeimmobilie - Vermietete Fläche (m²)",
  "ERE_TOTAL_AREA_SQM": "Gewerbeimmobilie - Gesamtfläche (m²)",
  "ERE_VACANT_AREA_SQM": "Gewerbeimmobilie - Leerstandsfläche (m²)",
  "errorHandler": "Fehlerseite",
  "ESTIMATED_RENTAL_VALUE": "Marktmiete pro m² (€/m²/Jahr)",
  "ESTIMATED_RENTAL_VALUE_WOU": "Marktmiete",
  "ETAT_DE_LA_FONCTION_DE_L_EQUIPEMENT": "Etat de la fonction de l'équipement",
  "ETAT_VISUEL_DE_L_EQUIPEMENT": "Etat visuel de l'équipement",
  "EU VAT IDENTIFICATION NUMBER": "(de) EU VAT Identification Number",
  "EU_VAT_IDENTIFICATION_NUMBER": "(de) EU VAT Identification Number",
  "EVALUATION DATE": "Bewertungsstichtag",
  "EVOLUTION OF RENTAL EXPENSES": "Entwicklung der Bewirtschaftungskosten",
  "EVOLUTION OF RENTAL INCOME": "Entwicklung der Mieteinnahmen",
  "EVOLUTION YEAR": "Jahr",
  "EVOLUTION_YEAR": "Jahr",
  "Excel": "Excel",
  "EXPECTED_YIELD": "Zielrendite",
  "EXPENSES BANK ACCOUNT": "Bankkonto-Aufwendungen",
  "EXPENSES_BANK_ACCOUNT": "(de) Compte bancaire Dépenses",
  "EXPORT DATE": "Heruntergeladen am",
  "EXTERNAL DEBT": "Fremdkapital",
  "EXTERNAL FUND NAME": "Funds",
  "EXTERNAL_BUILDING_NATURE_LABEL": "(de) External Property Type",
  "EXTERNAL_FUND_NAME": "Funds",
  "EXTERNAL_GEOGRAPHIC_ZONE_CODE": "(de) Customer Geographical Zone",
  "EXTRANET REF": "(de) Extranet Reference Number",
  "EXTRANET_REF": "(de) Extranet Reference Number",
  "EXTRAORDINARY DEPRECIATION": "Außergewöhnliche Abschreibung (€)",
  "EXTRAORDINARY ITEMS": "Außerordentliches Ergebnis (€)",
  "EXTRAORDINARY RENT": "(de) Extraordinary Rent (€)",
  "EXTRAORDINARY RENT END DATE": "(de) Extraordinary Rent End Date",
  "EXTRAORDINARY RENT PERIOD": "(de) Extraordinary Rent Period (€)",
  "EXTRAORDINARY RENT START DATE": "(de) Extraordinary Rent Start Date",
  "EXTRAORDINARY RESULT": "Außerordentliches Ergebnis (€)",
  "EXTRAORDINARY_DEPREC": "Außergewöhnliche Abschreibung (€)",
  "EXTRAORDINARY_DEPRECIATION": "Außergewöhnliche Abschreibung (€)",
  "EXTRAORDINARY_ITEMS": "Außerordentliches Ergebnis (€)",
  "EXTRAORDINARY_RENT": "(de) Extraordinary Rent (€)",
  "EXTRAORDINARY_RENT_END_DATE": "(de) Extraordinary Rent End Date",
  "EXTRAORDINARY_RENT_PERIOD": "(de) Extraordinary Rent Period (€)",
  "EXTRAORDINARY_RENT_START_DATE": "(de) Extraordinary Rent Start Date",
  "FACADE_ACCUMULATED_DEPRECIATION": "Kumulierte Abschreibung - Wesentliche Bestandteile",
  "FACADE_VALUE": "Wert der wesentlichen Bestandteile",
  "FAIR MARKET RENT": "Brutto-Marktmiete (MM) (€/Jahr)",
  "FAIR MARKET RENT WOU": "Netto-Marktmiete",
  "FAIR_MARKET_RENT": "Brutto-Marktmiete (MM) (€/Jahr)",
  "FAIR_MARKET_RENT_WOU": "Brutto-Marktmiete (MM)",
  "FEBRUARY_AMOUNT": "Februar (€)",
  "FEE NAME": "(de) Fee name",
  "FEE TYPE CODE": "(de) Fee Type Code",
  "FEE TYPE NAME": "(de) Fee Type",
  "FEE_CENTER_CODE": "(de) Code centre honoraire",
  "FEE_NAME": "(de) Fee name",
  "FEE_TYPE_CODE": "(de) Fee Type Code",
  "FEE_TYPE_NAME": "(de) Fee Type",
  "FIN_VAC_CURRENT_MONTH": "Leerstandsmiete aktueller Monat",
  "FIN_VAC_MINUS_01_MONTH": "Leerstandsmiete 1 Monat zuvor",
  "FIN_VAC_MINUS_02_MONTH": "Leerstandsmiete 2 Monaten zuvor",
  "FIN_VAC_MINUS_03_MONTH": "Leerstandsmiete 3 Monaten zuvor",
  "FIN_VAC_MINUS_04_MONTH": "Leerstandsmiete 4 Monaten zuvor",
  "FIN_VAC_MINUS_05_MONTH": "Leerstandsmiete 5 Monaten zuvor",
  "FIN_VAC_MINUS_06_MONTH": "Leerstandsmiete 6 Monaten zuvor",
  "FIN_VAC_MINUS_07_MONTH": "Leerstandsmiete 7 Monaten zuvor",
  "FIN_VAC_MINUS_08_MONTH": "Leerstandsmiete 8 Monaten zuvor",
  "FIN_VAC_MINUS_09_MONTH": "Leerstandsmiete 9 Monaten zuvor",
  "FIN_VAC_MINUS_10_MONTH": "Leerstandsmiete 10 Monaten zuvor",
  "FIN_VAC_MINUS_11_MONTH": "Leerstandsmiete 11 Monaten zuvor",
  "FINAL CAPITAL CLOSING DATE": "Abschlussstichtag",
  "FINAL_CAPITAL_CLOSING_DATE": "Abschlussstichtag",
  "FINANCIAL INCOME": "Finanzergebnis (€)",
  "FINANCIAL OCCUPANCY RATE": "Wirtschaftliche Vermietungsquote",
  "FINANCIAL OCCUPANCY SUMMARY": "Vermietungsstand nach Mietertrag",
  "FINANCIAL RESULT": "Finanzergebnis (€)",
  "FINANCIAL VACANCY": "Leerstandsmiete (€/Jahr)",
  "FINANCIAL VACANCY BY SQM": "Leerstandsmiete pro m² (€/m²/Jahr)",
  "FINANCIAL VACANCY BY YEAR": "Leerstandsmiete (€/Jahr)",
  "FINANCIAL VACANCY CUMULATE": "Kumulierte Leerstandsmiete (€)",
  "FINANCIAL VACANCY M2": "Leerstandsmiete (€)",
  "FINANCIAL VACANCY PER SQM": "Leerstandsmiete pro m² (€/m²/Jahr)",
  "FINANCIAL VACANCY RATE": "Leerstandsquote nach Mietertrag",
  "FINANCIAL VACANCY SHEET": "Leerstandsmiete",
  "FINANCIAL VACANCY WOU": "Leerstandsmiete",
  "FINANCIAL_INCOME": "Finanzergebnis (€)",
  "FINANCIAL_OCC_RATE": "Wirtschaftliche Vermietungsquote",
  "FINANCIAL_OCCUPANCY_RATE": "Wirtschaftliche Vermietungsquote",
  "FINANCIAL_VAC_RATE": "Wirtschaftliche Leerstandsquote",
  "FINANCIAL_VACANCY": "Leerstandsmiete (€/Jahr)",
  "FINANCIAL_VACANCY_PER_SQM": "Leerstandsmiete pro m² (€/m²/Jahr)",
  "FINANCIAL_VACANCY_RATE": "Leerstandsquote nach Mietertrag",
  "FITTINGS_ACCUMULATED_DEPRECIATION": "Kumulierte Abschreibung - Zubehör",
  "FITTINGS_VALUE": "Wert des Zubehörs",
  "FIXTURES_ACCUMULATED_DEPRECIATION": "Kumulierte Abschreibung - Einbauten",
  "FIXTURES_VALUE": "Wert der Einbauten",
  "FLOOR": "Geschoss-Nummer",
  "FLOORS": "Geschoss",
  "FOO": "Footer (de)",
  "FormatFile": "(de) Format fichier",
  "FormatMontant": "(de) Format des montants",
  "FormatSeparatorThousands": "Tausendertrennzeichen-Format",
  "FR_PORTFOLIO_LABEL": "(de) Portfolio Name (French)",
  "FR_SECTOR_LABEL": "(de) Market Geography (French)",
  "FREED LEASES NUMBER": "Gekündigten Mietverträgen",
  "FREED LEASES RATE": "Kündigungsquote (Mietverträge)",
  "FREED UNITS AREA": "Vermietete Fläche von gekündigten Mietverträgen (m²)",
  "FREED UNITS AREA RATE": "Kündigungsquote (vermietbare Fläche)",
  "FREED UNITS NUMBER": "Anzahl von gekündigten Mieteinheiten",
  "FREED UNITS RATE": "Kündigungsquote (Mieteinheiten)",
  "FREED_LEASES_NUMBER": "Gekündigten Mietverträgen",
  "FREED_LEASES_RATE": "Kündigungsquote (Mietverträge)",
  "FREED_UNITS_AREA": "Vermietete Fläche von gekündigten Mietverträgen (m²)",
  "FREED_UNITS_AREA_RATE": "Kündigungsquote (vermietbare Fläche)",
  "FREED_UNITS_NUMBER": "Anzahl von gekündigten Mieteinheiten",
  "FREED_UNITS_RATE": "Kündigungsquote (Mieteinheiten)",
  "FreezePanesFromRowNumber": "Frieren Sie die Scheiben aus der Reihe Nr. Ein",
  "FreezePanesUntilLineNumber": "Frieren Sie die Scheiben bis zur Linie Nr. Ein",
  "FREQUENCY": "Intervall (in Monaten)",
  "FREQUENCY CODE": "Fakturierungsintervall",
  "FREQUENCY_CODE": "Fakturierungsintervall (in Monaten)",
  "FULLSCREEN": "Vollbildschirm",
  "FUND ADDRESS": "Adresse",
  "FUND CODE": "Fondscode",
  "FUND DATA END DATE": "Validity End Date",
  "FUND DATA START DATE": "Aktualisiert am",
  "FUND DATE OF DATA": "Abschlussstichtag",
  "FUND ISIN CODE": "ISIN-Code",
  "FUND LEGAL VEHICLE STRUCT": "Rechtsform / Objekt-Nutzungsart",
  "FUND MANAGER": "Fondsverwalter",
  "FUND NAME": "Fondsname",
  "FUND PRORATA": "Fonds/Vehikel (anteilig)",
  "FUND_CODE": "Fondscode",
  "FUND_DATA_END_DATE": "Validity End Date",
  "FUND_DATA_START_DATE": "Aktualisiert am",
  "FUND_ISIN_CODE": "ISIN-Code",
  "FUND_MANAGER": "Fondsverwalter",
  "FUND_NAME": "Fondsname",
  "FUNDING_AMOUNT": "Finanzierungsbetrag",
  "FUNDS MANAGEMENT": "Fonds-Repositorium",
  "GARBAGE COLLECTION TAX AMOUNT": "(de) Garbage Collection Tax Amount (€)",
  "GARBAGE_COLLECTION_TAX_AMOUNT": "(de) Garbage Collection Tax Amount (€)",
  "GEARING RATIO": "Fremdkapitalquote",
  "GENERAL EXPENSES REPORT": "allgemeine Ausgabenaufstellung",
  "GENERAL INFORMATION": "Stammdaten",
  "generalErrorMsg": "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch mal. Falls das Problem weiterhin besteht, nehmen sie bitte Kontakt mit dem Kundenbetreuungsdienst.",
  "GEOGRAPHICAL BREAKDOWN": "Aufteilung des Netto-Verkehrswerts nach Standort",
  "GEOGRAPHICAL SUB ZONE": "Unterstandort",
  "GEOGRAPHICAL ZONE": "Standort",
  "GEOGRAPHICAL_SUB_ZONE": "Unterstandort",
  "GEOGRAPHICAL_ZONE": "Standort",
  "GLOBAL RETURNS": "Total Returns",
  "GONE": "(de) Gone",
  "GR_HEAD_RENT_BY_GR_BOOK_VAL": "Brutto-Buchwertrendite",
  "GRAPH TENANT TURNOVER LEASES NUMBER": "Entwicklung der Neuvermietungen und Kündigungen (Mietverträge)",
  "GRAPH TENANT TURNOVER UNITS AREA": "Entwicklung der Neuvermietungen und Kündigungen (vermietbare Fläche)",
  "GRAPH TENANT TURNOVER UNITS NUMBER": "Entwicklung der Neuvermietungen und Kündigungen (Mieteinheiten)",
  "Graph1Attr1": "Vermietete Fläche",
  "Graph1Attr2": "Leerstandsfläche",
  "Graph2Attr1": "Büro",
  "Graph2Attr2": "Geschäft",
  "Graph3Attr3": "Potentieller Mietreinertrag",
  "Graph3Attr4": "Netto-Marktmiete",
  "Graph4Attr1": "Umgelegte umlegbare Bewirtschaftungskosten (BWK)",
  "Graph4Attr2": "Umlegbare Bewirtschaftungskosten (BWK)",
  "Graph4Attr3": "Nicht umlegbare Bewirtschaftungskosten (nBWK)",
  "Graph4Attr4": "Gesamtbewirtschaftungskosten",
  "Graph5Attr1": "Vertragsmiete (VM)",
  "Graph5Attr2": "Sonderkündigungsrecht",
  "Graph5Attr3": "Mietvertragsablauf",
  "Graph5Attr4": "Neuvermietungsquote",
  "Graph6Attr1": "Ist",
  "Graph6Attr2": "Plan",
  "Graph6Attr3": "Laufende Instandhaltung",
  "Graph6Attr4": "Sanierung",
  "graphArbitrageHistoryArea": "Überblick der Verkäufe von Immobilien nach Immobilientyp und Gesamtfläche",
  "graphArbitrageHistoryValue": "Überblick der Investitionsvolumen nach Immobilientyp und Anschaffungswert",
  "graphHoldoverTenancyRentAmount": "Aufteilung der nicht ausgezogenen Mietern nach Mietvertragstyp",
  "graphHoldoverTenancyRentAmountPerYear": "Nicht ausgezogene Mieter",
  "graphLeaseEnterpriseSchedule1": "Nächstmögliche Kündigungstermine (vermietbare Fläche)",
  "graphLeaseEnterpriseSchedule2": "Nächstmögliche Kündigungstermine (Vertragsmiete)",
  "graphLeaseMonitoringAmount": "Mietsteigerung und Mietanpassungspotenzial",
  "graphLeaseMonitoringByNature": "Aufteilung der Mietverträgen nach Nutzungsart",
  "graphOutstandPayBalanceBreak": "Fälligkeitsliste-Aufteilung nach laufenden und gekündigten Mietverträge",
  "graphPortfolioBreakdownByNatureArea": "Gesamtfläche-Aufteilung nach Objekt-Nutzungsart",
  "graphPortfolioBreakdownByNatureBuildingsNumber": "Immobilienanzahl-Aufteilung nach Objekt-Nutzungsart",
  "graphPortfolioBreakdownByNatureLeasesNumber": "Aufteilung der Anzahl von Mietverträgen nach Objekt-Nutzungsart",
  "graphPortfolioBreakdownByNatureMarketValue": "Aufteilung des Netto-Verkehrswerts nach Objekt-Nutzungsart",
  "graphPortfolioBreakdownByNatureRent": "Aufteilung der Vertragsmiete nach Objekt-Nutzungsart",
  "graphPortfolioBreakdownByNatureUnitsNumber": "Aufteilung der Anzahl von Mieteinheiten nach Objekt-Nutzungsart",
  "graphPortfolioBreakdownByTypeArea": "Gesamtfläche-Aufteilung nach Immobilientyp",
  "graphPortfolioBreakdownByTypeBuildingsNumber": "Immobilienanzahl-Aufteilung nach Immobilientyp",
  "graphPortfolioBreakdownByTypeLeasesNumber": "Aufteilung der Anzahl von Mietverträgen nach Immobilientyp",
  "graphPortfolioBreakdownByTypeMarketValue": "Aufteilung des Netto-Verkehrswerts nach Immobilientyp",
  "graphPortfolioBreakdownByTypeRent": "Aufteilung der Vertragsmiete nach Immobilientyp",
  "graphPortfolioBreakdownByTypeUnitsNumber": "Aufteilung der Anzahl von Mieteinheiten nach Immobilientyp",
  "graphPortfolioEvolutionArea": "Portfolio-Entwicklung (Gesamtfläche)",
  "graphPortfolioEvolutionBuildNatArea": "Portfolio-Entwicklung nach Immobilientyp (Gesamtfläche)",
  "graphPortfolioEvolutionBuildNatGrossHeadlineRent": "Portfolio-Entwicklung nach Immobilientyp (Vertragsmiete)",
  "graphPortfolioEvolutionBuildNatNetEffectiveRent": "Portfolio-Entwicklung nach Immobilientyp (Netto-Mieteinnahmen)",
  "graphPortfolioEvolutionBuildNatValue": "Portfolio-Entwicklung nach Immobilientyp (Netto-Marktwert)",
  "graphPortfolioEvolutionBuildNumber": "Portfolio-Entwicklung (Anzahl von Immobilien)",
  "graphPortfolioEvolutionBuildNumberNat": "Portfolio-Entwicklung nach Immobilientyp (Anzahl von Immobilien)",
  "graphPortfolioEvolutionTotalGrossHeadlineRent": "Portfolio-Entwicklung (Vertragsmiete)",
  "graphPortfolioEvolutionTotalNetEffectiveRent": "Portfolio-Entwicklung (Netto-Mieteinnahmen)",
  "graphPortfolioEvolutionValue": "Portfolio-Entwicklung (Netto-Marktwert)",
  "graphPurchaseHistoryArea": "Überblick der Investitionsvolumen nach Immobilientyp und Gesamtfläche",
  "graphPurchaseHistoryValue": "Überblick der Investitionsvolumen nach Immobilientyp und Anschaffungswert",
  "graphTenantTurnoverLeasesNumber": "Entwicklung der Neuvermietungen und Kündigungen (Mietverträge)",
  "graphTenantTurnoverUnitsArea": "Entwicklung der Neuvermietungen und Kündigungen (vermietbare Fläche)",
  "graphTenantTurnoverUnitsNumber": "Entwicklung der Neuvermietungen und Kündigungen (Mieteinheiten)",
  "graphVacantUnitsByUnitType": "Aufteilung der leerstehenden Mieteinheiten nach Mieteinheiten-Nutzungsart",
  "graphVacantUnitsByVacancyType": "Aufteilung der leerstehenden Mieteinheiten nach Leerstandsart",
  "GROSS BOOK VALUE": "Bruttobuchwert (€)",
  "GROSS CAPITAL RATE": "Brutto-Kapitalisierungszinssätze",
  "GROSS DEBT": "Fremdkapital (€)",
  "GROSS DEBT UNDERLYING VEHICLES": "Fremdkapital (Beteiligungen) (€)",
  "GROSS EFFECT RENT RECEIVABLE": "Ist-Effektivmiete (EM) (€/Jahr)",
  "GROSS EFFECTIVE RENT": "Effektivmiete (EM) (€/Jahr)",
  "GROSS EFFECTIVE RENT WOU": "Effektivmiete (EM)",
  "GROSS HEADLINE RENT": "Vertragsmiete (VM) (€/Jahr)",
  "GROSS HEADLINE RENT BY NET MARKET VALUE": "Brutto-Anfangsrendite auf Basis VM",
  "GROSS HEADLINE RENT M2": "Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "GROSS HEADLINE RENT PERCENT": "% der gesamten Vertragsmiete (VM)",
  "GROSS HEADLINE RENT WOU": "Vertragsmiete (VM)",
  "GROSS INITIAL YIELD": "Brutto-Anfangsrendite",
  "GROSS INITIAL YIELDS": "Bruttorendite",
  "GROSS LEASING AREA": "Bruttomietfläche (m²)",
  "GROSS LEASING AREA WOU": "Bruttomietfläche",
  "GROSS MARKET VALUE": "Brutto-Verkehrswert (€)",
  "GROSS POTENTIAL RENT": "Sollmiete (SM) (€/Jahr)",
  "GROSS POTENTIAL RENTAL INCOME": "Potentielle Brutto-Mieteinnahmen",
  "GROSS RECEIPT RENT": "Eingezogene Miete (€)",
  "GROSS RECEIPT RENT WOU": "Eingezogene Miete",
  "GROSS RECEIPTED RENT": "Ist-Effektivmiete (€)",
  "GROSS RECEIPTED RENT BY GROSS BOOK VALUE": "",
  "GROSS RECEIPTED RENT BY NET MARKET VALUE": "",
  "GROSS RECEIPTED RENT FUND": "Ist-Effektivmiete (€)",
  "GROSS RENTAL INCOME": "Brutto-Mieteinnahmen",
  "GROSS RENTAL INCOME FUND": "Brutto-Mieteinnahmen (€)",
  "GROSS RENTAL INCOME OWNED": "Miete (€)",
  "GROSS REVERS CAPITAL RATE": "Brutto-Kapitalisierungszinssatz auf Marktmietniveau",
  "GROSS REVERS POTENT OCCUP AREA": "Auf die Vermietete Fläche bezogene",
  "GROSS REVERS POTENT OCCUP AREA FULL": "Mietanpassungspotenzial (vermietete Fläche)",
  "GROSS REVERS POTENT TOTAL PROP": "Auf die Gesamtfläche bezogene",
  "GROSS REVERS POTENT TOTAL PROP FULL": "Mietanpassungspotenzial (Gesamtfläche)",
  "GROSS REVERSIONARY POTENTIAL": "Mietanpassungspotenzial",
  "GROSS REVERSIONARY YIELD": "Brutto-Anpassungsrendite auf Basis MM",
  "GROSS_BOOK_VALUE": "Bruttobuchwert (€)",
  "GROSS_EFFECT_RENT_RECEIVABLE": "Ist-Effektivmiete (EM) YYYY-1 (€/Jahr)",
  "GROSS_EFFECTIVE_RENT": "Effektivmiete (EM) (€/Jahr)",
  "GROSS_HEADLINE_RENT": "Vertragsmiete (VM) (€/Jahr)",
  "GROSS_HEADLINE_RENT_M2": "Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "GROSS_HEADLINE_RENT_PERCENT": "% der gesamten Vertragsmiete (VM)",
  "GROSS_LEASABLE_AREA_SQM": "Bruttomietfläche (m²)",
  "GROSS_MARKET_VALUE": "Brutto-Verkehrswert (€)",
  "GROSS_MARKET_VALUE_Dashboard": "Brutto-Verkehrswert",
  "GROSS_POTENTIAL_RENT": "Sollmiete (SM) (€/Jahr)",
  "GROSS_POTENTIAL_RENT_WOU": "Sollmiete (SM)",
  "GROSS_RECEIPTED_RENT": "Ist-Effektivmiete (€)",
  "GROSS_REVERS_CAPITAL_RATE": "Brutto-Kapitalisierungszinssatz auf Marktmietniveau",
  "GROSS_REVERS_POTENT_OCCUP_AREA": "Mietanpassungspotenzial (vermietete Fläche)",
  "GROSS_REVERS_POTENT_TOTAL_PROP": "Mietanpassungspotenzial (Gesamtfläche)",
  "GROSS_REVERSIONARY_YIELD": "Brutto-Anpassungsrendite auf Basis MM",
  "GUARANTEE BANK 1": "(de) Bank of Guarantee 1",
  "GUARANTEE BANK 2": "(de) Bank of Guarantee 2",
  "GUARANTEE BANK 3": "(de) Bank of Guarantee 3",
  "GUARANTEE END DATE 1": "(de) End Date of Guarantee 1",
  "GUARANTEE END DATE 2": "(de) End Date of Guarantee 2",
  "GUARANTEE END DATE 3": "(de) End Date of Guarantee 3",
  "GUARANTEE START DATE 1": "(de) Start date of Guarantee 1",
  "GUARANTEE START DATE 2": "(de) Start date of Guarantee 2",
  "GUARANTEE START DATE 3": "(de) Start date of Guarantee 3",
  "GUARANTEE TYPE": "Mietkautionsart",
  "GUARANTEE TYPE 1": "(de) Type of Guarantee 1",
  "GUARANTEE TYPE 2": "(de) Type of Guarantee 2",
  "GUARANTEE TYPE 3": "(de) Type of Guarantee 3",
  "GUARANTEE_BANK_1": "(de) Bank of Guarantee 1",
  "GUARANTEE_BANK_2": "(de) Bank of Guarantee 2",
  "GUARANTEE_BANK_3": "(de) Bank of Guarantee 3",
  "GUARANTEE_END_DATE_1": "(de) End Date of Guarantee 1",
  "GUARANTEE_END_DATE_2": "(de) End Date of Guarantee 2",
  "GUARANTEE_END_DATE_3": "(de) End Date of Guarantee 3",
  "GUARANTEE_START_DATE_1": "(de) Start date of Guarantee 1",
  "GUARANTEE_START_DATE_2": "(de) Start date of Guarantee 2",
  "GUARANTEE_START_DATE_3": "(de) Start date of Guarantee 3",
  "GUARANTEE_TYPE": "Mietkautionsart",
  "GUARANTEE_TYPE_1": "(de) Type of Guarantee 1",
  "GUARANTEE_TYPE_2": "(de) Type of Guarantee 2",
  "GUARANTEE_TYPE_3": "(de) Type of Guarantee 3",
  "HD1": "Header 1 (de)",
  "HD2": "Header 2 (de)",
  "HD3": "Header 3 (de)",
  "HEA": "Header (de)",
  "HEAD OFFICE": "Hauptbüro",
  "HeaderLine": "Kopfzeile behalten",
  "HEADLINE GROSS CAPITAL RATE": "Brutto-Kapitalisierungszinssatz auf Netto-Kaltmietniveau",
  "HEADLINE GROSS INITIAL YIELD": "Brutto-Anfangsrendite auf Basis VM",
  "HEADLINE NET CAPITAL RATE": "Netto-Kapitalisierungszinssatz auf Netto-Kaltmietniveau",
  "HEADLINE NET INITIAL YIELD": "Netto-Anfangsrendite auf Basis nachhaltiger NME",
  "HEADLINE_GROSS_CAPITAL_RATE": "Brutto-Kapitalisierungszinssatz auf Netto-Kaltmietniveau",
  "HEADLINE_GROSS_INITIAL_YIELD": "Brutto-Anfangsrendite auf Basis VM",
  "HEADLINE_NET_CAPITAL_RATE": "Netto-Kapitalisierungszinssatz auf Netto-Kaltmietniveau",
  "HEADLINE_NET_INITIAL_YIELD": "Netto-Anfangsrendite auf Basis nachhaltiger NME",
  "HELD SHARES NUMBER": "Anzahl gehaltener Anteilen",
  "HELD_SHARES_NUMBER": "Anzahl gehaltener Anteilen",
  "HELD_SHARES_NUMBER_mismatch": "Anzahl der gehaltenen Anteile ungültig",
  "hlAlertsPage": "Alarme Seite",
  "hlLoginLink": "Anmeldung",
  "hlMyAssets": "Maximieren",
  "HOLDING COMPANY": "Dachgesellschaft",
  "HOLDING_COMPANY": "Dachgesellschaft",
  "HOLDOVER LEASE": "Anzahl von Mietverträgen",
  "HOLDOVER LEASE NUMBER": "Anzahl der nicht ausgezogenen Mietern",
  "HOLDOVER LEASE SUMMARY": "Mietvertragsüberblick",
  "HOLDOVER RENT": "Vertragsmiete (€/Jahr)",
  "HOLDOVER RENT M2": "Vertragsmiete pro m² (€/m²/Jahr)",
  "HOLDOVER TENANCY": "Nicht ausgezogene Mieter zum",
  "HOLDOVER TENANCY BREAKDOWN BY LEASE NATURE": "Aufteilung der nicht ausgezogenen Mietern nach Mietvertragstyp",
  "HOLDOVER TENANCY SHEET": "Liste der nicht ausgezogenen Mietern",
  "HOLDOVER TENANCY SUMMARY": "Nicht ausgezogene Mieter",
  "HOUSING_COMPLEX": "(de) Groupe d'immeubles",
  "httpErrorMsg": "Es ist ein HTTP Fehler aufgetreten. Seite nicht gefunden. Bitte versuchen Sie es noch mal.",
  "ID_ARBITRAGE_TYPE": "(de) Disposition Type Identity",
  "ID_BUILDING_NATURE": "(de) Property Type Identity",
  "ID_CURRENT_INDEX_TYPE": "(de) Index Current Type Code",
  "ID_DOCUMENT_TYPE": "(de) Document Type Code",
  "ID_INDEX_DIRECTION": "(de) Rent Review Direction Code",
  "ID_INDEX_FLEXIBILITY": "(de) Flexibility Index Code",
  "ID_INDEX_RHYTHM_UNIT_OF_ADJUSTMENT": "(de) Rent Review Frequency Unit of Ajustment",
  "ID_INDEXATION_THRESHOLD_TYPE": "(de) Rent Review Threshold Type Code",
  "ID_INITIAL_INDEX_TYPE": "(de) Index Initial Type Code",
  "ID_INVESTOR": "(de) Investor Identity",
  "ID_LEGAL_VEHICLE_STRUCTURE": "(de) Legal Vehicle Structure Identity",
  "ID_NEXT_INDEX_TYPE": "(de) Index Next Type Code",
  "ID_PURCH_WORK_PROGRES_STATE": "Status der Renovierung",
  "ID_PURCHASE_GOAL": "Zweck des Erwerbs",
  "ID_PURCHASE_PROGRES_STATE": "Erwerbsstatus",
  "ID_PURCHASE_TYPE": "Bezugsart",
  "ID_REINVOICING_METHOD": "(de) Reinvoicing Method Code",
  "ID_REPOSITORY_ARBITRAGE": "Verkauf-Nummer",
  "ID_REPOSITORY_FILE_IMPORT": "(de) Import File Identity",
  "ID_REPOSITORY_PURCHASE": "Erwerb-Nummer",
  "ID_SALE_PROGRES_STATE": "Verkaufsstatus",
  "ID_SALE_PROGRESSION_STATE": "(de) Disposition Status Identity",
  "ID_SEQUENCE": "(de) Sequence Number",
  "IDENTIFICATION": "Objekt-Überblick",
  "IMPACT_CRITERES_ESG": "Impact critères ESG",
  "IMPAIR LOSS CALC": "Kalkulierte außerplanmäßige Abschreibung (€)",
  "IMPAIR_LOSS_CALC": "Kalkulierte außerplanmäßige Abschreibung (€)",
  "IMPAIRMENT CHARGE": "Netto-Buchwert nach außerplanmäßiger Abschreibung (€)",
  "IMPAIRMENT LOSS": "Benutzerdefinierte außerplanmäßige Abschreibung (€)",
  "IMPAIRMENT RATIO": "Abschreibungsquote",
  "IMPAIRMENT_CHARGE": "Netto-Buchwert nach außerplanmäßiger Abschreibung (€)",
  "IMPAIRMENT_CHARGE_RECORDED_IN_PRIOR_PERIODS": "Zuvor erfasster Wertminderungsaufwand",
  "IMPAIRMENT_LOSS": "Benutzerdefinierte außerplanmäßige Abschreibung (€)",
  "IMPAIRMENT_RATIO": "Abschreibungsquote",
  "IMPORT_DATE": "(de) Import Date",
  "ImportParamTranscodingError": "(de) Error when importing param_transcoding file",
  "ImportParamTranscodingErrorColumnIsEmpty": "Das Feld Spalte ist leer",
  "ImportParamTranscodingErrorColumnNotExist": "Die spalte existiert nicht",
  "ImportParamTranscodingErrorOnePortfolio": "(de) Only one portfolio by file can be imported",
  "ImportParamTranscodingErrorOnePropertyManager": "(de) Only one Property Manager by file can be imported",
  "ImportParamTranscodingErrorOneTranscodingType": "(de) Only one transcoding type by file can be imported",
  "ImportParamTranscodingErrorOneTranscodingTypeFilled": "(de) At least one of the FR, EN or DE label must be filled",
  "ImportParamTranscodingErrorPortfolioIsEmpty": "Das Feld portfolio ist leer",
  "ImportParamTranscodingErrorTableIsEmpty": "Das Feld Tabelle ist leer",
  "ImportParamTranscodingErrorTableNotExist": "Der Tabelle existiert nicht",
  "ImportParamTranscodingErrorTableOrColumnNotSame": "Die Felder Tabelle und Spalte müssen in jeder Zeile identisch sein.",
  "ImportParamTranscodingPortfolioNotExist": "Das portfolio existiert nicht",
  "IMPUTATION": "Imputation",
  "IN_ADVANCE": "Im Voraus",
  "IN_ARREARS": "Im Rückstand",
  "INACTIVE_AREA": "Inactive Area",
  "INACTIVE_AS_OF_THE_END_DATE": "Inaktiv zum Enddatum",
  "INACTIVE_AS_OF_THE_REPORT_DATE": "Inaktiv zum Zeitpunkt",
  "INACTIVE_AS_OF_THE_START_DATE": "Inaktiv zum Beginndatum",
  "INCENTIVE RATE": "% Incentive",
  "INCENTIVE_RATE": "% Incentive",
  "INCEPTION DATE": "Datum Fondsauflage",
  "INCEPTION_DATE": "Datum Fondsauflage",
  "IncludBuildingInTS": "(de) Immeubles à inclure Etat Locatif",
  "INCLUDING TAX AMOUNT": "Rechnungsbetrag mit Steuern",
  "INCLUDING TAX AMOUNT 1": "Rechnungsbetrag",
  "INCLUDING TAX AMOUNT TOTAL": "Betrag einschließlich aller Steuern",
  "INCLUDING TAX AMOUNT TOTAL 1": "Rechnungsbetrag",
  "INCLUDING_TAX_AMOUNT": "Rechnungsbetrag mit Steuern",
  "INCLUDING_TAX_AMOUNT_1": "Rechnungsbetrag",
  "INCLUDING_TAX_AMOUNT_TOTAL": "Betrag einschließlich aller Steuern",
  "INCLUDING_TAX_AMOUNT_TOTAL_1": "Rechnungsbetrag",
  "INCOME INCREASE ON RENTED AREA": "Auf die Vermietete Fläche bezogene",
  "INCOME INCREASE PROPERTY WIDE": "Auf die Gesamtfläche bezogene",
  "INCOME RETURN": "Cashflow-Rendite (CFR)",
  "INCOME RETURN FUND": "Einkommensrendite",
  "INCOME TAX": "Steuern vom Einkommen und Ertrag (€)",
  "INCOME_RETURN": "Cashflow-Rendite (CFR)",
  "INCOME_TAX": "Steuern vom Einkommen und Ertrag (€)",
  "INDEX FLOOR": "(de) Indexation Floor",
  "INDEX_DELAY_OF_ADJUSTMENT": "(de) Rent Review Ajustment Delay",
  "INDEX_FLOOR": "(de) Indexation Floor",
  "INDEX_RHYTHM": "(de) Rent Review Frequency",
  "INDEXATION CAP": "(de) Indexation Cap",
  "INDEXATION_CAP": "(de) Indexation Cap",
  "INDEXATION_THRESHOLD": "(de) Rent Review Threshold",
  "INDUSTRIAL AREA": "Produktionsfläche (m²)",
  "INDUSTRIAL_AREA": "Produktionsfläche (m²)",
  "INIT_ANNUAL_AMOUNT": "(de) Initial Annual Amount",
  "INIT_ANNUAL_AMOUNT_PER_UNIT": "(de) Initial Annual Amount Per Unit",
  "INIT_BALANCE": "0000-Saldo zum Beginndatum",
  "INITIAL ANNUAL RENT AMOUNT": "Anfangsmiete (€/Jahr)",
  "INITIAL ANNUAL RENT AMOUNT M2": "Anfangsmiete pro m² (€/m²/Jahr)",
  "INITIAL DEPOSIT VALUE": "Anfängliche Sicherheitsleistung (€)",
  "INITIAL EQUIPMENT GUARANTEE": "(de) Initial equipment guarantee (€)",
  "INITIAL GUARANTEE 1": "(de) Inital guarantee 1 (€)",
  "INITIAL GUARANTEE 2": "(de) Inital guarantee 2 (€)",
  "INITIAL GUARANTEE 3": "(de) Inital guarantee 3 (€)",
  "INITIAL INDEX": "(de) Initial index type",
  "INITIAL INDEX DATE": "Datum Basis-Indexstand",
  "INITIAL INDEX VALUE": "(de) Initial index",
  "INITIAL VALUE": "Vorletzter Wert",
  "INITIAL WORKING CAPITAL": "(de) Initial working capital (€)",
  "INITIAL_ANNUAL_RENT_AMOUNT": "Anfangsmiete (€/Jahr)",
  "INITIAL_ANNUAL_RENT_AMOUNT_M2": "Anfangsmiete pro m² (€/m²/Jahr)",
  "INITIAL_ANNUAL_RENT_AMOUNT_SQM": "Anfangsmiete pro m² (€/m²/Jahr)",
  "INITIAL_DEPOSIT_VALUE": "Anfängliche Sicherheitsleistung (€)",
  "INITIAL_EQUIPMENT_GUARANTEE": "(de) Initial equipment guarantee (€)",
  "INITIAL_GUARANTEE_1": "(de) Inital guarantee 1 (€)",
  "INITIAL_GUARANTEE_2": "(de) Inital guarantee 2 (€)",
  "INITIAL_GUARANTEE_3": "(de) Inital guarantee 3 (€)",
  "INITIAL_INDEX": "(de) Initial index",
  "INITIAL_INDEX_DATE": "Datum Basis-Indexstand",
  "INITIAL_INDEX_PERIODICITY": "(de) Rent Review Initial Frequency",
  "INITIAL_INDEX_VALUE": "Basis-Indexstand",
  "INITIAL_VALUE": "Vorletzter Wert",
  "INITIAL_WORKING_CAPITAL": "(de) Inital working capital (€)",
  "InPlaceTenants": "Laufende Mietverträge",
  "InsertFilterLineNumber": "Frieren Sie die Scheiben aus der Reihe Nr. Ein",
  "INSURANCE BEGIN DATE": "Datum des Versicherungsbeginns",
  "INSURANCE DUNNING DATE": "Mahndatum der Versicherung",
  "INSURANCE DUNNING LEVEL": "Mahnstufe der Versicherung",
  "INSURANCE END DATE": "Datum des Endes der Versicherung",
  "INSURANCE PERIOD": "Versicherungsperiode",
  "INSURANCE POLICY NUMBER": "(de) Insurance Policy Number",
  "INSURANCE PREMIMS AMOUNT": "(de) Insurance Premiums Amount (€)",
  "INSURANCE_BEGIN_DATE": "Datum des Versicherungsbeginns",
  "INSURANCE_DUNNING_DATE": "Mahndatum der Versicherung",
  "INSURANCE_DUNNING_LEVEL": "Mahnstufe der Versicherung",
  "INSURANCE_END_DATE": "Datum des Endes der Versicherung",
  "INSURANCE_PERIOD": "Dauer der Versicherung (Monaten)",
  "INSURANCE_POLICY_NUMBER": "(de) Insurance Policy Number",
  "INSURANCE_PREMIMS_AMOUNT": "(de) Insurance Premiums Amount (€)",
  "INTEREST LETTER SENDING DATE": "Datum der Interessenbekundung",
  "INTEREST PAID ON CURRENT ACCOUNT": "Zinsen aus Kontokorrent (€)",
  "INTEREST_LETTER_SENDING_DATE": "Datum der Interessenbekundung",
  "INTEREST_LETTER_SENDING_DATE_mismatch": "Datum der Interessenbekundung ungültig",
  "INVESTMENT STRUCTURE": "Fondsstruktur",
  "INVESTOR": "Anleger-Name",
  "INVESTOR CODE": "Anleger-Nummer",
  "INVESTOR CONTRIBUTIONS": "Eigenkapital",
  "INVESTOR DISTRIBUTION": "Anleger-Ausschüttung",
  "INVESTOR GROUP": "Unternehmensverbindung",
  "INVESTOR LEVEL NAV": "Anlagerebene Nettoinventarwert (€)",
  "INVESTOR LEVEL NAV TITLE": "Anlagerebene Nettoinventarwert",
  "INVESTOR NAME": "Anleger-Name",
  "INVESTOR NAV AND RETURNS": "Anlagerebene Leistung",
  "INVESTOR RETURN": "Anlagerebene Rendite",
  "INVESTOR SHARE OF NAV": "Anlagerebene Nettoinventarwert (€)",
  "INVESTOR_CODE": "Anleger-Numer",
  "INVESTOR_GROUP": "Dachgesellschaft",
  "INVESTOR_NAME": "Anleger-Name",
  "INVESTOR_SHARE_OF_NAV": "Rücknahmepreis (€)",
  "INVESTORS": "Anleger",
  "INVOICE": "(de) Label",
  "INVOICE DURING PERIOD INC TAX AMNT": "Rechnungsbetrag (€)",
  "INVOICE NUMBER": "(de) Invoice Number",
  "INVOICE_DURING_PERIOD_INC_TAX_AMNT": "Rechnungsbetrag (€)",
  "INVOICE_NUMBER": "(de) Invoice Number",
  "INVOICES NOT DUE INC TAX AMOUNT": "Noch nicht fällige Rechnungen (€)",
  "INVOICES_NOT_DUE_INC_TAX_AMOUNT": "Noch nicht fällige Rechnungen (€)",
  "INVOICING": "Rechnungsbetrag (€)",
  "INVOICING BANK ACCOUNT": "Bankkonto-Fakturierung",
  "INVOICING DATE": "(de) Invoicing Date",
  "INVOICING FREQUENCY LABEL": "Fakturierungsintervall",
  "INVOICING INCL TAX AMOUNT": "Rechnungsbetrag (€)",
  "INVOICING ITEM": "Rechnungsposten-Typ",
  "INVOICING_BANK_ACCOUNT": "(de) Compte bancaire Facturation",
  "INVOICING_DATE": "(de) Invoicing Date",
  "INVOICING_FREQUENCY_LABEL": "Fakturierungsintervall",
  "INVOICING_INCL_TAX_AMOUNT": "Rechnungsbetrag (€)",
  "INVOICING_PERIOD": "Rechnungsbetrag (€)",
  "IS BUILDING NEUTRALIZED": "Neutralisiert?",
  "IS DIRECT DEBIT": "Abbuchung ?",
  "IS GREEN LEASE": "(de) Green Lease (yes/no)?",
  "IS HOLDOVER LEASE": "(de) Holdover Lease?",
  "IS IN LITIGATION": "Schwebendes Verfahren?",
  "IS LEASE RENEWED": "Verlängerter Mietvertrag ?",
  "IS LOOKTHROUGH ENTITY": "Steuerrechtssubjekt (ja/nein)?",
  "IS MAIN UNIT": "Hautpmieteinheit (ja/nein)?",
  "IS OPERATING EXPENSES REBILLED": "(de) Are Operating Expenses Rebilled (yes/no)?",
  "IS RENT CATCH UP": "(de) Rent Catch-up (yes/no)?",
  "IS SERVICES CHARGES CAPPED": "(de) Are Services Charges Capped (yes/no)?",
  "IS_BUILDING_NEUTRALIZED": "Neutralisiert?",
  "IS_DIRECT_DEBIT": "Abbuchung?",
  "IS_GREEN_LEASE": "(de) Green Lease (yes/no)?",
  "IS_HOLDOVER_LEASE": "(de) Holdover Lease?",
  "IS_IN_LITIGATION": "Schwebendes Verfahren?",
  "IS_LEASE_RENEWED": "Verlängerter Mietvertrag ?",
  "IS_LOOK_THROUGH_ENTITY": "Steuerrechtssubjekt (1=ja/0=nein)?",
  "IS_LOOKTHROUGH_ENTITY": "Steuerrechtssubjekt?",
  "IS_MAIN_UNIT": "Hautpmieteinheit (ja/nein)?",
  "IS_OPERATING_EXPENSES_REBILLED": "(de) Are Operating Expenses Rebilled (yes/no)?",
  "IS_OWNER": "(de) Property Owner Fund (1=yes/0=no)?",
  "IS_REGULAR_CHARGE_REVIEWABLE": "(de) Reviewable Billing Item?",
  "IS_RENT": "(de) Is a Rent?",
  "IS_RENT_CATCH_UP": "(de) Rent Catch-up (yes/no)?",
  "IS_RENTAL_FREE": "(de) Rent Free?",
  "IS_SERVICES_CHARGES_CAPPED": "(de) Are Services Charges Capped (yes/no)?",
  "ISIN": "ISIN-Code",
  "isSaveDirectory": "Absendeverzeichnis",
  "isSaveGED": "Im EDM speichern",
  "isSaveMultiple": "Eine Datei pro Objekt",
  "isSaveOne": "Eine einzige Datei",
  "IsSeparatorThousands": "Tausendertrennzeichen (Ja/Nein)",
  "ISSUED SHARES NUMBER": "Anzahl umlaufender Anteile",
  "ISSUED_SHARES_NUMBER": "Anzahl umlaufender Anteile",
  "ITEM_END_DATE": "(de) Billing Item End Date",
  "ITEM_START_DATE": "(de) Billing Item Start Date",
  "JANUARY_AMOUNT": "Januar (€)",
  "JOURNAL CODE": "(de) Journal",
  "JOURNAL_CODE": "(de) Journal",
  "JOURNAL_LABEL": "(de) Journal Label",
  "JULY_AMOUNT": "Juli (€)",
  "JUNE_AMOUNT": "Juni (€)",
  "KEY FIGURES": "Kennzahlen",
  "LAND PRICE": "Grundstückspreis (€)",
  "LAND_PRICE": "Grundstückspreis (€)",
  "LAND_PRICE_mismatch": "Grundstückspreis ungültig",
  "LAND_PRICE_WOU": "Grundstückspreis",
  "LAND_VALUE": "Bodenwert",
  "LANDED N": "(de) Landed",
  "LANDED N M1": "(de) Landed",
  "LANDED_N": "(de) Landed YYYY",
  "LANDED_N_M1": "(de) Landed YYYY-1",
  "LANDLORD NOTICE DATE": "(de) Landlord Notice Date",
  "LANDLORD_NOTICE_DATE": "(de) Landlord Notice Date",
  "LAST ANNUAL RENT AMOUNT": "Letztere jährliche Miete (€/Jahr)",
  "LAST EDITION": "Zuletzt bearbeitet:",
  "LAST INDEXATION DATE": "(de) Last indexation date",
  "LAST LEASE RENT AMOUNT": "Vorheriger Mietvertrag Vertragsmiete (VM) (€/Jahr)",
  "LAST LEASE RENT AMOUNT M2": "Vorheriger Mietvertrag Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "LAST RENT REVIEW DATE": "(de) Last Rent Review Date",
  "LAST TENANT NAME": "Vorheriger Mieter-Name",
  "LAST TENANT TRADE NAME": "Vorheriger Mieter-Handelsname",
  "LAST VALUATION DATE": "Bewertungsstichtag",
  "LAST_INDEXATION_DATE": "(de) Last indexation date",
  "LAST_LEASE_RENT_AMOUNT": "Vorheriger Mietvertrag Vertragsmiete (VM) (€/Jahr)",
  "LAST_LEASE_RENT_AMOUNT_M2": "Vorheriger Mietvertrag Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "LAST_RENT_REVIEW_DATE": "(de) Last Rent Review Date",
  "LAST_TENANT_NAME": "Vorheriger Mieter-Name",
  "LAST_TENANT_TRADE_NAME": "Vorheriger Mieter-Handelsname",
  "LAST_VALUATION_DATE": "Bewertungsstichtag",
  "LAST_VALUATION_DATE_YYYY": "Bewertungsstichtag YYYY-1",
  "LATITUDE": "Breitengrad",
  "lbAlertAndPortfolio": "Meine Alarme & Mein Portfolio",
  "lbAlphanumeric": "(de) lbAlphanumeric",
  "lbAmerican": "Amerikanisch",
  "lbAmoutFormat": "Betragsformat",
  "lbAPRAFinancialOccupancyEvolution": "Entwicklung des EPRA-Vermietungsstands nach Marktmiete",
  "lbAttribute": "(de) Attribute",
  "lbCapitalDecrease": "Wertverminderung",
  "lbCapitalGrowth": "Wertsteigerung",
  "lbCapitalReturnTotalReturnEvolution": "Entwicklung der Einkommensrendite, Wertsteigerungsrendite und Gesamtrendite auf Basis Nettoinventarwert",
  "lbCodeFormat": "Nummerformat",
  "lbCollectionPeriods": "Zahlungsperioden ersetzen",
  "lbCollectionPeriodsSBigError": "(de) Veuillez saisir une valeur inférieur à la suivante",
  "lbCollectionPeriodsSmallError": "(de) Veuillez saisir une valeur supérieure à la précédente",
  "lbColumnFormat": "Spaltenformat",
  "lbCulture": "(de) culture",
  "lbDate": "(de) Date",
  "lbDateFormat": "Datumsformat",
  "lbDefault": "(de) By Default",
  "lbDispalyThePropertiesThatAre": "Objekte anzeigen:",
  "lbElement": "(de) Element",
  "lbEnglish": "Englisch",
  "lbERV": "Vertragsmiete entspricht der Marktmiete",
  "lbExcludFollowJournal": "Die folgenden Sammeljournal-Klassen nicht berücksichtigen",
  "lbFieldToBeLeft": "Das Feld leer bleiben lassen",
  "lbFileNameFomatDate": "Dateiname - Datum und Zeit",
  "lbFileSeparator": "Datenfeldtrennzeichen",
  "lbFinancialOccupancyEvolution": "Entwicklung des Vermietungsstands nach Mietertrag",
  "lbFixedValue": "Festwert",
  "lbFrench": "Französisch",
  "lbGerman": "Deutsche",
  "lbHeaderCollectionPerdios": "(de) This page's settings concern only the ventilation periods of the \"Rental income collection\", \"Detailed Rental Situation\" and \"Aging Schedule\" reports.",
  "lbHeadlineRentBreakByTenant": "Aufteilung der Vertragsmiete nach Mieter",
  "lbInterfaceDuplicated": "(de) Votre interface [...] [....] a bien été dupliquée en [.....]",
  "lbInvalidatefixedValue": "(de) Lla valeur fixe n'est pas valide",
  "lbInvalidateInterfaceName": "(de) Le nom de l'interface n'est pas valide",
  "lbInvalidateNameADBFile": "(de) Le nom de l'ADB n'est pas valide",
  "lbInvalidateNameFile": "(de) Le nom du fichier n'est pas valide",
  "lbl10LinesByPage": "10 Linien / Seite",
  "lbl20LinesByPage": "20 Linien / Seite",
  "lbl48Law": "1948-Gesetz",
  "lbl48LawEuro": "1948-Gesetz (€)",
  "lbl50LinesByPage": "50 Linien / Seite",
  "lbl5LinesByPage": "5 Linien / Seite",
  "lblABEPage": "(de) Bank Movements",
  "lblAccommodation": "Unterkunft",
  "lblAccountsType": "Kontotyp",
  "lblACSAccountStream": "Buchhaltung Stream",
  "lblActivation": "Aktiviert",
  "lblActivationFalse": "Schnittstelle ist deaktiviert",
  "lblAddAdditionalField": "(de) Add an Additional Data Column",
  "lblAddCustomColumn": "(de) Add a Custom Column",
  "lblAddCustomMappingColumn": "(de) Add a Report Column",
  "lblAdditionalField": "Themen",
  "lblAdditionalFieldCode": "Themen-Code",
  "lblAdditionalFieldFormatError": "Der Wert ist Keine Zahl",
  "lblAdditionalFieldImport": "Zusätzlicher Daten verwalten",
  "lblAdditionalFieldImportsList": "Importprotokoll",
  "lblAdditionalFieldManagement": "Themenerstellung",
  "lblAdditionalFieldName": "Thema Etikett",
  "lblAdditionalFieldType": "Ebene",
  "lblAdditionalFieldTypes": "Ebene",
  "lblAddress": "Adresse",
  "lblADFPage": "Zusätzlicher Datum",
  "lblAdministrativeLease": "Auf Staatsverwaltung und Gebietskörperschaft ausgerichteter Mietvertrag",
  "lblAdministrativeLeaseEuro": "Auf Staatsverwaltung und Gebietskörperschaft ausgerichteter Mietvertrag (€)",
  "lblALEPage": "Alarme erstellen",
  "lblAlertCancelInterfaceSequence": "(de) Are you sure you want to delete this sequence?",
  "lblAlertDeleteAdditionalField": "Sind Sie sicher, dass Sie dieses Thema löschen möchten?",
  "lblAlertDeleteArbitrage": "Vorsicht ! Sind Sie sicher, dass Sie den Verkauf löschen möchten ? Der Verkauf wird dann von der Liste der laufenden Verkäufe zurückgezogen werden. Möchten Sie jedoch den Verkauf später anzeigen oder aktualisieren, gehen Sie zur Liste der abgeschlossenen Verkäufe.",
  "lblAlertDeleteFunds": "Sind sie sicher, dass Sie diese Fonds-Einheit löschen möchten?",
  "lblAlertDeleteInterface": "(de) Are you sure you want to delete this data stream?",
  "lblAlertDeletePreviousFundUpdate": "Sind sie sicher, dass Sie diese Gesellschaft löschen möchten?",
  "lblAlertDeletePurchase": "Vorsicht ! Sind Sie sicher, dass Sie den Erwerb löschen möchten ? Der Erwerb wird dann von der Liste der laufenden Erwerbe zurückgezogen werden. Möchten Sie jedoch den Erwerb später anzeigen oder aktualisieren, gehen Sie zur Liste der abgeschlossenen Erwerbe.",
  "lblAlertDeleteRecord": "Sind sie sicher, dass Sie diesen Eintrag löschen möchten?",
  "lblAlertDeleteReportTemplate": "Sind Sie sicher, dass Sie diese Berichtsvorlage löschen möchten?",
  "lblAlertDeleteUser": "Sind sie sicher, dass Sie diesen Benutzer löschen möchten?",
  "lblAlertEvolutionyear1": "Entwicklung der Kennzahlen über die letzten",
  "lblAlertEvolutionyear2": "Entwicklung der Kennzahlen über die letzten",
  "lblAlertEvolutionyear3": "Entwicklung der Kennzahlen über die letzten",
  "lblAlertLaunchInterface": "Wollen Sie die Schnittstelle sofort starten ?",
  "lblAlertLeaseBreakRetail": "Ein gewerblicher Mietvertrag einen Kündigungstermin in den nächsten (Monaten) hat :",
  "lblAlertLeaseExpiringOther": "Ein sonstiger Mietvertrag in den nächsten (Monaten) abläuft :",
  "lblAlertLeaseExpiringResidential": "Ein Wohnungsmietvertrag in den nächsten (Monaten) abläuft :",
  "lblAlertLeaseExpiringRetail": "Ein gewerblicher Mietvertrag in den nächsten (Monaten) abläuft :",
  "lblAlertPropertyValueDecreased": "Der Verkehrswert eines Objekts ist zwischen dem letzten und vorletzten Verkehrswertgutachten auf (%) gefallen:",
  "lblAlertPropertyValueIncreased": "Der Verkehrswert eines Objekts ist zwischen dem letzten und vorletzten Verkehrswertgutachten auf (%) gestiegen:",
  "lblAlertRangeError": "Fehlerbereich (muss zwischen 1 und 999 liegen)",
  "lblAlertRangeError_0_999999999": "Fehlerbereich (muss zwischen 0 und 999999999 liegen)",
  "lblAlertReload": "Sind Sie sicher, die Daten neu zu laden?",
  "lblAlertRentDecreasedAfterAdjustment": "Eine Mietminderung nach Indexanpassung von (%)",
  "lblAlertRentDecreasedInitialRent": "Eine Mietminderung im Vergleich mit der Anfangsmiete von (%)",
  "lblAlertRentIncreasedAfterAdjustment": "Eine Mietsteigerung nach Indexanpassung von (%)",
  "lblAlertRentIncreasedInitialRent": "Eine Mietsteigerung im Vergleich mit der Anfangsmiete von (%)",
  "lblAlertRequiredError": "Feld erforderlich",
  "lblAlertsManagement": "Alarme erstellen",
  "lblAlertSoldArbitrage": "Vorsicht ! Sind Sie sicher, dass Sie den Status eines Objektes zu 'Verkauft' ändern möchten ? Der Verkauf wird dann von der Liste der laufenden Verkäufe zurückgezogen werden. Möchten Sie jedoch den Verkauf später anzeigen oder aktualisieren, gehen Sie zur Liste der abgeschlossenen Verkäufe.",
  "lblAlertTenancySchedule": "Mieterliste-Alarme",
  "lblAlertTerm1": "Eine Benachrichtung erhalten, wenn es Mietverträge gibt, die einen Kündigungstermin in den nächsten (Monaten) haben",
  "lblAlertTerm2": "Eine Benachrichtung erhalten, wenn es Mietverträge gibt, die einen Kündigungstermin in den nächsten (Monaten) haben",
  "lblAlertTreshold": "Alarmschwelle",
  "lblAlertVacancy": "Eine Benachrichtung erhalten, wenn die Leerstandsquote eines Objekts (%) mindestens erreicht",
  "lblAlertVacancyMixed": "Die Leerstandsquote eines Mischobjekts (%) mindestens erreicht :",
  "lblAlertVacancyOffice": "Die Leerstandsquote eines Büroobjekts (%) mindestens erreicht :",
  "lblAlertVacancyOther": "Die Leerstandsquote eines Sonderobjekts (%) mindestens erreicht :",
  "lblAlertVacancyRate": "Leerstandsquote",
  "lblAlertVacancyResidential": "Die Leerstandsquote eines Wohnobjekts (%) mindestens erreicht :",
  "lblAlertVacancyRetail": "Die Leerstandsquote eines Geschäftsobjekts (%) mindestens erreicht :",
  "lblAlertVacancyStorage": "Die Leerstandsquote eines Logistikobjekts (%) mindestens erreicht :",
  "lblAlertVacantUnit": "Eine Mieteinheit steht leer seit mehr als (Monaten) :",
  "lblAlertVacantUnitOptional": "Nur Mieteinheiten wählen, die mehr als (m²) Fläche mindestens haben :",
  "lblAlertValidateArbitrage": "Vorsicht ! Sind Sie sicher, dass Sie den Verkauf bestätigen möchten ? Der Verkauf wird dann von der Liste der laufenden Verkäufe zurückgezogen werden. Möchten Sie jedoch den Verkauf später anzeigen oder aktualisieren, gehen Sie zur Liste der abgeschlossenen Verkäufe.",
  "lblAlertValidatePurchase": "Vorsicht ! Sind Sie sicher, dass Sie den Erwerb bestätigen möchten ? Der Erwerb wird dann von der Liste der laufenden Erwerbe zurückgezogen werden. Möchten Sie jedoch den Erwerb später anzeigen oder aktualisieren, gehen Sie zur Liste der abgeschlossenen Erwerbe.",
  "lblAnalysisDate": "Zeitpunkt",
  "lblAnnualFinancialVacancy": "Leerstandsmiete (€/Jahr)",
  "lblAnnualFinancialVacancyCumulate": "Kumulierte Leerstandsmiete (€)",
  "lblAnnualRent": "Vertragsmiete (VM) (€/Jahr)",
  "lblAnnualRentSqm": "Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "lblApplyAllBuildings": "Gilt für alle Objekte",
  "lblApplySelectBuildingOnly": "Gilt nur für die ausgewählten Objekte und Portfolio(s)",
  "lblArbitrage": "Verkäufe erstellen",
  "lblArbitrageAlreadyExists": "Das Objekt ist in der Liste der zum Verkauf stehenden Objekten schon vorhanden",
  "lblArbitrageHistory": "Überblick der Verkäufe von Immobilien",
  "lblArbitrageType": "Verkaufstyp",
  "lblArea": "Gesamte vermietbare Fläche (m²)",
  "lblAreaDashboard": "Gesamte vermietbare Fläche",
  "lblAreaLeaseRenewals": "Vermietete Fläche von verlängerten Mietverträgen (m²)",
  "lblAreaLeasesTerminated": "Vermietete Fläche von gekündigten Mietverträgen (m²)",
  "lblAreaLeaseTerminationsNotified": "Vermietete Fläche von Kündigungen (m²)",
  "lblAreaRelettings": "Neu vermietete Mietfläche (m²)",
  "lblAreaScale": "Mietfläche",
  "lblAreasUnderManagementByBuildingType": "(de) Areas under Management by Building Type",
  "lblAreasUnderManagementByUnitType": "(de) Areas under Management by Unit Type",
  "lblAreaUnit": "Flächeneinheit",
  "lblArrivalDate": "Mieter-Einzugsdatum",
  "lblAssetManager": "Vermögensverwalter",
  "lblAuditAction": "Veränderungen",
  "lblAuditTables": "Datenbanktabellen",
  "lblAuditTrail": "Prüfprotokoll",
  "lblAuthorizedPage": "Berechtigte Seiten",
  "lblAuthorizedPortfolio": "Erlaubte Portfolios",
  "lblAUTPage": "Prüfprotokoll",
  "lblAUTRawReport": "Prüfprotokoll - unverarbeitet",
  "lblAUTReport": "Prüfprotokoll",
  "lblAverageVacancyRate": "Durchschnittliche physische Leerstandsquote (%)",
  "lblBackDashoard": "Zurück zum Dashboard",
  "lblBalanceBeforeOverpayments": "Zahlungsbilanz Saldo exkl. Vorauszahlungen (€)",
  "lblBalanceSheetRevisionDate": "Bilanz aktualisiert am",
  "lblBaseReportRequired": "Bitte wählen Sie eine Berichtsvorlage",
  "lblBetweenOneAndTwoMonth": "1-2 Monaten",
  "lblBetweenTwoAndThreeMonth": "2-3 Monaten",
  "lblBlokingLogin": "Ihr Benutzerkonto wurde gesperrt, wenden Sie sich an den Administrator.",
  "lblBranches": "Niederlassungen",
  "lblBreakdownByPropertyType": "Aufteilung des Netto-Verkehrswerts nach Objekt-Nutzungsart",
  "lblBreakRentIncomeByCollectionPreiod": "Verteilung der Ist-Mieteinnahmen nach Laufzeit",
  "lblBudgetMonitoring": "Soll-Ist-Vergleich Bilanz",
  "lblBudgetStatus": "(de) Budget Status",
  "lblBudgetStatusAll": "Alle",
  "lblBudgetStatusClosed": "(de) Closed",
  "lblBudgetStatusOpen": "(de) Open",
  "lblBudgetType": "(de) Budget Type",
  "lblBudgetTypeAll": "(de) Opex and Capex",
  "lblBudgetTypeCapex": "(de) Capex",
  "lblBudgetTypeList": "(de) Budget Type",
  "lblBudgetTypeListCapex": "(de) Capex",
  "lblBudgetTypeListOpex": "(de) Opex",
  "lblBudgetTypeListOpexCapex": "(de) Opex and Capex",
  "lblBudgetTypeListOpexProduct": "(de) Opex and Product",
  "lblBudgetTypeListProduct": "(de) Product",
  "lblBudgetTypeOpex": "(de) Opex",
  "lblBuilding": "(DE)Building",
  "lblBuildingBadElement": "Der Typ {2} mit Wert {3} ist nicht relevant für das Objekte {1 {0} abgelehnte Zeile.",
  "lblBuildingId": "Objekt-ID",
  "lblBuildingLease": "Erbpachtvertrag",
  "lblBuildingLeaseEuro": "Erbpachtvertrag (€)",
  "lblBuildingName": "Objekt-Name",
  "lblBuildingNatures": "Objekt-Nutzungsarten",
  "lblBuildingPhysicalVacancyRate": "",
  "lblBuildingPlural": "{0} Objekte der Nutzungsart {1}",
  "lblBuildingPurchaseValue": "Anschaffungswert (€)",
  "lblBuildingReference": "Objekt-Repositorium",
  "lblBuildings": "Objekt(e)",
  "lblBuildings2": "Objekte",
  "lblBuildingSaleValue": "Verkaufswert (€)",
  "lblBuildingSaleValueSqm": "Verkaufswert pro m² (€/m²)",
  "lblBuildingSaleValueTotal": "Gesamter Verkaufswert (€)",
  "lblBuildingsFound": "gefundene(s) Objekt(e)",
  "lblBuildingSingular": "{0} Objekt der Nutzungsart {1}",
  "lblBuildingsMatching": "entspricht (entsprechen) ausgewählten Kriterien",
  "lblBuildingToInclude": "(de) Properties to Include",
  "lblBuildingTotalAreaSqm": "Gesamte vermietbare Fläche (m²)",
  "lblBuildingTotalValue": "Gesamter Wert (€)",
  "lblBuildingTotalValueSqm": "Anschaffungswert pro m² (€/m²)",
  "lblBuildingType": "Objekt-Nutzungsart",
  "lblBuildingUnknown": "Der Objektscode {1} existiert nicht, {0} abgelehnte Zeile.",
  "lblBuildingUpdatedOn": "Objekt-Datenblatt zum",
  "lblBuildingValue": "Netto-Verkehrswert (€)",
  "lblBuildingValueDashboard": "Netto-Verkehrswert",
  "lblBuildingValueDecreasedPlural": "Minderungen der Verkehrswerte",
  "lblBuildingValueDecreasedSingular": "Minderung des Verkehrswerts",
  "lblBuildingValueIncreasedPlural": "Erhöhungen der Verkehrswerte",
  "lblBuildingValueIncreasedSingular": "Erhöhung des Verkehrswerts",
  "lblBUIPage": "Objekt-Datenblatt Export",
  "lblBUIReport": "Objekt-Datenblatt",
  "lblBUMPage": "Soll-Ist-Vergleich Bilanz",
  "lblBUMRawReport": "Soll-Ist-Vergleich Bilanz - unverarbeitet",
  "lblBUMReport": "Soll-Ist-Vergleich Bilanz",
  "lblBusinessMixedPremises": "Misch- und Geschäftsräume",
  "lblBusinessUnits": "Geschäftseinheiten",
  "lblBuyer": "Käufer",
  "lblCacheInfosValidityCheck": "Abgelaufen Einziehung Frist",
  "lblCapiGross": "Brutto-Kapitalisierungszinssätze",
  "lblCapiNet": "Netto-Kapitalisierungszinssätze",
  "lblCapitalEmployed": "Gesamt Finanzierung (€)",
  "lblCapitalGrowthBreakdown": "Aufteilung der Wertänderung nach Objekt",
  "lblCapitalReturn": "Wertänderungsrendite (WÄR)",
  "lblCapitalReturnEquityFairValue": "Wertsteigerungsrendite auf Basis des Eigenkapitals",
  "lblCapitalReturnOnNAV": "Wertsteigerungsrendite auf Basis des Nettoinventarwerts",
  "lblCapitalStructure": "Kapitalstruktur",
  "lblCarPark": "Stellplatz",
  "lblCarParkEuro": "Stellplatz (€)",
  "lblCarParkLease": "Stellplatzmietvertrag",
  "lblCarParkLeaseEuro": "Stellplatzmietvertrag (€)",
  "lblCashFlowBreakdown": "Aufteilung des Objekt-Cashflows",
  "lblCashOnCash": "Cash-on-Cash",
  "lblCashOnCashPerShare": "Ausschüttung pro Aktie (€)",
  "lblCashOnCashReturn": "Cash-on-Cash Rendite",
  "lblCBPPage": "Aufteilung der Zahlungsperioden ersetzen",
  "lblChangeParams": "Suchkriterien ändern",
  "lblChangePassword": "Passwort ändern",
  "lblChangePwdLegend": "Passwortänderung Formular",
  "lblChangesRecorded": "Die Änderungen werden in Betracht gezogen",
  "lblCity": "Stadt",
  "lblCivilLease": "Auf Vereine ausgerichteter Mietvertrag",
  "lblCivilLeaseEuro": "Auf Vereine ausgerichteter Mietvertrag (€)",
  "lblClient": "Kunde",
  "lblCMOBreakdownRawReport": "Verteilung der Ist-Mieteinnahmen nach Laufzeit - unverarbeitet",
  "lblCMOBreakdownReport": "Verteilung der Ist-Mieteinnahmen nach Laufzeit",
  "lblCMOBreakdownSumRawReport": "(de) Breakdown of Rental Income by Collection Period Running Total - raw",
  "lblCMOBreakdownSumReport": "(de) Breakdown of Rental Income by Collection Period Running Total",
  "lblCMOPage": "Überwachung der Ist-Mieteinnahmen",
  "lblCMORawReport": "Überwachung der Ist-Mieteinnahmen - unverarbeitet",
  "lblCMOReport": "Überwachung der Ist-Mieteinnahmen",
  "lblCollectionMonitoring": "Überwachung der Ist-Mieteinnahmen",
  "lblCollectionMonitoringBreakdown": "Verteilung der Ist-Mieteinnahmen nach Laufzeit",
  "lblCollectionMonitoringDate": "Überwachung der Ist-Mieteinnahmen",
  "lblCollectionMonitoringSum": "Kumulierte Verteilung der Ist-Mieteinnahmen nach Laufzeit",
  "lblCollectionPeriods": "Aufteilung der Zahlungsperioden ersetzen",
  "lblCollectMonitBreakdownByBuilding": "(de) Running Total of Rental Income by Collection Period and by Property",
  "lblCollectMonitBreakdownByPortfolio": "(de) Running Total of Rental Income by Collection Period and by Portfolio",
  "lblColumnsSelectedRequired": "Bitte wählen Sie mindestens 2 Spalten!",
  "lblCommercialLease": "Gewerblicher Mietvertrag",
  "lblCommercialLeaseEuro": "Gewerblicher Mietvertrag (€)",
  "lblCommercialLeasePlural": "gewerbliche Mietverträge",
  "lblCommercialLeaseSingular": "gewerblicher Mietvertrag",
  "lblcompanie": "Fonds / Gesellschaften",
  "lblCompanies": "Companies",
  "lblCompany": "Gesellschaft",
  "lblCompanyAccomodation": "Dienstwohnung",
  "lblCompanyAccomodationEuro": "Dienstwohnung (€)",
  "lblCompanyRegistrationNumber": "Handels- und Gesellschaftsregister-Nummer",
  "lblComparisonYear": "Vergleichsjahr",
  "lblConcentrationAndExposure": "Konzentrationsrisiken (auf Basis der vermietbaren und vermieteten Fläche)",
  "lblConcentrationRiskAndExposure": "Konzentrationsrisiken (auf Basis des Netto-Verkehrswerts and der VM)",
  "lblConcernedPortfolio": "Einschlägige Portfolios",
  "lblConfirmNewPassword": "Neues Passwort bestätigen",
  "lblConfirmNewPasswordMismatch": "Das neue Passwort und das bestätigte neue Passwort müssen identisch sein",
  "lblConfirmNewPasswordRequired": "Neues Passwort bestätigen erforderlich",
  "lblConfirmPassword": "Passwort bestätigen",
  "lblConfirmPasswordRequired": "Passwort bestätigen erforderlich",
  "lblConsolidatedAccountsType": "Konsolidiert",
  "lblConstantPatrimony": "Im Like-for-Like Vergleich",
  "lblCostPriceFinancialInstruments": "Anschaffungskosten der Finanzinstrumente (€)",
  "lblCountry": "Land",
  "lblCoworkingSpacesIdFKML": "Coworkingräume (Île-de-France)",
  "lblCreateAdditionalField": "Themenerstellung",
  "lblCreateFund": "Neuer Fonds",
  "lblCreateReportTemplate": "Neue Berichtsvorlage",
  "lblCreateTheme": "Themenerstellung",
  "lblCreateUser": "Neuer Benutzer",
  "lblCSVSeparator": "Zeichen zur Trennung von Daten Feldern in CSV-Dateiformat",
  "lblCurrency": "Währung",
  "lblCurrentAccountAlreadyExists": "Die ausgewählte Kontonummer wurde schon benutzt.",
  "lblCurrentAccountAndLoanAccountAlreadyExists": "",
  "lblCurrentPassword": "Aktuelles Passwort",
  "lblCurrentPasswordRequired": "Aktuelles Passwort erforderlich",
  "lblCurrentSales": "Laufende Verkäufe",
  "lblCurrentYield": "Einkommensrendite",
  "lblCutOffDate": "Abschlussstichtag",
  "lblCutOffDateLocked": "Die Nettobuchwerte können nicht mit diesem Abschlussstichtag hochgeladen werden: Benutzerdefinierte unrealisierte Gewinne/Verluste wurden schon zu diesem Abschlussstichtag bestätigt.",
  "lblCutOffDateNotLocked": "Benutzerdefinierte unrealisierte Gewinne/Verluste wurden zu diesem Abschlussstichtag nicht bestätigt.",
  "lblCutOffRevisionDate": "Netto-Buchwerte aktualisiert am",
  "lblCVAPage": "Net Asset Value-Verfahren",
  "lblCVARawReport": "Net Asset Value-Verfahren - unverarbeitet",
  "lblCVAReport": "Net Asset Value-Verfahren",
  "lblDashboard": "Willkommen",
  "lblDashboardAlerts": "Dashboard-Alarme",
  "lblDataReloaded": "Hallo,            Die Daten vom Immobilien-Verwalter {0} wurden erfolgreich neu geladen.            Mit besten Grüßen,        Das Alhambra-Team",
  "lblDataReloading": "Die letzten Daten vom Immobilien-Verwalter {0} werden gerade neu geladen. Sobald der Prozess abgeschlossen ist, werden Sie eine E-mail erhalten.",
  "lblDataReloadSubject": "Alhambra - Daten neu laden",
  "lblDate": "Zeitpunkt",
  "lblDateFollow": "Werte müssen aufeinander folgen, {0} abgelehnte Zeile.",
  "lblDateFormat": "tt-mm-jjjj",
  "lblDateFormatDropDown": "{0:dd.MM.yyyy}",
  "lblDatePasswordLeast2Days": "Sie können Ihr Passwort nur {0} Tage nach der letzten Aktualisierung ändern.",
  "lblDay": "T",
  "lblDefaultUserError": "Es ist ein unbekannter Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es noch mal. Falls das Problem weiterhin besteht, nehmen sie bitte Kontakt mit dem Systemadministrator.",
  "lblDEKPage": "(de) Deka Reporting",
  "lblDepreciation": "Abschreibungen (€)",
  "lblDestinationCode": "(de) Destination Code",
  "lblDestinationCode_required": "(de) Destination Code Required",
  "lblDestinationDe": "(de) Destination Label (German)",
  "lblDestinationDe_required": "(de) Destination Label (German) required",
  "lblDestinationEn": "(de) Destination Label (English)",
  "lblDestinationEn_required": "(de) Destination Label (English) required",
  "lblDestinationFr": "(de) Destination Label (French)",
  "lblDestinationFr_required": "(de) Destination Label (French) required",
  "lblDestinationLabel": "Destination Label",
  "lblDetailledError": "Ausführlicher Fehler:",
  "lblDeTranscodingTypeLabel": "Deutscher Bezeichnung der Liste",
  "lblDevelopmentPropertiesIdFKML": "Projektstandorte (Île-de-France)",
  "lblDirectBuildingOutstandingPayments": "Offene Posten nach Objekt",
  "lblDirectBuildingOutstandingPaymentsRaw": "Offene Posten nach Objekt - unverarbeitet",
  "lblDirectLeaseOutstandingPayments": "Offene Posten nach Mietvertrag",
  "lblDirectLeaseOutstandingPaymentsRaw": "Offene Posten nach Mietvertrag - unverarbeitet",
  "lblDisplayAlert": "Alarm auf Dashboard anzeigen",
  "lblDistributions": "Ausschüttungen (€)",
  "lblDividendProfitDistributions": "Ausschüttungen (€)",
  "lblDividensPerShare": "Dividende pro Aktie (€)",
  "lblDontSendBefore": "Keine Schrift vor dem",
  "lblDOPPage": "Offene Posten",
  "lblDoubleErvFormatError": "Eine der Marktmiete ist keine Zahl",
  "lblDoubleFundOwnershipFormatError": "Der Wert ist Keine Zahl",
  "lblDoubleLeaseRepositoryFormatError": "Ein der Werte ist keine Zahl",
  "lblDoubleNetBookValueFormatError": "Der Wert ist Keine Zahl",
  "lblDoubleValuationFormatError": "Der Wert ist Keine Zahl",
  "lblDoubleYieldFormatError": "Eine der Zielrenditen ist keine Zahl",
  "lblDownloadReport": "Den Bericht herunterladen",
  "lblDTZPage": "Vermietungssituation",
  "lblDuplicateEmail": "Diese E-mail-Adresse ist schon für einen Benutzer vergeben. Bitte geben Sie eine andere E-mail-Adresse.",
  "lblDuplicateUserName": "Der Benutzername ist schon vergeben. Bitte geben Sie einen anderen Benutzername.",
  "lblDuration": "Restmietvertragslaufzeit (nächstmöglicher Kündigungstermin) (J-M-T)",
  "lblDuration_WOU": "Restmietvertragslaufzeit (nächstmöglicher Kündigungstermin)",
  "lblDurationToLeaseEnd": "Restmietvertragslaufzeit (Mietvertragsablauf) (J-M-T)",
  "lblDurationToLeaseEnd_WOU": "Restmietvertragslaufzeit (Mietvertragsablauf)",
  "lblEarningPerShar": "Gewinn je Aktie (€)",
  "lblEarningsPerShareDividPerShareCashSharEvol": "Entwicklung des Gewinns je Aktie, der Dividende pro Aktie und der Ausschüttung pro Aktie",
  "lblEditTemplate": "Vorlage bearbeiten",
  "lblEffectiveInitYield": "Anfangsrendite auf Basis EM",
  "lblEffectiveNetInitialYield_YOU": "Netto-Anfangsrendite auf Basis EM",
  "lblEffectiveNetInitYield": "Netto-Anfangsrendite auf NME ab (%):",
  "lblEmail": "E-Mail",
  "lblEmailRequired": "E-Mail erforderlich",
  "lblEmailRequiredError": "E-Mail ist erforderlich",
  "lblEmailSent": "Email gesendet",
  "lblEmailUnknown": "Unbekanntes E-mail-Adresse",
  "lblEmphyteuticLease": "Erbbaurecht",
  "lblEmphyteuticLeaseEuro": "Erbbaurecht (€)",
  "lblEmptyLabelRow": "Eine oder mehrere Zeilen haben eine leere Beschriftung.",
  "lblEmptyLabelValueRow": "Eine oder mehrere Zeilen haben eine leere Beschriftung und einen leeren Basisausdruck.",
  "lblEmptyValueRow": "Eine oder mehrere Zeilen haben einen leeren Basisausdruck.",
  "lblEndDate": "Bis zum",
  "lblEnTranscodingTypeLabel": "Englische Bezeichnung der Liste",
  "lblEquity": "Eigenkapital (€)",
  "lblEquityAtFairValue": "Marktwert Eigenkapital (€)",
  "lblEquityContributed": "Eigenkapital",
  "lblEquityFairValuePerShare": "Marktwert Eigenkapital pro Anteil (€)",
  "lblEquityFinancing": "Finanzierung",
  "lblERENonOperating": "(de) Commercial Real Estate (Non-Operating)",
  "lblERPAFinancialOccupancyRate": "EPRA-Vermietungsquote nach Marktmiete",
  "lblERPAFinancialOccupancySum": "EPRA-Vermietungsstand nach Marktmiete",
  "lblERPAFinancialVacancyRate": "EPRA-Leerstandsquote nach Marktmiete",
  "lblError": "Fehler:",
  "lblErrorHandler": "Fehlerbehandlungsroutine:",
  "lblErrorMessage": "Fehlermeldung",
  "lblErrorPositive": "Nur positive Zahlen",
  "lblErrorRaport": "Ein leerer Bericht kann nicht heruntergeladen werden",
  "lblErrorSendGED": "Fehler beim Versenden der Rechnungsmail",
  "lblErvDownloadBuildingUnit": "Marktmiete auf Mieteinheit-Ebene für jedes einzelnes Objekt hochladen",
  "lblErvDownloadBuildingUnitType": "Marktmiete auf Mieteinheit-Nutzungsart-Ebene für jedes einzelne Objekt hochladen",
  "lblErvDownloadBuildingUnitTypeRoom": "Marktmiete auf Mieteinheit-Nutzungsart-Ebene für jedes einzelnes Objekt hochladen",
  "lblErvDownloadSectorUnitType": "Marktmiete auf Mieteinheit-Nutzungsart-Ebene für jedes einzelne Marktsegment hochladen",
  "lblERVImportsList": "Importprotokoll",
  "lblEuro": "€",
  "lblEuroSqm": "€/m²",
  "lblEuroSqmYear": "€/m²/Jahr",
  "lblEuroYear": "€/Jahr",
  "lblEvolBalanceOutstandPayment": "Veränderung der Zahlungsbilanz",
  "lblEvolution": "Entwicklung",
  "lblEVPPage": "Entwicklung des Immobilienportfolios",
  "lblEVPRawReport": "Entwicklung des Immobilienportfolios - unverarbeitet",
  "lblEVPReport": "Entwicklung des Immobilienportfolios",
  "lblExpensesBankAccount": "Bankkonto-Aufwendungen",
  "lblExpenseType": "(de) Expense Type",
  "lblExpenseTypeAll": "Alle",
  "lblExpenseTypeNonRecoverable": "(de) Non Recoverable",
  "lblExpenseTypeRecoverable": "(de) Recoverable",
  "lblExpiringLeasesLegend": "* Nächstmögliche Kündigungstermine innerhalb der im Alarme erstellen Menü definierten Periode",
  "lblExternalDebt": "Fremdkapital",
  "lblExternalFunds": "Fonds",
  "lblExtraCondition": "Zusätzliche Bedingung (optional)",
  "lblExtraordinaryResult": "Außerordentliches Ergebnis (€)",
  "lblFalse": "Falsch",
  "lblFeeCenter": "(de) Centres d'honoraire",
  "lblFees": "Gebühren (€)",
  "lblFieldNamingConvention": "Benennungsregel der Felden",
  "lblFileError": "Datenformat der Import-Datei ungültig. Bitte benutzen Sie die zum Download verfügbare Vorlage.",
  "lblFileFormatError": "Datenformat der Import-Datei ungültig. Bitte benutzen Sie die zum Download verfügbare Vorlage.",
  "lblFileFormatErrorOnlyAlpha": "Die Spalte CODE DER LISTE darf nicht leer sein und darf nur alphanumerische Zeichen, - und _ enthalten.",
  "lblFilename": "Dateiname",
  "lblFilenameAdditionalField": "(D)AdditionalField.csv",
  "lblFilenameErvBuildingUnit": "marktmiete_mieteinheit.csv",
  "lblFilenameErvBuildingUnitType": "marktmiete_mieteinheit_nutzungsart.csv",
  "lblFilenameErvBuildingUnitTypeRoom": "marktmiete_mieteinheit_nutzungsart_zimmer.csv",
  "lblFilenameErvSectorUnitType": "marktmiete_marktsegment_nutzungsart.csv",
  "lblFilenameFundOwnership": "anlegerdaten.csv",
  "lblFilenameLeaseRepository": "mietvertrag.csv",
  "lblFilenameNetBookValue": "nbw.csv",
  "lblFilenameParamTranscoding": "Liste_Zu_Erstellen_",
  "lblFilenameValuation": "bewertung_daten.csv",
  "lblFilenameYield": "zielrenditen.csv",
  "lblFilterBy": "Filtern nach",
  "lblFinalCapitalClosingDate": "Abschlussstichtag",
  "lblFinancialIncome": "Finanzerträge (€)",
  "lblFinancialOccupancyRate": "Vermietungsquote nach Mietertrag",
  "lblFinancialOccupancyRate_WOU": "Wirtschaftliche Vermietungsquote",
  "lblFinancialOccupancySum": "Vermietungsstand nach Mietertrag",
  "lblFinancialOccupancySummary": "Vermietungsstand nach Mietertrag",
  "lblFinancialResult": "Finanzergebnis (€)",
  "lblFinancialResultBridge": "Finanzergebnis-Aufteilung",
  "lblFinancialVacancy": "Leerstandsmiete (€/Jahr)",
  "lblFinancialVacancy_WOU": "Leerstandsmiete",
  "lblFinancialVacancyRate": "Leerstandsquote nach Mietertrag",
  "lblFinancialVacancyRate_WOU": "Leerstandsquote nach Mietertrag",
  "lblFinancialVacancySqm": "Leerstandsmiete pro m² (€/m²/Jahr)",
  "lblFincancialExpenses": "Finanzaufwendungen (€)",
  "lblFirstName": "Vorname",
  "lblFonts": "Fonts (de)",
  "lblFormatDateIssue": "Falsches Datenformat « {1} » auf Linie {0}.",
  "lblFormIntro": "Bitte füllen Sie das Formular aus",
  "lblFOSPage": "Anlegerdaten Hochladen",
  "lblFrame": "Einrahmung der Spalte mit Anführungszeichen",
  "lblFriday": "Freitag",
  "lblFrTranscodingTypeLabel": "Franzosischer Bezeichnung der Liste",
  "lblFundCode": "Fondscode",
  "lblFundCodeAlreadyExists": "Diese Nummer wurde für eine andere Gesellschaft schon benutzt",
  "lblFundCodeRequired": "Fondscode erforderlich",
  "lblFundDataStartDate": "Aktualisiert am",
  "lblFundingAmount": "Finanzierung",
  "lblFundIsinCode": "ISIN-Code",
  "lblFundManager": "Fondsverwalter",
  "lblFundName": "Fondsname",
  "lblFundNameRequired": "Fondsname erforderlich",
  "lblFundOwnership": "Anlegerdaten Hochladen",
  "lblFundOwnershipFund": "Anlegerdaten",
  "lblFundOwnershipImportsList": "Importprotokoll",
  "lblFundOwnershipUnderlyingVehicles": "Anlegerdaten",
  "lblFundProrata": "Fonds/Vehikel (anteilig)",
  "lblFundRepositoryExplanations": "Dieses Menü benutzen Sie, um Fonds- bzw. Investitionsstruktur in Alhambra zu gestalten, das heisst:<BR />  - Bestehende Immobilienobjektgesellschaften mit weiteren Informationen aktualisieren.  Immobilienobjektgesellschaften (das heisst Zweckgesellschaften mit direkt gehaltenen Objekten) werden bei der Integration von Daten aus Property Management-Anwendungen automatisch geschaffen.<BR />  - Weitere Gesellschaften mit keinen direkt gehaltenen Objekten auf höherer, HoldCo- bzw. Fonds-Ebene (Investmentvermögen wie zum Beispiel Immobilien-Sondervermögen, Spezial-Sondervermögen, Investment-KG, Spezial-Investment-KG) schaffen und aktualisieren.<BR />  - Beteiligungen an Gesellschaften sowie Investitionsbeziehungen zwischen Gesellschaften erstellen.<BR />  <BR />  Daten werden am Benutzerdefinierten Datum historisiert.",
  "lblFundSaved": "Die Fonds-Einheit [...] wurde erfolgreich gespeichert.",
  "lblFundsManagement": "Fonds-Repositorium",
  "lblFundUpdated": "Die Fonds-Einheit [...] wurde erfolgreich aktualisiert.",
  "lblFundValuation": "Grundstücksbewertung",
  "lblFUNPage": "Fonds-Datenblatt Export",
  "lblFurnishedRental": "Möblierte Miete",
  "lblFurnishedRentalEuro": "Möblierte Miete (€)",
  "lblFURPropertiesIdFKML": "Nachsorge-Kliniker (Île-de-France)",
  "lblGearingRatio": "Fremdkapitalquote",
  "lblGECPage": "allgemeine Ausgabenaufstellung - gebuchte Rechnungen",
  "lblGECRawReport": "allgemeine Ausgabenaufstellung - gebuchte Rechnungen - unverarbeitet",
  "lblGECReport": "allgemeine Ausgabenaufstellung - gebuchte Rechnungen",
  "lblGeneral": "Allgemein",
  "lblGeneralInformations": "Stammdaten",
  "lblGeneralSettings": "Allgemeine Einstellungen",
  "lblGenerateInterfaceACS": "Verwalten Buchhaltungsdatenstrom",
  "lblGenerateInterfaceLRC": "Verwalten Rechnungsposten Schnittstelle",
  "lblGenerateInterfaceTS": "Verwalten Mieterliste-Datenstrom",
  "lblGeographicalBreakdown": "Aufteilung des Netto-Verkehrswerts nach Standort",
  "lblGeoZones": "Standorte",
  "lblGEPPage": "allgemeine Ausgabenaufstellung - bezahlte Rechnungen",
  "lblGEPRawReport": "allgemeine Ausgabenaufstellung - bezahlte Rechnungen unverarbeitet",
  "lblGEPReport": "allgemeine Ausgabenaufstellung - bezahlte Rechnungen",
  "lblGERPage": "allgemeine Ausgabenaufstellung",
  "lblGERRawReport": "allgemeine Ausgabenaufstellung - unverarbeitet",
  "lblGERReport": "allgemeine Ausgabenaufstellung",
  "lblGlobalParemetersPage": "Allgemeine Einstellungen",
  "lblGlobalPortfolio": "(de) Global Portfolio",
  "lblGlobalReturns": "Total Returns",
  "lblGlobalReturnsBreakdown": "Verteilung des Total Returns",
  "lblGovernmentRegulatedResidentialLease": "Geregelter Wohnungsmietvertrag",
  "lblGovernmentRegulatedResidentialLeaseEuro": "Geregelter Wohnungsmietvertrag (€)",
  "lblGrandParisZoning": "Grand Paris Begrenzung",
  "lblGross": "Bruttorendite",
  "lblGrossAndNetCapitalRates": "Brutto- und Netto-Kapitalisierungszinssätze",
  "lblGrossAndNetInitYields": "Brutto- und Nettorendite",
  "lblGrossCapitalizationRateEvol": "Entwicklung der Brutto-Kapitalisierungszinssätze",
  "lblGrossDebt": "Fremdkapital (€)",
  "lblGrossDebtUnderlyingVehicles": "Fremdkapital (Beteiligungen) (€)",
  "lblGrossEffectiveRent": "Effektivmiete (EM) (€/Jahr)",
  "lblGrossEffectiveRent_WOU": "Effektivmiete (EM)",
  "lblGrossEffectiveRentRate": "Effektivmiete (EM)",
  "lblGrossHeadlineRent_WOU": "Vertragsmiete (VM)",
  "lblGrossHeadlineRentBreakByGeo": "Aufteilung der Vertragsmiete nach Standort",
  "lblGrossHeadlineRentBreakByPropType": "Aufteilung der Vertragsmiete nach Objekt-Nutzungsart",
  "lblGrossHeadlineRentBreakBySector": "(de) Gross Headline Rent Breakdown by Sector",
  "lblGrossHeadlineRentBreakByTenant": "Aufteilung der Vertragsmiete nach Mieter",
  "lblGrossHeadlineRentBreakByTenantIndustry": "Aufteilung der Vertragsmiete nach Branchen-Schlüssel",
  "lblGrossHeadlineRentBridge": "Veränderung der Vertragsmiete",
  "lblGrossInitilaYieldEvol": "Entwicklung der Bruttorendite",
  "lblGrossReceiptedRent": "Ist-Effektivmiete (€)",
  "lblGrossReceiptedRentByGrossBookValue": "Brutto-Buchwertrendite",
  "lblGrossReceiptedRentByNetMarketValue": "Mietrendite auf Basis Netto-Verkehrswert",
  "lblGrossRent": "Vertragsmiete (VM) (€/Jahr)",
  "lblGrossRentalIncome": "Miete (€)",
  "lblGrossRentalIncomeEvol": "Entwicklung der Brutto-Mieteinnahmen",
  "lblGrossRentSqm": "Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "lblGrossRentSqm_WOU": "Vertragsmiete (VM) pro m²",
  "lblGrossReversPotentOccupArea_WOU": "Vermietete Fläche",
  "lblGrossReversPotentTotalProp_WOU": "Gesamtfläche",
  "lblHeaderLine": "Kopfzeile behalten",
  "lblHeadlineGrossInitialYield": "Brutto-Anfangsrendite auf Basis VM",
  "lblHeadlineInitYield": "Anfangsrendite auf Basis VM",
  "lblHeadlineNetInitialYield_YOU": "Netto-Anfangsrendite auf Basis nachhaltiger NME",
  "lblHeadlineNetInitYield": "Netto-Anfangsrendite auf nachhaltiger NME ab (%):",
  "lblHeadlineRentAs": "Vertragsmiete zum DD/MM/YYYY (€)",
  "lblHeadlineRentLeaseRenewals": "Vertragsmiete von verlängerten Mietverträgen (€)",
  "lblHeadlineRentLeasesTerminated": "Vertragsmiete von gekündigten Mietverträgen (€)",
  "lblHeadlineRentLeaseTerminationsNotified": "Vertragsmiete von Kündigungen (€)",
  "lblHeadlineRentRelettings": "Vertragsmiete von Neuvermietungen (€)",
  "lblHeadOffice": "Hauptbüro",
  "lblHealthcare": "Gesundheitsimmobilien",
  "lblHideFilters": "Filter ausblenden",
  "lblHideGraph": "Kopfzeile ausblenden",
  "lblHideMap": "Kopfzeile ausblenden",
  "lblHideParams": "Einstellungen ausblenden",
  "lblHoldoverLeasesLegend": "* Nicht ausgezogene Mieter",
  "lblHoldoverTenancy": "Liste der nicht ausgezogenen Mietern",
  "lblHOTPage": "Liste der nicht ausgezogenen Mietern",
  "lblHOTRawReport": "Liste der nicht ausgezogenen Mietern - unverarbeitet",
  "lblHOTReport": "Liste der nicht ausgezogenen Mietern",
  "lblIdExists": "Die Objekt-Nummer ist schon vergeben.",
  "lblImmoStatMarketGeograhies": "ImmoStat-Marktsegmente",
  "lblImportDate": "Daten zum",
  "lblImportedBy": "Benutzer",
  "lblInceptiondate": "Datum Fondsauflage oder Übernahme",
  "lblIncomeReturn": "Einkommensrendite",
  "lblIncomeReturnEquityFairValBridgs": "Entwicklung der Einkommensrendite, Wertsteigerungsrendite und Gesamtrendite auf Basis Marktwert Eigenkapital",
  "lblIncomeReturnEquityFairValue": "Einkommensrendite auf Basis des Eigenkapitals",
  "lblIncomeReturnOnNAV": "Einkommensrendite auf Basis des Nettoinventarwerts",
  "lblIndustrialPremises": "Industrie-Immobilien",
  "lblInfOneMonth": "< 1 Monat",
  "lblInfThreeMonth": "< 3 Monaten",
  "lblInfTwoMonth": "< 2 Monaten",
  "lblInnerMsgError": "Ausführlichere Fehlermeldung:",
  "lblInsertNewNeutralizationPeriod": "Möchten Sie eine neue Neutralisierungsperiode hinzufügen?",
  "lblInterestPaidCurrentAccount": "Zinsen aus Kontokorrent (€)",
  "lblInterestPaidOnCurrentAccount": "Zinsen aus Kontokorrent (€)",
  "lblinterface_name": "(DE) Interface name",
  "lblinterface_type": "(DE) Interface type",
  "lblInterfaceInsertAccounting": "(de) Your accounting stream [...] has been successfully saved and added to the list of available accounting streams.",
  "lblInterfaceInsertAS": "(de) Your data stream - Accounting Stream [...] has been successfully saved and added to the list of available data streams.",
  "lblInterfaceInsertTS": "(de) Your data stream - Tenancy Schedule [...] has been successfully saved and added to the list of available data streams.",
  "lblInterfaceMappingExport": "Datentyp Exportieren",
  "lblInterfaceMappingWarningMessage": "Achtung, Einstellungen für einen Datentyp importieren wird die aktuelle Einstellungen diesem Datentyp löschen.",
  "lblInterfaceName": "(de) Interface Name",
  "lblInterfaceNameAlreadyExists": "(de) An Interface by this Name already exists",
  "lblInterfaceSequencesHistory": "(de) Sendings history of the accounting stream",
  "lblInterfaceSequencesLRCHistory": "Sendungshistorie der Schnittstelle für Rechnungsposten",
  "lblInterfaceSequencesTSHistory": "Historie der Sendungen des Mietspiegels",
  "lblInterfaceUpdateAccounting": "(de) Your accounting stream - [..] has been successfully updated.",
  "lblInterfaceUpdateAS": "(de) Your data stream - Accounting Stream [...] has been successfully updated.",
  "lblInterfaceUpdateSI": "(de) Your data stream - Specific Interface [...] has been successfully updated.",
  "lblInterfaceUpdateTS": "(de) Your data stream - Tenancy Schedule [...] has been successfully updated.",
  "lblInvalidAnswer": "Die Antwort zum Abrufen des Passworts ist ungültig. Wiederholen Sie die Eingabe und versuchen Sie es noch mal.",
  "lblInvalidBuildingCode": "Ungültige Objekt-Nummer",
  "lblInvalidBuildingName": "Ungültiger Objekt-Name",
  "lblInvalidBuildingNature": "Ungültige Objekt-Nutzungsart",
  "lblInvalidBuildingUniqueCode": "Ungültige Objekt-Nummer",
  "lblInvalidEmail": "Die eingegebene E-mail Adresse ist ungültig. Wiederholen Sie die Eingabe und versuchen Sie es noch mal.",
  "lblInvalidPassword": "Das eingegebene Passwort ist ungültig. Wiederholen Sie die Eingabe und versuchen Sie es noch mal.",
  "lblInvalidPMName": "Ungültiger Property Manager-Name",
  "lblInvalidQuestion": "Die Frage zum Abrufen des Passworts ist ungültig. Wiederholen Sie die Eingabe und versuchen Sie es noch mal.",
  "lblInvalidstartManagementDate": "Das Datum-Betriebsbeginn ist ungültig",
  "lblInvalidUserName": "Der eingegebene Benutzername ist ungültig. Wiederholen Sie die Eingabe und versuchen Sie es noch mal.",
  "lblInverstorDistribution": "Anleger-Ausschüttung",
  "lblInvestmentStructure": "Fondsstruktur",
  "lblInvestorContributions": "Eigenkapital",
  "lblInvestorDistribution": "Anleger-Ausschüttung",
  "lblInvestorLevelNAV": "Anlagerebene Nettoinventarwert",
  "lblInvestorNavAndReturns": "Anlagerebene Leistung",
  "lblInvestorReturn": "Anlagerebene Rendite",
  "lblInvestors": "Anleger",
  "lblInvestorShareOfNAV": "Anlagerebene Nettoinventarwert (€)",
  "lblInvoicingAmount": "Rechnungsbetrag (€)",
  "lblInvoicingBankAccount": "Bankkonto-Fakturierung",
  "lblIsBuildingNeutralized": "Das Objekt gilt als neutralisiert, wenn das letzte Datum der Daten zu einer der Neutralisierungsperioden des Objekts gehört",
  "lblIsLookThroughEntity": "Steuerrechtssubjekt?",
  "lblIsProviderActive": "Aktiver Lieferant",
  "lblIssuedSharesNumber": "Anzahl umlaufender Anteile",
  "lblJournalFamilies": "(de) Journal Families",
  "lblKeyFigures": "Kennzahlen",
  "lblLASPage": "(de) LASALLE Interface",
  "lblLastAnnualRent": "Letztere jährliche Miete (€)",
  "lbllastName": "Familienname",
  "lblLatitude": "Breitengrad",
  "lblLatitudeIncorrectFormat": "Breitengrad: Numerische Format erwartet (z.B. : -18,4587 or 27,5893)",
  "lblLatitudeIncorrectRange": "Breitengrad : Muss zwischen -90 und +90 liegen",
  "lblLaunch": "Möchten Sie die Schnittstelle sofort starten ?",
  "lblLaunchValidation": "Starten wir den Start der Schnittstelle",
  "lblLaw1948Lease": "Wohnungsmietvertrag (1948-Gesetz)",
  "lblLaw1948LeaseEuro": "Wohnungsmietvertrag (1948-Gesetz) (€)",
  "lblLease": "Mietvertrag-Nummer",
  "lblLeaseBreakByReversionPotential": "Aufteilung der Mietverträgen nach Mietanpassungspotenzial",
  "lblLeaseCount": "Anzahl von Mietverträgen",
  "lblLeaseEndAnnualRentAmount": "Vertragsmiete der auslaufenden Mietverträge",
  "lblLeaseEndCount": "Anzahl der auslaufenden Mietverträge",
  "lblLeaseEndDate": "Mietende",
  "lblLeaseEndRentArea": "Vermietete Fläche der auslaufenden Mietverträge",
  "lblLeaseEntrepriseSchedule": "Nächstmögliche Kündigungstermine",
  "lblLeaseExpiring6M": "Innerhalb 6 Monaten auslaufenden Mietverträge",
  "lblLeaseExpiryProfileArea": "Auslaufprofil der Mietverträge (auf Basis der vermieteten Fläche)",
  "lblLEaseExpiryProfileHeadRent": "Auslaufprofil der Mietverträge (auf Basis VM)",
  "lblLeaseHeadlineRentExceeding": "Nur Mietverträge wählen, die mehr als (€) Vertragsmiete mindestens haben :",
  "lblLeaseMonitoring": "Mietsteigerung und Mietanpassungspotenzial",
  "lblLeaseNatures": "Mietvertragsart",
  "lblLeaseNumber": "Anzahl von Mietverträgen",
  "lblLeasePlural": "Mietverträge",
  "lblLeaseRegularChargesLevel": "(de) Data at level",
  "lblLeaseRegularChargesLevelLease": "Mietvertrag",
  "lblLeaseRegularChargesLevelUnit": "Mieteinheit",
  "lblLeaseRegularChargesStatus": "(de) Include elements",
  "lblLeaseRegularChargesStatusAll": "Alles",
  "lblLeaseRegularChargesStatusFuture": "(de) Applicable in the future",
  "lblLeaseRegularChargesStatusPast": "(de) Applicable the past",
  "lblLeaseRegularChargesStatusPresent": "(de) Applicable in the present",
  "lblLeaseRenewals": "Verlängerungen (€)",
  "lblLeaseRepository": "Mietvertragsdaten ersetzen",
  "lblLeaseRepositoryImportsList": "Importprotokoll",
  "lblLeaseSingular": "Mietvertrag",
  "lblLeaseStartDate": "Mietbeginn",
  "lblLeaseTermination": "Gekündigte Mietverträge (€)",
  "lblLeaseTypes": "Mietvertragstypen",
  "lblLegalVehicleStructure": "Rechtsform",
  "lblLEMPage": "Mietsteigerung und Mietanpassungspotenzial",
  "lblLEMRawReport": "Mietsteigerung und Mietanpassungspotenzial - unverarbeitet",
  "lblLEMReport": "Mietsteigerung und Mietanpassungspotenzial",
  "lblLESPage": "Nächstmögliche Kündigungstermine",
  "lblLESRawReport": "Nächstmögliche Kündigungstermine - unverarbeitet",
  "lblLESReport": "Nächstmögliche Kündigungstermine",
  "lblLevel": "Ebene",
  "lblLevels": "Ebene",
  "lblLikeForLikeCapitalDecrease": "Like-for-Like Verminderung (€)",
  "lblLikeForLikeCapitalGrowth": "Like-for-Like Wachstum (€)",
  "lblLinkGED": "Guten Tag  Im Anschluss an Ihre Anfrage zu Alhambra finden Sie hier den Link zu den Dokumenten:",
  "lblLoanAccountAlreadyExists": "Die Gesellschafterdarlehen-Nummer wurde schon benutzt.",
  "lblLoanToValue": "Loan-To-Value (LTV)",
  "lblLocationConcentration": "Standortkonzentration (5 grösste Netto-Verkehrwerte)",
  "lblLocationConcentrationRentArea": "Standortkonzentration (5 grösste vermietbaren Flächen)",
  "lblLogIn": "Anmelden",
  "lblLoginError": "Fehler bei der Anmeldung oder beim Passwort",
  "lblLoginForm": "Anmeldeformular",
  "lblLoginLastAttemptWarning": "Ein falsches Passwort mehr und Ihre Konto wird deaktiviert.",
  "lblLoginName": "Anmeldung",
  "lblLoginRequired": "Anmeldung erforderlich",
  "lblLoginTooShort": "Anmeldung muss zumindest {0} Zeichen enthalten",
  "lblLoginWithLocalAccount": "Mit einem lokalen Konto anmelden",
  "lblLongitude": "Längengrad",
  "lblLongitudeIncorrectFormat": "Längengrad : Numerische Format erwartet (z.B. : -118,4587 or 127,5893)",
  "lblLongitudeIncorrectRange": "Längengrad : Muss zwischen -180 und +180 liegen",
  "lblLRCBillingItems": "Rechnungsposten Schnittstelle",
  "lblMAFPage": "Themen erstellen",
  "lblManageAdditionalFieldList": "Themen erstellen",
  "lblManageDataMapping": "(de) Manage Input Data Mapping",
  "lblManageInterfaceList": "(de) Manage Interfaces",
  "lblManageInterfaceMapping": "(de) Manage Output Data Mapping",
  "lblManageSpecificInterfaceList": "Spezificshe Schnittstellen verwalten",
  "lblManagingDirector": "Geschäftsleiter",
  "lblMarketToMarketFinancialInstruments": "Neubewertung der Finanzinstrumente (€)",
  "lblMarketValue": "Marktmiete erstellen",
  "lblMaxCharLength": "Maximale Zeichenlänge",
  "lblMCOPropertiesIdFKML": "MCO-Kliniker (Île-de-France)",
  "lblMDMPage": "(de) Manage Input Data Mapping",
  "lblMIMPage": "(de) Manage Output Data Mapping",
  "lblMITPage": "(de) Manage Interfaces",
  "lblMixed": "Mischimmobilie",
  "lblMixedEuro": "Mischimmobilie (€)",
  "lblMixedLease": "Mischmietvertrag",
  "lblMixedLeaseEuro": "Mischmietvertrag (€)",
  "lblModifyColumns": "Die Spalten wählen",
  "lblMonday": "Montag",
  "lblMsgNbColsDashboard": "Sie können nicht mehr als 4 Kennzahlen auswahlen",
  "lblMSIPage": "Spezificshe Schnittstellen verwalten",
  "lblMsqErrorPassword": "Das Passwort muss mindestens {0} Zeichen enthalten, darunter einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen !*@#$%^+=",
  "lblMultiPurpose": "Mehrzweck-Immobilie",
  "lblMyAccount": "Mein Konto",
  "lblMyAssets": "Mein Portfolio",
  "lblNameOfTemplate": "Vorlage-Name",
  "lblNameRequiredError": "Familienname erforderlich",
  "lblNature": "Nutzungsart",
  "lblNaturesOfOccupancy": "Keine Immobilie wählen, die völlig… ist",
  "lblNaturesOfOccupancyTitle": "Kein Objekt mit… wählen",
  "lblNe": "Netto",
  "lblNegativeRentReviews": "Negative Mietanpassungen (€)",
  "lblNet": "Nettorendite",
  "lblNetAssetVal": "Nettoinventarwert (€)",
  "lblNetAssetValEquitFairValBridge": "Nettoinventarwert und Marktwert Eigenkapital",
  "lblNetAssetValEquitFairValEvol": "Entwicklung des Nettoinventarwerts und Marktwert Eigenkapitals",
  "lblNetAssetValPerSharEquitFairValPerSharEvol": "Entwicklung des Anteilswerts und des Marktwerts Eigenkapital pro Anteil",
  "lblNetAssetValue": "Nettoinventarwert (NAV)",
  "lblNetAssetValueEquitySecurities": "Nettoinventarwert von Beteiligungen (€)",
  "lblNetAssetValueFund": "Nettoinventarwert (NAV) (€)",
  "lblNetAssetValuePerShare": "Anteilswert (€)",
  "lblNetAssetValueUnderlyingVehicles": "Nettoinventarwert von Beteiligungen (€)",
  "lblNetBookValue": "Netto-Buchwert erstellen",
  "lblNetBookValueEquitySecurities": "Nettobuchwert von Beteiligungen (€)",
  "lblNetBookValueFund": "Nettobuchwert (€)",
  "lblNetBookValueImportsList": "Importprotokoll",
  "lblNetBookValueUnderlyingVehicles": "Nettobuchwert von Beteiligungen (€)",
  "lblNetCapitalizationRateEvol": "Entwicklung der Netto-Kapitalisierungszinssätze",
  "lblNetChangeInCurrentAccount": "Veränderung Kontokorrent (€)",
  "lblNetChangeInShareCapital": "Erhöhung/Herabsetzung des gezeichneten Kapitals (€)",
  "lblNetChangeInSharePremium": "Erhöhung/Herabsetzung der Kapitalrücklage (€)",
  "lblNetEffectiveRent_WOU": "Netto-Mieteinnahmen (NME)",
  "lblNetHeadlineRent_WOU": "Nachhaltige Netto-Mieteinnahmen (NME)",
  "lblNetInitialYieldEvol": "Entwicklung der Nettorendite",
  "lblNetMarketRent_WOU": "Netto-Marktmiete",
  "lblNetMarketValBreakByGeo": "Aufteilung des Netto-Verkehrswerts nach Standort",
  "lblNetMarketValBreakByPropType": "Aufteilung des Netto-Verkehrswerts nach Objekt-Nutzungsart",
  "lblNetMarketValue": "Netto-Verkehrswert (€)",
  "lblNetMarketValueAsAt": "Netto-Verkehrswert zum DD/MM/YYYY (€)",
  "lblNetMarketValueBridge": "Veränderung des Netto-Verkehrswerts",
  "lblNetOperatingIncome": "Betriebsergebnis (€)",
  "lblNetOperatingIncomeBridge": "Betriebsergebnis-Aufteilung",
  "lblNetPotentialRent_WOU": "Potentieller Mietreinertrag",
  "lblNetRentalIncomeEvol": "Entwicklung der Netto-Mieteinnahmen",
  "lblNetResult": "Jahresergebnis (€)",
  "lblNetResultAfterTax": "Gewinn oder Verlust nach Steuern (€)",
  "lblNetResultBeforeTax": "Gewinn vor Steuern (€)",
  "lblNetResultBridge": "Jahresüberschuss/Jahresfehlbetrag-Aufteilung",
  "lblNetResultReturnOnGrossBookOutSessionValue": "NCF-Rendite exkl. Verkäufe auf Basis Brutto-Buchwert",
  "lblNetResultReturnOnGrossBookValue": "NCF-Rendite auf Basis Brutto-Buchwert",
  "lblNetResultTax": "Steuern vom Einkommen und Ertrag (€)",
  "lblNetReversionaryYield_WOU": "Netto-Anpassungsrendite auf Basis MM",
  "lblNeutEndDateInNextPeriod": "Das Enddatum der Objektsneutralisierung gehört zur nächsten Neutralisierungsperiode!",
  "lblNeutralizationEndDateEmpty": "Das Enddatum der Objektsneutralisierung wurde nicht definiert!",
  "lblNeutralizationEndDateNotADate": "Das Enddatum der Objektsneutralisierung ist kein gültiges Datum!",
  "lblNeutralizationStartDateEmpty": "Das Anfangsdatum der Objektsneutralisierung wurde nicht definiert!",
  "lblNeutralizationStartDateNotADate": "Das Anfangsdatum der Objektsneutralisierung ist kein gültiges Datum!",
  "lblNeutralizationType": "Neutralisierung",
  "lblNeutStartDateGreaterThanNeutEndDate": "Das Anfangsdatum ist neuer als das Enddatum der Objektsneutralisierung!",
  "lblNeutStartDateInPreviousPeriod": "Das Anfangsdatum ist älter als das Enddatum der vorherigen Neutralisierungsperiode!",
  "lblNeutStartDateLowerThanStartManagementDate": "Das Anfangsdatum ist älter als das Betriebsbeginn des Objekts!",
  "lblNewBuildingRepository": "Ein neues Objekt zum Repositorium hinzufügen",
  "lblNewERVImport": "Marktmiete erstellen",
  "lblNewFund": "Neuer Fonds",
  "lblNewFundOwnershipImport": "Anlegerdaten Hochladen",
  "lblNewLeaseRepositoryImport": "Mietvertragsdaten ersetzen",
  "lblNewNetBookValueImport": "Netto-Buchwert erstellen",
  "lblNewPassword": "Neues Passwort",
  "lblNewPasswordRequired": "Neues Passwort erforderlich",
  "lblNewPurchase": "Neuer Erwerb",
  "lblNewSale": "Neuer Verkauf",
  "lblNewTemplate": "Neue Vorlage",
  "lblNewUserMailBodyEnd": "Mit besten Grüßen,  Das Alhambra-Team",
  "lblNewUserMailBodyMiddle": "Hier können sie sich anmelden :",
  "lblNewUserMailBodyMiddle1": "- Passwort:",
  "lblNewUserMailBodyMiddle2": "Hier können sie sich anmelden :",
  "lblNewUserMailBodyStart": "Hallo,  Bitte finden Sie untenstehend die für Alhambra :  - Anmeldung :",
  "lblNewUserMailBodyStart1": "Hallo,  Bitte finden Sie untenstehend die für Alhambra :  - Anmeldung :",
  "lblNewUserMailBodyStart2": "Hallo,  Bitte finden Sie untenstehend die für Alhambra :  - Temporäres Passwort :",
  "lblNewUserMailSubject": "ALHAMBRA - Neuer Benutzer",
  "lblNewValuationImport": "Bewertung-Daten erstellen",
  "lblNewYieldImport": "Zielrenditen erstellen",
  "lblNextLeaseBreakDate": "Nächstmöglicher Kündigungstermin",
  "lblNextMonths": "Monat(en)",
  "lblNo": "Nicht",
  "lblNoBuildingProviderSelected": "Kein Objekt oder Anbieter ausgewählt. Bitte wählen Sie mindestens ein Objekt oder ein Anbieter.",
  "lblNoBuildingSelected": "Kein Objekt ausgewählt. Bitte wählen Sie mindestens ein Objekt.",
  "lblNoColumn": "Bitte wählen Sie eine oder mehrere Spalten aus.",
  "lblNoCompanySelected": "Keine Gesellschaft ausgewählt. Bitte wählen Sie mindestens eine Gesellschaft.",
  "lblNoData": "Keine Daten gefunden",
  "lblNoDataCharts": "Für dieses Diagramm stehen keine Daten zur Verfügung",
  "lblNoDatasetSelected": "Kein Datensatz ausgewählt",
  "lblNoFileSelected": "Keine Datei ausgewählt",
  "lblNoInvestorSelected": "No investor selected. Please select one or more investor.",
  "lblNoNewTranscodingType": "Sie können keine neuen Datentypen erstellen.",
  "lblNoNewTypeXLinesRejected": "Sie können keine neuen Datentypen erstellen. {0} Linien wurden abgelehnt.",
  "lblNoRssArticles": "Keinen Artikel gefunden",
  "lblNoTemplate": "Keine Vorlage",
  "lblNumberBuildings": "Anzahl von Immobilien",
  "lblNumberLeaseRenewals": "Anzahl von Verlängerungen",
  "lblNumberLeases": "Anzahl von Mietverträgen",
  "lblNumberLeasesTerminated": "Anzahl von gekündigten Mietverträgen",
  "lblNumberLeaseTerminationsNotified": "Anzahl von Kündigungen",
  "lblNumberOfBuildingsUnderManagementByBuildingType": "(de) Number of Buildings Under Management by Building Type",
  "lblNumberOfHousings": "(de) Number of Housings",
  "lblNumberOfInterfaces": "(de) Number of Linked Data Streams",
  "lblNumberOfOwnedFunds": "Anzahl von Tochtergesellschaften",
  "lblNumberOfSharesUnallocated": "Anzahl unverteilter Anteile (kalkuliert)",
  "lblNumberOfSharesUnallocatedIncorrect": "Die Anzahl der sich im Umlauf befindenden Anteile oder die Anzahl der gehaltenen Anteile ist ungültig",
  "lblNumberRelettings": "Anzahl von Neuvermietungen",
  "lblNumberRents": "Anzahl von Mieten",
  "lblNumberTenants": "Anzahl von Mietern",
  "lblOccupancy": "Vermietungsstand",
  "lblOccupiedAreaBreakBySector": "Aufteilung der vermieteten Fläche nach Standort",
  "lblOccupiedAreaErv": "Marktmiete der vermietete Fläche (€/Jahr)",
  "lblOfficeRetailOutlet": "Büro / Geschäft",
  "lblOfficeRetailOutletEuro": "Büro / Geschäft (€)",
  "lblOffices": "Büro",
  "lblOPBPage": "Entwicklung der Zahlungsbilanzen und der bezahlten Beträge",
  "lblOPBRawReport": "Entwicklung der Zahlungsbilanzen und der bezahlten Beträge - unverarbeitet",
  "lblOPBReport": "Entwicklung der Zahlungsbilanzen und der bezahlten Beträge",
  "lblOperatingExpenses": "Bewirtschaftungskosten (€)",
  "lblOperatingProperties": "(de) Operating Properties",
  "lblOperatingPropertiesSqm": "(de) Operating Real Estate (m²)",
  "lblOperatingRealEstate": "(de) Operating Real Estate (ERE + RRE)",
  "lblOperatingResult": "Betriebsergebnis (€)",
  "lblOperatingReturnOnGrossBookValue": "Nettobetriebserfolgsrendite auf Basis Brutto-Buchwert",
  "lblOPMPage": "Stichtag offene Zahlungen",
  "lblOPMRawReport": "Stichtag offene Zahlungen - unverarbeitet",
  "lblOPMReport": "Stichtag offene Zahlungen",
  "lblOPPBULRawReport": "Aggregierte Bilanz Gebäude nach Lieferanten und Rechnungen - unverarbeitet",
  "lblOPPBULReport": "Aggregierte Bilanz Gebäude nach Lieferanten und Rechnungen",
  "lblOPPPage": "Aggregierte Bilanz der Lieferanten",
  "lblOPPPRORawReport": "Aggregierte Bilanz der Lieferanten nach Gebäuden und Rechnungen - unverarbeitet",
  "lblOPPPROReport": "Aggregierte Bilanz der Lieferanten nach Gebäuden und Rechnungen",
  "lblOPPRawReport": "Aggregierte Bilanz Gebäude nach Lieferanten - unverarbeitet",
  "lblOPPReport": "Aggregierte Bilanz Gebäude nach Lieferanten",
  "lblOther": "Sonstiges",
  "lblOtherBreakMonths": "Die Linie rot hervorheben, wenn ein sonstiger Mietvertrag einen Kündigungstermin in den nächsten (Monaten) hat :",
  "lblOtherEuro": "Sonstiges",
  "lblOtherIncome": "Weitere Erträge (€)",
  "lblOtherLease": "Sonstiger Mietvertrag",
  "lblOtherLeaseendMonths": "Die Linie rot hervorheben, wenn ein sonstiger Mietvertrag in den nächsten (Monaten) abläuft :",
  "lblOtherLeasePlural": "sonstige Mietverträge",
  "lblOtherLeaseSingular": "sonstiger Mietvertrag",
  "lblOUTCOTRawReport": "Offene Posten - unverarbeitet",
  "lblOUTCOTReport": "Offene Posten",
  "lblOUTINVRawReport": "Fälligskeitliste nach Rechnung - unverarbeitet",
  "lblOUTINVRCIRawReport": "Fälligskeitliste nach Rechnung und Rechnungsposten - unverarbeitet",
  "lblOUTINVRCIReport": "Fälligskeitliste nach Rechnung und Rechnungsposten",
  "lblOUTINVReport": "Fälligskeitliste nach Rechnung",
  "lblOUTLEARCIRawReport": "Fälligskeitliste nach Mietvertrag und Rechnungsposten - unverarbeitet",
  "lblOUTLEARCIReport": "Fälligskeitliste nach Mietvertrag und Rechnungsposten",
  "lblOUTPage": "Fälligkeitsliste",
  "lblOUTRawReport": "Fälligskeitliste nach Mietvertrag - unverarbeitet",
  "lblOUTReport": "Fälligskeitliste nach Mietvertrag",
  "lblOutstandingDebt": "Fremdkapital (€)",
  "lblOutstandingPayment": "Fälligkeitsliste",
  "lblOutstandingPayments": "Fälligskeitliste nach Mietvertrag und Rechnungsposten",
  "lblOutstandingPayProvider": "Aggregierte Bilanz der Lieferanten",
  "lblOutstandPayInvoice": "Fälligskeitliste nach Rechnung",
  "lblOutstandPayInvoiceBillItem": "Fälligskeitliste nach Rechnung und Rechnungsposten",
  "lblOutstandPaymentOfThePeriod": "Offene Posten der Periode (€)",
  "lblOutstandPaymentRateOfThePeriod": "Rückstandsquote",
  "lblOutstandPayMonitoring": "Stichtag offene Zahlungen",
  "lblOutstandPayProvider": "Aggregierte Bilanz der Lieferanten",
  "lblOutstandPaySummary": "Fälligskeitliste nach Mietvertrag",
  "lblOutstdingRateAmountBalance": "Entwicklung der Rate Bezahlungen gegen Zahlungsbilanz Saldo",
  "lblOverPaymentAmount": "Überzahlung",
  "lblOverRented": "Vertragsmiete liegt über der Marktmiete",
  "lblOwnedBuildings": "OBJEKTÜBERSICHT DIREKT GEHALTEN",
  "lblOwners": "Eigentümer",
  "lblParams": "Einstellungen",
  "lblParamTranscoding": "ParamTranscoding",
  "lblPassRecoveryLinkTimeCheck": "Abgelaufen Einziehung Link",
  "lblPassword": "Passwort",
  "lblPasswordAlreadyUsed": "Das eingegebene Passwort ist schon vergeben.",
  "lblPasswordChanged": "Ihr Passwort wurde erfolgreich geändert",
  "lblPasswordChangedMailBodyEnd": "Mit besten Grüßen,  Das Alhambra-Team",
  "lblPasswordChangedMailBodyStart": "Hallo,  Bitte finden Sie untenstehend Ihr neues Passwort für Alhambra :",
  "lblPasswordChangedMailSubject": "ALHAMBRA - Ihr Passwort wurde erfolgreich geändert",
  "lblPasswordChangeError": "Ihr Passwort kann nicht geändert werden.  Stellen Sie sicher dass:  - das eingegebene vorherige Passwort gültig ist,  - ihr neues Passwort mindestens 8 Zeichen lang ist,  - ihr neues Passwort sowohl Buchstaben als auch Ziffern und Sonderzeichen enthält.  ",
  "lblPasswordCreateError": "Incorrect password. The password must be at least 8 characters long and have at least one alphabetical character, one number and one special character.",
  "lblPasswordExpired": "Ihr Passwort ist abgelaufen. Bitte vergeben Sie ein neues Passwort.",
  "lblPasswordMustAtLeasrEightCharLong": "Ihr Passwort muss mindestens 8 Zeichen enthalten.",
  "lblPasswordMustContainDigit": "Ihr Passwort muss ein Ziffer enthalten.",
  "lblPasswordMustContainSpecialChar": "Ihr Passwort muss ein Sonderzeichen enthalten.",
  "lblPasswordRequired": "Das eingegebene Passwort ist ungültig.  Stellen Sie sicher dass, das Passwort mindestens 8 Zeichen lang ist und sowohl Buchstaben als auch Ziffern und Sonderzeichen enthält.  ",
  "lblPATPage": "Laufende Verkäufe",
  "lblPATRawReport": "Laufende Verkäufe - unverarbeitet",
  "lblPATReport": "Laufende Verkäufe",
  "lblPatrimony": "Immobilienportfolio",
  "lblPaymentAmount": "Zahlungsbetrag",
  "lblPBAPage": "Objekt-Ebene Saldenbilanz",
  "lblPBARawReport": "Objekt-Ebene Saldenbilanz - Unverarbeitet",
  "lblPBAReport": "Objekt-Ebene Saldenbilanz",
  "lblPendingArbitrageTransactions": "Laufende Verkäufe",
  "lblPendingPurchaseTransactions": "Laufende Erwerbe",
  "lblPercent": "%",
  "lblPercentOfTotalOutstandingPayment": "% des gesamten Zahlungsrückstands",
  "lblPerfImmo": "Leistung",
  "lblPeriod1": "(de) Borne supérieure Période 1",
  "lblPeriod2": "(de) Borne supérieure Période 2",
  "lblPeriod3": "(de) Borne supérieure Période 3",
  "lblPeriod4": "(de) Borne supérieure Période 4",
  "lblPeriod5": "(de) Borne supérieure Période 5",
  "lblPhysicalOccRate": "Vermietungsquote",
  "lblPhysicalOccupancySummary": "Vermietungsstand nach Mietfläche",
  "lblPhysicalOccupancySummaryEvolution": "Entwicklung des Vermietungsstands nach Mietfläche",
  "lblPhysicalVacancy": "Physische Leerstandsquote",
  "lblPhysicalVacRate": "Physische Leerstandsquote",
  "lblPICPage": "gebuchte Lieferantenrechnungen",
  "lblPICRawReport": "gebuchte Lieferantenrechnungen - unverarbeitet",
  "lblPICReport": "gebuchte Lieferantenrechnungen",
  "lblPIPPage": "bezahlte Lieferantenrechnungen",
  "lblPIPRawReport": "bezahlte Lieferantenrechnungen - unverarbeitet",
  "lblPIPReport": "bezahlte Lieferantenrechnungen",
  "lblPleaseWait": "Bitte warten Sie…",
  "lblPMBuildingCode": "Objekt-Nummer vom Immobilien-Verwalter",
  "lblPMRPage": "(de) Tableau de bord ADB",
  "lblPMRReport": "(de) Tableau de bord ADB",
  "lblPMRReportEREBuildingType": "Gewerbeimmobilie",
  "lblPMRReportEREBuildingTypeSqm": "Gewerbeimmobilie (m²)",
  "lblPMRReportRREBuildingType": "Wohnimmobilie",
  "lblPMRReportRREBuildingTypeSqm": "Wohnimmobilie (m²)",
  "lblPOBPage": "Aufteilung des Immobilienportfolios",
  "lblPOBRawReport": "Aufteilung des Immobilienportfolios - unverarbeitet",
  "lblPOBReport": "Aufteilung des Immobilienportfolios",
  "lblPortfolio": "Portfolio",
  "lblPortfolioBreakByGeography": "Aufteilung des Portfolios nach Standort",
  "lblPortfolioBreakByMarketGeo": "Aufteilung des Portfolios nach Marktsegment",
  "lblPortfolioBreakByPropertyType": "Aufteilung des Portfolios nach Objekt-Nutzungsart",
  "lblPortfolioBreakByTenant": "Aufteilung des Portfolios nach Mieter",
  "lblPortfolioBreakByTenantIndustry": "Aufteilung des Portfolios nach Branchen-Schlüssel",
  "lblPortfolioBreakdown": "Aufteilung des Immobilienportfolios",
  "lblPortfolioBreakdownByBuildingNature": "Aufteilung nach Immobilientyp",
  "lblPortfolioBreakdownByBuildingType": "Aufteilung nach Typologien",
  "lblPortfolioCode": "Portfolio-Nummer",
  "lblPortfolioCode_required": "Portfolio-Nummer erforderlich",
  "lblPortfolioEvolution": "Entwicklung des Immobilienportfolios",
  "lblPortfolioLabel": "DE_PORTFOLIO_LABEL",
  "lblPortfolioName": "Portfolio-Name",
  "lblPortfolioNameDe": "Portfolio-Name (Deutsch)",
  "lblPortfolioNameDe_required": "Portfolio-Name (Deutsch) erforderlich",
  "lblPortfolioNameEn": "Portfolio-Name",
  "lblPortfolioNameEn_required": "Portfolio-Name (Englisch) erforderlich",
  "lblPortfolioNameFr": "Portfolio-Name (Französisch)",
  "lblPortfolioNameFr_required": "Portfolio-Name (Französisch) erforderlich",
  "lblPortfolios": "Portfolios",
  "lblPortfolioSize": "Portfoliovolumen",
  "lblPortfolioYieldsAndReturns": "Portfolio-Ebene Renditen",
  "lblPositiveRentReviews": "Positive Mietanpassungen (€)",
  "lblPostalCode": "Postleitzahl",
  "lblPotentialInitYield": "Anfangsrendite auf Basis SM",
  "lblPotentialNetInitialYield_YOU": "Netto-Anfangsrendite auf Basis potentiellem Mietreinertrag",
  "lblPPTPage": "Laufende Erwerbe",
  "lblPPTRawReport": "Laufende Erwerbe - unverarbeitet",
  "lblPPTReport": "Laufende Erwerbe",
  "lblPreferredPortfolio": "Lieblingsportfolio",
  "lblPreviousDate": "Das Startdatum muss vor dem Enddatum liegen, {0} abgelehnte Zeile.",
  "lblPreviousFundUpdateDeleted": "Die Aktualisierung der Gesellschaft [...] wurde erfolgreich gelöscht.",
  "lblPreviouslyUpdatedOn": "Zuvor aktualisiert am",
  "lblPreviousNeutEndDateNotSet": "Das Enddatum für die vorherige Neutralisierungsperiode wird nich definiert!",
  "lblProfessional": "Auf Freiberufler ausgerichteter Mietvertrag",
  "lblProfessionalEuro": "Auf Freiberufler ausgerichteter Mietvertrag (€)",
  "lblProfessionalLease": "Auf Freiberufler ausgerichteter Mietvertrag",
  "lblProfessionalLeaseEuro": "Auf Freiberufler ausgerichteter Mietvertrag (€)",
  "lblProfile": "Profil",
  "lblProperty": "Objekt",
  "lblPropertyAcquisitions": "Erwerbe (€)",
  "lblPropertyAreaExceeding": "Nur Objekte wählen, die mehr als (m²) Gesamtfläche mindestens haben :",
  "lblPropertyBalance": "Objekt-Ebene Saldenbilanz",
  "lblPropertyBalanceSummary": "Objekt-Ebene Saldenbilanz Zusammenfassung",
  "lblPropertyBreakByGeo": "Aufteilung der Immobilienanzahl nach Standort",
  "lblPropertyBreakByPropertyType": "Aufteilung der Immobilienanzahl nach Objekt-Nutzungsart",
  "lblPropertyDisposals": "Verkäufe (€)",
  "lblPropertyDistribution": "Aufteilung der Objekte nach Einkommens- und Wertänderungsrendite",
  "lblPropertyManagementActivities": "(de) Property Management Activities",
  "lblPropertyManager": "Immobilien-Verwalter",
  "lblPropertyManagerCode": "Immobilien Manager-Nummer",
  "lblPropertyManagerCode_required": "Immobilien Manager-Nummer erforderlich",
  "lblPropertyManagerName": "Immobilien Manager-Name",
  "lblPropertyManagerName_required": "Immobilien Manager-Name erforderlich",
  "lblPropertyNetMarketValueExceeding": "Wenn der Verkehrswert des Objekts (€) erreicht :",
  "lblPropertyValuation": "Wertberichtigung der Objekte",
  "lblProviderError": "Es ist ein Fehler mit dem Authentifizierungsprovider aufgetreten. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es noch mal. Falls das Problem weiterhin besteht nehmen sie bitte Kontakt mit dem Systemadministrator.",
  "lblProviderInvoices": "Lieferantenrechnungen",
  "lblProviderInvoicesAccounted": "gebuchte Lieferantenrechnungen",
  "lblProviderInvoicesPaid": "bezahlte Lieferantenrechnungen",
  "lblPurchase": "Erwerbe erstellen",
  "lblPurchaseAgreementDate": "Datums des Kaufvertrags",
  "lblPurchaseHistory": "Überblick der Investitionsvolumen",
  "lblPurchaseManagement": "Erwerbe erstellen",
  "lblPurchaseSearch": "Ein neu gekauftes Objekt suchen",
  "lblPurchaseTitle": "Laufende Erwerbe",
  "lblPurchaseType": "Bezugsart",
  "lblPVAPage": "Wertberichtigung der Objekte",
  "lblPVARawReport": "Wertberichtigung der Objekte - unverarbeitet",
  "lblPVAReport": "Wertberichtigung der Objekte",
  "lblPwdExists": "Sie können kein altes passwort benutzen",
  "lblPwdExpired": "Ihre Password hat abgelaufen, bitte klicken Sie on \"Password ändern\" um es zu erneuern",
  "lblPwdRecorded": "Passwort gespeichert",
  "lblPwdRecoveryLegend": "Passwort-Wiederherstellung",
  "LblPwdSpecialChar": "Das Passwort muss mindestens {0} Zeichen enthalten, darunter einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen !*@#$%^+=",
  "lblRackRented": "Vertragsmiete entspricht der Marktmiete",
  "lblRangeErrorYield": "Die Zielrendite muss zwischen 0 und 1 liegen",
  "lblRaw": "Brutto",
  "lblRealEstatePortfolio": "Immobilienportfolio",
  "lblRebillings": "Einmalige Mieterbelastung (€)",
  "lblReceiveNotification": "Eine Benachrichtung erhalten, wenn…",
  "lblReconciliationSettlement": "Nebenkostenabrechnung (€)",
  "lblReferenceYear": "Referenzjahr",
  "lblRegularCharges": "(de) Billing Items",
  "lblRegularChargesCode": "Fakturierungselement-Nummer",
  "lblRegularChargesExpenses": "Auszuschließende Bewirtschaftungskosten",
  "lblRegularChargesExpensesExcluded": "Auszuschließende Bewirtschaftungskosten",
  "lblRegularChargesLabel": "Fakturierungselement-Bezeichnung",
  "lblRegularChargesOtherIncomes": "Auszuschließende Sonstige Einnahme",
  "lblRegularChargesOtherIncomesExcluded": "Auszuschließende Sonstige Einnahme",
  "lblRegularChargesRents": "Auszuschließende Miete",
  "lblRegularChargesRentsExcluded": "Auszuschließende Miete",
  "lblRegularChargesWorks": "Auszuschließende Renovierung",
  "lblRegularChargesWorksExcluded": "Auszuschließende Renovierung",
  "lblRelettings": "Neuvermietungen (€)",
  "lblReloadExplanations": "Dieses Menü benutzen Sie, um:<BR/>  - Daten teilweise neu zu laden, damit die manuell hochgeladenen daten (Bewertung-Daten, Marktmiete, Netto-Buchwerte) berücksichtigt werden<BR/>  - Daten vollständig neu zu laden, damit die Veränderungen am Objekt-Repositorium (Objekt-Nutzungsart, Portfolio, Immobilien-Marktsegment, usw.) berücksichtigt werden<BR/>  <BR/>  Daten neu zu laden könnte die Leistungsfähigkeit der Anwendung beinflussen (vorübergehende Schwäche, Leistungsausfall zum Herunterladen der Berichte). Es ist deswegen empfohlen, die Daten während Zeiten geringerer Auslastung neu zu laden order die Benutzer rechtzeitig über geplante Aufgaben zu informieren.",
  "lblReloadPage": "Daten neu laden",
  "lblRELPage": "Daten neu laden",
  "lblRememberPassword": "Passwort speichern ?",
  "lblRentalIncome": "Mieteinnahmen",
  "lblRentalIncomeMonitoring": "Überwachung der Soll-Mieteinnahmen",
  "lblRentalIncomeMonitoringCompare": "Jahresvergleich der Fakturierung",
  "lblRentalIncomeMonitoringDetailed": "Fakturierung seit Jahresbeginn",
  "lblRentalSituation": "Detaillierte Vermietungssituation",
  "lblRentalVacantArea": "Vermietbare Leerstandsfläche (m²)",
  "lblRentalVacantAreaRate": "% des gesamten Leerstands",
  "lblRentDecreasedPlural": "Mietminderungen",
  "lblRentDecreasedSingular": "Mietminderung",
  "lblRentedArea": "Vermietete Fläche (m²)",
  "lblRentedArea_WOU": "Vermietete Fläche",
  "lblRentEvolution1Y": "Mietentwicklung im Laufe des letzten Jahres",
  "lblRentIncreasedPlural": "Mietsteigerungen",
  "lblRentIncreasedSingular": "Mietsteigerung",
  "lblRents": "Vertragsmiete (VM) (€/Jahr)",
  "lblRents_WOU": "Vertragsmiete (VM)",
  "lblRentVariationRate": "Mietsteigerungsquote",
  "lblReportGenerationFailed": "Alhambra - Fehler beim Generieren des Berichts: {0}",
  "lblReportGenerationFailedBody": "Hallo,      Leider ist beim Generieren Ihres Berichts {0} ein Fehler aufgetreten.  Bitte versuchen Sie es später noch einmal.      Mit freundlichen Grüßen,    Das Alhambra-Team",
  "lblReportInterfaceSequencesHistory": "(DE)Sendings history of the report interface",
  "lblReportLaunchInBackgroundMessage": "Sobald der Bericht zum Laden zur Verfügung steht, werden Sie eine E-mail mit dem Download-Link erhalten!",
  "lblReportName": "Zugehöriger Standard-Bericht",
  "lblReportReadyToDownload": "Alhambra - Bericht zum Herunterladen: {0}",
  "lblReportReadyToDownloadBody": "Hallo,    Klicken Sie bitte auf den folgenden Link oder kopieren und einfügen Sie den Link in die Adresseleiste von ihrem Browser, um den Bericht herunterzuladen:  {0}    Vorsicht: Nachdem das Download abgeschlossen ist, wird der Bericht vom Server gelöscht.    Mit freundlichen Grüßen,    Das Alhambra-Team",
  "lblReportTemplateManagement": "Berichtsvorlage erstellen",
  "lblReportTemplateSaved": "Ihre Vorlage [...] wurde erfolgreich gespeichert und der Liste der verfügbaren Berichte hinzugefügt. Die Vorlage können Sie im Bildschirmmenü \"Berichtsvorlage erstellen\" aktualisieren oder löschen.",
  "lblReportTemplateUpdated": "Ihre Vorlage [...] wurde erfolgreich gespeichert",
  "lblRepositoryArbitrageSaved": "Der Verkauf [...] wurde erfolgreich gespeichert.",
  "lblRepositoryArbitrageUpdated": "Der Verkauf [...] wurde erfolgreich aktualisiert.",
  "lblRepositoryBuildingReport": "Objekt-Repositorium",
  "lblRepositoryBuildingSaved": "Das Objekt [...] wurde erfolgreich gespeichert.",
  "lblRepositoryBuildingUpdated": "Das Objekt [...] wurde erfolgreich aktualisiert.",
  "lblRepositoryFundReport": "Fonds-Repositorium",
  "lblRepositoryListing": "Objekt-Repositorium-Liste",
  "lblRepositoryPurchaseSaved": "Der Erwerb [...] wurde erfolgreich gespeichert.",
  "lblRepositoryPurchaseUpdated": "Der Erwerb [...] wurde erfolgreich aktualisiert.",
  "lblResidential": "Wohnen",
  "lblResidentialBreakMonths": "Die Linie rot hervorheben, wenn ein Wohnungsmietvertrag einen Kündigungstermin in den nächsten (Monaten) hat :",
  "lblResidentialCareIdFKML": "Pflegewohnheime (Île-de-France)",
  "lblResidentialEuro": "Wohnen (€)",
  "lblResidentialLease": "Wohnungsmietvertrag (1989-Gesetz)",
  "lblResidentialLeaseendMonths": "Die Linie rot hervorheben, wenn ein Wohnungsmietvertrag in den nächsten (Monaten) abläuft :",
  "lblResidentialLeaseEuro": "Wohnungsmietvertrag (1989-Gesetz) (€)",
  "lblResidentialLeasePlural": "Wohnungsmietvertrage",
  "lblResidentialLeaseSingular": "Wohnungsmietvertrag",
  "lblRESPage": "Detaillierte Vermietungssituation",
  "lblRESRawReport": "Detaillierte Vermietungssituation - raw",
  "lblRESReport": "Detaillierte Vermietungssituation",
  "lblRestartEmailBody": "Hallo,      Dies ist der letzte Tag der Unterbrechung der Sendungen.      Die Sendungen der Schnittstelle werden daher morgen wieder aufgenommen.      Mit freundlichen Grüßen,      Das Alhambra-Team",
  "lblRestartEmailSubject": "Alhambra - Warnung vor der Wiederaufnahme der Sendungen",
  "lblResults": "Ergebnisse",
  "lblRetailBreakMonths": "Die Linie rot hervorheben, wenn ein gewerblicher Mietvertrag einen Kündigungstermin in den nächsten (Monaten) hat :",
  "lblRetailLease": "Gewerblicher Mietvertrag (€)",
  "lblRetailLeaseendMonths": "Die Linie rot hervorheben, wenn ein gewerblicher Mietvertrag in den nächsten (Monaten) abläuft :",
  "lblRetailOutlets": "Geschäft",
  "lblReturnOnEquityLevered": "Gesamtkapitalrendite",
  "lblReturnOnEquityUnlevered": "Eigenkapitalrendite",
  "lblReversionaryYield": "Anpassungsrendite auf Basis MM",
  "lblRIMDetailledRawReport": "Fakturierung seit Jahresbeginn - unverarbeitet",
  "lblRIMDetailledReport": "Fakturierung seit Jahresbeginn",
  "lblRIMPage": "Überwachung der Soll-Mieteinnahmen",
  "lblRIMRawReport": "Überwachung der Mieteinnahmen - unverarbeitet",
  "lblRIMReport": "Überwachung der Mieteinnahmen",
  "lblRisk": "Risiken",
  "lblRLEPage": "Mietvertragsdaten ersetzen",
  "lblRotationRate": "Neuvermietungsquote",
  "lblRPAPage": "Verkäufe erstellen",
  "lblRPBPage": "Objekt-Repositorium",
  "lblRPFPage": "Fonds-Repositorium",
  "lblRPMPage": "Marktmiete erstellen",
  "lblRPNPage": "Netto-Buchwert erstellen",
  "lblRPPPage": "Erwerbe erstellen",
  "lblRPTPage": "Berichtsvorlage erstellen",
  "lblRPVPage": "Bewertung-Daten erstellen",
  "lblRPYPage": "Rendite erstellen",
  "lblRRENonOperating": "(de) Residential Real Estate (Non-Operating)",
  "lblRunningTotalOfRentIncomByCollectionPeriod": "Kumulierte Verteilung der Ist-Mieteinnahmen nach Laufzeit",
  "lblSales": "Verkäufe erstellen",
  "lblSaleTitle": "Laufende Verkäufe",
  "lblSaleVacantArea": "Nach Verkauf leerstehende Leerstandsfläche (m²)",
  "lblSaleVacantAreaRate": "% des gesamten Leerstands",
  "lblSamePassword": "Das neue Passwort muss unterschiedlich zum vorherigen Passwort sein",
  "lblSaturday": "Samstag",
  "lblSDGPage": "(de) Security Deposits Guarantees",
  "lblSDGRawReport": "(de) Security Deposits Guarantees - unverarbeitet",
  "lblSDGReport": "(de) Security Deposits Guarantees",
  "lblSeachInTheGridColumns": "Bericht oder Vorlage suchen",
  "lblSector": "Marktsegment",
  "lblSectorCode": "Marktsegment-Nummer",
  "lblSectorCode_required": "Marktsegment-Nummer erforderlich",
  "lblSectorConcentration": "Sektorale Konzentration (5 grösste Netto-Verkehrwerte)",
  "lblSectorConentrationRentArea": "Sektorale Konzentration (5 grösste vermietbaren Flächen)",
  "lblSectorErv": "Immobilien-Marktsegment (für Marktmiete-Benutzung notwendig)",
  "lblSectorLabel": "DE_SECTOR_LABEL",
  "lblSectorNameDe": "Marktsegment-Name (Deutsch)",
  "lblSectorNameDe_required": "Marktsegment-Name (Deutsch) erforderlich",
  "lblSectorNameEn": "Marktsegment-Name (Englisch)",
  "lblSectorNameEn_required": "Marktsegment-Name (Englisch) erforderlich",
  "lblSectorNameFr": "Marktsegment-Name (Französisch)",
  "lblSectorNameFr_required": "Marktsegment-Name (Französisch) erforderlich",
  "lblSecuritDepositsGuarantees": "[DE] Security Deposits Guarantees",
  "lblSecurityDepositBankAccount": "Bankkonto-Sicherheitsleistung",
  "lblSecurityDepositsGuarantees": "(de) Security Deposits Guarantees",
  "lblSeeBuilding": "Objekt-Überblick anzeigen",
  "lblSelect4Indicators": "Bitte wählen Sie bis zu 4 Kennzahlen",
  "lblSelectAccount": "Eine Kontonummer auswählen",
  "lblSelectACode": "Wählen Sie einen Code",
  "lblSelectActivesLeases": "Laufende Mietverträge",
  "lblSelectAdvancePayAmount": "(de) Overpayments Amount from (€):",
  "lblSelectAllLeases": "Laufende und gekündigte Mietverträge",
  "lblSelectAMapping": "kein Mapping",
  "lblSelectAreaSup": "Mietfläche ab (m²):",
  "lblSelectBaseReport": "Eine Standardberichtsvorlage wählen",
  "lblSelectedParams": "Ausgewählte Suchkriterien",
  "lblSelectedRaport": "Sie müssen einen Bericht auswählen",
  "lblSelectEffectiveNetInitYield": "Netto-Anfangsrendite auf NME ab (%):",
  "lblSelectHeadlineNetInitYield": "Netto-Anfangsrendite auf nachhaltiger NME ab (%):",
  "lblSelectInactivesLeases": "Gekündigte Mietverträge",
  "lblSelectInLitigationLeases": "Rechtsstreit betroffene Mietverträge",
  "lblSelectKML": "Zusätzliche Daten anzeigen",
  "lblSelectLeaseActiveAll": "Laufende und gekündigte Mietverträge mit oder ohne Zahlungsrückstand",
  "lblSelectLeaseActiveWithOutstandingPayments": "Laufende Mietverträge mit Zahlungsrückstand",
  "lblSelectLeaseAllWithOutstandingPayments": "Laufende und gekündigte Mietverträge mit Zahlungsrückstand",
  "lblSelectLeaseDate": "Mietverträge anzeigen",
  "lblSelectLeaseDateBreak": "Alle Mietverträge mit Kündigungstermin",
  "lblSelectLeaseDateEnd": "Nur auslaufende Mietverträge",
  "lblSelectLeaseInactiveWithOutstandingPayments": "Gekündigte Mietverträge mit Zahlungsrückstand",
  "lblSelectLeaseInLitigation": "Rechtsstreit betroffene Mietverträge mit Zahlungsrückstand",
  "lblSelectLeaseWithoutTenantExitDate": "Ohne Mieter-Auszugsdatum",
  "lblSelectLeaseWithTenantExitDate": "Mit Mieter-Auszugsdatum",
  "lblSelectOneYear": "Selected period can't be more then one year",
  "lblSelectPaymentsAmount": "Nur Immobilien mit mehr als (€) gesamten Zahlungsrückstand mindestens wählen",
  "lblSelectPaymentsMonths": "Nur Immobilien mit mehr als (Monaten) Zahlungsrückstandslaufzeit mindestens wählen",
  "lblSelectPortfolio": "Ein Portfolio auswählen",
  "lblSelectPortfolios": "Portfolios wählen",
  "lblSelectRawReportTemplateColumns": "Wählen Sie die Spalten, die im Bericht angezeigt werden sollen.  Die Reihenfolge der Spalten können Sie per Drag-and-drop ändern.",
  "lblSelectRentAmountSup": "Vertragsmiete ab (€):",
  "lblSelectRentVariationRate": "Mietsteigerungsquote ab (%):",
  "lblSelectReport": "Wählen Sie einen Bericht",
  "lblSelectReportTemplateColumns": "Wählen Sie die Spalten, die im Bericht angezeigt werden sollen",
  "lblSelectTwoYear": "(de) Selected period can't be more then two year",
  "lblSelectVacancyAreaSup": "Leerstandsfläche ab (m²):",
  "lblSelectVacancyMonths": "Leerstandslaufzeit ab (Monaten):",
  "lblSelectVacancyRateSup": "Leerstandsquote ab (%):",
  "lblSeller": "Verkäufer",
  "lblSendGED": "Sie werden bald eine E-mail mit EDM Linke erhalten",
  "lblServiceCharge": "NK-VZ (€)",
  "lblShareAcquisitionPrice": "Ausgabepreis (€)",
  "lblShareCapital": "Gezeichnetes Kapital (€)",
  "lblShareholderCurrentAccount": "Kontokorrent (wenn Anteilseigner)",
  "lblShareholderCurrentAccountEquityFinancing": "Anleger-Kontokorrent (€)",
  "lblShareholderLoanAccount": "Gesellschafterdarlehen des Anteilseigners / Anteilsinhabers",
  "lblShareholderLoansContributed": "Gesellschafterdarlehen (€)",
  "lblSharePremium": "Kapitalrücklage (€)",
  "lblSharePremiumDistributed": "Ausschüttung aus Kapitalrücklage (€)",
  "lblShareWithOtherUsers": "Vorlage mit anderen Benutzern teilen",
  "lblShortTermLease": "Befristeter Mietvertrag",
  "lblShortTermLeaseEuro": "Befristeter Mietvertrag (€)",
  "lblShowFilters": "Filter anzeigen",
  "lblShowGraph": "Kopfzeile einblenden",
  "lblShowList": "Objektliste anzeigen",
  "lblShowMap": "Kopfzeile einblenden",
  "lblShowParams": "Einstellungen einblenden",
  "lblSigningDate": "Datum Eigentumsübertragung",
  "lblSimplifiedPAndL": "Ertrags- und Aufwandsrechnung",
  "lblSocialAccountsType": "Sozial",
  "lblSourceCode": "Source Code",
  "lblSourceLabel": "Source Label",
  "lblSqm": "m²",
  "lblStartDate": "Vom",
  "lblStartDateEmpty": "Leeres Startdatum, {0} abgelehnte Zeile.",
  "lblStatus": "Status",
  "lblStatutoryInterests": "Gesetzliche Zinsen (€)",
  "lblStopSendingBetween": "an",
  "lblStopSendingEnd": "inklusive",
  "lblStopSendingStart": "Senden Sie nicht mehr die",
  "lblStorageDistribution": "Logistikimmobilie",
  "lblStudentHousingIdFKML": "Seit 2004 neu gebauten Studentenwohnungen (Île-de-France)",
  "lblSubsidiaryOfGroup": "Tochtergesellschaft",
  "lblSubsidiaryOfGroup_required": "Tochtergesellschaft erforderlich",
  "lblSunday": "Sonntag",
  "lblSupThreeMonth": "> 3 Monaten",
  "lblTax": "Steuer",
  "lblTaxeType": "(de) Display of Amounts",
  "lblTaxeTypeHT": "(de) Before tax",
  "lblTaxeTypeTTC": "(de) Tax Included",
  "lblTEMPage": "Neuvermietungen, Verlängerungen und Kündigungen",
  "lblTemplateName": "Bitte nehmen Sie einen Namen für die Berichtsvorlage ein",
  "lblTemplateNameAlreadyExists": "Der Vorlagenname ist schon vergeben",
  "lblTemplateNameRequired": "Bitte nehmen Sie einen Namen ein",
  "lblTemporaryOccupancyAuthorisation": "Genehmigung für vorübergehende Belegung",
  "lblTemporaryOccupancyAuthorisationEuro": "Genehmigung für vorübergehende Belegung (€)",
  "lblTempPwd": "Temporäres Codiert",
  "lblTempPwdExpired": "Temporäres Codiert abgelaufen",
  "lblTempPwdRecorded": "(de) Password recorded",
  "lblTempPwdRequired": "Bitte geben Sie das temporäre Codiert an",
  "lblTempPwdWrongFormat": "Falsches temporäres codiert format",
  "lblTEMRawReport": "Neuvermietungen, Verlängerungen und Kündigungen - unverarbeitet",
  "lblTEMReport": "Neuvermietungen, Verlängerungen und Kündigungen",
  "lblTenancyAtWillLease": "Unbefristeter Mietvertrag",
  "lblTenancyAtWillLeaseEuro": "Unbefristeter Mietvertrag (€)",
  "lblTenancyMonitoring": "Neuvermietungen, Verlängerungen und Kündigungen",
  "lblTenancyMonitoringEnd": "Gekündigte Mietverträge",
  "lblTenancyMonitoringEndRaw": "Gekündigte Mietverträge - unverarbeitet",
  "lblTenancyMonitoringNotice": "Mietvertragskündigungen",
  "lblTenancyMonitoringNoticeRaw": "Mietvertragskündigungen - unverarbeitet",
  "lblTenancyMonitoringReletting": "Neuvermietungen",
  "lblTenancyMonitoringRelettingRaw": "Neuvermietungen - unverarbeitet",
  "lblTenancyMonitoringRenewal": "Mietvertragsverlängerungen",
  "lblTenancyMonitoringRenewalRaw": "Mietvertragsverlängerungen - unverarbeitet",
  "lblTenancySchedule": "Mieterliste",
  "lblTenancyScheduleAlerts": "Mieterliste-Alarme",
  "lblTenant": "Mieter-Name",
  "lblTenantConcentration": "Mieterkonzentration (5 grösste Vertragsmieten)",
  "lblTenantConentrationRentArea": "Mieterkonzentration (5 grösste vermietete Flächen)",
  "lblTenantExitAnnualRentAmount": "Vertragsmiete der Mietverträge mit Mieter-Auszugsdatum",
  "lblTenantExitCount": "Anzahl der der Mietverträge mit Mieter-Auszugsdatum",
  "lblTenantExitRentArea": "Vermietete Fläche der Mietverträge mit Mieter-Auszugsdatum",
  "lblTenantIndustryConcentration": "Mieterbranchen-Konzentration (5 grösste Vertragsmieten)",
  "lblTenantIndustryConecentrationRentArea": "Mieterbranchen-Konzentration (5 grösste vermietete Flächen)",
  "lblTenantTurnover": "Neuvermietungs- und Kundigungsquoten",
  "lblTerminatedLeasesLegend": "* Gekündigte Mietverträge",
  "lblTerminationDate": "Datum Fondsauflösung oder Verkauf",
  "lblTETPage": "Neuvermietungs- und Kundigungsquoten",
  "lblTETRawReport": "Neuvermietungs- und Kundigungsquoten - unverarbeitet",
  "lblTETReport": "Neuvermietungs- und Kundigungsquoten",
  "lblThemeBadElement": "Das Thema {2} ist nicht relevant für das Element {1 {0} abgelehnte Zeile.",
  "lblThemeBadLevel": "Das Thema {1} ist auf der Ebene {1 {0} abgelehnte Zeile.",
  "lblThemeNotExist": "Das Thema {1} existiert nicht, {0} abgelehnte Zeile.",
  "lblThursday": "Donnerstag",
  "lblTotalArea": "Gesamte vermietbare Fläche (m²)",
  "lblTotalArea_WOU": "Gesamte vermietbare Fläche",
  "lblTotalCashDistributed": "Gesamt Ausschüttung (€)",
  "lblTotalDataReloading": "Die Daten vom Immobilien-Verwalter {0} werden heute Nacht um {1} neu laden.",
  "lblTotalEquityContributed": "Gesamt Eigenkapital (€)",
  "lblTotalEquityFinancing": "Gesamt Finanzierung (€)",
  "lblTotalGrossDebt": "Gesamt Fremdkapital (€)",
  "lblTotalInverstorDistributions": "Gesamt Anleger erhaltene Auschüttungen (€)",
  "lblTotalInvestmentPrice": "Ausgabepreis und Gesellschafterdarlehen (€)",
  "lblTotalInvestmentValue": "Marktwert Eigenkapital (€)",
  "lblTotalInvestorDistributions": "Gesamt Anleger erhaltene Auschüttungen (€)",
  "lblTotalRentAreaBreakByGeo": "Aufteilung der Gesamtfläche nach Standort",
  "lblTotalRentAreaBreakByPropType": "Aufteilung der Gesamtfläche nach Objekt-Nutzungsart",
  "lblTotalRentAreaBreakByTenant": "Aufteilung der Gesamtfläche nach Mieter",
  "lblTotalRentAreaBreakByTenantIndustry": "Aufteilung der vermieteten Fläche nach Mieter",
  "lblTotalReturn": "Gesamtrendite",
  "lblTotalReturnEquityFairValue": "Gesamtrendite auf Basis des Eigenkapitals",
  "lblTotalReturnNAVBridge": "Aufteilung der Gesamtrendite auf Basis des Nettoinventarwerts",
  "lblTotals": "Gesamt",
  "lblTransactionsHistory": "Überblick der Transaktionsvolumen",
  "lblTranscoding_type_data": "Daten zu erstellen",
  "lblTranscodingType": "Code der Liste",
  "lblTRHArbRawReport": "Überblick der Verkäufe von Immobilien - unverarbeitet",
  "lblTRHArbReport": "Überblick der Verkäufe von Immobilien",
  "lblTRHPage": "Überblick der Transaktionsvolumen",
  "lblTRHPurchRawReport": "Überblick der Investitionsvolumen - unverarbeitet",
  "lblTRHPurchReport": "Überblick der Investitionsvolumen",
  "lblTrue": "Richtig",
  "lblTSBuildingRawReport": "Mieterliste nach Objekt - unverarbeitet",
  "lblTSBuildingReport": "Mieterliste nach Objekt",
  "lblTSLeaseRawReport": "Mieterliste nach Mietvertrag - unverarbeitet",
  "lblTSLeaseReport": "Mieterliste nach Mietvertrag",
  "lblTSMixedRawReport": "Mieterliste Gemischt - unverarbeitet",
  "lblTSMixedReport": "Mieterliste Gemischt",
  "lblTSPage": "Mieterliste",
  "lblTSUnitDetailedRawReport": "Mieterliste Detailliert nach Mieteinheit - unverarbeitet",
  "lblTSUnitDetailedReport": "Mieterliste Detailliert nach Mieteinheit",
  "lblTSUnitRawReport": "Mieterliste nach Mieteinheit - unverarbeitet",
  "lblTSUnitReport": "Mieterliste nach Mieteinheit",
  "lblTSUnitTypeRawReport": "Mieterliste nach Mieteinheit-Nutzungsart - unverarbeitet",
  "lblTSUnitTypeReport": "Mieterliste nach Mieteinheit-Nutzungsart",
  "lblTuesday": "Dienstag",
  "lblTypeInterface": "(de) Type interface",
  "lblUnderlyingVehicles": "Beteiligung(en)",
  "lblUnderRented": "Vertragsmiete liegt unter der Marktmiete",
  "lblUnit": "Einheit",
  "lblUnitNatures": "Mieteinheit-Nutzungsarten",
  "lblUnitNumber": "Anzahl von Mieteinheiten",
  "lblUnitTypes": "Mieteinheit-Nutzungsart",
  "lblUnlawfulOccupancy": "Gesetzwidrige Belegung",
  "lblUnlawfulOccupancyEuro": "Gesetzwidrige Belegung (€)",
  "lblUnrealCapitalGainLossDirectInvestProp": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Objekten (€)",
  "lblUnrealCapitalGainLossEquitySecur": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Beteiligungen (€)",
  "lblUnrealCapitalGainLossFinancialInstruments": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Finanzinstrumente (€)",
  "lblUnrealizedGainsLossesOnShares": "Unrealisierter Gewinn/Verlust aus gehaltenen Anteilen (€)",
  "lblUnrealizedInvestmentPropertyGainLoss": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Objekten (€)",
  "lblUnrealizedNonPropertyGainLoss": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Beteiligungen (€)",
  "lblUpcomingLeaseEvents": "Nächstmögliche Kündigungstermine",
  "lblUpdateAdditionalField": "Thema aktualisieren",
  "lblUpdateBuilding": "Ein Objekt aktualisieren",
  "lblUpdateBuildingRepository": "Ein neues Objekt im Repositorium aktualisieren",
  "lblUpdatedAt": "Aktualisiert am",
  "lblUpdatedAt2": "Aktualisiert am",
  "lblUpdateErvError": "Fehler beim Aktualisieren der Marktmiete",
  "lblUpdateErvOk": "Die Marktmieten wurden erfolgreich aktualisiert",
  "lblUpdateFund": "Fonds aktualisieren",
  "lblUpdateFundOwnershipError": "Es ist ein Fehler bei der Aktualisierung der Anlegerdaten aufgetreten.",
  "lblUpdateLeaseRepositoryError": "Fehler bei der Aktualisierung von Miertrag-bezogenen daten",
  "lblUpdateNetBookValueError": "Fehler beim Aktualisieren der Netto-Buchwert-bezogenen Daten",
  "lblUpdatePurchase": "Den Erwerb aktualisieren",
  "lblUpdateReportTemplate": "Die Berichtsvorlage aktualisieren",
  "lblUpdateSales": "Verkauf aktualisieren",
  "lblUpdateUser": "Einen Benutzer aktualisieren",
  "lblUpdateValuationError": "Fehler beim Aktualisieren der Bewertung-Daten",
  "lblUpdateValuationOk": "Die Bewertung-Daten wurden erfolgreich aktualisiert",
  "lblUpdateYieldError": "Fehler beim Aktualisieren der Zielrenditen",
  "lblUpdateYieldOk": "Die Zielrenditen wurden erfolgreich aktualisiert",
  "lblUserLocked": "Abgesperrt",
  "lblUserLogin": "Anmeldung",
  "lblUserMail": "Mail",
  "lblUserManagement": "Benutzer erstellen",
  "lblUserName": "Benutzer-Name",
  "lblUserNameRequired": "Benutzer-Name erforderlich",
  "lblUserRejected": "Die Anfrage zur Erstellung eines Benutzers wurde abgebrochen. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es noch mal. Falls das Problem weiterhin besteht nehmen sie bitte Kontakt mit dem Systemadministrator.",
  "lblUSLPage": "Benutzer erstellen",
  "lblVACAnaRawReport": "Vermietungsstand - unverarbeitet",
  "lblVACAnaReport": "Vermietungsstand",
  "lblVacancy": "Leerstandsanalyse",
  "lblVacancyAnalysis": "Vermietungsstand",
  "lblVacancyArea": "Leerstandsfläche",
  "lblVacancyBreakByPeriod": "Leerstandsaufteilung nach Laufzeit",
  "lblVacancyBreakdownByPeriod": "Leerstandsaufteilung nach Laufzeit",
  "lblVacancyCost": "Leerstandsmiete",
  "lblVacancyEvolution": "Vierteljährliche Entwicklung der Vermietung",
  "lblVacancyEvolutionOneYear": "Leerstandsentwicklung",
  "lblVacancyFinancial": "€",
  "lblVacancyNature": "Leerstandsaufteilung nach Art",
  "lblVacancyOccupEvolution": "Leerstandsaufteilung nach Leerstandsgrund",
  "lblVacancyPhysicalPercent": "%",
  "lblVacancyPhysicalSqm": "m²",
  "lblVacancyRate": "Physische Leerstandsquote",
  "lblVacancyTypes": "Leerstandsart",
  "lblVacancyUnit": "Anzahl der leerstehenden Mieteinheiten",
  "lblVacantArea": "Leerstandsfläche (m²)",
  "lblVacantArea_WOU": "Leerstandsfläche",
  "lblVacantArea2M": "Leerstandsfläche <= 2 Monaten (m²)",
  "lblVacantArea4M": "Leerstandsfläche <= 4 Monaten (m²)",
  "lblVacantAreaSup4M": "Leerstandsfläche > 4 Monaten (m²)",
  "lblVacantTenantCode": "--LEERSTEHEND--",
  "lblVacantUnits": "Leerstehende Mieteinheiten",
  "lblVacantUnitSingular": "Mieteinheit leerstehend",
  "lblVacantUnitsLegend": "* Leerstehende oder inaktive Mieteinheiten",
  "lblVacantUnitsPlural": "Mieteinheiten leerstehend",
  "lblVacArea2-4M": "Leerstandsfläche 2-4 Monaten (m²)",
  "lblVacArea2M": "Leerstandsfläche < 2 Monaten (m²)",
  "lblVacArea4M": "Leerstandsfläche > 4 Monaten (m²)",
  "lblVACNatRawReport": "Leerstandsaufteilung nach Art - unverarbeitet",
  "lblVACNatReport": "Leerstandsaufteilung nach Art",
  "lblVACPage": "Leerstandsanalyse",
  "lblVACPhyRawReport": "Monatliche Entwicklung des Leerstands - unverarbeitet",
  "lblVACPhyReport": "Monatliche Entwicklung des Leerstands",
  "lblValuation": "Bewertung-Daten erstellen",
  "lblValuationDashboard": "Fonds-Leistung",
  "lblValuationImportsList": "Importprotokoll",
  "lblValue": "Netto-Verkehrswert (€)",
  "lblValueDate": "Wertstellung",
  "lblValuePurchase": "Gesamter Anschaffungswert (€)",
  "lblVAUPage": "Leerstehende Mieteinheiten",
  "lblVAURawReport": "Leerstehende Mieteinheiten - unverarbeitet",
  "lblVAUReport": "Leerstehende Mieteinheiten",
  "lblVehicleNetAssetValue": "Fonds-Nettoinventarwert (100%)",
  "lblVehicleTerm": "Laufzeit (in Jahren)",
  "lblVehiculeFormationDate": "Datum der Auflegung",
  "lblWALB": "WALB",
  "lblWALBAndWALT": "WALB / WALT",
  "lblWALBInYears": "WALB (in Jahren)",
  "lblWALT": "WALT",
  "lblWALTInYears": "WALT (in Jahren)",
  "lblWednesday": "Mittwoch",
  "lblWelcomeTitle": "Willkommen zu Alhambra",
  "lblWorksVacantArea": "Nach Sanierung leerstehende Leerstandsfläche (m²)",
  "lblWorksVacantAreaRate": "% des gesamten Leerstands",
  "lblWrongCurrentPassword": "Das eingegebene Passwort ist nicht korrekt",
  "lblXLinesInserted": "{0} Linien wurden erfolgreich eingefügt.",
  "lblXLinesRejected": "{0} Linien wurden abgelehnt.",
  "lblXLinesUpdated": "(de) {0} lines have been updated.",
  "lblXValuesInserted": "{0} Werte wurden erfolgreich eingefügt.",
  "lblXValuesRejected": "{0} Werte wurden abgelehnt.",
  "lblYearPeriod1": "Jahr(e) (Vergleichsjahr 1)",
  "lblYearPeriod2": "Jahr(e) (Vergleichsjahr 2)",
  "lblYearPeriod3": "Jahr(e) (Vergleichsjahr 3)",
  "lblYearToDateInvoicingRunningTotal": "(de) Year-to-Date Invoicing Running Total",
  "lblYes": "Ja",
  "lblYield": "Rendite erstellen",
  "lblYieldImportsList": "Importprotokoll",
  "lblYieldMonitoring": "Rendite-Analyse",
  "lblYIMPage": "Rendite-Analyse",
  "lblYIMRawReport": "Rendite-Analyse - unverarbeitet",
  "lblYIMReport": "Rendite-Analyse",
  "lblyyyyMMdd": "JJJJMMTT",
  "lblyyyyMMdd_hhmmss": "JJJJMMTT_SSMMZZ",
  "lblZACIdFKML": "ZAC-Bezirke",
  "lbNewAccountStream": "Neuer Buchhaltungsdatenstrom",
  "lbNewAdditionalField": "Neues Thema",
  "lbnumeric": "(de) numeric",
  "lbOccupiedAreaBreakByTenant": "Aufteilung der vermieteten Fläche nach Mieter",
  "lbOutstandingAmountEvolution": "Entwicklung der bezahlten Beträge",
  "lbOutstandingBalanceEvolution": "Entwicklung der Zahlungsbilanzen",
  "lbPage": "Seite",
  "lbPositional": "Positionell",
  "lbPurchaseConfirmCode": "Bitte die Immobilie-Nummer bestätigen",
  "lbReportTemplateDuplicated": "(de) Votre modéle de rapport [...] a bien été dupliquée en [....]",
  "lbSearchCriteria": "Suchkriterien",
  "lbSeparatorDecimal": "Dezimaltrennzeichen",
  "lbSpace": "(de) Space",
  "lbTotalDistribution": "Gesamtausschüttung",
  "lbTtResetCriteria": "Suchkriterien zurücksetzen",
  "lbTtUpdateCriteria": "Suchkriterien aktualisieren",
  "LEASE": "Mietvertrag",
  "LEASE BREAK DATE 1": "(de) Lease Break Date 1",
  "LEASE BREAK DATE 2": "(de) Lease Break Date 2",
  "LEASE BREAK DATE 3": "(de) Lease Break Date 3",
  "LEASE BREAK PERIOD 1": "Kündigungsfrist 1 (Monate)",
  "LEASE BREAK PERIOD 2": "Kündigungsfrist 2 (Monate)",
  "LEASE BREAK PERIOD 3": "Kündigungsfrist 3 (Monate)",
  "LEASE BREAKDOWN BY NATURE": "Aufteilung der Mietverträgen nach Nutzungsart",
  "LEASE CODE": "Mietvertrag-Nummer",
  "LEASE COMMENTS": "Kommentare",
  "LEASE COMMITMENT TERM": "(de) Lease Commitment Term",
  "LEASE DURATION DD/MM/YYYY": "Mietvertragslaufzeit (J-M-T)",
  "LEASE END DATE": "Mietende",
  "LEASE ENTERPRISE SCHEDULE": "Nächstmögliche Kündigungstermine zum",
  "LEASE FLOORS": "Geschoss-Nummer(n)",
  "LEASE MONITORING": "Mietsteigerung und Mietanpassungspotenzial",
  "LEASE MONITORING SHEET": "Überwachung der Mietverträge",
  "LEASE NATURE LABEL": "Mietvertragsart",
  "LEASE PAYMENT MODE LABEL": "Payment mode",
  "LEASE RENEWAL REPORT": "Mietvertragsverlängerungen",
  "LEASE RENEWAL REPORT RAW": "Mietvertragsverlängerungen zum",
  "LEASE SIGNED DATE": "(de) Lease Signed Date",
  "LEASE START DATE": "Mietbeginn",
  "LEASE SUMMARY": "Mietvertragsüberblick",
  "LEASE TENANCY SCHEDULE": "Mieterliste nach Mietvertrag zum",
  "LEASE TENANCY SCHEDULE SHEET": "Mieterliste nach Mietvertrag",
  "LEASE TERM": "Mietvertragsdauer (J-M-T)",
  "LEASE TERMINATION NOTICE REPORT": "Mietvertragskündigungen",
  "LEASE TERMINATION NOTICE REPORT RAW": "Mietvertragskündigungen zum",
  "LEASE TERMINATION REPORT": "Gekündigte Mietverträge",
  "LEASE TERMINATION REPORT RAW": "Gekündigte Mietverträge zum",
  "LEASE TOTAL": "Gesamt Mietvertrag",
  "LEASE TURNOVER RATE": "Umschlagsquote (Mietverträge)",
  "LEASE TYPE LABEL": "Mietvertragstyp",
  "LEASE UNITS": "Mieteinheit-Nummer(n)",
  "LEASE_BREAK_DATE_1": "(de) Lease Break Date 1",
  "LEASE_BREAK_DATE_2": "(de) Lease Break Date 2",
  "LEASE_BREAK_DATE_3": "(de) Lease Break Date 3",
  "LEASE_BREAK_PERIOD_1": "Kündigungsfrist 1 (Monate)",
  "LEASE_BREAK_PERIOD_2": "Kündigungsfrist 2 (Monate)",
  "LEASE_BREAK_PERIOD_3": "Kündigungsfrist 3 (Monate)",
  "LEASE_CODE": "Mietvertrag-Nummer",
  "LEASE_COMMENTS": "Kommentare",
  "LEASE_COMMITMENT_TERM": "(de) Lease Commitment Term",
  "LEASE_DURATION": "Mietvertragslaufzeit (J-M-T)",
  "LEASE_END_DATE": "Mietende",
  "LEASE_FLOORS": "Geschoss-Nummer(n)",
  "LEASE_NATURE_CODE": "(de) Lease Nature Code",
  "LEASE_NATURE_LABEL": "Mietvertragsart",
  "LEASE_PAYMENT_MODE_LABEL": "Zahlungsart",
  "LEASE_SIGNED_DATE": "(de) Lease Signed Date",
  "LEASE_START_DATE": "Mietbeginn",
  "LEASE_TURNOVER_RATE": "Umschlagsquote (Mietverträge)",
  "LEASE_TYPE_CODE": "(de) Lease Type Code",
  "LEASE_TYPE_LABEL": "Mietvertragstyp",
  "LEASE_UNITS": "Mieteinheit-Nummer(n)",
  "LEASES NUMBER": "Anzahl von Mietverträgen",
  "LEASES PERCENT": "% der gesamten Anzahl von Mietverträgen",
  "LEASES_ACTIVE": "Laufende Mietverträge",
  "LEASES_COUNT": "Anzahl von Mietverträgen",
  "LEASES_INACTIVE": "Inaktiven Mietverträge",
  "LEASES_LITIGATION": "Rechtsstreit betroffene Mietverträge",
  "LEASES_NUMBER": "Gesamtanzahl von Mietverträgen",
  "LEASES_PERCENT": "% der gesamten Anzahl von Mietverträgen",
  "LEGAL PERSON": "Rechtsperson",
  "LEGAL RESERVES": "Gesetzliche Rücklagen (€)",
  "LEGAL VEHICLE STRUCTURE": "Rechtsform",
  "LEGAL_PERSON": "Rechtsperson",
  "LEGAL_RESERVES": "Gesetzliche Rücklagen (€)",
  "LEGAL_VEHICLE_STRUCTURE": "Rechtsform",
  "LEVEL": "Level",
  "LEVEL1 BUDGET SECTION": "Budgetlinie",
  "LEVEL1 BUDGET SECTION BRUT": "(de) Budget Level 1",
  "LEVEL1 BUDGET SECTION CODE": "Budgetlinie-Nummer",
  "LEVEL1_BUDGET_SECTION": "Budgetlinie",
  "LEVEL1_BUDGET_SECTION_CODE": "Budgetlinie-Nummer",
  "LEVEL2 BUDGET SECTION": "(de) Budget Level 2",
  "LEVEL2 BUDGET SECTION 1": "Haushaltszeichen Stufe 2",
  "LEVEL2 BUDGET SECTION CODE": "Budget-Kategorie",
  "LEVEL2 BUDGET SECTION CODE 1": "Haushaltscode Ebene 2",
  "LEVEL2_BUDGET_SECTION": "(de) Budget Level 2",
  "LEVEL2_BUDGET_SECTION_1": "Haushaltszeichen Stufe 2",
  "LEVEL2_BUDGET_SECTION_CODE": "Budget-Kategorie-Nummer",
  "LEVEL2_BUDGET_SECTION_CODE_1": "Haushaltscode Ebene 2",
  "LEVEL3 BUDGET SECTION": "(de) Budget Level 3",
  "LEVEL3 BUDGET SECTION 1": "Haushaltszeichen Stufe 3",
  "LEVEL3 BUDGET SECTION CODE": "(de) Budget Level 3 Code",
  "LEVEL3_BUDGET_SECTION": "(de) Budget Level 3",
  "LEVEL3_BUDGET_SECTION_1": "Haushaltszeichen Stufe 3",
  "LEVEL3_BUDGET_SECTION_CODE": "(de) Budget Level 3 Code",
  "LEVEL4 BUDGET SECTION": "(de) Budget Level 4",
  "LEVEL4 BUDGET SECTION CODE": "(de) Budget Level 4 Code",
  "LEVEL4_BUDGET_SECTION": "(de) Budget Level 4",
  "LEVEL4_BUDGET_SECTION_CODE": "(de) Budget Level 4 Code",
  "liAbe": "(de) Bank Movements",
  "liAllBuildings": "-- Alle Eigentume --",
  "Line2H3Title1": "Objekt-Überblick",
  "Line3H3Title1": "Vermietungsstand",
  "Line3H3Title2": "Aufteilung der vermieteten Fläche nach Nutzungsart",
  "Line4H3Title2": "Bewirtschaftungskosten",
  "Line5H3Title1": "Kennzahlen",
  "Line5H3Title2": "Netto-Anfangsrenditen (NAR)",
  "Line5H3Title3": "Mietanpassungspotenzial",
  "Line7H3Title1": "Entwicklung der Mieteinnahmen",
  "Line7H3Title2": "Entwicklung der Bewirtschaftungskosten",
  "Line8H3Title1": "Nächstmögliche Kündigungstermine",
  "Line8H3Title2": "Überwachung von Sanierung und Instandhaltung",
  "liNeutralizedBuildings": "Neutralisierte Objekte",
  "linkChangePwd": "Passwort ändern",
  "linkForgottenPwd": "Passwort vergessen ?",
  "liNotNeutralizedBuildings": "Nicht neutralisierte Objekte",
  "LITIGATION AMOUNT": "Rechtsstreitsbetrag (€)",
  "LITIGATION INFORMATION": "Anmerkungen zum Rechtsstreit",
  "LITIGATION SITUATION LABEL": "(de) Qualité individu",
  "LITIGATION STATUS LABEL": "Status der Rechtsstreitigkeit",
  "LITIGATION TYPE LABEL": "Art der Rechtsstreitigkeit",
  "LITIGATION_AMOUNT": "Rechtsstreitsbetrag (€)",
  "LITIGATION_INFORMATION": "Anmerkungen zum Rechtsstreit",
  "LITIGATION_SITUATION_LABEL": "(de) Qualité individu",
  "LITIGATION_STATUS_LABEL": "Status der Rechtsstreitigkeit",
  "LITIGATION_TYPE_LABEL": "Art der Rechtsstreitigkeit",
  "LIVING AREA": "Nettowohnfläche (m²)",
  "LIVING AREA WOU": "Nettowohnfläche",
  "LIVING_AREA_SQM": "Nettowohnfläche (m²)",
  "lkb1Year": "1 Jahr",
  "lkb3Year": "3 Jahre",
  "lkb6Year": "6 Jahre",
  "lkbDeSelectAll": "Alles abwählen",
  "lkbEditPortfolio": "Portfolios wählen",
  "lkbModAlerts": "Alarme bearbeiten",
  "lkbSelectAll": "Alles auswählen",
  "lnkClear": "Filter löschen",
  "lnLoginNameFormatString": "{0}",
  "LOAN TO VALUE": "Loan-To-Value (LTV)",
  "locAlertBuildingValueVariation": "wesentliche zwischen dem letzten und vorletzten Verkehrswertgutachten",
  "locAlertLeaseBreak": "mit einem demnächsten Kündigungstermin",
  "locAlertLeaseExpiring": "bald auslaufend",
  "locAlertRentVariationInitialRent": "im Vergleich mit dem Anfangsmiete",
  "locAlertRentVariationRentAdjustment": "wesentliche zwischen der letzten und vorletzten Indexanpassung",
  "locAlerts": "Alarme",
  "locAlertVacancy": "mit einer wesentliche leerstandsquote",
  "locAlertVacantUnit": "seit mehrere Monate",
  "locEvolution": "Entwicklung im Laufe der letzten",
  "locKeyValues": "Portfolio-Überblick zum",
  "locMyAssets": "Mein Portfolio",
  "locNews": "Nachrichtungen",
  "locSeeMore": "Mehr sehen",
  "LONGITUDE": "Längengrad",
  "LOSS AFTER PDDI": "Unrealisierte Gewinne und Verluste nach weiterer Abschreibung (€)",
  "LOSS AFTER RESERVE": "Unrealisierte Gewinne und Verluste nach Abschreibung (€)",
  "LOSS BEFORE RESERVE": "Unrealisierte Gewinne und Verluste vor Abschreibung (€)",
  "LOSS_AFTER_PDDI": "Unrealisierte Gewinne und Verluste nach weiterer Abschreibung (€)",
  "LOSS_AFTER_RESERVE": "Unrealisierte Gewinne und Verluste nach Abschreibung (€)",
  "LOSS_BEFORE_RESERVE": "Unrealisierte Gewinne und Verluste vor Abschreibung (€)",
  "lsLoginStatusLogoutText": "Abmelden",
  "MAIN DEPOSIT VALUE": "Sicherheitsleistung (€)",
  "MAIN MANAGER NAME": "Immobilienverwalter-Name",
  "MAIN UNIT CODE": "Hauptmieteinheit-Nummer",
  "MAIN UNIT TOTAL NUMBER": "Anzahl von Mieteinheiten",
  "MAIN UNIT TYPE LABEL": "Hauptmieteinheit-Nutzungsart",
  "MAIN_DEPOSIT_VALUE": "Sicherheitsleistung (€)",
  "MAIN_MANAGER_NAME": "Immobilienverwalter-Name",
  "MAIN_UNIT": "Hauptmieteinheit-Nummer",
  "MAIN_UNIT_BUILDING": "Gebäude-Nummer",
  "MAIN_UNIT_FLOOR": "Geschoss-Nummer",
  "MAIN_UNIT_NATURE_CODE": "Hauptmieteinheit-Nutzungsart-Nummer",
  "MAIN_UNIT_NATURE_LABEL": "Hauptmieteinheit-Nutzungsart-Bezeichnung",
  "MAIN_UNIT_STAIRCASE": "Treppenhaus-Nummer",
  "MAIN_UNIT_TOTAL_NUMBER": "Anzahl von Mieteinheiten",
  "MAIN_UNIT_TOTAL_NUMBER_mismatch": "Anzahl von Mieteinheiten ungültig",
  "MAIN_UNIT_TYPE_CODE": "Hauptmieteinheit-Nutzungsart-Nummer",
  "MAIN_UNIT_TYPE_LABEL": "Hauptmieteinheit-Nutzungsart-Bezeichnung",
  "MAJOR REFURBISHMENT AMOUNT": "(de) Major refurbishment amount",
  "MAJOR_REFURBISHMENT_AMOUNT": "(de) Major refurbishment amount (€)",
  "MANAGER NAME": "(de) Manager name",
  "MANAGER_NAME": "(de) Manager name",
  "MANAGING DIRECTOR": "Geschäftsleiter",
  "MANAGING_DIRECTOR": "Geschäftsleiter",
  "MARCH_AMOUNT": "März (€)",
  "MARK_VALUE_EXCL_DUTIES_VAT_SQM": "Netto-Verkehrswert pro m² (€/m²)",
  "MARKET VALUE": "Verkehrswert",
  "MARKET VALUE EXCL DUTIES AND FEES": "Netto-Verkehrswert",
  "MARKET VALUE EXCL DUTIES VAT": "Netto-Marktwert (€)",
  "MARKET VALUE INCL DUTIES AND FEES": "Brutto-Verkehrswert",
  "MARKET_VALUE_EXCL_DUTIES_VAT": "Netto-Verkehrswert (€)",
  "MAX_NUMBER_OF_LEVELS": "Anzahl von Geschossen",
  "MAX_NUMBER_OF_LEVELS_mismatch": "Anzahl von Geschossen ungültig",
  "MAXIMUM NUMBER OF FLOORS": "Anzahl von Geschossen",
  "MAXIMUM NUMBER OF LEVELS": "Anzahl von Geschossen",
  "MAY_AMOUNT": "Mai (€)",
  "menuAbe": "(de) Bank Movements",
  "menuAuditTrail": "Prüfprotokoll",
  "menuBudgetMonitoring": "Soll-Ist-Vergleich Bilanz",
  "menuCollectionMonitoring": "Überwachung der Ist-Mieteinnahmen",
  "menuDeka": "(de) Deka reporting",
  "menuDirectOutstandingPayments": "Offene Forderungen",
  "menuDtz": "Vermietungssituation",
  "menuGeneralExpensesReportAccounted": "Allgemeine Ausgabenaufstellung - Gebuchte Rechnungen",
  "menuGeneralExpensesReportPaid": "Allgemeine Ausgabenaufstellung - Bezahlte Rechnungen",
  "menuGER": "Allgemeine Ausgabenaufstellung",
  "menuHoldoverTenancy": "Liste der nicht ausgezogenen Mietern",
  "menuLasalleInterface": "(de) LASALLE Interface",
  "menuLeaseEntrepriseSchedule": "Nächstmögliche Kündigungstermine",
  "menuLeaseMonitoring": "Mietsteigerung und Mietanpassungspotenzial",
  "menuMyAccount": "Mein Konto",
  "menuMyAssets": "Mein Portfolio",
  "menuMyReports": "Meine Berichte",
  "menuOutstandingPaymentProvider": "Aggregierte Bilanz der Lieferanten",
  "menuOutstandingPayments": "Fälligkeitsliste",
  "menuOutstandPayBalances": "Entwicklung der Zahlungsbilanzen und der bezahlten Beträge",
  "menuOutstandPayMonitoring": "Stichtag offene Zahlungen",
  "MenuParam": "Einstellungen",
  "menuPendingArbitrageTransactions": "Laufende Verkäufe",
  "menuPendingPurchaseTransactions": "Laufende Erwerbe",
  "menuPendingTransactions": "Laufende Transaktionen",
  "menuPMR": "(de) Tableau de bord ADB",
  "menuPortfolioBreakdown": "Aufteilung des Immobilienportfolios",
  "menuPortfolioEvolution": "Entwicklung des Immobilienportfolios",
  "menuPropertyBalance": "Objekt-Ebene Saldenbilanz",
  "menuPropertyValuation": "Wertberichtigung der Objekte",
  "menuProviderInvoices": "Lieferantenrechnungen",
  "menuProviderInvoicesAccounted": "Gebuchte Lieferantenrechnungen",
  "menuProviderInvoicesPaid": "Bezahlte Lieferantenrechnungen",
  "menuReload": "Daten neu laden",
  "menuRentalIncomeMonitoring": "Überwachung der Soll-Mieteinnahmen",
  "menuRentalSituation": "Detaillierte Vermietungssituation",
  "menuSecuritDepositsGuarantees": "(de) Security Deposits Guarantees",
  "menuSecurityDepositsGuarantees": "(de) Security Deposits Guarantees",
  "menuTenancyMonitoring": "Neuvermietungen, Verlängerungen und Kündigungen",
  "menuTenancySchedule": "Mieterliste",
  "menuTenantTurnover": "Neuvermietungs- und Kundigungsquoten",
  "menuTransactionsHistory": "Überblick der Transaktionsvolumen",
  "menuUsersList": "Benutzer erstellen",
  "menuVacancyAnalysis": "Leerstandsanalyse",
  "menuVacantUnits": "Leerstehende Mieteinheiten",
  "menuYieldMonitoring": "Rendite-Analyse",
  "MGMT FEES OF GARB COLLECT TAX": "(de) Garbage Collection Tax Management Fees (€)",
  "MGMT_FEES_OF_GARB_COLLECT_TAX": "(de) Garbage Collection Tax Management Fees (€)",
  "MinDateHistory": "Keine Schrift vor dem",
  "MISCELLANEOUS AREA": "Sonstige Flächen (m²)",
  "MISCELLANEOUS RESERVE": "Sonstige Rücklagen (€)",
  "MISCELLANEOUS_AREA": "Sonstige Flächen (m²)",
  "MISCELLANEOUS_RESERVE": "Sonstige Rücklagen (€)",
  "Modification": "Aktualisierung",
  "MONTANT_DEJA_ENGAGE": "Montant déjà engagé (€)",
  "MONTANT_DEJA_FACTURE_REALISE": "Montant déjà facturé / réalisé",
  "MONTANT_PREVISIONNEL_DES_ENGAGEMENTS_EMIS_PAR_ANNEE": "Montant engagé",
  "MONTANT_PREVISIONNEL_DES_FACTURES_A_RECEVOIR_PAR_ANNEE": "Montant facturé",
  "MONTANT_TOTAL_PREVISIONNEL_CHANTIER": "Montant total prévisionnel chantier (€)",
  "MONTH ON MONTH SALES GROWTH": "Umsatzentwicklung",
  "MONTH ROLLING SALES VOLUME": "Rollierender Umsatz (€)",
  "MONTH_01_AMOUNT": "1ste Spalte Betrag (€)",
  "MONTH_01_YYYY": "Januar YYYY (€)",
  "MONTH_02_AMOUNT": "2te Spalte Betrag (€)",
  "MONTH_02_YYYY": "Februar YYYY (€)",
  "MONTH_03_AMOUNT": "3te Spalte Betrag (€)",
  "MONTH_03_YYYY": "März YYYY (€)",
  "MONTH_04_AMOUNT": "4te Spalte Betrag (€)",
  "MONTH_04_YYYY": "April YYYY (€)",
  "MONTH_05_AMOUNT": "5te Spalte Betrag (€)",
  "MONTH_05_YYYY": "Mai YYYY (€)",
  "MONTH_06_AMOUNT": "6te Spalte Betrag (€)",
  "MONTH_06_YYYY": "Juni YYYY (€)",
  "MONTH_07_AMOUNT": "7te Spalte Betrag (€)",
  "MONTH_07_YYYY": "Juli YYYY (€)",
  "MONTH_08_AMOUNT": "8te Spalte Betrag (€)",
  "MONTH_08_YYYY": "August YYYY (€)",
  "MONTH_09_AMOUNT": "9te Spalte Betrag (€)",
  "MONTH_09_YYYY": "September YYYY (€)",
  "MONTH_10_AMOUNT": "10te Spalte Betrag (€)",
  "MONTH_10_YYYY": "Oktober YYYY (€)",
  "MONTH_11_AMOUNT": "11te Spalte Betrag (€)",
  "MONTH_11_YYYY": "November YYYY (€)",
  "MONTH_12_AMOUNT": "12te Spalte Betrag (€)",
  "MONTH_12_YYYY": "Dezember YYYY (€)",
  "MONTH_13_AMOUNT": "13te Spalte Betrag (€)",
  "MONTH_14_AMOUNT": "14te Spalte Betrag (€)",
  "MONTH_15_AMOUNT": "15te Spalte Betrag (€)",
  "MONTH_16_AMOUNT": "16te Spalte Betrag (€)",
  "MONTH_17_AMOUNT": "17te Spalte Betrag (€)",
  "MONTH_18_AMOUNT": "18te Spalte Betrag (€)",
  "MONTH_19_AMOUNT": "19te Spalte Betrag (€)",
  "MONTH_20_AMOUNT": "20te Spalte Betrag (€)",
  "MONTH_21_AMOUNT": "21te Spalte Betrag (€)",
  "MONTH_22_AMOUNT": "22te Spalte Betrag (€)",
  "MONTH_23_AMOUNT": "23te Spalte Betrag (€)",
  "MONTH_24_AMOUNT": "24te Spalte Betrag (€)",
  "MONTH_ON_MONTH_SALES_GROWTH": "Umsatzentwicklung im Monat MM/YYYY ggü. dem Vormonat MM-1/YYYY",
  "MONTH_ROLLING_SALES_VOLUME": "Rollierender Umsatz (€)",
  "MONTHLY AMOUNT": "Monatlicher Betrag (€/Monat)",
  "MONTHLY MONITORING VACANCY": "Monatliche Entwicklung des Leerstands zum",
  "MONTHLY OUTSTAND PAY RATE": "Rückstandsquote des Monats",
  "MONTHLY SALES VOLUME": "Monatlicher Umsatz (€/Monat)",
  "MONTHLY SALES VOLUME M MINUS 1": "Monatlicher Umsatz (€/Monat)",
  "MONTHLY SALES VOLUME Y MINUS 1": "Monatlicher Umsatz (€/Monat)",
  "MONTHLY_OUTSTAND_PAY_RATE": "Monatliche Rückstandsquote",
  "MONTHLY_SALES_VOLUME": "Monatlicher Umsatz MM/YYYY (€/Monat)",
  "MONTHLY_SALES_VOLUME_M_MINUS_1": "Monatlicher Umsatz MM-1/YYYY (€/Monat)",
  "MONTHLY_SALES_VOLUME_Y_MINUS_1": "Monatlicher Umsatz MM/YYYY-1 (€/Monat)",
  "MOTIF_DE_L_INTERVENTION_DE_MAINTENANCE": "Motif de l'intervention de maintenance",
  "NameFile": "(de) Nom du fichier",
  "NameFileAndADB": "(de) Nom du fichier -Nom de l’ADB",
  "NATURE": "Objekt-Nutzungsart",
  "NATURE TOTAL": "Gesamt",
  "NB_FLAGGED_RECORDS": "(de) Number of Flagged Records",
  "NET ASSET VALUE": "Nettoinventarwert (NAV) (€)",
  "NET ASSET VALUE ON SPV AND HOLDCO": "Nettoinventarwert von Beteiligungen (€)",
  "NET ASSET VALUE PER SHARE": "Anteilswert (€)",
  "NET ASSET VALUE REPORT": "Net Asset Value-Verfahren",
  "NET ASSET VALUE TITLE": "Nettoinventarwert (NAV)",
  "NET ASSET VALUE UNDERLYING VEHICLES": "Nettoinventarwert von Beteiligungen (€)",
  "NET BOOK VALUE": "Nettobuchwert (€)",
  "NET BOOK VALUE AFTER IMPAIR": "Unrealisierter Gewinn/Verlust nach außerplanmäßiger Abschreibung (€)",
  "NET BOOK VALUE AFTER PDDI": "Nettobuchwert nach weiterer Abschreibung (€)",
  "NET BOOK VALUE AFTER RESERVE": "Nettobuchwert nach Abschreibung (€)",
  "NET BOOK VALUE BEFORE RESERVE": "Nettobuchwert vor Abschreibung (€)",
  "NET BOOK VALUE ON SPV AND HOLDCO": "Nettobuchwert von Beteiligungen (€)",
  "NET BOOK VALUE UNDERLYING VEHICLES": "Nettobuchwert von Beteiligungen (€)",
  "NET CAPITAL RATE": "Netto-Kapitalisierungszinssätze",
  "NET CHANGE IN CURRENT ACCOUNT": "Veränderung Kontokorrent (€)",
  "NET CHANGE IN SHARE CAPITAL": "Erhöhung/Herabsetzung des gezeichneten Kapitals (€)",
  "NET CHANGE IN SHARE PREMIUM": "Erhöhung/Herabsetzung der Kapitalrücklage (€)",
  "NET EFFECTIVE RENT": "Netto-Mieteinnahmen (NME) (€/Jahr)",
  "NET EFFECTIVE RENT WOU": "Netto-Mieteinnahmen (NME)",
  "NET HEADLINE RENT": "Nachhaltige Netto-Mieteinnahmen (NME) (€/Jahr)",
  "NET HEADLINE RENT WOU": "Nachhaltige Netto-Mieteinnahmen (NME)",
  "NET INCOME": "Jahresergebnis (€)",
  "NET INCOME AFTER TAX": "Gewinn oder Verlust nach Steuern (€)",
  "NET INCOME BEFORE TAX": "Gewinn oder Verlust vor Steuern (€)",
  "NET INITIAL YIELD": "Nettorendite",
  "NET INITIAL YIELDS": "Nettorendite",
  "NET INITIAL YIELDS SHEET": "Nettorendite",
  "NET MARKET RENT": "Netto-Marktmiete (€/Jahr)",
  "NET MARKET VALUE": "Netto-Verkehrswert (€)",
  "NET OPERATING INCOME": "Netto-Mieteinnahmen (NME) (€/Jahr)",
  "NET POTENTIAL RENT": "Potentieller Mietreinertrag (€/Jahr)",
  "NET POTENTIAL RENTAL INCOME": "Potentielle Netto-Mieteinnahmen",
  "NET RENTAL INCOME": "Netto-Mieteinnahmen",
  "NET RESULT": "Jahresergebnis (€)",
  "NET RESULT AFTER TAX": "Gewinn oder Verlust nach Steuern (€)",
  "NET RESULT BEFORE TAX": "Gewinn oder Verlust vor Steuern (€)",
  "NET RESULT RETURN ON GROSS BOOK VALUE": "NCF-Rendite auf Basis Brutto-Buchwert",
  "NET RESULT RETURN ON GROSS BOOK VALUE OUT": "NCF-Rendite exkl. Verkäufe auf Basis Brutto-Buchwert",
  "NET RESULT TAX": "Steuern vom Einkommen und Ertrag (€)",
  "NET REVERS CAPITAL RATE": "Netto-Kapitalisierungszinssatz auf Marktmietniveau",
  "NET REVERS POTENT OCCUP AREA": "Auf die Vermietete Fläche bezogene",
  "NET REVERS POTENT OCCUP AREA FULL": "Mietanpassungspotenzial (vermietete Fläche)",
  "NET REVERS POTENT TOTAL PROP": "Auf die Gesamtfläche bezogene",
  "NET REVERS POTENT TOTAL PROP FULL": "Mietanpassungspotenzial (Gesamtfläche)",
  "NET REVERSIONARY POTENTIAL": "Mietanpassungspotenzial",
  "NET REVERSIONARY YIELD": "Netto-Anpassungsrendite auf Basis MM",
  "NET_ASSET_VALUE": "Nettoinventarwert (NAV) (€)",
  "NET_ASSET_VALUE_ON_SPV": "Nettoinventarwert von Beteiligungen (€)",
  "NET_ASSET_VALUE_ON_SPV_AND_HOLDCO": "Nettoinventarwert von Beteiligungen (€)",
  "NET_ASSET_VALUE_PER_SHARE": "Anteilswert (€)",
  "NET_ASSET_VALUE_REPORT": "Net Asset Value-Verfahren",
  "NET_BOOK_VALUE_AFTER_IMPAIR": "Unrealisierter Gewinn/Verlust nach außerplanmäßiger Abschreibung (€)",
  "NET_BOOK_VALUE_AFTER_PDDI": "Nettobuchwert nach weiterer Abschreibung (€)",
  "NET_BOOK_VALUE_AFTER_RESERVE": "Nettobuchwert nach Abschreibung (€)",
  "NET_BOOK_VALUE_BEFORE_RESERVE": "Nettobuchwert vor Abschreibung (€)",
  "NET_BOOK_VALUE_ON_SPV": "Nettobuchwert von Beteiligungen (€)",
  "NET_BOOK_VALUE_ON_SPV_AND_HOLDCO": "Nettobuchwert von Beteiligungen (€)",
  "NET_BOOK_VALUE-RELATED_DATA_SUCCESSFULLY_UPDATED": "Die Netto-Buchwert-bezogenen Daten wurden erfolgreich aktualisiert",
  "NET_EFFECTIVE_RENT": "Netto-Mieteinnahmen (NME) (€/Jahr)",
  "NET_FLOOR_AREA_SQM": "Nettogeschossfläche (NGF) (m²)",
  "NET_HEADLINE_RENT": "Nachhaltige Netto-Mieteinnahmen (NME) (€/Jahr)",
  "NET_INCOME": "Jahresergebnis (€)",
  "NET_INCOME_AFTER_TAX": "Jahresüberschuss/Jahresfehlbetrag (€)",
  "NET_INCOME_BEFORE_TAX": "Gewinn oder Verlust vor Steuern (€)",
  "NET_MARKET_RENT": "Netto-Marktmiete (€/Jahr)",
  "NET_MARKET_RENT_WOU": "Netto-Marktmiete",
  "NET_MARKET_VALUE": "Netto-Verkehrswert (€)",
  "NET_MARKET_VALUE_SQM": "Netto-Verkehrswert pro m² (€/m²)",
  "NET_MARKET_VALUE_WOU": "Netto-Verkehrswert",
  "NET_MARKET_VALUE_YYYY": "Netto-Verkehrswert YYYY-1 (€)",
  "NET_OPERATING_INCOME": "Tatsächliche Netto-Mieteinnahmen (NME) (€/Jahr)",
  "NET_POTENTIAL_RENT": "Potentieller Mietreinertrag (€/Jahr)",
  "NET_POTENTIAL_RENT_WOU": "Potentieller Mietreinertrag",
  "NET_REVERS_CAPITAL_RATE": "Netto-Kapitalisierungszinssatz auf Marktmietniveau",
  "NET_REVERS_POTENT_OCCUP_AREA": "Mietanpassungspotenzial (vermietete Fläche)",
  "NET_REVERS_POTENT_TOTAL_PROP": "Mietanpassungspotenzial (Gesamtfläche)",
  "NET_REVERSIONARY_YIELD": "Netto-Anpassungsrendite auf Basis MM",
  "NEUTRALIZATION": "Neutralisierung",
  "NEUTRALIZATION END DATE": "Neutralisierung-Anfangsdatum",
  "NEUTRALIZATION START DATE": "Neutralisierung-Enddatum",
  "NEUTRALIZATION_END_DATE": "(de) Neutralization End Date",
  "NEUTRALIZATION_START_DATE": "(de) Neutralization Start Date",
  "NEW LEASES NUMBER": "Anzahl von Neuvermietungen",
  "NEW LEASES RATE": "Neuvermietungsquote (Mietverträge)",
  "NEW UNITS AREA": "Neu vermietete Mietfläche (m²)",
  "NEW UNITS AREA RATE": "Neuvermietungsquote (vermietbare Fläche)",
  "NEW UNITS NUMBER": "Neu vermieteten Mieteinheiten",
  "NEW UNITS RATE": "Neuvermietungsquote (Mieteinheiten)",
  "NEW VALUE": "Aktueller Wert",
  "NEW_LEASES_NUMBER": "Anzahl von Neuvermietungen",
  "NEW_LEASES_RATE": "Neuvermietungsquote (Mietverträge)",
  "NEW_UNITS_AREA": "Neu vermietete Fläche (m²)",
  "NEW_UNITS_AREA_RATE": "Neuvermietungsquote (vermietbare Fläche)",
  "NEW_UNITS_NUMBER": "Neu vermieteten Mieteinheiten",
  "NEW_UNITS_RATE": "Neuvermietungsquote (Mieteinheiten)",
  "NEW_VALUE": "Aktueller Wert",
  "NewSendReportExcel": "(de) Nouvel Envoi Reporting PDF/Excel",
  "NEXT INDEX DATE": "Datum nächstmögliche Indexanpassung",
  "NEXT LEASE BREAK DATE": "Nächstmöglicher Kündigungstermin",
  "NEXT LEASE HEADLINE RENT": "Nächster Mietvertrag - Vertragsmiete (€/Jahr)",
  "NEXT LEASE HEADLINE RENT M2": "Nächster Mietvertrag - Vertragsmiete (€/Jahr/m²)",
  "NEXT REVISION DATE": "Datum nächstmögliche Mietanpassung",
  "NEXT RISK DATE": "Kündigungsausgang spätestens bis",
  "NEXT TENANT CODE": "Nächster Mieter-Nummer",
  "NEXT TENANT MOVE IN DATE": "Nächster Mieter-Einzugsdatum",
  "NEXT TENANT MOVE OUT DATE": "Nächster Mieter-Auszugsdatum",
  "NEXT TENANT NAME": "Nächster Mieter-Name",
  "NEXT_INDEX_DATE": "Datum nächstmögliche Indexanpassung",
  "NEXT_INDEX_VALUE": "(de) Next Index Value",
  "NEXT_LEASE_BREAK_DATE": "Nächstmöglicher Kündigungstermin",
  "NEXT_LEASE_HEADLINE_RENT": "Nächster Mietvertrag - Vertragsmiete (€/Jahr)",
  "NEXT_LEASE_HEADLINE_RENT_M2": "Nächster Mietvertrag - Vertragsmiete (€/Jahr/m²)",
  "NEXT_REVISION_DATE": "Datum nächstmögliche Mietanpassung",
  "NEXT_RISK_DATE": "Kündigungsausgang spätestens bis",
  "NEXT_TENANT_CODE": "Nächster Mieter-Nummer",
  "NEXT_TENANT_MOVE_IN_DATE": "Nächster Mieter-Einzugsdatum",
  "NEXT_TENANT_MOVE_OUT_DATE": "Nächster Mieter-Auszugsdatum",
  "NEXT_TENANT_NAME": "Nächster Mieter-Name",
  "NIV": "Level",
  "NO": "Nein",
  "NO DATA": "Keine Daten gefunden",
  "NOM_BATÎMENT": "Nom bâtiment",
  "NON RECOV OPERATING EXPENSES": "Nicht umlegbare Bewirtschaftungskosten (nBWK) (€/Jahr)",
  "NON RECOVERABLE EXPENSES": "Nicht umlegbare Bewirtschaftungskosten (nBWK) (€/Jahr)",
  "NON RECOVERABLE EXPENSES WOU": "Nicht umlegbare Bewirtschaftungskosten (nBWK)",
  "NON_RECOV_OPERATING_EXPENSES": "Nicht umlegbare Bewirtschaftungskosten (nBWK) YYYY-1 (€/Jahr)",
  "NON_RECOVERABLE_EXPENSES": "Nicht umlegbare Bewirtschaftungskosten (nBWK) (€/Jahr)",
  "NON_RECOVERABLE_EXPENSES_WOU": "Nicht umlegbare Bewirtschaftungskosten (nBWK)",
  "NOT DUE INC TAX AMOUNT": "Rechnungsbetrag (€)",
  "NOT RECOVERABLE": "(de) Not Recoverable",
  "NOT_DUE_INC_TAX_AMOUNT": "Rechnungsbetrag (€)",
  "NOTICE DATE": "Kündigungsdatum",
  "NOTICE PERIOD": "Kündigungsfrist (Monate)",
  "NOTICE TYPE LABEL": "Kündigungstyp",
  "NOTICE_DATE": "Kündigungsdatum",
  "NOTICE_PERIOD": "Kündigungsfrist (Monate)",
  "NOTICE_TYPE_CODE": "(de) Lease Termination Notice Type Code",
  "NOTICE_TYPE_LABEL": "Kündigungstyp",
  "NOUVELLE_REGLE_MISE_EN_CONFORMITE_DE_L_OBJET": "Nouvelle règle de mise en conformité",
  "NOVEMBER_AMOUNT": "November (€)",
  "NULL": "Unbekannt",
  "NUMBER": "Nummer",
  "NUMBER OF BUILDINGS": "Anzahl von Objekten",
  "NUMBER OF CAR PARKS": "Anzahl von Stellplätzen",
  "NUMBER OF DOUBLE CAR PARKS": "Anzahl von 2 Stellplätzen",
  "NUMBER OF LEASE ENDED": "Mietvertragsablauf",
  "NUMBER OF LEASE IN RISK": "Sonderkündigungsrecht",
  "NUMBER OF LEASE RENEWALS": "Anzahl von Verlängerungen",
  "NUMBER OF LEASES": "Anzahl von Mietverträgen",
  "NUMBER OF LEASES AT DATE": "Anzahl von Mietverträgen zum",
  "NUMBER OF OCCUPIED UNITS": "Anzahl der vermieteten Mieteinheiten",
  "NUMBER OF OWNERS": "Anzahl von Eigentümern",
  "NUMBER OF PARKING PLACES": "Anzahl von Stellplätzen",
  "NUMBER OF PARKING SPACES": "Anzahl von Stellplätzen",
  "NUMBER OF PROPERTIES": "Portfolio-Entwicklung (Anzahl von Immobilien)",
  "NUMBER OF PROPERTIES BY NATURE": "Portfolio-Entwicklung nach Immobilientyp (Anzahl von Immobilien)",
  "NUMBER OF ROOMS": "Anzahl von Zimmern",
  "NUMBER OF SECOND UNIT PERCENT": "% der gesamten Anzahl von Stellplätzen",
  "NUMBER OF SECONDARY UNIT": "Anzahl von Stellplätzen",
  "NUMBER OF SIMPLE CAR PARKS": "Anzahl von 1 Stellplätzen",
  "NUMBER OF TENANTS": "Anzahl von Mietern",
  "NUMBER OF UNITS": "Anzahl von Mieteinheiten",
  "NUMBER OF UNITS FOR SALE": "Anzahl von Zum Verkauf stehenden Mieteinheiten",
  "NUMBER OF VACANT UNITS": "Anzahl der leerstehenden Mieteinheiten",
  "NUMBER PARKING": "Anzahl von Stellplätzen",
  "NUMBER_LINE": "Anzahl der Zeilen in der Datei",
  "NUMBER_OF_BUILDINGS": "Anzahl von Objekten",
  "NUMBER_OF_BUILDINGS_mismatch": "Anzahl von Gebäuden ungültig",
  "NUMBER_OF_DOUBLE_CAR_PARKS": "Anzahl von 2 Stellplätzen",
  "NUMBER_OF_LEASE_RENEWALS": "Anzahl von Verlängerungen",
  "NUMBER_OF_LEASES": "Anzahl von Mietverträgen",
  "NUMBER_OF_LEASES_AT_DATE": "Anzahl von Mietverträgen zum DD/MM/YYYY",
  "NUMBER_OF_OCCUPIED_UNITS": "Anzahl der vermieteten Mieteinheiten",
  "NUMBER_OF_PARKING_PLACES": "Anzahl von Stellplätzen",
  "NUMBER_OF_PARKING_SPACES": "Anzahl von Stellplätzen",
  "NUMBER_OF_ROOMS": "Anzahl von Zimmern",
  "NUMBER_OF_SECOND_UNIT_PERCENT": "% der gesamten Anzahl von Stellplätzen",
  "NUMBER_OF_SECONDARY_UNIT": "Anzahl von Stellplätzen",
  "NUMBER_OF_SIMPLE_CAR_PARKS": "Anzahl von 1 Stellplätzen",
  "NUMBER_OF_TENANTS": "Anzahl von Mietern",
  "NUMBER_OF_UNITS": "Anzahl von Mieteinheiten",
  "NUMBER_OF_UNITS_FOR_SALE": "Anzahl von Zum Verkauf stehenden Mieteinheiten",
  "NUMBER_OF_UNITS_FOR_SALE_mismatch": "Anzahl von Zum Verkauf stehenden Mieteinheiten ungültig",
  "NUMBER_OF_UNITS_FOR_SALE_required": "Anzahl von Zum Verkauf stehenden Mieteinheiten erforderlich",
  "NUMBER_OF_VACANT_UNITS": "Anzahl der leerstehenden Mieteinheiten",
  "NUMBER_PARKING_SPACES": "Anzahl von Stellplätzen",
  "OBJET": "Objet du chantier",
  "OCCUPANCY": "Vermietung",
  "OCCUPANCY COST RATIO": "Umsatzmietbelastung (VM + NK-VZ)",
  "OCCUPANCY SUMMARY": "Vermietungsstand",
  "OCCUPANCY_COST_RATIO": "Umsatzmietbelastung (VM + NK-VZ)",
  "OCCUPIED AREA": "Vermietete Fläche (m²)",
  "OCCUPIED AREA SUMMARY": "Aufteilung der vermieteten Fläche nach Nutzungsart",
  "OCCUPIED_AREA_SQM": "Vermietete Fläche (m²)",
  "OCTOBER_AMOUNT": "Oktober (€)",
  "OFFICE AREA": "Bürofläche (m²)",
  "OFFICE TAX AMOUNT": "(de) Office Tax Amount (€)",
  "OFFICE_AREA": "Bürofläche (m²)",
  "OFFICE_TAX_AMOUNT": "(de) Office Tax Amount (€)",
  "ON LITIGATION": "Schwebendes Verfahren (ja/nein)?",
  "ON THE": "zum",
  "ON_LITIGATION": "Schwebendes Verfahren (ja/nein)?",
  "OPERATING EXPENSES": "Bewirtschaftungskosten (€)",
  "OPERATING RESULT": "Betriebsergebnis (€)",
  "OPERATING RETURN ON GROSS BOOK VALUE": "Nettobetriebserfolgsrendite auf Basis Brutto-Buchwert",
  "OPERATION": "Operation",
  "OPERATION NATURE CODE": "Code Art der Operation",
  "OPERATION NATURE LABEL": "Bezeichnung Art der Operation",
  "OPERATION_NATURE_CODE": "Code Art der Operation",
  "OPERATION_NATURE_LABEL": "Bezeichnung Art der Operation",
  "OperationalManagementInterfaceTitle": "Operative Management-Schnittstelle",
  "OTHER INCOME": "Weitere Erträge (€)",
  "OTHER OCCUPIED AREA": "(de) Other - Occupied Area (m²)",
  "OTHER REVENUE RESERVES": "Andere Gewinnrücklagen / Sonstige Rücklagen",
  "OTHER TOTAL AREA": "(de) Other - Total Area (m²)",
  "OTHER VACANT AREA": "(de) Other - Vacant Area (m²)",
  "OTHER_REVENUE_RESERVES": "Andere Gewinnrücklagen / Sonstige Rücklagen",
  "OUT COT AT": "Offene Posten zum",
  "OUT COT RAW": "Offene Posten",
  "OUTSTAND PAY BALANCES": "Entwicklung der Zahlungsbilanzen und der bezahlten Beträge",
  "OUTSTAND PAY BALANCES RAW": "Entwicklung der Zahlungsbilanzen und der bezahlten Beträge - unverarbeitet",
  "OUTSTAND PAY DAY 180": "91-180 Tage (€)",
  "OUTSTAND PAY DAY 30": "< 30 Tage (€)",
  "OUTSTAND PAY DAY 365": "6 Monate-1 Jahr (€)",
  "OUTSTAND PAY DAY 60": "30-60 Tage (€)",
  "OUTSTAND PAY DAY 90": "61-90 Tage (€)",
  "OUTSTAND PAY DAY 999": "> 1 Jahr (€)",
  "OUTSTAND PAY DEPOSIT": "Sicherheitsleistung (€)",
  "OUTSTAND PAY EVOLUTION": "Vierteljährliche Entwicklung der Zahlungsrückstände",
  "OUTSTAND PAY INC TAX AMOUNT": "Zahlungsrückstände (€)",
  "OUTSTAND PAY INCL TAX AMOUNT": "Mieter-Passivsaldo (€)",
  "OUTSTAND PAY INSURANCES": "Sachversicherung und sonstiges (€)",
  "OUTSTAND PAY OPERATING EXP": "Aufwand (€)",
  "OUTSTAND PAY OTHER": "Unverteilt (€)",
  "OUTSTAND PAY RENT": "Miete (€)",
  "OUTSTAND PAY TAXES": "Steuern (€)",
  "OUTSTAND PAY TERM EQUIV": "Anzahl der ausstehenden Terminen",
  "OUTSTAND PAY VAT AMOUNT": "Mehrwertsteuer und sonstige Steuern (€)",
  "OUTSTAND PAYMENT INC TAX AMOUNT": "Zahlungsrückstände",
  "OUTSTAND RATE": "Rückstandsquote",
  "OUTSTAND_PAY_DAY_1": "<= x Tage",
  "OUTSTAND_PAY_DAY_180": "91-180 Tage",
  "OUTSTAND_PAY_DAY_180_SHORT": "91-180 Tage",
  "OUTSTAND_PAY_DAY_2": "x-y Tage",
  "OUTSTAND_PAY_DAY_3": "x-y Tage",
  "OUTSTAND_PAY_DAY_30": "< 30 Tage",
  "OUTSTAND_PAY_DAY_30_SHORT": "< 30 Tage",
  "OUTSTAND_PAY_DAY_365": "6 Monate-1 Jahr",
  "OUTSTAND_PAY_DAY_365_SHORT": "6 Monate-1 Jahr",
  "OUTSTAND_PAY_DAY_4": "x-y Tage",
  "OUTSTAND_PAY_DAY_5": "x-y Tage",
  "OUTSTAND_PAY_DAY_6": "> x Tage",
  "OUTSTAND_PAY_DAY_60": "30-60 Tage",
  "OUTSTAND_PAY_DAY_60_SHORT": "30-60 Tage",
  "OUTSTAND_PAY_DAY_90": "61-90 Tage",
  "OUTSTAND_PAY_DAY_90_SHORT": "61-90 Tage",
  "OUTSTAND_PAY_DAY_999": "> 1 Jahr",
  "OUTSTAND_PAY_DAY_999_SHORT": "> 1 Jahr",
  "OUTSTAND_PAY_DEPOSIT": "Sicherheitsleistung (€)",
  "OUTSTAND_PAY_EVOLUTION": "Vierteljährliche Entwicklung der Zahlungsrückstände",
  "OUTSTAND_PAY_INC_TAX_AMOUNT": "Zahlungsrückstände (€)",
  "OUTSTAND_PAY_INSURANCES": "Sachversicherung und sonstiges (€)",
  "OUTSTAND_PAY_OPERATING_EXP": "Bewirtschaftungskosten (€)",
  "OUTSTAND_PAY_OTHER": "Unverteilt (€)",
  "OUTSTAND_PAY_RENT": "Miete (€)",
  "OUTSTAND_PAY_TAXES": "Steuern (€)",
  "OUTSTAND_PAY_TERM_EQUIV": "Anzahl der ausstehenden Terminen",
  "OUTSTAND_PAY_VAT_AMOUNT": "Mehrwertsteuer und sonstige Steuern (€)",
  "OUTSTAND_PAYMENT_INC_TAX_AMOUNT": "Zahlungsrückstände",
  "OUTSTAND_RATE": "Rückstandsquote",
  "OUTSTANDING AMOUNT EVOLUTION": "Entwicklung der bezahlten Beträge",
  "OUTSTANDING BALANCE EVOLUTION": "Entwicklung der Zahlungsbilanzen",
  "OUTSTANDING PAY PROVIDER": "Aggregierte Bilanz Gebäude nach Lieferanten",
  "OUTSTANDING PAY PROVIDER BUILDING": "Aggregierte Bilanz Gebäude nach Lieferanten und Rechnungen",
  "OUTSTANDING PAY PROVIDER PROVIDER": "Aggregierte Bilanz der Lieferanten nach Gebäuden und Rechnungen",
  "OUTSTANDING PAYMENT": "Zahlungsrückstände",
  "OUTSTANDING PAYMENTS": "Übersicht der ausstehenden Zahlungen",
  "OUTSTANDING PAYMENTS AMOUNT": "Zahlungsrückstände (€)",
  "OUTSTANDING PAYMENTS AS AT": "Übersicht der ausstehenden Zahlungen zum",
  "OUTSTANDING PAYMENTS BY PERIOD": "Fälligkeitsliste nach Laufzeit",
  "OUTSTANDING PAYMENTS DETAILLED BY TENANT": "Top 10 Mieter mit Zahlungsrückstand",
  "OUTSTANDING RATE AMOUNT BALANCE": "Entwicklung der Rate Bezahlungen gegen Zahlungsbilanz Saldo",
  "OUTSTANDING RENT": "Restzahlung (€)",
  "OUTSTANDING RENT WOU": "Restzahlung",
  "OUTSTANDING_PAYMENTS": "Zahlungsrückstände",
  "OUTSTANDING_PAYMENTS_BY_PERIOD": "Fälligkeitsliste nach Laufzeit",
  "OUTSTANDING_PAYMENTS_DETAILLED_BY_TENANT": "Top 10 Mieter mit Zahlungsrückstand",
  "OVERPAYMENTS INC TAX AMNT": "Überzahlungen oder nicht betroffener Eingang (€)",
  "OVERPAYMENTS INC TAX AMOUNT": "Überzahlungen (€)",
  "OVERPAYMENTS RATE": "Überzahlungsquote",
  "OVERPAYMENTS_INC_TAX_AMNT": "Überzahlungen oder nicht betroffener Eingang (€)",
  "OVERPAYMENTS_INC_TAX_AMOUNT": "Überzahlungen (€)",
  "OWNED BUILDINGS": "Objectübersicht Direkt Gehalten",
  "OWNER": "Eigentümer",
  "OWNER CODE": "Eigentümer-Nummer",
  "OWNER EQUITY": "Eigenkapital (€)",
  "OWNER FINANCIAL DATA": "Eigentümer-Finanzdaten",
  "OWNER NAME": "Eigentümer-Name",
  "OWNER TOTAL": "Gesamt",
  "OWNER_CODE": "Eigentümer-Nummer",
  "OWNER_CODE1": "Eigentümer-Nummer",
  "OWNER_EQUITY": "Eigenkapital (€)",
  "OWNER_FINANCIAL_DATA": "Eigentümer-Finanzdaten",
  "OWNER_NAME": "Eigentümer-Name",
  "OWNER_NAME_ARBITRAGE": "Erwerber-Name",
  "OWNER_NAME_required": "Erwerber-Name erforderlich",
  "OWNER_NAME1": "Eigentümer-Name",
  "OWNERSHIP DATA END DATE": "Bis zum",
  "OWNERSHIP DATA START DATE": "Vom",
  "OWNERSHIP RATE": "Beteiligungshöhe",
  "OWNERSHIP_DATA_END_DATE": "Bis zum",
  "OWNERSHIP_DATA_START_DATE": "Vom",
  "OWNERSHIP_RATE": "Beteiligungshöhe",
  "pageBuildingTitle": "Objekt-Datenblatt",
  "pageExportBuildingTitle": "Objekt-Datenblatt Export",
  "pageExportFundTitle": "Fonds-Datenblatt Export",
  "pageFundTitle": "Fonds-Datenblatt",
  "PageManageAdditionalFieldListTitle": "Themen erstellen",
  "PageManageInterfaceListTitle": "Datenströme erstellen",
  "PageManageSpecificInterfaceListTitle": "Spezificshe Schnittstellen verwalten",
  "pageManageTitle": "Mein Konto",
  "pageRepositoryBuildingTitle": "Objekt-Repositorium",
  "PAR": "Parameters (de)",
  "PARTIAL PAYMENT INCL TAX AMOUNT": "Teilzahlungen (€)",
  "PARTIAL_PAYMENT_INCL_TAX_AMOUNT": "Teilzahlungen (€)",
  "PASSING RENT": "Fakturierung seit Jahresbeginn (€)",
  "PAY INCL TAX AMOUNT SUM PERCENT": "% des eingezogenen Betrags",
  "PAY INCL TAX AMOUNT SUM RATE": "Eingezogener Betrag (€)",
  "PAYMENT 11 TO 20 DAYS": "11-20 Tage (€)",
  "PAYMENT 11 TO 20 RATE": "11-20 Tage (%)",
  "PAYMENT 21 TO 31 DAYS": "21-31 Tage (€)",
  "PAYMENT 21 TO 31 RATE": "21-31 Tage (%)",
  "PAYMENT 5 TO 10 DAYS": "5-10 Tage (€)",
  "PAYMENT 5 TO 10 RATE": "5-10 Tage (%)",
  "PAYMENT AMOUNT": "(de) Paid Amount",
  "PAYMENT BEFORE PERIOD INC TAX AMNT": "Eingänge auf vor der Periode fällige Rechnungen (€)",
  "PAYMENT DATE": "(de) Payment Date",
  "PAYMENT DAYS": "Tage (€)",
  "PAYMENT DURING PERIOD INC TAX AMNT": "Einnahmen im Berichtszeitraum (€)",
  "PAYMENT GREATER 31 DAYS": "> 31 Tage (€)",
  "PAYMENT GREATER 31 RATE": "> 31 Tage (%)",
  "PAYMENT INCL TAX AMOUNT": "Bezahlt (€)",
  "PAYMENT INCL TAX AMOUNT PERCENT": "% des eingezogenen Betrags",
  "PAYMENT INCL TAX AMOUNT RATE": "Eingezogener Betrag (€)",
  "PAYMENT LESS 5 DAYS": "< 5 Tage (€)",
  "PAYMENT LESS 5 RATE": "< 5 Tage (%)",
  "PAYMENT MODE": "(de) Payment Mode",
  "PAYMENT PERIOD 1": "< 30 Tage (€)",
  "PAYMENT PERIOD 1 RATE": "< 30 Tage (%)",
  "PAYMENT PERIOD 2": "30-60 Tage (€)",
  "PAYMENT PERIOD 2 RATE": "30-60 Tage (%)",
  "PAYMENT PERIOD 2 SUM": "30-60 Tage kumuliert (€)",
  "PAYMENT PERIOD 2 SUM RATE": "30-60 Tage kumuliert (%)",
  "PAYMENT PERIOD 3": "60-90 Tage (€)",
  "PAYMENT PERIOD 3 RATE": "60-90 Tage (%)",
  "PAYMENT PERIOD 3 SUM": "60-90 Tage kumuliert (€)",
  "PAYMENT PERIOD 3 SUM RATE": "60-90 Tage kumuliert (%)",
  "PAYMENT PERIOD 4": "90-180 Tage (€)",
  "PAYMENT PERIOD 4 RATE": "90-180 Tage (%)",
  "PAYMENT PERIOD 4 SUM": "90-180 Tage kumuliert (€)",
  "PAYMENT PERIOD 4 SUM RATE": "90-180 Tage kumuliert (%)",
  "PAYMENT PERIOD 5": "180-365 Tage (€)",
  "PAYMENT PERIOD 5 RATE": "180-365 Tage (%)",
  "PAYMENT PERIOD 5 SUM": "180-365 Tage kumuliert (€)",
  "PAYMENT PERIOD 5 SUM RATE": "180-365 Tage kumuliert (%)",
  "PAYMENT PERIOD 6": "> 365 Tage (€)",
  "PAYMENT PERIOD 6 RATE": "> 365 Tage (%)",
  "PAYMENT PERIOD 6 SUM": "> 365 Tage kumuliert (€)",
  "PAYMENT PERIOD 6 SUM RATE": "> 365 Tage kumuliert (%)",
  "PAYMENT PERIOD LESS 0": "< 0 Tage (€)",
  "PAYMENT RATE": "Tage (%)",
  "PAYMENT SUM": "Tage kumuliert (€)",
  "PAYMENT SUM RATE": "Tage kumuliert (%)",
  "PAYMENT_11_20_DAYS": "11-20 Tage",
  "PAYMENT_21_31_DAYS": "21-31 Tage",
  "PAYMENT_5_10_DAYS": "5-10 Tage",
  "PAYMENT_AMOUNT": "(de) Paid Amount",
  "PAYMENT_BEFORE_PERIOD_INC_TAX_AMNT": "Eingänge auf vor der Periode fällige Rechnungen (€)",
  "PAYMENT_DATE": "(de) Payment Date",
  "PAYMENT_DURING_PERIOD_INC_TAX_AMNT": "Einnahmen im Berichtszeitraum (€)",
  "PAYMENT_INCL_TAX_AMNT": "Bezahlt (€)",
  "PAYMENT_INCL_TAX_AMOUNT": "Eingezogener Betrag (€)",
  "PAYMENT_INCL_TAX_AMOUNT_RATE": "\\% des eingezogenen Betrags",
  "PAYMENT_INCL_TAX_AMOUNT_SUM": "Eingezogener Betrag (€)",
  "PAYMENT_INCL_TAX_AMOUNT_SUM_RATE": "\\% des eingezogenen Betrags",
  "PAYMENT_LESS_5_DAYS": "< 5 Tage",
  "PAYMENT_METHOD_CODE": "(de) Payment Method Code",
  "PAYMENT_MODE": "(de) Payment Mode",
  "PAYMENT_MORE_31_DAYS": "> 31 Days",
  "PAYMENT_PERIOD_1": "< {0} Tage (€)",
  "PAYMENT_PERIOD_1_RATE": "< {0} Tage (%)",
  "PAYMENT_PERIOD_2": "{0}-{1} Tage (€)",
  "PAYMENT_PERIOD_2_RATE": "{0}-{1} Tage (%)",
  "PAYMENT_PERIOD_2_SUM": "{0}-{1} Tage kumuliert (€)",
  "PAYMENT_PERIOD_2_SUM_RATE": "{0}-{1} Tage kumuliert (%)",
  "PAYMENT_PERIOD_3": "{0}-{1} Tage (€)",
  "PAYMENT_PERIOD_3_RATE": "{0}-{1} Tage (%)",
  "PAYMENT_PERIOD_3_SUM": "{0}-{1} Tage kumuliert (€)",
  "PAYMENT_PERIOD_3_SUM_RATE": "{0}-{1} Tage kumuliert (%)",
  "PAYMENT_PERIOD_4": "{0}-{1} Tage (€)",
  "PAYMENT_PERIOD_4_RATE": "{0}-{1} Tage (%)",
  "PAYMENT_PERIOD_4_SUM": "{0}-{1} Tage kumuliert (€)",
  "PAYMENT_PERIOD_4_SUM_RATE": "{0}-{1} Tage kumuliert (%)",
  "PAYMENT_PERIOD_5": "{0}-{1} Tage (€)",
  "PAYMENT_PERIOD_5_RATE": "{0}-{1} Tage (%)",
  "PAYMENT_PERIOD_5_SUM": "{0}-{1} Tage kumuliert (€)",
  "PAYMENT_PERIOD_5_SUM_RATE": "{0}-{1} Tage kumuliert (%)",
  "PAYMENT_PERIOD_6": "> {0} Tage (€)",
  "PAYMENT_PERIOD_6_RATE": "> {0} Tage (%)",
  "PAYMENT_PERIOD_6_SUM": "> {0} Tage kumuliert (€)",
  "PAYMENT_PERIOD_6_SUM_RATE": "> {0} Tage kumuliert (%)",
  "PAYMENT_PERIOD_LESS_0": "< 0 Tage (€)",
  "PAYS": "Pays",
  "PC EXPECTED YIELD": "Zielrendite",
  "PC MGMT FEES GARB COLLECT TAX": "(de) Garbage Collection Tax Management Fees (%)",
  "PC PORTFOLIO NET MARKET VALUE": "% des gesamten Netto-Verkehrswerts",
  "PC PORTFOLIO TOTAL RETURN": "% vom Portfolioreturn",
  "PC PROPERTY TAX MGMT FEES": "(de) Property Tax Management Fees (%)",
  "PC RECOV PROP MANAGT FEES ADM": "Anteil der Administrativ Verwaltungskosten umgelegbar",
  "PC RECOV PROP MANAGT FEES TEN": "Anteil der Verwaltungskosten umgelegbar",
  "PC RECOVERABLE 606": "Anteil der Gebäudesanierung umgelegbar",
  "PC RECOVERABLE INSURANCE": "Anteil der Sachversicherung umgelegbar",
  "PC RECOVERABLE OFFICE TAX": "Anteil der Steuer auf Bürofläche umgelegbar",
  "PC RECOVERABLE PROPERTY TAX": "Anteil der Grundsteuer umgelegbar",
  "PC RECOVERABLE REFURB EXCL 606": "(de) % Recoverable - Major Refurbishment",
  "PC STEP RENT 1 OF GHL RENT": "Staffelmiete 1 zur Vertragsmiete (%)",
  "PC STEP RENT 2 OF GHL RENT": "Staffelmiete 2 zur Vertragsmiete (%)",
  "PC STEP RENT 3 OF GHL RENT": "Staffelmiete 3 zur Vertragsmiete (%)",
  "PC STEP RENT 4 OF GHL RENT": "Staffelmiete 4 zur Vertragsmiete (%)",
  "PC STEP RENT 5 OF GHL RENT": "Staffelmiete 5 zur Vertragsmiete (%)",
  "PC STEP RENT 6 OF GHL RENT": "Staffelmiete 6 zur Vertragsmiete (%)",
  "PC_EXPECTED_YIELD": "Zielrendite",
  "PC_INDEX_LEVERAGE": "(de) Rent Review Leverage",
  "PC_MGMT_FEES_GARB_COLLECT_TAX": "(de) Garbage Collection Tax Management Fees (%)",
  "PC_PORTFOLIO_NET_MARKET_VALUE": "% des gesamten Netto-Verkehrswerts",
  "PC_PORTFOLIO_TOTAL_RETURN": "% vom Portfolioreturn",
  "PC_PROPERTY_TAX_MGMT_FEES": "(de) Property Tax Management Fees (%)",
  "PC_RECOV_PROP_MANAGT_FEES_ADM": "Anteil der Administrativ Verwaltungskosten umgelegbar",
  "PC_RECOV_PROP_MANAGT_FEES_TEN": "Anteil der Verwaltungskosten umgelegbar",
  "PC_RECOVERABLE_606": "Anteil der Gebäudesanierung umgelegbar",
  "PC_RECOVERABLE_INSURANCE": "Anteil der Sachversicherung umgelegbar",
  "PC_RECOVERABLE_OFFICE_TAX": "Anteil der Steuer auf Bürofläche umgelegbar",
  "PC_RECOVERABLE_PROPERTY_TAX": "Anteil der Grundsteuer umgelegbar",
  "PC_RECOVERABLE_REFURB_EXCL_606": "(de) % Recoverable - Major Refurbishment",
  "PC_STEP_RENT_1_OF_GHL_RENT": "Staffelmiete 1 zur Vertragsmiete (%)",
  "PC_STEP_RENT_2_OF_GHL_RENT": "Staffelmiete 2 zur Vertragsmiete (%)",
  "PC_STEP_RENT_3_OF_GHL_RENT": "Staffelmiete 3 zur Vertragsmiete (%)",
  "PC_STEP_RENT_4_OF_GHL_RENT": "Staffelmiete 4 zur Vertragsmiete (%)",
  "PC_STEP_RENT_5_OF_GHL_RENT": "Staffelmiete 5 zur Vertragsmiete (%)",
  "PC_STEP_RENT_6_OF_GHL_RENT": "Staffelmiete 6 zur Vertragsmiete (%)",
  "PCT RENT AMOUNT": "% der Objekt-Vertragsmiete (VM)",
  "PCT RENTED AREA": "% der Gesamtfläche des Objekts",
  "PCT_RENT_AMOUNT": "% der Objekt-Vertragsmiete (VM)",
  "PCT_RENTED_AREA": "% der Gesamtfläche des Objekts",
  "PDDI": "Verlust aus Wertminderung (€)",
  "PDF": "PDF",
  "PENDING RE DISPOSITIONS": "Laufende Verkäufe",
  "PENDING RE PURCHASES": "Laufende Erwerbe",
  "PER SHARE ACQUISITION PRICE": "Ausgabepreis (€)",
  "PER SHARE UNREALIZED GAINS AND LOSSES": "Unrealisierter Gewinn/Verlust pro Anteil (€)",
  "PER_SHARE_ACQUISITION_PRICE": "Ausgabepreis (€)",
  "PER_SHARE_UNREAL_GAINS_LOSSES": "Unrealisierter Gewinn/Verlust pro Anteil (€)",
  "PER_SHARE_UNREALIZED_GAINS_AND_LOSSES": "Unrealisierter Gewinn/Verlust pro Anteil (€)",
  "PERCENT TOTAL PROPERTY AREA": "% der Gesamtfläche",
  "PERCENT TOTAL RENT AMOUNT": "% der Objekt-Vertragsmiete (VM)",
  "PERCENT_OF_TOTAL_AREA": "% der Gesamtfläche",
  "PERCENT_TOTAL_PROPERTY_AREA": "% der Gesamtfläche",
  "PERCENT_TOTAL_RENT_AMOUNT": "% der Objekt-Vertragsmiete (VM)",
  "PERCENTAGE RENT": "Umsatzmiete (€/Jahr)",
  "PERCENTAGE RENT Y N": "(de) Percentage Rent (yes/no)?",
  "PERCENTAGE_RENT": "Umsatzmiete (€/Jahr)",
  "PERCENTAGE_RENT Y N": "(de) Percentage Rent (yes/no)?",
  "PERCENTAGE_RENT_Y_N": "(de) Percentage Rent (yes/no)?",
  "PERFORMANCE INDICATORS": "Kennzahlen",
  "PERIOD": "Zeitraum",
  "PERIOD AMOUNT": "Buchung",
  "PERIOD INVOICES DUE INC TAX AMNT": "Fällige Rechnungen für den Zeitraum (€)",
  "PERIOD_AMOUNT": "Betrag der Periode",
  "PERIOD_INVOICES_DUE_INC_TAX_AMNT": "Fällige Rechnungen für den Zeitraum (€)",
  "PHYSICAL OCCUPANCY RATE": "Vermietungsquote",
  "PHYSICAL OCCUPANCY SUMMARY": "Vermietungsstand nach Mietfläche",
  "PHYSICAL OCCUPATION RATE": "Vermietungsquote",
  "PHYSICAL VACANCY M2": "Leerstandsfläche (m²)",
  "PHYSICAL VACANCY PC": "Physische Leerstandsquote (%)",
  "PHYSICAL VACANCY RATE": "Physische Leerstandsquote",
  "PHYSICAL_OCCUPANCY_RATE": "Vermietungsquote",
  "PHYSICAL_OCCUPATION_RATE": "Vermietungsquote",
  "PHYSICAL_VACANCY_RATE": "Physische Leerstandsquote",
  "PM BUILDING CODE": "Objekt-Nummer vom Immobilien-Verwalter",
  "PORTFOLIO": "Portfolio",
  "PORTFOLIO BREAKDOWN ASSET NATURE AREAS": "Gesamtfläche-Aufteilung nach Objekt-Nutzungsart",
  "PORTFOLIO BREAKDOWN ASSET NATURE BUILDINGS": "Immobilienanzahl-Aufteilung nach Objekt-Nutzungsart",
  "PORTFOLIO BREAKDOWN ASSET NATURE GROSS HEADLINE RENT": "Aufteilung der Vertragsmiete nach Objekt-Nutzungsart",
  "PORTFOLIO BREAKDOWN ASSET NATURE LEASES": "Aufteilung der Anzahl von Mietverträgen nach Objekt-Nutzungsart",
  "PORTFOLIO BREAKDOWN ASSET NATURE UNITS": "Aufteilung der Anzahl von Mieteinheiten nach Objekt-Nutzungsart",
  "PORTFOLIO BREAKDOWN ASSET NATURE VALUES": "Aufteilung des Netto-Verkehrswerts nach Objekt-Nutzungsart",
  "PORTFOLIO BREAKDOWN ASSET TYPE AREAS": "Gesamtfläche-Aufteilung nach Immobilientyp",
  "PORTFOLIO BREAKDOWN ASSET TYPE BUILDINGS": "Immobilienanzahl-Aufteilung nach Immobilientyp",
  "PORTFOLIO BREAKDOWN ASSET TYPE GROSS HEADLINE RENT": "Aufteilung der Vertragsmiete nach Immobilientyp",
  "PORTFOLIO BREAKDOWN ASSET TYPE LEASES": "Aufteilung der Anzahl von Mietverträgen nach Immobilientyp",
  "PORTFOLIO BREAKDOWN ASSET TYPE UNITS": "Aufteilung der Anzahl von Mieteinheiten nach Immobilientyp",
  "PORTFOLIO BREAKDOWN ASSET TYPE VALUES": "Aufteilung des Netto-Verkehrswerts nach Immobilientyp",
  "PORTFOLIO CODE": "Portfolio-Nummer",
  "PORTFOLIO DETAIL": "Portfolio Überblick",
  "PORTFOLIO EVOLUTION €": "Portfolio-Entwicklung (Netto-Marktwert)",
  "PORTFOLIO EVOLUTION ASSET NATURE €": "Portfolio-Entwicklung nach Immobilientyp (Netto-Marktwert)",
  "PORTFOLIO EVOLUTION ASSET NATURE M2": "Portfolio-Entwicklung nach Immobilientyp (Gesamtfläche)",
  "PORTFOLIO EVOLUTION GROSS HEADLINE RENT": "Portfolio-Entwicklung (Vertragsmiete)",
  "PORTFOLIO EVOLUTION GROSS HEADLINE RENT BY NATURE": "Portfolio-Entwicklung nach Immobilientyp (Vertragsmiete)",
  "PORTFOLIO EVOLUTION M2": "Portfolio-Entwicklung (Gesamtfläche)",
  "PORTFOLIO EVOLUTION NET EFFECTIVE RENT": "Portfolio-Entwicklung (Netto-Mieteinnahmen)",
  "PORTFOLIO EVOLUTION NET EFFECTIVE RENT BY NATURE": "Portfolio-Entwicklung nach Immobilientyp (Netto-Mieteinnahmen)",
  "PORTFOLIO LABEL": "(de) Portfolio label",
  "PORTFOLIO SIZE": "Portfoliovolumen",
  "PORTFOLIO YIELD AND RETURN": "Portfolio-Ebene Renditen",
  "PORTFOLIO_CODE": "Portfolio-Nummer",
  "PORTFOLIO_CODE_required": "Portfolio erforderlich",
  "PORTFOLIO_LABEL": "(de) Portfolio label",
  "POSTAL CODE": "Postleitzahl",
  "POSTAL_CODE": "Postleitzahl",
  "POTENTIAL GROSS CAPITAL RATE": "Brutto-Kapitalisierungszinssatz auf Potentialmietniveau",
  "POTENTIAL GROSS INITIAL YIELD": "Brutto-Anfangsrendite auf Basis SM",
  "POTENTIAL INCOME INCREASE": "Mietanpassungspotenzial (€)",
  "POTENTIAL INCOME INCREASE WOU": "Mietanpassungspotenzial",
  "POTENTIAL NET CAPITAL RATE": "Netto-Kapitalisierungszinssatz auf Potentialmietniveau",
  "POTENTIAL NET INITIAL YIELD": "Netto-Anfangsrendite auf Basis potentiellem Mietreinertrag",
  "POTENTIAL NET RENTAL INCOME": "Potentieller Nettomietertrag bei Vollvermietung (€)",
  "POTENTIAL NET RENTAL INCOME WOU": "Potentieller Mietreinertrag",
  "POTENTIAL_GROSS_CAPITAL_RATE": "Brutto-Kapitalisierungszinssatz auf Potentialmietniveau",
  "POTENTIAL_GROSS_INITIAL_YIELD": "Brutto-Anfangsrendite auf Basis SM",
  "POTENTIAL_NET_CAPITAL_RATE": "Netto-Kapitalisierungszinssatz auf Potentialmietniveau",
  "POTENTIAL_NET_INITIAL_YIELD": "Netto-Anfangsrendite auf Basis potentiellem Mietreinertrag",
  "POURCENTAGE_D_AVANCEMENT_DU_CHANTIER": "Avancement du chantier (%)",
  "POURCENTAGE_RECUPERATION": "Récupération (%)",
  "PRESENT": "(de) Present",
  "PREV ANNUAL M2 AMOUNT": "Vorheriger Mietvertrag - Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "PREV GROSS HEADLINE RENT": "Vorheriger Mietvertrag - Vertragsmiete (VM) (€/Jahr)",
  "PREV LEASE ANNUAL RENT AMOUNT": "Vorheriger Mietvertrag - Vertragsmiete (VM) (€/Jahr)",
  "PREV RENTED AREA": "Vorheriger Mietvertrag - Vermietete Fläche (m²)",
  "PREV_ANNUAL_RENT_AMOUNT_SQM": "Vertragsmiete vor der Indexanpassung pro m² (€/m²/Jahr)",
  "PREV_LEASE_ANNUAL_RENT_AMOUNT": "Vorheriger Mietvertrag - Vertragsmiete (VM) (€/Jahr)",
  "PREV_VALUE_EXCL_DUTIES_VAT": "Vorheriger Netto-Verkehrswert (€)",
  "PREV_VALUE_EXCL_DUTIES_VAT_SQM": "Vorheriger Netto-Verkehrswert pro m² (€/m²)",
  "Preview": "Vorschau",
  "PREVIOUS LEASE AREA SQM": "Vorheriger Mietvertrag - Vermietete Fläche (m²)",
  "PREVIOUS LEASE HEADLINE RENT": "Vorheriger Mietvertrag - Vertragsmiete (€/Jahr)",
  "PREVIOUS LEASE HEADLINE RENT M2": "Vorheriger Mietvertrag - Vertragsmiete (€/Jahr/m²)",
  "PREVIOUS LEASE SUMMARY": "Überblick des vorherigen Mietvertrags",
  "PREVIOUS LEASE TENANT NAME": "Vorheriger Mieter-Name",
  "PREVIOUS LEASE TRADE NAME": "Vorheriger Mieter-Handelsname",
  "PREVIOUS RENT AMOUNT PER SQM": "Vorheriger Mietvertrag - Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "PREVIOUS TENANT CODE": "Vorheriger Mieter-Nummer",
  "PREVIOUS TENANT MOVE OUT DATE": "Vorheriger Mieter-Auszugsdatum",
  "PREVIOUS TENANT NAME": "Vorheriger Mieter-Name",
  "PREVIOUS_ANNUAL_RENT_AMOUNT": "Vertragsmiete vor der Indexanpassung (€/Jahr)",
  "PREVIOUS_LAST_VALUATION_DATE": "Bewertungsstichtag YYYY-2",
  "PREVIOUS_LEASE_AREA_SQM": "Vorheriger Mietvertrag - Vermietete Fläche (m²)",
  "PREVIOUS_LEASE_HEADLINE_RENT": "Vorheriger Mietvertrag - Vertragsmiete (€/Jahr)",
  "PREVIOUS_LEASE_HEADLINE_RENT_M2": "Vorheriger Mietvertrag - Vertragsmiete (€/Jahr/m²)",
  "PREVIOUS_LEASE_TENANT_NAME": "Vorheriger Mieter-Name",
  "PREVIOUS_LEASE_TRADE_NAME": "Vorheriger Mieter-Handelsname",
  "PREVIOUS_NET_MARKET_VALUE": "Netto-Verkehrswert YYYY-2 (€)",
  "PREVIOUS_RENT_AMOUNT_PER_SQM": "Vorheriger Mietvertrag - Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "PREVIOUS_REVISION_DATE": "(de) Previous Rent Review Date",
  "PREVIOUS_TENANT_CODE": "Vorheriger Mieter-Nummer",
  "PREVIOUS_TENANT_MOVE_OUT_DATE": "Vorheriger Mieter-Auszugsdatum",
  "PREVIOUS_TENANT_NAME": "Vorheriger Mieter-Name",
  "PRIMARY UNIT": "Hautpmieteinheit (ja/nein)?",
  "PRIVATE UNIT AREA": "(de) Private Unit Area (m²)",
  "PRIVATE_UNIT_AREA": "(de) Private Unit Area (m²)",
  "PRO RATED FINANCIAL VACANCY": "Leerstandsmiete pro rata temporis (€)",
  "PRO_RATED_FINANCIAL_VACANCY": "Leerstandsmiete pro rata temporis (€)",
  "PROPERTY": "Objekte",
  "PROPERTY BALANCE": "Objekt-Ebene Saldenbilanz",
  "PROPERTY BALANCE SUMMARY": "Objekt-Ebene Saldenbilanz - Konsolidiert",
  "PROPERTY DISTRIBUTION": "Mieteinnahmen, Total Return und Netto-Verkehrswert nach Objekt und Nutzungsart",
  "PROPERTY MANAGER": "Immobilien-Verwalter",
  "PROPERTY MANAGER CODE": "Immobilien Manager-Nummer",
  "PROPERTY MANAGER REPORT": "(de) Tableau de bord ADB",
  "PROPERTY MANAGER REPORT TRIMESTER": "(de) Tableau de bord ADB - trimestre",
  "PROPERTY SUMMARY": "Objekt-Überblick",
  "PROPERTY TAX AMOUNT": "(de) Property Tax Amount (€)",
  "PROPERTY TAX MANAGEMENT FEES": "(de) Property Tax Management Fees (€)",
  "PROPERTY TOTAL": "(de) Property Total",
  "PROPERTY TYPE BREAKDOWN": "Aufteilung des Netto-Verkehrswerts nach Objekt-Nutzungsart",
  "PROPERTY VALUATION": "Wertberichtigung der Objekte zum",
  "PROPERTY VALUATION SHEET": "Wertberichtigung der Objekte",
  "PROPERTY_MANAGER": "Immobilien-Verwalter",
  "PROPERTY_MANAGER_CODE": "Immobilien Manager-Nummer",
  "PROPERTY_MANAGER_NAME": "Immobilien-Verwalter",
  "PROPERTY_TAX_AMOUNT": "(de) Property Tax Amount (€)",
  "PROPERTY_TAX_MANAGEMENT_FEES": "(de) Property Tax Management Fees (€)",
  "PROVIDER": "Lieferant",
  "PROVIDER CODE": "(de) Provider Code",
  "PROVIDER INVOICES ACCOUNTED": "gebuchte Lieferantenrechnungen",
  "PROVIDER INVOICES PAID": "(de) Paid provider invoices",
  "PROVIDER NAME": "(de) Provider Name",
  "PROVIDER TOTAL": "Gesamtanbieter",
  "PROVIDER TYPE CODE": "(de) Provider Type Code",
  "PROVIDER TYPE NAME": "(de) Provider Type",
  "PROVIDER_CODE": "(de) Provider Code",
  "PROVIDER_NAME": "(de) Provider Name",
  "PROVIDER_TOTAL": "Gesamtanbieter",
  "PROVIDER_TYPE_CODE": "(de) Provider Type Code",
  "PROVIDER_TYPE_NAME": "(de) Provider Type",
  "PROVISIONS FOR EXPENSES": "Nebenkostenvorauszahlung (€)",
  "PROVISIONS FOR EXPENSES WOU": "Nebenkostenvorauszahlung",
  "PURCH WORK PROGRES STATE": "Status der Renovierung",
  "PURCHASE": "Erwerbe erstellen",
  "PURCHASE ACT SIGNING DATE": "Datum Eigentumsübertragung",
  "PURCHASE ACT SIGNING DT": "Datum Eigentumsübertragung",
  "PURCHASE AGREEMENT DATE": "Datum der Kaufvereinbarung",
  "PURCHASE FEES AMOUNT": "Anschaffungsnebenkosten (€)",
  "PURCHASE GOAL": "Zweck des Erwerbs",
  "PURCHASE HISTORY €": "Überblick der Investitionsvolumen nach Immobilientyp und Netto-Marktwert",
  "PURCHASE HISTORY M2": "Überblick der Investitionsvolumen nach Immobilientyp und Gesamtfläche",
  "PURCHASE HISTORY RECORD": "Überblick der Investitionsvolumen",
  "PURCHASE PROGRES STATE": "Erwerbsstatus",
  "PURCHASE STASUS": "Erwerbsstatus",
  "PURCHASE TOT VALUE": "Gesamter Kaufpreis des Objekts",
  "PURCHASE TOTAL VALUE": "Anschaffungswert (€)",
  "PURCHASE TOTAL VALUE SQM": "Gesamter Kaufpreis pro m² (€/m²)",
  "PURCHASE TYPE": "Bezugsart",
  "PURCHASE_ACT_SIGNING_DATE": "Datum Eigentumsübertragung",
  "PURCHASE_ACT_SIGNING_DATE_mismatch": "Datum Eigentumsübertragung ungültig",
  "PURCHASE_AGREEMENT_DATE": "Datum der Kaufvereinbarung",
  "PURCHASE_AGREEMENT_DATE_mismatch": "Datum der Kaufvereinbarung ungültig",
  "PURCHASE_AGREEMENT_DATE_required": "Datum der Kaufvereinbarung erforderlich",
  "PURCHASE_FEES_AMOUNT": "Anschaffungsnebenkosten (€)",
  "PURCHASE_FEES_AMOUNT_mismatch": "Anschaffungsnebenkosten ungültig",
  "PURCHASE_FEES_AMOUNT_WOU": "Anschaffungsnebenkosten",
  "PURCHASE_STATUS": "Erwerbsstatus",
  "PURCHASE_TYPE": "Bezugsart",
  "PURCHASER NAME": "Erwerber-Name",
  "PURCHASER_NAME": "Erwerber-Name",
  "PwdNotContaintFollowSpecialChar": "(de) The password should not contain the following special characters : \">\" and \"<",
  "QUARTER": "Q",
  "QUARTERLY OCCUPANCY EVOLUTION": "Vierteljährliche Entwicklung der Vermietung",
  "QuoteColumns": "Einrahmung der Spalte mit Anführungszeichen",
  "RATE BUDGET N REAL N MINUS 1": "Plan vs. Ist Abweichung relativ (%)",
  "RATE REALISED N BUDGETED N": "Ist vs. Plan Abweichung relativ (%)",
  "RATE REALISED N N MINUS 1": "Ist vs. Ist Abweichung relativ (%)",
  "RATE_BUDGET_N_REAL_N_MINUS_1": "Plan YYYY vs. Ist YYYY-1 Abweichung relativ (%)",
  "RATE_REALISED_N_BUDGETED_N": "Ist YYYY vs. Plan YYYY Abweichung relativ (%)",
  "RATE_REALISED_N_N_MINUS_1": "Ist YYYY vs. Ist YYYY-1 Abweichung relativ (%)",
  "RE LETTING PERIOD": "Neuvermietungsdauer (NVD) (Tage)",
  "RE_LETTING_PERIOD": "Neuvermietungsdauer (NVD) (Tage)",
  "REAL ESTATE CASH FLOW": "Objekt-Cashflow (€/Jahr)",
  "REAL ESTATE PORTFOLIO": "Immobilienportfolio",
  "REAL ESTATE PORTFOLIO BREAKDOWN": "Aufteilung des Immobilienportfolios zum",
  "REAL ESTATE PORTFOLIO EVOLUTION": "Entwicklung des Immobilienportfolios zum",
  "REAL ESTATE PORTFOLIO EVOLUTION SHEET": "Portfolio-Entwicklung",
  "REAL_ESTATE_CASH_FLOW": "Objekt-Cashflow YYYY-1 (€/Jahr)",
  "REALISED 01 N": "Realisiert Januar",
  "REALISED 02 N": "Realisiert Februar",
  "REALISED 03 N": "Realisiert März",
  "REALISED 04 N": "Realisiert April",
  "REALISED 05 N": "Realisiert Mai",
  "REALISED 06 N": "Realisiert Juni",
  "REALISED 07 N": "Realisiert Juli",
  "REALISED 08 N": "Realisiert August",
  "REALISED 09 N": "Realisiert September",
  "REALISED 10 N": "Realisiert Oktober",
  "REALISED 11 N": "Realisiert November",
  "REALISED 12 N": "Realisiert December",
  "REALISED ACCOUNTING N M1": "(de) Realised accounting",
  "REALISED AMOUNT BT": "Ist (€/Jahr)",
  "REALISED N": "Realisiert",
  "REALISED N M1": "(de) Realised",
  "REALISED N MINUS 1": "Ist (€/Jahr)",
  "REALISED N MINUS 2": "Ist (€/Jahr)",
  "REALISED N TODATE M1": "Realisiert",
  "REALISED T1 N": "Realisiert V1",
  "REALISED T2 N": "Realisiert V2",
  "REALISED T3 N": "Realisiert V3",
  "REALISED T4 N": "Realisiert V4",
  "REALISED TODATE N": "Realisiert",
  "REALISED_01_N": "Realisiert Januar YYYY",
  "REALISED_02_N": "Realisiert Februar YYYY",
  "REALISED_03_N": "Realisiert März YYYY",
  "REALISED_04_N": "Realisiert April YYYY",
  "REALISED_05_N": "Realisiert May YYYY",
  "REALISED_06_N": "Realisiert June YYYY",
  "REALISED_07_N": "Realisiert Juli YYYY",
  "REALISED_08_N": "Realisiert August YYYY",
  "REALISED_09_N": "Realisiert September YYYY",
  "REALISED_10_N": "Realisiert Oktober YYYY",
  "REALISED_11_N": "Realisiert November YYYY",
  "REALISED_12_N": "Realisiert Dezember YYYY",
  "REALISED_ACCOUNTING_N_M1": "Realisiert accounting DDMMYYYY-1",
  "REALISED_N": "Realisiert YYYY",
  "REALISED_N_M1": "(de) Realised YYYY-1",
  "REALISED_N_MINUS_1": "Ist YYYY-1 (€/Jahr)",
  "REALISED_N_MINUS_2": "Ist YYYY-2 (€/Jahr)",
  "REALISED_N_TODATE_M1": "Realisiert DDMMYYYY-1",
  "REALISED_T1_N": "Realisiert V1 YYYY",
  "REALISED_T2_N": "Realisiert V2 YYYY",
  "REALISED_T3_N": "Realisiert V3 YYYY",
  "REALISED_T4_N": "Realisiert V4 YYYY",
  "REALISED_TODATE_N": "Realisiert DDMMYYYY",
  "RECONSTITUTION VALUE": "Neu kalkulierte Bewertung (€)",
  "RECONSTITUTION_VALUE": "Neu kalkulierte Bewertung (€)",
  "RECOV OPERATING EXPENSES": "Umlegbare Bewirtschaftungskosten (BWK) (€/Jahr)",
  "RECOV_OPERATING_EXPENSES": "Umlegbare Bewirtschaftungskosten (BWK) YYYY-1 (€/Jahr)",
  "RECOVERABLE": "(de) Recoverable",
  "RECOVERABLE 1": "Einbringlich / Nicht einbringlich",
  "RECOVERABLE AMOUNT": "Erzielbare Betrag (€) / Erzielbarer Wert (€)",
  "RECOVERABLE EXPENSES": "Umlegbare Bewirtschaftungskosten (BWK) (€/Jahr)",
  "RECOVERABLE EXPENSES WOU": "Umlegbare Bewirtschaftungskosten (BWK)",
  "RECOVERABLE GARB COLLECT TAX": "(de) % Recoverable - Garbage Collection Tax",
  "RECOVERABLE MARKETING FEES": "(de) % Recoverable - Marketing Fees",
  "RECOVERABLE NOT RECOVERABLE": "(de) Recoverable / Not Recoverable",
  "RECOVERABLE_1": "Einbringlich / Nicht einbringlich",
  "RECOVERABLE_AMOUNT": "Erzielbare Betrag (€) / Erzielbarer Wert (€)",
  "RECOVERABLE_EXPENSES": "Umlegbare Bewirtschaftungskosten (BWK) (€/Jahr)",
  "RECOVERABLE_EXPENSES_WOU": "Umlegbare Bewirtschaftungskosten (BWK)",
  "RECOVERABLE_GARB_COLLECT_TAX": "(de) % Recoverable - Garbage Collection Tax",
  "RECOVERABLE_MARKETING_FEES": "(de) % Recoverable - Marketing Fees",
  "RECOVERED EXPENSES": "Umgelegte Bewirtschaftungskosten (BWK) (€/Jahr)",
  "RECOVERED EXPENSES WOU": "Umgelegte Bewirtschaftungskosten (BWK)",
  "RECUPERABLE": "Récupérable ?",
  "REFERENCE VALUE": "Neubewertung (€)",
  "REFERENCE_NUMBER": "(de) Reference Number",
  "REFURBISHMENT AND MAINTENANCE MONITORING": "Überwachung von Sanierung und Instandhaltung",
  "REFURBISHMENT CLAUSE": "(de) Refurbishment Clause",
  "REFURBISHMENT_CLAUSE": "(de) Refurbishment Clause",
  "REGISTRATION FEES AMOUNT": "Eintragungsgebühren (€)",
  "REGISTRATION_FEES_AMOUNT": "Eintragungsgebühren (€)",
  "REGISTRATION_FEES_AMOUNT_mismatch": "Eintragungsgebühren ungültig",
  "REGISTRATION_FEES_AMOUNT_WOU": "Eintragungsgebühren",
  "REGUL_CHARGE_SUB_ITEM": "(de) Sub-Billing Item",
  "REGULAR CHARGES ITEM CODE": "Fakturierungselement-Nummer",
  "REGULAR CHARGES ITEM LABEL": "Fakturierungselement-Bezeichnung",
  "REGULAR_CHARGES_ITEM_CODE": "Rechnungsposten-Typ-Nummer",
  "REGULAR_CHARGES_ITEM_LABEL": "Rechnungsposten-Typ",
  "RELETTING REPORT": "Neuvermietungen",
  "RELETTING REPORT RAW": "Neuvermietungen zum",
  "REMAINING TERM TO THE LEASE TERMINATION": "(de) Remaining Term to the Lease Termination (y-m-d)",
  "REMAINING_TERM_TO_THE_LEASE_TERMINATION": "(de) Remaining Term to the Lease Termination (y-m-d)",
  "RENT ADJUSTMENTS": "Incentivekosten (IncK) (€)",
  "RENT ADJUSTMENTS WOU": "Incentivekosten (IncK)",
  "RENT FREE 1 END DATE": "Mietfreizeit 1 Enddatum",
  "RENT FREE 1 PERIOD": "Mietfreizeit 1 Dauer (Monate)",
  "RENT FREE 1 START DATE": "Mietfreizeit 1 Startdatum",
  "RENT FREE 2 END DATE": "Mietfreizeit 2 Enddatum",
  "RENT FREE 2 PERIOD": "Mietfreizeit 2 Dauer (Monate)",
  "RENT FREE 2 START DATE": "Mietfreizeit 2 Startdatum",
  "RENT FREE 3 END DATE": "Mietfreizeit 3 Enddatum",
  "RENT FREE 3 PERIOD": "Mietfreizeit 3 Dauer (Monate)",
  "RENT FREE 3 START DATE": "Mietfreizeit 3 Startdatum",
  "RENT FREE AMOUNT 1": "jährlicher Betrag der Mietfreizeit 1",
  "RENT FREE AMOUNT 2": "jährlicher Betrag der Mietfreizeit 2",
  "RENT FREE AMOUNT 3": "jährlicher Betrag der Mietfreizeit 3",
  "RENT FREE END DATE": "Allgemeine Mietfreizeit Enddatum",
  "RENT FREE LEFT AMOUNT": "Verbleibende Mietfrei (€)",
  "RENT FREE LEFT NB MONTH": "Verbleibende Mietfreizeit (Monate)",
  "RENT FREE NB MONTH": "Gesamte Mietfreizeit (Monate)",
  "RENT FREE PERIOD": "Mietfreie Zeit (J-M-T)",
  "RENT FREE TOTAL AMOUNT": "Gesamte Mietfrei (€)",
  "RENT MARKET VALUE": "Marktmiete pro m² (€/m²/Jahr)",
  "RENT RECEIVABLE CUR BIL TERM": "(de) Rent Receivable Current Billing Term (€)",
  "RENT RECEIVABLE YEAR TO DATE": "(de) Rent Receivable Year-to-Date (€)",
  "RENT REVIEW FREQUENCY": "(de ) Rent Review Frequency",
  "RENT REVIEW LEVERAGE": "(de) Rent Review Leverage",
  "RENT TO SALES RATIO": "Umsatzmietbelastung (VM)",
  "RENT UPLIFT": "Mietsteigerungsquote seit Mietbeginn",
  "RENT VARIATION": "Mietsteigerung",
  "RENT VARIATION AND REVERSIONARY POTENTIAL": "Mietsteigerung und Mietanpassungspotenzial",
  "RENT VARIATION RATE": "Mietsteigerungsquote",
  "RENT_FREE 1 END DATE": "Mietfreie Zeit Jahr 1 - Enddatum",
  "RENT_FREE 1 PERIOD": "Mietfreie Zeit Jahr 1 (Monate)",
  "RENT_FREE 1 START DATE": "Mietfreie Zeit Jahr 1 - Beginndatum",
  "RENT_FREE 2 END DATE": "Mietfreie Zeit Jahr 2 - Enddatum",
  "RENT_FREE 2 PERIOD": "Mietfreie Zeit Jahr 2 (Monate)",
  "RENT_FREE 2 START DATE": "Mietfreie Zeit Jahr 2 - Beginndatum",
  "RENT_FREE 3 END DATE": "Mietfreie Zeit Jahr 3 - Enddatum",
  "RENT_FREE 3 PERIOD": "Mietfreie Zeit Jahr 3 (Monate)",
  "RENT_FREE 3 START DATE": "Mietfreie Zeit Jahr 3 - Beginndatum",
  "RENT_FREE AMOUNT": "Mietfreie Zeit - Betrag (€)",
  "RENT_FREE AMOUNT 1": "Mietfreie Zeit - Betrag für Jahr 1 (€)",
  "RENT_FREE AMOUNT 2": "Mietfreie Zeit - Betrag für Jahr 2 (€)",
  "RENT_FREE AMOUNT 3": "Mietfreie Zeit - Betrag für Jahr 3 (€)",
  "RENT_FREE END DATE": "Enddatum der Mietfreien Zeit",
  "RENT_FREE PERIOD": "Mietfreie Zeit 2 (J-M-T)",
  "RENT_FREE START DATE": "Beginndatum der Mietfreien Zeit",
  "RENT_FREE_1_END_DATE": "Mietfreizeit 1 Enddatum",
  "RENT_FREE_1_PERIOD": "Mietfreizeit 1 Dauer (Monate)",
  "RENT_FREE_1_START_DATE": "Mietfreizeit 1 Startdatum",
  "RENT_FREE_2_END_DATE": "Mietfreizeit 2 Enddatum",
  "RENT_FREE_2_PERIOD": "Mietfreizeit 2 Dauer (Monate)",
  "RENT_FREE_2_START_DATE": "Mietfreizeit 2 Startdatum",
  "RENT_FREE_3_END_DATE": "Mietfreizeit 3 Enddatum",
  "RENT_FREE_3_PERIOD": "Mietfreizeit 3 Dauer (Monate)",
  "RENT_FREE_3_START_DATE": "Mietfreizeit 3 Startdatum",
  "RENT_FREE_AMOUNT": "Mietfreie Zeit - Betrag (€)",
  "RENT_FREE_AMOUNT_1": "jährlicher Betrag der Mietfreizeit 1",
  "RENT_FREE_AMOUNT_2": "jährlicher Betrag der Mietfreizeit 2",
  "RENT_FREE_AMOUNT_3": "jährlicher Betrag der Mietfreizeit 3",
  "RENT_FREE_END_DATE": "Allgemeine Mietfreizeit Enddatum",
  "RENT_FREE_LEFT_AMOUNT": "Verbleibende Mietfrei (€)",
  "RENT_FREE_LEFT_NB_MONTH": "Verbleibende Mietfreizeit (Monate)",
  "RENT_FREE_NB_MONTH": "Gesamte Mietfreizeit (Monate)",
  "RENT_FREE_PERIOD": "Mietfreie Zeit (J-M-T)",
  "RENT_FREE_START_DATE": "Beginndatum der Mietfreien Zeit",
  "RENT_FREE_TOTAL_AMOUNT": "Gesamte Mietfrei (€)",
  "RENT_INIT_VARIATION_RATE": "(de) Rent Uplift Compared To Initial Rent",
  "RENT_MARKET_VALUE": "Marktmiete pro m² (€/m²/Jahr)",
  "RENT_RECEIVABLE_CUR_BIL_TERM": "(de) Rent Receivable Current Billing Term (€)",
  "RENT_RECEIVABLE_YEAR_TO_DATE": "(de) Rent Receivable Year-to-Date (€)",
  "RENT_REVIEW_FREQUENCY": "(de ) Rent Review Frequency",
  "RENT_REVIEW_LEVERAGE": "(de) Rent Review Leverage",
  "RENT_TO_SALES_RATIO": "Umsatzmietbelastung (VM)",
  "RENT_UPLIFT": "Mietsteigerungsquote seit Mietvertragsbeginn",
  "RENT_VARIATION": "Mietsteigerungsquote",
  "RENT_VARIATION_RATE": "Mietsteigerungsquote",
  "RENTAL EXPENSES": "Bewirtschaftungskosten",
  "RENTAL EXPENSES WOU": "Bewirtschaftungskosten",
  "RENTAL INCOME": "Mieteinnahmen (€)",
  "RENTAL INCOME MONITORING": "Überwachung der Mieteinnahmen zum",
  "RENTAL INCOME MONITORING COMPARE": "Jahresvergleich der Fakturierung zum",
  "RENTAL INCOME MONITORING COMPARE RAW": "Jahresvergleich der Fakturierung zum",
  "RENTAL INCOME MONITORING RAW": "Überwachung der Mieteinnahmen zum",
  "RENTAL INCOME MONITORING SHEET": "Überwachung der Soll-Mieteinnahmen",
  "RENTAL INCOME WOU": "Mieteinnahmen",
  "RENTAL SITUATION": "(de) Rental Situation",
  "RENTAL TERM LABEL": "Laufzeit",
  "RENTAL VACANCY": "Temporärer Leerstand (konjunkturell)",
  "RENTAL VACANT AREA": "Vermietbare Leerstandsfläche (m²)",
  "RENTAL VACANT AREA RATE": "% des gesamten Leerstands",
  "RENTAL_TERM_LABEL": "Laufzeit",
  "RENTAL_VACANT_AREA": "Vermietbare Leerstandsfläche (m²)",
  "RENTAL_VACANT_AREA_RATE": "% des gesamten Leerstands (%)",
  "RENTED AREA": "Vermietete Fläche (m²)",
  "RENTED_AREA": "Vermietete Fläche (m²)",
  "REPAIR CLAUSE": "(de) Repair Clause",
  "REPAIR_CLAUSE": "(de) Repair Clause",
  "REPARTITION CODE": "Verteilungscode",
  "REPARTITION LABEL": "Verteilungslabel",
  "REPARTITION_CODE": "Verteilungscode",
  "REPARTITION_LABEL": "Verteilungslabel",
  "REPORT COMMENTS": "Kommentare",
  "REPORT DATE": "Zeitpunkt",
  "REPORT_COMMENTS": "(de) Comments",
  "REPORT_EXPORT_ABE": "(de) Reporting (de) Bank Movements",
  "REPORT_EXPORT_ALE": "(de) Reporting Alarme erstellen",
  "REPORT_EXPORT_AUT": "(de) Reporting Prüfprotokoll",
  "REPORT_EXPORT_BUI": "(de) Reporting Objekt-Datenblatt Export",
  "REPORT_EXPORT_BUM": "(de) Reporting Soll-Ist-Vergleich Bilanz",
  "REPORT_EXPORT_CBP": "(de) Reporting Aufteilung der Zahlungsperioden ersetzen",
  "REPORT_EXPORT_CMO": "(de) Reporting Überwachung der Ist-Mieteinnahmen",
  "REPORT_EXPORT_CVA": "(de) Reporting Net Asset Value-Verfahren",
  "REPORT_EXPORT_DEK": "(de) Reporting (de) Deka Reporting",
  "REPORT_EXPORT_DOP": "(de) Reporting Offene Posten",
  "REPORT_EXPORT_DTZ": "(de) Reporting Vermietungssituation",
  "REPORT_EXPORT_EVP": "(de) Reporting Entwicklung des Immobilienportfolios",
  "REPORT_EXPORT_FOS": "(de) Reporting Anlegerdaten Hochladen",
  "REPORT_EXPORT_FUN": "(de) Reporting Fonds-Datenblatt Export",
  "REPORT_EXPORT_Glo": "(de) Reporting Allgemeine Einstellungen",
  "REPORT_EXPORT_HOT": "(de) Reporting Liste der nicht ausgezogenen Mietern",
  "REPORT_EXPORT_LEM": "(de) Reporting Mietsteigerung und Mietanpassungspotenzial",
  "REPORT_EXPORT_LES": "(de) Reporting Nächstmögliche Kündigungstermine",
  "REPORT_EXPORT_MDM": "(de) Reporting Datenmapping erstellen",
  "REPORT_EXPORT_MIT": "(de) Reporting (de) Manage Interfaces",
  "REPORT_EXPORT_OPB": "(de) Reporting Entwicklung der Zahlungsbilanzen und der bezahlten Beträge",
  "REPORT_EXPORT_OPM": "(de) Reporting Übersicht der ausstehenden Zahlungen",
  "REPORT_EXPORT_OUT": "(de) Reporting Fälligkeitsliste",
  "REPORT_EXPORT_PAT": "(de) Reporting Laufende Verkäufe",
  "REPORT_EXPORT_PBA": "(de) Reporting Objekt-Ebene Saldenbilanz",
  "REPORT_EXPORT_POB": "(de) Reporting Aufteilung des Immobilienportfolios",
  "REPORT_EXPORT_PPT": "(de) Reporting Laufende Erwerbe",
  "REPORT_EXPORT_PVA": "(de) Reporting Wertberichtigung der Objekte",
  "REPORT_EXPORT_RES": "(de) Reporting Detaillierte Vermietungssituation",
  "REPORT_EXPORT_RIM": "(de) Reporting Überwachung der Soll-Mieteinnahmen",
  "REPORT_EXPORT_RLE": "(de) Reporting Mietvertragsdaten ersetzen",
  "REPORT_EXPORT_RPA": "(de) Reporting Verkäufe erstellen",
  "REPORT_EXPORT_RPB": "(de) Reporting Objekt-Repositorium",
  "REPORT_EXPORT_RPF": "(de) Reporting Fonds-Repositorium",
  "REPORT_EXPORT_RPM": "(de) Reporting Marktmiete erstellen",
  "REPORT_EXPORT_RPN": "(de) Reporting Netto-Buchwert erstellen",
  "REPORT_EXPORT_RPP": "(de) Reporting Erwerbe erstellen",
  "REPORT_EXPORT_RPT": "(de) Reporting Berichtsvorlage erstellen",
  "REPORT_EXPORT_RPV": "(de) Reporting Bewertung-Daten erstellen",
  "REPORT_EXPORT_RPY": "(de) Reporting Rendite erstellen",
  "REPORT_EXPORT_SDG": "(de) Reporting Security Deposits Guarantees",
  "REPORT_EXPORT_TEM": "(de) Reporting Neuvermietungen, Verlängerungen und Kündigungen",
  "REPORT_EXPORT_TET": "(de) Reporting Neuvermietungs- und Kundigungsquoten",
  "REPORT_EXPORT_TRH": "(de) Reporting Überblick der Transaktionsvolumen",
  "REPORT_EXPORT_TS": "(de) Reporting Tenancy Schedule",
  "REPORT_EXPORT_USL": "(de) Reporting Benutzer erstellen",
  "REPORT_EXPORT_VAC": "(de) Reporting Leerstandsanalyse",
  "REPORT_EXPORT_VAU": "(de) Reporting Leerstehende Mieteinheiten",
  "REPORT_EXPORT_YIM": "(de) Reporting Rendite-Analyse",
  "REPORT_INTERFACE": "Mieterliste",
  "REPOSITORY ARBITRAGE": "Verkauf-Nummer",
  "REPOSITORY BUILDING": "Objekt-Repositorium",
  "REPOSITORY FUND": "Fonds-Repositorium",
  "REPOSITORY PORTFOLIO": "Portfolio-Repositorium",
  "REPOSITORY PURCHASE": "Erwerb-Repositorium",
  "REPOSITORY SECTOR": "Marktsegment-Repositorium",
  "REPOSITORY_ARBITRAGE": "Verkauf-Nummer",
  "REPOSITORY_BUILDING": "Objekt-Repositorium",
  "REPOSITORY_FUND": "Fonds-Repositorium",
  "REPOSITORY_PORTFOLIO": "Portfolio-Repositorium",
  "REPOSITORY_PURCHASE": "Erwerb-Repositorium",
  "REPOSITORY_SECTOR": "Marktsegment-Repositorium",
  "RESERVE FOR DEPRECIATION": "Abschreibung (€)",
  "RESERVE_FOR_DEPRECIATION": "Abschreibung (€)",
  "RESIDENTIAL ABATEMENT": "Mietfreie Zeit - Betrag (€)",
  "RESIDENTIAL ANNUAL AMOUNT": "Jährlicher Betrag (€/Jahr)",
  "RESIDENTIAL AREA": "Wohnfläche (m²)",
  "RESIDENTIAL GROSS HEADLINE RENT": "Vertragsmiete (VM) (€/Jahr)",
  "RESIDENTIAL GROSS HEADLINE RENT M2": "Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "RESIDENTIAL REAL ESTATE": "Wohnimmobilie",
  "RESIDENTIAL RENTED AREA": "Vermietete Fläche (m²)",
  "RESIDENTIAL TOTAL AREA": "Gesamte vermietbare Fläche (m²)",
  "RESIDENTIAL VACANT AREA": "Leerstandsfläche (m²)",
  "RESIDENTIAL_AREA": "Wohnfläche (m²)",
  "RESULT PENDING ASSIGN BENEFIT": "Nicht verteilter Jahreserfolg (€)",
  "RESULT PENDING ASSIGN DEFICIT": "Nicht verteilter Jahresfehlbetrag (€)",
  "RESULT_PENDING_ASSIGN_BENEFIT": "Nicht verteilter Jahreserfolg (€)",
  "RESULT_PENDING_ASSIGN_DEFICIT": "Nicht verteilter Jahresfehlbetrag (€)",
  "RETAIL AREA": "Ladenfläche (m²)",
  "RETAIL_AREA": "Ladenfläche (m²)",
  "RETAINED EARNINGS": "Ergebnisvortrag / Gewinn-/Verlustvortrag (€)",
  "RETAINED_EARNINGS": "Ergebnisvortrag / Gewinn-/Verlustvortrag (€)",
  "RETURN ON EQUITY LEVERED": "Gesamtkapitalrendite",
  "RETURN ON EQUITY UNLEVERED": "Eigenkapitalrendite",
  "REVERSION TO RENTAL MARKET VALUE": "Mietanpassung auf Marktniveau",
  "REVERSIONARY POTENTIAL": "Mietanpassungspotenzial",
  "REVERSIONARY YIELD": "Netto-Anpassungsrendite auf Basis MM",
  "REVERSIONARY_POTENTIAL": "Mietanpassungspotenzial",
  "REVISION PERIODICITY CODE": "Häufigkeit der Indexanpassung",
  "REVISION_PERIODICITY_CODE": "(de) Rent Review Frequency Code",
  "RISK NEXT DATE": "Nächstmöglicher Kündigungstermin",
  "ROW LABEL": "(de) Row label",
  "ROW_CODE": "(de) Row code",
  "ROW_LABEL": "(de) Row label",
  "RRE ABATEMENT": "Wohnimmobilie - Mietfreie Zeit - Betrag (€)",
  "RRE GROSS HEADLINE RENT": "Wohnimmobilie - Vertragsmiete (VM) (€/Jahr)",
  "RRE GROSS HEADLINE RENT M2": "Wohnimmobilie - Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "RRE RENTED AREA": "Wohnimmobilie - Vermietete Fläche (m²)",
  "RRE TOTAL AREA": "Wohnimmobilie - Gesamtfläche (m²)",
  "RRE VACANT AREA": "Wohnimmobilie - Leerstandsfläche (m²)",
  "RRE_ABATEMENT": "Wohnimmobilie - Mietfreie Zeit (€)",
  "RRE_GROSS_HEADLINE_RENT": "Wohnimmobilie - Vertragsmiete (VM) (€/Jahr)",
  "RRE_GROSS_HEADLINE_RENT_M2": "Wohnimmobilie - Vertragsmiete (VM) pro m² (€/m²/Jahr)",
  "RRE_OCCUPIED_AREA_SQM": "Wohnimmobilie - Vermietete Fläche (m²)",
  "RRE_TOTAL_AREA_SQM": "Wohnimmobilie - Gesamtfläche (m²)",
  "RRE_VACANT_AREA_SQM": "Wohnimmobilie - Leerstandsfläche (m²)",
  "SALE ACT SIGNING DATE": "Datum Eigentumsübertragung",
  "SALE AGREEMENT DATE": "Datums des Kaufvertrags",
  "SALE DECISION DATE": "Entscheidungsdatum des Verkaufs",
  "SALE EXPECTED DATE": "Voraussichtliches Verkaufsdatum",
  "SALE EXPECTED PRICE": "Voraussichtlicher Verkaufspreis (€)",
  "SALE PRICE AMOUNT": "Verkaufspreis (€)",
  "SALE PROGRES STATE": "Verkaufsstatus",
  "SALE VACANT AREA": "Nach Verkauf leerstehende Leerstandsfläche (m²)",
  "SALE VACANT AREA RATE": "% des gesamten Leerstands",
  "SALE_ACT_SIGNING_DATE": "Datum Eigentumsübertragung",
  "SALE_ACT_SIGNING_DATE_mismatch": "Datum Eigentumsübertragung ungültig",
  "SALE_ACT_SIGNING_DATE_required": "Datum Eigentumsübertragung erforderlich",
  "SALE_AGREEMENT_DATE": "Datums des Kaufvertrags",
  "SALE_AGREEMENT_DATE_mismatch": "Datum des Kaufvertrags ungültig",
  "SALE_AGREEMENT_DATE_required": "Datum des Kaufvertrags erforderlich",
  "SALE_COMMITMENT_DATE": "Datum der Kaufverpflichtung",
  "SALE_COMMITMENT_DATE_mismatch": "Datum der Kaufverpflichtung ungültig",
  "SALE_DECISION_DATE": "Entscheidungsdatum des Verkaufs",
  "SALE_DECISION_DATE_mismatch": "Entscheidungsdatum des Verkaufs ungültig",
  "SALE_EXPECTED_DATE": "Voraussichtliches Verkaufsdatum",
  "SALE_EXPECTED_PRICE": "Voraussichtlicher Verkaufspreis (€)",
  "SALE_EXPECTED_PRICE_mismatch": "Voraussichtlicher Verkaufspreis ungültig",
  "SALE_EXPECTED_PRICE_WOU": "Voraussichtlicher Verkaufspreis",
  "SALE_PRICE_AMOUNT": "Verkaufspreis (€)",
  "SALE_PRICE_AMOUNT_mismatch": "Verkaufspreis ungültig",
  "SALE_PRICE_AMOUNT_WOU": "Verkaufspreis",
  "SALE_VACANT_AREA": "Nach Verkauf leerstehende Leerstandsfläche (m²)",
  "SALE_VACANT_AREA_RATE": "% des gesamten Leerstands (%)",
  "SEARCH": "Suchen",
  "SEARCH CRITERIA": "Suchkriterien",
  "SECTOR CODE": "Marktsegment-Nummer",
  "SECTOR LABEL": "Marktsegment",
  "SECTOR_CODE": "Marktsegment-Nummer",
  "SECURIT DEPOSIT IN MONTH": "(de) Security deposit in month",
  "SECURIT_DEPOSIT_IN_MONTH": "(de) Security deposit in month",
  "SECURITY DEPOSIT BANK ACCOUNT": "Bankkonto-Sicherheitsleistung",
  "SECURITY DEPOSIT CALLS": "(de) Security deposit call (€)",
  "SECURITY DEPOSIT RECEIVABLE": "(de) Security deposit receivable (€)",
  "SECURITY DEPOSIT REFUNDS": "(de) Security deposit refunds (€)",
  "SECURITY DEPOSITS GUARANTEES": "(de) Security Deposits Guarantees",
  "SECURITY_DEPOSIT_CALLS": "(de) Security deposit call (€)",
  "SECURITY_DEPOSIT_RECEIVABLE": "(de) Security deposit receivable (€)",
  "SECURITY_DEPOSIT_REFUNDS": "(de) Security deposit refunds (€)",
  "SelectLaunchDate": "(de) Sélectionner une date de lancement pour l’interface spécifique",
  "SelectReportModel": "(de) Sélectionner un modèle de rapport standard",
  "SELLER CHARGED NET PRICE": "Vom Verkäufer angebotener Nettopreis (€)",
  "SELLER NAME": "Verkäufer",
  "SELLER_CHARGED_NET_PRICE": "Vom Verkäufer angebotener Nettopreis (€)",
  "SELLER_CHARGED_NET_PRICE_mismatch": "Vom Verkäufer angebotener Nettopreis ungültig",
  "SELLER_CHARGED_NET_PRICE_WOU": "Vom Verkäufer angebotener Nettopreis",
  "SELLER_NAME": "Verkäufer",
  "SEPTEMBER_AMOUNT": "September (€)",
  "SERVICE CHARGES": "Nebenkostenvorauszahlung (NK-VZ) (€/Jahr)",
  "SERVICE CHARGES CAPS": "NK-VZ-Deckelung (€/Jahr)",
  "SERVICE CHARGES CAPS M2": "NK-VZ-Deckelung pro m² (€/m²/Jahr)",
  "SERVICE CHARGES M2": "Nebenkostenvorauszahlung (NK-VZ) pro m² (€/m²/Jahr)",
  "SERVICE LABEL": "(de) Service Label",
  "SERVICE_CHARGES": "Nebenkostenvorauszahlung (NK-VZ) (€/Jahr)",
  "SERVICE_CHARGES_CAPS": "NK-VZ-Deckelung (€/Jahr)",
  "SERVICE_CHARGES_CAPS_M2": "NK-VZ-Deckelung pro m² (€/m²/Jahr)",
  "SERVICE_CHARGES_M2": "Nebenkostenvorauszahlung (NK-VZ) pro m² (€/m²/Jahr)",
  "SERVICE_CODE": "(de) Service Code",
  "SERVICE_LABEL": "(de) Service Label",
  "SHARE ACQUISITION PRICE": "Ausgabepreis (€)",
  "SHARE CAPITAL": "Gezeichnetes Kapital (€)",
  "SHARE PREMIUM": "Kapitalrücklage (€)",
  "SHARE PREMIUM DISTRIBUTED": "Ausschüttung aus Kapitalrücklage (€)",
  "SHARE_PREMIUM": "Kapitalrücklage (€)",
  "SHARE_WITH_OTHER_USERS": "Vorlage mit anderen Benutzern teilen",
  "SHAREHOLDER CURRENT ACCOUNT": "Kontokorrent des Anteilseigners / Anteilsinhabers (€)",
  "SHAREHOLDER CURRENT ACNT": "Kontokorrent (wenn Anteilseigner)",
  "SHAREHOLDER LOANS CONTRIBUT": "Gesellschafterdarlehen-Konto (wenn Anteilseigner)",
  "SHAREHOLDER LOANS CONTRIBUTED": "Gesellschafterdarlehen des Anteilseigners / Anteilsinhabers (€)",
  "SHAREHOLDER_CURRENT_ACCOUNT": "Kontokorrent des Anteilseigners / Anteilsinhabers  (€)",
  "SHAREHOLDER_LOAN_ACCOUNT": "Gesellschafterdarlehen des Anteilseigners / Anteilsinhabers (€)",
  "SHAREHOLDER_LOANS_CONTRIBUTE": "Gesellschafterdarlehen des Anteilseigners / Anteilsinhabers (€)",
  "SHAREHOLDER_LOANS_CONTRIBUTED": "Gesellschafterdarlehen des Anteilseigners / Anteilsinhabers (€)",
  "SHOW_ALL": "Alles",
  "SIMPLIFIED P AND L": "Ertrags- und Aufwandsrechnung",
  "SIRET NUMBER": "Unternehmenscode",
  "SIRET_NUMBER": "Unternehmenscode",
  "SITE NAME": "(de) Site name",
  "SITE_CODE": "(de) Site Code",
  "SITE_NAME": "(de) Site name",
  "SOCIAL DATA": "Sozialdaten",
  "SOCIAL_DATA": "Sozialdaten",
  "SOLD_OWNER_CODE": "Eigentümer-Nummer",
  "SOLD_OWNER_NAME": "Eigentümer-Name",
  "SPACE PRODUCTIVITY": "Quadratmeterumsatz (€/m²/Jahr)",
  "SPACE_PRODUCTIVITY": "Quadratmeterumsatz (€/m²/Jahr)",
  "SQM": "m²",
  "ST1": "Sub Total 1 (de)",
  "ST2": "Sub Total 2 (de)",
  "ST3": "Sub Total 3 (de)",
  "STAIRCASE": "Treppenhaus-Nummer",
  "START MANAGEMENT DATE": "Betriebsbeginn",
  "START_DATE": "(de) Start Date",
  "START_MANAGEMENT_DATE": "Betriebsbeginn",
  "START_MANAGEMENT_DATE_mismatch": "Betriebsbeginn ungültig",
  "STATUORY RESERVES": "Satzungsgemäße Rücklagen und Rücklagen für eigene Aktien (€)",
  "STATUORY_RESERVES": "Satzungsgemäße Rücklagen und Rücklagen für eigene Aktien (€)",
  "STATUS LEASE": "(de) Lease Status",
  "STATUS PURCHASE": "Status",
  "STATUS SALE": "Erwerbsstatus",
  "STATUS_LEASE": "(de) Lease Status",
  "STATUT": "Erwerbsstatus",
  "STATUTORY INTERESTS": "Gesetzliche Zinsen (€)",
  "STEP 1 START DATE": "Erhöhungs-termin - Staffel 1",
  "STEP 2 START DATE": "Erhöhungs-termin - Staffel 2",
  "STEP 3 START DATE": "Erhöhungs-termin - Staffel 3",
  "STEP 4 START DATE": "Erhöhungs-termin - Staffel 4",
  "STEP 5 START DATE": "Erhöhungs-termin - Staffel 5",
  "STEP 6 START DATE": "Erhöhungs-termin - Staffel 6",
  "STEP_1_START_DATE": "Erhöhungs-termin - Staffel 1",
  "STEP_2_START_DATE": "Erhöhungs-termin - Staffel 2",
  "STEP_3_START_DATE": "Erhöhungs-termin - Staffel 3",
  "STEP_4_START_DATE": "Erhöhungs-termin - Staffel 4",
  "STEP_5_START_DATE": "Erhöhungs-termin - Staffel 5",
  "STEP_6_START_DATE": "Erhöhungs-termin - Staffel 6",
  "STEPPED RENT": "(de) Stepped Rent (yes/no)?",
  "STEPPED RENT 1": "Staffelmiete 1 (€)",
  "STEPPED RENT 2": "Staffelmiete 2 (€)",
  "STEPPED RENT 3": "Staffelmiete 3 (€)",
  "STEPPED RENT 4": "Staffelmiete 4 (€)",
  "STEPPED RENT 5": "Staffelmiete 5 (€)",
  "STEPPED RENT 6": "Staffelmiete 6 (€)",
  "STEPPED_RENT": "(de) Stepped Rent (yes/no)?",
  "STEPPED_RENT_1": "Staffelmiete 1 (€)",
  "STEPPED_RENT_2": "Staffelmiete 2 (€)",
  "STEPPED_RENT_3": "Staffelmiete 3 (€)",
  "STEPPED_RENT_4": "Staffelmiete 4 (€)",
  "STEPPED_RENT_5": "Staffelmiete 5 (€)",
  "STEPPED_RENT_6": "Staffelmiete 6 (€)",
  "STORAGE AREA": "Lagerfläche (m²)",
  "STORAGE_AREA": "Lagerfläche (m²)",
  "STRUCTURE CAPITAL": "Kapitalstruktur",
  "SUB_BUDGET_CODE": "(de) Sub-Budget Code",
  "SUB_BUDGET_LABEL": "(de) Sub-Budget Label",
  "SUB_DESTINATION_CODE": "(de) Sub-destination Code",
  "SUB_DESTINATION_LABEL": "(de) Sub-destination Label",
  "SUBMISSION_DATE": "(de) Sending Date",
  "SubmissionDate": "(de) Submission Date",
  "SubmissionDate1": "Absendedatum 1",
  "SubmissionDate2": "Absendedatum 2",
  "SUBSIDIARY OF GROUP": "Tochtergesellschaft",
  "SUBSIDIARY_OF_GROUP": "Tochtergesellschaft",
  "SUSPENSE ACCOUNT": "Zwischenkonto (€)",
  "SUSPENSE_ACCOUNT": "Zwischenkonto (€)",
  "tabCollectionMonitoringBuilding": "Nach Objekt",
  "tabCollectionMonitoringBuildingNature": "Nach Objekt",
  "tabCollectionMonitoringBuildingNatureSum": "Nach Objekt-Nutzungsart kumuliert",
  "tabCollectionMonitoringBuildingSum": "Nach Objekt kumuliert",
  "tabCollectionMonitoringFund": "(de) By Fund Entity Name",
  "tabCollectionMonitoringFundSum": "(de) By Fund Entity Name Running Total",
  "tabCollectionMonitoringInvoice": "(de) By Invoice",
  "tabCollectionMonitoringInvoiceSum": "(de) By Invoice Running Total",
  "tabCollectionMonitoringInvoicingItem": "Nach Rechnungsposten",
  "tabCollectionMonitoringInvoicingItemSum": "Nach Rechnungsposten kumuliert",
  "tabCollectionMonitoringLease": "Nach Mietvertrag",
  "tabCollectionMonitoringLeaseSum": "Nach Mietvertrag kumuliert",
  "tabCollectionMonitoringOwner": "(de) By Owner",
  "tabCollectionMonitoringOwnerSum": "(de) By Owner Running Total",
  "tabCollectionMonitoringPortfolio": "(de) By Portfolio",
  "tabCollectionMonitoringPortfolioSum": "(de) By Portfolio Running Total",
  "tabHoldoverTenancyBuildingNature": "Nach Objekt-Nutzungsart",
  "tabHoldoverTenancyLeaseNature": "Nach Mietvertragstyp",
  "TABLE BY BUILDING NATURE": "Diagramm nach Objekt-Nutzungsart",
  "TABLE BY PROPERTY TYPE": "Diagramm nach Immobilientyp",
  "TABLE NAME": "Datenbanktabellename",
  "TABLE_NAME": "Datenbanktabellename",
  "Table1Attr1": "Objekt-Name",
  "Table1Attr2": "Adresse",
  "Table1Attr3": "Portfolio",
  "Table1Attr4": "Objekt-Überblick zum",
  "Table1Attr5": "Objekt-Nummer",
  "Table2Attr1": "Objekt-Nutzungsart",
  "Table2Attr10": "Gesamte Nettogeschossfläche (NGF)",
  "Table2Attr11": "Brutto-Verkehrswert",
  "Table2Attr12": "Nettowohnfläche",
  "Table2Attr13": "Anzahl von Mietern",
  "Table2Attr14": "Nutzfläche",
  "Table2Attr15": "Anzahl von Mietverträgen",
  "Table2Attr16": "Gewichtete Nutzfläche",
  "Table2Attr17": "Anzahl von Mieteinheiten",
  "Table2Attr18": "Bruttomietfläche",
  "Table2Attr19": "Anzahl von Stellplätzen",
  "Table2Attr2": "Baujahr",
  "Table2Attr3": "Andere Nutzung(en)",
  "Table2Attr4": "Erwerbsdatum",
  "Table2Attr5": "Anzahl von Gebäuden",
  "Table2Attr6": "Eigentumsverhältnis",
  "Table2Attr7": "Anzahl von Geschossen",
  "Table2Attr8": "Netto-Verkehrswert",
  "Table2Attr9": "zum",
  "Table3Attr2": "Incentivekosten (IncK)",
  "Table3Attr4": "Restzahlung",
  "Table3Attr5": "Eingezogene Miete",
  "Table3Attr8": "Doppel-Nettomiete",
  "Table4Attr1": "Nicht umlegbare Bewirtschaftungskosten (nBWK)",
  "Table4Attr2": "Umlegbare Bewirtschaftungskosten (BWK)",
  "Table4Attr3": "Darunter : Umgelegt",
  "Table4Attr4": "Darunter : Nicht umgelegt",
  "Table4Attr5": "Gesamtbewirtschaftungskosten",
  "Table4Attr6": "Nebenkostenvorauszahlung",
  "tabLeaseMonitoringBuildingNature": "Nach Objekt-Nutzungsart",
  "tabLeaseMonitoringLeaseNature": "Nach Mietvertragstyp",
  "tabPhysicalVacancyByM2Financial": "Leerstandsmiete",
  "tabPhysicalVacancyByM2M2": "Leerstandsfläche",
  "tabRealEstatePortfolioBreakdownByNature": "Diagramm nach Objekt-Nutzungsart",
  "tabRealEstatePortfolioBreakdownByType": "Diagramm nach Immobilientyp",
  "tabRealEstatePortfolioEvolutionBuildingNature": "Nach Objekt-Nutzungsart",
  "tabRealEstatePortfolioEvolutionYear": "Nach Jahr",
  "tabRegularChargesMonitoringBuilding": "Nach Objekt",
  "tabRegularChargesMonitoringBuildingNature": "Nach Objekt-Nutzungsart",
  "tabRegularChargesMonitoringInvoicingItem": "Nach Rechnungsposten",
  "tabRegularChargesMonitoringLease": "Nach Mietvertrag",
  "tabRentalIncomeMonitoringBuildingNature": "Nach Objekt-Nutzungsart",
  "tabRentalIncomeMonitoringYear": "Nach Jahr",
  "tabYieldMonitoring": "Rendite-Analyse",
  "tabYieldMonitoringCapiGross": "Brutto-Kapitalisierungszinssätze",
  "tabYieldMonitoringGlobalReturns": "Total Returns",
  "tabYieldMonitoringGross": "Bruttorendite",
  "tabYieldMonitoringNet": "Nettorendite",
  "TARGET YIELD": "Zielrendite",
  "TAX": "Steuer",
  "TAX EXEMPTION": "(de) Tax Exemption (yes/no)?",
  "TAX_EXEMPTION": "(de) Tax Exemption (yes/no)?",
  "tbFundDataStartDate_mismatch": "Aktualisierungsdatum ist ungültig",
  "tbFundDataStartDate_required": "Das Aktualisierungsdatum ist erforderlich",
  "tbInceptionDate_mismatch": "Das eingegebene Datum Fondsauflage ist ungültig",
  "tbInceptionDate_required": "Das Datum Fondsauflage ist erforderlich",
  "TEN GONE NO LIT OUT PAY RATE": "Rückstandsquote der sonstigen ausgezogenen Mieter",
  "TEN PRES NO LIT OUT PAY RATE": "Rückstandsquote der sonstigen vorhandenen Mieter",
  "TEN PRESENT LIT OUT PAY RATE": "Rückstandsquote der vorhandenen Mieter mit schwebendem Verfahren",
  "TEN_GONE_NO_LIT_OUT_PAY_RATE": "Rückstandsquote der sonstigen ausgezogenen Mieter",
  "TEN_PRES_NO_LIT_OUT_PAY_RATE": "Rückstandsquote der sonstigen vorhandenen Mieter",
  "TEN_PRESENT_LIT_OUT_PAY_RATE": "Rückstandsquote der vorhandenen Mieter mit schwebendem Verfahren",
  "TENANCY MONITORING": "Neuvermietungen, Verlängerungen und Kündigungen",
  "TENANT": "Mieter",
  "TENANT ARRIVAL DATE": "Mieter-Einzugsdatum",
  "TENANT CODE": "Mieter-Nummer",
  "TENANT EXIT DATE": "Mieter-Auszugsdatum",
  "TENANT GONE LIT OUT PAY RATE": "Rückstandsquote der ausgezogenen Mieter mit schwebendem Verfahren",
  "TENANT GONE OUT PAY RATE": "Rückstandsquote der ausgezogenen Mieter",
  "TENANT INDUSTRY": "Branchen-Schlüssel",
  "TENANT NAME": "Mieter-Name",
  "TENANT PRESENCE": "Mieter vorhanden bzw. ausgezogen?",
  "TENANT PRESENT OUT PAY RATE": "Rückstandsquote der vorhandenen Mieter",
  "TENANT TOTAL": "Gesamt",
  "TENANT TURNOVER": "Neuvermietungs- und Kundigungsquoten",
  "TENANT_ADDRESS1": "Vorheriger Adresse",
  "TENANT_ADDRESS2": "Vorheriger Andere Adresse",
  "TENANT_ARRIVAL_DATE": "Mieter-Einzugsdatum",
  "TENANT_CITY": "Vorheriger Stadt",
  "TENANT_CODE": "Mieter-Nummer",
  "TENANT_COUNTRY": "Vorheriger Land",
  "TENANT_EXIT_DATE": "Mieter-Auszugsdatum",
  "TENANT_GONE_LIT_OUT_PAY_RATE": "Rückstandsquote der ausgezogenen Mieter mit schwebendem Verfahren",
  "TENANT_GONE_OUT_PAY_RATE": "Rückstandsquote der ausgezogenen Mieter",
  "TENANT_HOLDING_COMPANY": "Vorheriger Dachgesellschaft",
  "TENANT_IMPROVEMENTS": "Mieterausbauten",
  "TENANT_IMPROVEMENTS_ACCUMULATED_DEPRECIATION": "Kumulierte Abschreibung - Mieterausbauten",
  "TENANT_INDUSTRY": "Branchen-Schlüssel",
  "TENANT_INDUSTRY_CODE": "(de) Tenant Industry Code",
  "TENANT_NAME": "Mieter-Name",
  "TENANT_POSTAL_CODE": "Vorheriger Postleitzahl",
  "TENANT_PRESENCE": "Mieter vorhanden bzw. ausgezogen?",
  "TENANT_PRESENT_OUT_PAY_RATE": "Rückstandsquote der vorhandenen Mieter",
  "TENANTS": "Mieter",
  "TENURE": "Eigentumsverhältnis",
  "TERM": "(de) Terme quittancé",
  "TERM DATE": "Fakturierungsdatum",
  "TERM_DATE": "Fakturierungsdatum",
  "TERMINATION DATE": "Datum Fondsauflösung oder Verkauf",
  "TERMINATION_DATE": "Datum Fondsauflösung oder Verkauf",
  "tfColumnLabel": "Bezeichnung der Säule",
  "tfColumnName": "Name der Säule",
  "tfExpressionVal": "Grundlegender Ausdruck",
  "tfMappingVal": "Mapping",
  "THIRD PARTY FEES AMOUNT": "Nebenkosten (Makler, usw.) (€)",
  "THIRD_PARTY_FEES_AMOUNT": "Nebenkosten (Makler WHERE CODE =  usw.) (€)",
  "THIRD_PARTY_FEES_AMOUNT_mismatch": "Nebenkosten ungültig",
  "THIRD_PARTY_FEES_AMOUNT_WOU": "Nebenkosten (Makler, usw.)",
  "THOUSANDS": "(de) Thousands",
  "titleCreatUpdateReportExcel": "(de) CREER/MODIFIER L’ENVOI D’UN REPORTING EXCEL/PDF",
  "titleInterfaceAccounting": "(de) Create or update the data stream - Accounting",
  "titleInterfaceLowCode": "Den Datenstrom hinfügen oder aktualisieren",
  "titleInterfaceSpecific": "Die spezifische Schnittstelle ändern",
  "titleInterfaceTenancySchedule": "Den Datenstrom hinfügen oder aktualisieren - Mieterliste",
  "TOP ADVANCE PAY": "(de) Overpayments Ranking (K€) >",
  "TOP LEVEL BUDGET SECTION": "Budget-Kategorie",
  "TOP OUTSTAND PAY": "(de) Outstanding Payment Ranking (K€) >",
  "TOP_ADVANCE_PAY": "(de) Overpayments Ranking > {0} K€",
  "TOP_LEVEL_BUDGET_SECTION": "Budget-Kategorie",
  "TOP_OUTSTAND_PAY": "(de) Outstanding Payment Ranking > {0} K€",
  "TOTAL": "Gesamt",
  "TOTAL ACCUMULATED DEPRECIATION": "Gesamte kumulierte Abschreibung (€)",
  "TOTAL AREA PERCENT": "% der Gesamtfläche",
  "TOTAL CASH DISTRIBUTED": "Gesamt Ausschüttung (€)",
  "TOTAL EQUITY CONTRIBUTED": "Gesamt Eigenkapital (€)",
  "TOTAL EQUITY FINANCING": "Gesamt Finanzierung (€)",
  "TOTAL GROSS DEBT": "Gesamt Fremdkapital (€)",
  "TOTAL INVESTMENT PRICE": "Ausgabepreis und Gesellschafterdarlehen (€)",
  "TOTAL INVESTMENT VALUE": "Gesamter Anlagewert (€)",
  "TOTAL INVESTOR DISTRIBUTIONS": "Gesamt Anleger erhaltene Auschüttungen (€)",
  "TOTAL LEASES NUMBER PERCENT": "% der gesamten Anzahl von Mietverträgen",
  "TOTAL NET FLOOR AREA": "Gesamte Nettogeschossfläche (NGF) (m²)",
  "TOTAL NET FLOOR AREA WOU": "Gesamte Nettogeschossfläche (NGF)",
  "TOTAL PORTFOLIO": "(de) Total Portfolio",
  "TOTAL RENT": "Gesamtmiete (€/Jahr)",
  "TOTAL RENT M2": "Gesamtmiete pro m² (€/m²/Jahr)",
  "TOTAL RENTAL EXPENSES": "Gesamtbewirtschaftungskosten (€/Jahr)",
  "TOTAL RENTAL EXPENSES WOU": "Gesamtbewirtschaftungskosten",
  "TOTAL RETURN": "Total Return (TR)",
  "TOTAL RETURN BREAKDOWN": "Verteilung des Total Returns",
  "TOTAL RETURN FUND": "Total Return (TR)",
  "TOTAL UNITS NUMBER PERCENT": "% der gesamten Anzahl von Mieteinheiten",
  "TOTAL UNREALIZED GAINS AND LOSSES": "Gesamte unrealisierte Gewinne und Verluste (€)",
  "TOTAL VACANCY": "Gesamter Leerstand",
  "TOTAL_ACCUMULATED_DEPRECIATION": "Gesamte kumulierte Abschreibung (€)",
  "TOTAL_AMOUNT": "Gesamt (€)",
  "TOTAL_AREA_PERCENT": "% der Gesamtfläche",
  "TOTAL_INVESTMENT_VALUE": "Gesamter Anlagewert (€)",
  "TOTAL_LEASES_NUMBER_PERCENT": "% der gesamten Anzahl von Mietverträgen",
  "TOTAL_RENT": "Gesamtmiete (€/Jahr)",
  "TOTAL_RENT_M2": "Gesamtmiete pro m² (€/m²/Jahr)",
  "TOTAL_RENTAL_EXPENSES": "Gesamtbewirtschaftungskosten (€/Jahr)",
  "TOTAL_RENTAL_EXPENSES_WOU": "Gesamtbewirtschaftungskosten",
  "TOTAL_RETURN": "Total Return (TR)",
  "TOTAL_SQM": "Gesamt (m²)",
  "TOTAL_UNITS_NUMBER_PERCENT": "% der gesamten Anzahl von Mieteinheiten",
  "TOTAL_UNREAL_GAINS_LOSSES": "Gesamte unrealisierte Gewinne und Verluste (€)",
  "TOTAL_UNREALIZED_GAINS_AND_LOSSES": "Gesamte unrealisierte Gewinne und Verluste (€)",
  "TOTAL_USABLE_FLOOR_AREA_SQM": "Gesamte Nutzfläche (m²)",
  "TRADE NAME": "Handelsname",
  "TRADE REGISTER NUMBER": "(de) Trade Register Number",
  "TRADE_NAME": "Handelsname",
  "TRADE_REGISTER_NUMBER": "(de) Trade Register Number",
  "TRANSFERED SHARES NUMBER": "Anzahl der verkauften Anteile",
  "TRANSFERED SHARES PERCENTAGE": "% der verkauften Anteile",
  "TRANSFERED_SHARES_NUMBER": "Anzahl der verkauften Anteile",
  "TRANSFERED_SHARES_NUMBER_mismatch": "Anzahl der verkauften Anteile ungültig",
  "TRANSFERED_SHARES_PERCENTAGE": "% der verkauften Anteile",
  "TRANSFERED_SHARES_PERCENTAGE_mismatch": "% der verkauften Anteile ungültig",
  "trend": "(en) Trend",
  "tt_DB_BUILDING_TOTAL_AREA_SQM": "Die Mietfläche von Objekten im Portfolio zum Stichtag",
  "tt_DB_BUILDINGSNUMBER": "Die Gesamtanzahl von Objekten im Portfolio",
  "tt_DB_DURATION_TO_LEASE_END": "Die mit der Miete gewichtete durchschnittliche Restlaufzeit bis zum Mietvertragsauslauf aller Mietverträge bis zum Auslauf, in Jahren-Monaten-Tagen ausgedrückt",
  "tt_DB_DURATION_TO_NEXT_BREAK": "Die mit der Miete gewichtete durchschnittliche Restlaufzeit aller Mietverträge bis zum nächstmöglichen Kündigungstermin, in Jahren-Monaten-Tagen ausgedrückt",
  "tt_DB_EFFECTIVE_GROSS_INITIAL_YIELD": "Das Verhältnis auf Portfolioebene der Brutto-Effektivmiete zum Brutto-Verkehrswert zum Stichtag",
  "tt_DB_EFFECTIVE_NET_INITIAL_YIELD": "Das Verhältnis auf Portfolioebene der Netto-Effektivmiete zum Brutto-Verkehrswert zum Stichtag",
  "tt_DB_FAIR_MARKET_RENT": "Die gesamte Marktmiete des Portfolios, sollten alle Mieteinheiten zur Marktmiete vermietet werden",
  "tt_DB_FINANCIAL_OCCUPANCY_RATE": "Das Verhältnis des gesamten Mietertrags unter Berücksichtigung von Mietanreizen und nicht umlegbaren Bewirtschaftungskosten zum potentiellen Mietertrag",
  "tt_DB_FINANCIAL_VACANCY": "Die Marktmiete der zum Stichtag leer stehenden Mieteinheiten",
  "tt_DB_FINANCIAL_VACANCY_RATE": "Das Verhältnis der Leerstandsmiete zum potentiellen Mietertrag",
  "tt_DB_GROSS_EFFECTIVE_RENT": "Der Mietertrag unter Berücksichtigung von Mietanreizen aber ohne Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "tt_DB_GROSS_HEADLINE_RENT": "Die gesamte Vertragsmiete des Portfolios ohne Berücksichtigung von Mietanreizen und von nicht umlegbaren Bewirtschaftungskosten",
  "tt_DB_GROSS_MARKET_VALUE": "Die gesamte Marktmiete aller Objekten im Portfolio zum Stichtag",
  "tt_DB_GROSS_POTENTIAL_RENT": "Die Summe von Effektivmiete und von Marktmiete der zum Stichtag leer stehenden Mieteinheiten ohne Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "tt_DB_GROSS_REVERS_POTENT_OCCUP_AREA": "Das Verhältnis der Marktmiete der zum Stichtag vermieteten Mieteinheiten zur Vertragsmiete, prozentual ausgedrückt",
  "tt_DB_GROSS_REVERS_POTENT_TOTAL_PROP": "Das Verhältnis der Marktmiete aller Mieteinheiten zur Vertragsmiete, prozentual ausgedrückt",
  "tt_DB_GROSS_REVERSIONARY_YIELD": "Das Verhältnis auf Portfolioebene der Marktmiete zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "tt_DB_HEADLINE_GROSS_INITIAL_YIELD": "Das Verhältnis auf Portfolioebene der Vertragsmiete zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "tt_DB_HEADLINE_NET_INITIAL_YIELD": "Das Verhältnis auf Portfolioebene der Vertragsmiete unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "tt_DB_MARKET_VALUE_EXCL_DUTIES_VAT": "Die Summe von zum Stichtag Netto-Verkehrswerten aller Objekten im Portfolio",
  "tt_DB_NET_EFFECTIVE_RENT": "Der gesamte Mietertrag aller Objekten im Portfolio unter Berücksichtigung von Mietanreizen und nicht umlegbaren Bewirtschaftungskosten",
  "tt_DB_NET_HEADLINE_RENT": "Der gesamte Mietertrag aller Objekten im Portfolio ohne Berücksichtigung von Mietanreizen aber unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "tt_DB_NET_MARKET_RENT": "Die gesamte Marktmiete aller Objekten im Portfolio unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "tt_DB_NET_POTENTIAL_RENT": "Die Summe von Effektivmiete und von Marktmiete der zum Stichtag leer stehenden Mieteinheiten unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "tt_DB_NET_REVERSIONARY_YIELD": "Das Verhältnis auf Portfolioebene der Netto-Marktmiete zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "tt_DB_NUMBER_OF_VACANT_UNITS": "Die Gesamtanzahl von zum Stichtag leer stehenden Mieteinheiten aller Objekten im Portfolio",
  "tt_DB_PHYSICAL_OCCUPANCY_RATE": "Das Verhältnis der zum Stichtag vermieteten Flächen zur gesamten Mietfläche aller Objekten im Portfolio, prozentual ausgedrückt",
  "tt_DB_POTENTIAL_GROSS_INITIAL_YIELD": "Das Verhältnis auf Portfolioebene der Brutto-Potentialmiete zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "tt_DB_POTENTIAL_NET_INITIAL_YIELD": "Das Verhältnis auf Portfolioebene der Potentialmiete unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "tt_DB_RENTED_AREA": "Die gesamte vermietete Fläche aller Objekten im Portfolio zum Stichtag",
  "tt_DB_VACANCYRATE": "Das Verhältnis auf Portfolioebene der leerstehenden Fläche zur gesamten Mietfläche aller Objekten im Portfolio zum Stichtag, prozentual ausgedrückt",
  "tt_DB_VACANT_AREA_SQM": "Die gesamte leerstehende Fläche aller Objekten im Portfolio zum Stichtag",
  "tt_FUND_GROSS_HEADLINE_RENT": "Die gesamte Vertragsmiete aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (anteilig)",
  "tt_OUT_INVOICING_INCL_TAX_AMOUNT": "Die Summer aller Rechnungsbeträge, die zum Berichtsstichtag erforderlich waren und noch nicht eingezogen waren",
  "tt_OUT_OUTSTAND_PAY_INC_TAX_AMOUNT": "Die Differenz zwischen Rechnungsbetrag und eingezogenen Betrag",
  "tt_OUT_OVERPAYMENTS_INC_TAX_AMOUNT": "Die Summer aller Rechnungsbeträge, die zum Enddatum des Berichts eingezogen waren aber die noch nicht eingezogen waren",
  "tt_OUT_PAYMENT_INCL_TAX_AMOUNT": "Die Summer aller Rechnungsbeträge, die zum Berichtsstichtag eingezogen waren",
  "tt_PVA_GROSS_BOOK_VALUE": "Die Summe vom Kaufpreis des Objekts und von zum Abschlussstichtag kumulierten Investitionskosten",
  "tt_TEMEND_CURRENT_ANNUAL_RENT_AMOUNT": "Die Vertragsmiete des letzten Mietvertrags zum Auszugsdatum der Mieter",
  "tt_TEMEND_RENTED_AREA": "Die vermietete Fläche des letzten Mietvertrags zum Auszugsdatum der Mieter",
  "tt_TEMRELETTING_PREV_LEASE_ANNUAL_RENT_AMOUNT": "Die Vertragsmiete des vorherigen Mietvertrags zum Auszugsdatum der Mieter",
  "tt_TEMRELETTING_PREVIOUS_LEASE_AREA_SQM": "Die vermietete Fläche des vorherigen Mietvertrags zum Auszugsdatum der Mieter",
  "tt_TEMRENEWAL_PREV_LEASE_ANNUAL_RENT_AMOUNT": "Die Vertragsmiete des vorherigen Mietvertrags zum Mietende",
  "tt_TEMRENEWAL_PREVIOUS_LEASE_AREA_SQM": "Die vermietete Fläche des vorherigen Mietvertrags zum Mietende",
  "tt_YIM_FAIR_MARKET_RENT": "Die gesamte Marktmiete des Objekts, sollten alle Mieteinheiten zur Marktmiete vermietet werden",
  "ttADJUSTED_NET_BOOK_VALUE": "Der Netto-Buchwert ohne Berücksichtigung von in Vorperioden erfassten Wertminderungsaufwände aber unter Berücksichtigung von benutzerdefinierter außerplanmäßigen Abschreibung",
  "ttANNUAL_FINANCIAL_VACANCY": "Die Marktmiete der zum Stichtag leer stehenden Mieteinheiten",
  "ttANNUAL_RENT_AMOUNT": "Die Vertragsmiete ohne Berücksichtigung von Mietanreizen wie Mietfreien Zeiten",
  "ttAVERAGE_VACANCY_RATE": "Die durschnittliche physische Leerstandsquote über die letzten 12 Monaten ab dem Stichtag",
  "ttBreakdownByPropertyType": "Der nach Objekt-Nutzungsart-aufgeteilte gesamte Netto-Verkehrswert aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (anteilig)",
  "ttBUILDING_TOTAL_AREA": "Die gesamte Mietfläche aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (anteilig)",
  "ttBUILDING_TOTAL_AREA_SQM": "Die gesamte Mietfläche des Objekts zum Stichtag",
  "ttBUILDINGS_COUNT": "Die Gesamtanzahl von durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (100%)",
  "ttCAPITAL_RETURN": "Das Verhältnis der Verkehrswertveränderung zwischen Betrachtungsperiode und Vorperiode zur Summe von Verkehrswert der Vorperiode und Investitionskosten der Betrachtungsperiode, prozentual ausgedrückt",
  "ttCashOnCashReturn": "Das Verhältnis der gesamten Ausschüttung der Gesellschaft zur von Anleger eingesetzten Kapitaleinlage (inklusive Gesellschafterdarlehen), prozentual ausgedrückt",
  "ttCOLLECTION_RATE": "Das Verhältnis des eingezogenen Betrags zum Rechnungsbetrag",
  "ttCURRENT_ANNUAL_RENT_AMOUNT": "Die Vertragsmiete ohne Berücksichtigung von Mietanreizen wie Mietfreien Zeiten",
  "ttDividendProfitDistributions": "Gewinn oder Verlust falls die Gesellschaft kein Steuerrechtssubjekt ist oder ausgeschüttete Dividenden falls die Gesellschaft Steuerrechtssubjekt ist",
  "ttDURATION": "Die Mietvertragsrestlaufzeit bis zum nächstmöglichen Kündigungstermin, in Jahren-Monaten-Tagen ausgedrückt",
  "ttDURATION_TO_BREAK_DATE": "Die Mietvertragsrestlaufzeit bis zum nächstmöglichen Kündigungstermin, in Jahren-Monaten-Tagen ausgedrückt",
  "ttDURATION_TO_LEASE_END": "Die Mietvertragsrestlaufzeit bis zum Mietende, in Jahren-Monaten-Tagen ausgedrückt",
  "ttECONOMIC_RENT": "Die durschnittliche über die Laufzeit bis zum nächstmöglichen Kündigungstermin verteilte Effektivmiete des Mietvertrags unter Berücksichtigung von Mietanreizen wie Mietfreien Zeiten",
  "ttECONOMIC_RENT_BY_HEADLINE_RENT": "Das Verhältnis der durschnittlichen über die Laufzeit verteilten Effektivmiete zur Vertragsmiete",
  "ttEFFECTIVE_GROSS_CAPITAL_RATE": "Das Verhältnis auf Objektebene der Effektivmiete ohne Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Netto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttEFFECTIVE_GROSS_INITIAL_YIELD": "Das Verhältnis auf Objektebene der Effektivmiete zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttEFFECTIVE_NET_CAPITAL_RATE": "Das Verhältnis auf Objektebene der Effektivmiete unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Netto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttEFFECTIVE_NET_INITIAL_YIELD": "Das Verhältnis auf Objektebene der Effektivmiete unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttEFFECTIVE_RENT": "Die Vertragsmiete unter Berücksichtigung von Mietanreizen wie Mietfreien Zeiten",
  "ttFAIR_MARKET_RENT": "Die gesamte Marktmiete des Objekts, sollten alle Mieteinheiten zur Marktmiete vermietet werden, ohne Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "ttFINANCIAL_OCCUPANCY_RATE": "Das Verhältnis des gesamten Mietertrags unter Berücksichtigung von Mietanreizen und nicht umlegbaren Bewirtschaftungskosten zum potentiellen Mietertrag",
  "ttFINANCIAL_VACANCY": "Die Marktmiete der zum Stichtag leer stehenden Mieteinheiten",
  "ttFINANCIAL_VACANCY_RATE": "Das Verhältnis der Leerstandsmiete zum potentiellen Mietertrag",
  "ttFinancialOccupancySummary": "Der Vermietungsstand nach Mietertrag aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (anteilig)",
  "ttFREED_LEASES_RATE": "Das Verhältnis der Anzahl von innerhalb der Periode gekündigten Mietverträgen zur zum Anfangsdatum Gesamtanzahl von Mietverträgen, prozentual ausgedrückt",
  "ttFREED_UNITS_AREA_RATE": "Das Verhältnis der Mietfläche von innerhalb der Periode gekündigten Mietverträgen zur zum Anfangsdatum gesamten vermietbaren Fläche, prozentual ausgedrückt",
  "ttFREED_UNITS_RATE": "Das Verhältnis der Anzahl von innerhalb der Periode gekündigten Mieteinheiten zur zum Anfangsdatum Anzahl von Mieteinheiten, prozentual ausgedrückt",
  "ttFundPhysicalOccRate": "Das Verhältnis der vermieteten Fläche zur gesamten Mietfläche aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (anteilig)",
  "ttGearingRatio": "Das Verhältnis in Prozent der von der Gesellschaft aufgenommenen Darlehen (ohne Gesellschafterdarlehen) zu ihrer Gesamtfinanzierung",
  "ttGeographicalBreakdown": "Der nach Standort-aufgeteilte gesamte Netto-Verkehrswert aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (anteilig)",
  "ttGR_HEAD_RENT_BY_GR_BOOK_VAL": "Das Verhältnis der gesamten seit Jahresbeginn fakturierten Miete zum Bruttobuchwert aller durch die ausgewählte Gesellschaft gehaltenen Objekten, prozentual ausgedrückt",
  "ttGROSS_BOOK_VALUE": "Der gesamte Netto-Buchwert aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (anteilig)",
  "ttGROSS_EFFECTIVE_RENT": "Der gesamte Mietertrag des Objekts unter Berücksichtigung von Mietanreizen aber ohne Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "ttGROSS_HEADLINE_RENT": "Die gesamte Vertragsmiete des Objekts ohne Berücksichtigung von Mietanreizen und von nicht umlegbaren Bewirtschaftungskosten",
  "ttGROSS_POTENTIAL_RENT": "Die Summe von Effektivmiete und von Marktmiete der zum Stichtag leer stehenden Mieteinheiten ohne Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "ttGROSS_REVERS_CAPITAL_RATE": "Das Verhältnis auf Objektebene der Marktmiete ohne Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Netto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttGROSS_REVERS_POTENT_OCCUP_AREA": "Das Verhältnis der Marktmiete der zum Stichtag vermieteten Mieteinheiten zur Vertragsmiete des Objekts, prozentual ausgedrückt",
  "ttGROSS_REVERS_POTENT_TOTAL_PROP": "Das Verhältnis der Marktmiete aller Mieteinheiten zur Vertragsmiete des Objekts, prozentual ausgedrückt",
  "ttGROSS_REVERSIONARY_YIELD": "Das Verhältnis auf Objektebene der Marktmiete und zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttGrossDebt": "Die offene Forderung aus von der Gesellschaft aufgenommenen Darlehen (ohne Gesellschafterdarlehen)",
  "ttGrossDebtUnderlyingVehicles": "Die offene Forderung aus von den Tochtergesellschaften aufgenommenen Darlehen (ohne Gesellschafterdarlehen)",
  "ttGrossReceiptedRent": "Die gesamte seit Jahresbeginn fakturierte Miete aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (anteilig)",
  "ttGrossReceiptedRentByGrossBookValue": "Das Verhältnis der gesamten seit Jahresbeginn fakturierten Miete zum Bruttobuchwert aller durch die ausgewählte Gesellschaft gehaltenen Objekten, prozentual ausgedrückt",
  "ttGrossReceiptedRentByNetMarketValue": "Das Verhältnis der gesamten seit Jahresbeginn fakturierten Miete zum Netto-Verkehrswert aller durch die ausgewählte Gesellschaft gehaltenen Objekten, prozentual ausgedrückt",
  "ttHEADLINE_GROSS_CAPITAL_RATE": "Das Verhältnis auf Objektebene der Vertragsmiete ohne Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Netto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttHEADLINE_GROSS_INITIAL_YIELD": "Das Verhältnis auf Objektebene der Vertragsmiete zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttHEADLINE_NET_CAPITAL_RATE": "Das Verhältnis auf Objektebene der Vertragsmiete unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Netto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttHEADLINE_NET_INITIAL_YIELD": "Das Verhältnis auf Objektebene der Vertragsmiete unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttHeadlineGrossInitialYield": "Das Verhältnis der Vertragsmiete zum Netto-Verkehrswert zum Stichtag der durch die ausgewählte Gesellschaft gehaltenen Objekten, prozentual ausgedrückt",
  "ttIMPAIRMENT_CHARGE": "Der Bruttobuchwert jedes Objekts abzüglich kumulierten Abschreibungen und in Vorperioden erfassten Wertminderungsaufwände zum Abschlussstichtag",
  "ttIMPAIRMENT_RATIO": "Das Verhältnis der unrealisierten Verluste aus direkt gehaltenen Objekte ohne Berücksichtigung von in Vorperioden erfassten Wertminderungsaufwände zum Netto-Buchwert",
  "ttINCENTIVE_RATE": "Das Verhältnis der über die Laufzeit bis zum nächstmöglichen Kündigungstermin verteilten Effektivmiete zur Vertragsmiete",
  "ttINCOME_RETURN": "Das Verhältnis der Nettomieteinnahmen der Betrachtungsperiode zur Summe von Verkehrswert der Vorperiode und Investitionskosten der Betrachtungsperiode",
  "ttIncomeReturn": "Das Verhältnis der Einkommen der Gesellschaft zur von Anleger eingesetzten Kapitaleinlage (inklusive Gesellschafterdarlehen)",
  "ttINITIAL_ANNUAL_RENT_AMOUNT": "Die Anfangsmiete ohne Berücksichtigung von Mietanreizen wie Mietfreien Zeiten",
  "ttINVESTOR_SHARE_OF_NAV": "Der Nettoinventarwert aller vom ausgewählten Anleger gehaltenen Anteile",
  "ttInvestorShareOfNAV": "Der Nettoinventarwert aller sich im Umlauf befindenden Anteile oder vom ausgewählten Anleger gehaltenen Anteile",
  "ttINVOICING_INCL_TAX_AMOUNT": "Die Summer aller Rechnungsbeträge, die innerhalb der Periode gebucht wurden",
  "ttLEASE_DURATION": "Die Mietvertragslaufzeit zwischen Mietbeginn und Mietende, in Jahren-Monaten-Tagen ausgedrückt",
  "ttLEASE_TURNOVER_RATE": "Die Summe von Neuvermietungsquote (Mietverträge) und Kündigungsquote (Mietverträge) dividiert durch 2",
  "ttLEASES_NUMBER": "Die zum Periodenanfang Gesamtanzahl von Mietverträgen",
  "ttlGenerateInterfaceACS": "Verwalten Buchhaltungsdatenstrom",
  "ttlGenerateInterfaceLRC": "Verwalten Rechnungsposten Schnittstelle",
  "ttlGenerateInterfaceTS": "Verwalten Mieterliste-Datenstrom",
  "ttLoanToValue": "Das Verhältnis in Prozent der aufgenommenen Darlehen (ohne Gesellschafterdarlehen) zum Verkehrswert der Objekten der Gesellschaft und allen ihren Tochtergesellschaften (anteilig)",
  "ttLOSS_BEFORE_RESERVE": "Gewinn-oder Verlustpotential bei Verkauf des Objekts, d.h. die Differenz zwischen Netto-Verkehrswert und Netto-Buchwert ohne Berücksichtigung von in Vorperioden erfassten Wertminderungsaufwände",
  "ttNET_ASSET_VALUE": "Summe des von Anleger eingesetzte Eigenkapitals und aller unrealisierten Gewinne/Verluste aus direkt gehaltenen Objekten und Beteiligungen an Tochtergesellschaften",
  "ttNET_ASSET_VALUE_ON_SPV": "Der Nettoinventarwert von Beteiligungen an Tochtergesellschaften, d.h. die Summe der Nettoinventarwerte (anteilig) aller direkt gehaltenen Tochtergesellschaften",
  "ttNET_ASSET_VALUE_PER_SHARE": "Das Verhältnis des Nettoinventarwerts zur Anzahl der sich im Umlauf befindenden Anteile",
  "ttNET_BOOK_VALUE_AFTER_IMPAIR": "Gewinn-oder Verlustpotential bei Verkauf des Objekts, d.h. die Differenz zwischen Netto-Verkehrswert und Netto-Buchwert unter Berücksichtigung von in Vorperioden erfassten Wertminderungsaufwände",
  "ttNET_BOOK_VALUE_BEFORE_RESERVE": "Der Bruttobuchwert jedes Objekts abzüglich kumulierten Abschreibungen zum Abschlussstichtag",
  "ttNET_BOOK_VALUE_ON_SPV": "Die Anschaffungskosten von Beteiligungen an Tochtergesellschaften",
  "ttNET_EFFECTIVE_RENT": "Der gesamte Mietertrag des Objekts unter Berücksichtigung von Mietanreizen und nicht umlegbaren Bewirtschaftungskosten",
  "ttNET_HEADLINE_RENT": "Der gesamte Mietertrag des Objekts ohne Berücksichtigung von Mietanreizen aber unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "ttNET_MARKET_RENT": "Die gesamte Marktmiete des Objekts unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "ttNET_POTENTIAL_RENT": "Die Summe von Effektivmiete und von Marktmiete der zum Stichtag leer stehenden Mieteinheiten unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten",
  "ttNET_REVERS_CAPITAL_RATE": "Das Verhältnis auf Objektebene der Marktmiete unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Netto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttNET_REVERSIONARY_YIELD": "Das Verhältnis auf Objektebene der Netto-Marktmiete zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttNetAssetValuePerShare": "Das Verhältnis des Nettoinventarwerts zur Anzahl der sich im Umlauf befindenden Anteile",
  "ttNetMarketValue": "Der gesamte Netto-Verkehrswert aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (anteilig)",
  "ttNetResultReturnOnGrossBookValue": "Das Verhältnis des Gewinns oder Verlusts nach Steuern der ausgewählten Gesellschaft zum Bruttobuchwert aller direkt gehaltenen Objekte, prozentual ausgedrückt",
  "ttNEW_LEASES_RATE": "Das Verhältnis der Anzahl von neuen Mietverträgen bei Neuvermietungen in der Periode zur zum Periodenanfang Gesamtanzahl von Mietverträgen",
  "ttNEW_UNITS_AREA_RATE": "Das Verhältnis der vermieteten Fläche von neuen Mietverträgen bei Neuvermietungen in der Periode zur zum Periodenanfang gesamten Mietfläche",
  "ttNEW_UNITS_RATE": "Das Verhältnis der Anzahl von vermieteten Mieteinheiten bei Neuvermietungen in der Periode zur zum Periodenanfang Gesamtanzahl von Mieteinheiten",
  "ttNUMBER_OF_LEASES": "Die gesamte Anzahl von Mietverträgen aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (ohne Berücksichtigung der Beteiligungshöhe der Gesellschaft an ihrer Tochtergesellschaften)",
  "ttNUMBER_OF_TENANTS": "Die gesamte Anzahl von Mietern aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (ohne Berücksichtigung der Beteiligungshöhe der Gesellschaft an ihrer Tochtergesellschaften)",
  "ttNUMBER_OF_UNITS": "Die gesamte Anzahl von Mieteinheiten aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (ohne Berücksichtigung der Beteiligungshöhe der Gesellschaft an ihrer Tochtergesellschaften)",
  "ttNUMBER_PARKING_SPACES": "Die gesamte Anzahl von Stellplätzen aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (ohne Berücksichtigung der Beteiligungshöhe der Gesellschaft an ihrer Tochtergesellschaften)",
  "ttOperatingReturnOnGrossBookValue": "Das Verhältnis des Nettobetriebserfolgs einer Gesellschaft zum Brutto-Buchwert von direkt gehaltenen Objekten, prozentual ausgedrückt",
  "ttOUTSTAND_PAY_TERM_EQUIV": "Die Zahlungsrückstände in Anzahl von Monaten oder Vierteljahren ausgedrückt",
  "ttOUTSTAND_RATE": "Das Verhältnis der Zahlungsrückstände der benutzerdefinierten Periode zum Rechnungsbetrag der Periode",
  "ttOVERPAYMENTS_INC_TAX_AMOUNT": "Die Summer aller Rechnungsbeträge, die innerhalb der Periode gebucht aber nicht eingezogen wurden",
  "ttOwnedBuildings": "Die nachstehenden Kennzahlen berücksichtigen alle durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekte ohne Berücksichtigung der Beteiligungshöhe der Gesellschaft an ihrer Tochtergesellschaften",
  "ttOWNER_EQUITY": "Die Summe von gezeichneten Kapital, Kapitalrücklage, satzungsgemäßen Rücklagen und Rücklagen für eigene Aktien, gesetzlichen Rücklagen, Gewinn-/Verlustvortrag, nicht abgerufenen Kapital, sonstigen Rücklagen, Zwischenkonto, Wiederanlage, außergewöhnlichen Abschreibung, nicht verteilten Jahreserfolg oder Jahresfehlbetrag und Gewinn oder Verlust nach Steuern und - wenn steuerfrei - Gewinn oder Verlust vor Steuern",
  "ttPAYMENT_INCL_TAX_AMOUNT": "Die Summer aller Rechnungsbeträge, die innerhalb der Periode gebucht und eingezogen wurden",
  "ttPC_PORTFOLIO_NET_MARKET_VALUE": "Das Verhältnis des Netto-Verkehrswerts von jedem einzelnen Objekt zum gesamten Netto-Verkehrswert aller Objekten, prozentual ausgedrückt",
  "ttPC_PORTFOLIO_TOTAL_RETURN": "Die durch seinen Netto-Verkehrswert im Verhältnis zum gesamten Netto-Verkehrswert gewichtete Gesamtrendite des Objekts",
  "ttPER_SHARE_UNREAL_GAINS_LOSSES": "Die Differenz zwischen Anteilswert und Ausgabepreis (Kaufpreis) für den ausgewählten Anleger",
  "ttPHYSICAL_OCCUPANCY_RATE": "Das Verhältnis der zum Stichtag vermieteten Fläche zur gesamten Mietfläche des Objekts, prozentual ausgedrückt",
  "ttPHYSICAL_OCCUPATION_RATE": "Das Verhältnis der zum Stichtag vermieteten Fläche zur gesamten Mietfläche des Objekts, prozentual ausgedrückt",
  "ttPHYSICAL_VACANCY_RATE": "Das Verhältnis der zum Stichtag leerstehenden Fläche zur gesamten Mietfläche des Objekts, prozentual ausgedrückt",
  "ttPhysicalOccRate": "Das Verhältnis der zum Stichtag vermieteten Fläche zur gesamten Mietfläche des Objekts, prozentual ausgedrückt",
  "ttPhysicalOccupancySummary": "Der Vermietungsstand nach Mietfläche aller durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekten (anteilig)",
  "ttPortfolioSize": "Die nachstehenden Kennzahlen berücksichtigen alle durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekte und werden entsprechend der Beteiligungshöhe jeder Gesellschaft und dem ausgewählten Anleger anteilsmäßig",
  "ttPortfolioYieldsAndReturns": "Die nachstehenden Kennzahlen sind nur auf der ausgewählten Gesellschaftsebene",
  "ttPOTENTIAL_GROSS_CAPITAL_RATE": "Das Verhältnis auf Objektebene der Potentialmiete ohne Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Netto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttPOTENTIAL_GROSS_INITIAL_YIELD": "Das Verhältnis auf Objektebene der Potentialmiete ohne Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttPOTENTIAL_NET_CAPITAL_RATE": "Das Verhältnis auf Objektebene der Potentialmiete unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Netto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttPOTENTIAL_NET_INITIAL_YIELD": "Das Verhältnis auf Objektebene der Potentialmiete unter Berücksichtigung von nicht umlegbaren Bewirtschaftungskosten zum Brutto-Verkehrswert zum Stichtag, prozentual ausgedrückt",
  "ttPRO_RATED_FINANCIAL_VACANCY": "Die vom Leerstandsbeginn zeitgewichtete Leerstandsmiete der zum Stichtag leer stehenden Mieteinheiten",
  "ttRE_LETTING_PERIOD": "Die Laufzeit vom Leerstandsbeginn bis zum Anfangsdatum der Neuvermietung der Mieteinheit, in Jahren-Monaten-Tagen ausgedrückt",
  "ttRealEstatePortfolio": "Die nachstehenden Kennzahlen berücksichtigen alle durch die ausgewählte Gesellschaft und alle ihre Tochtergesellschaften gehaltenen Objekte und werden entsprechend der Beteiligungshöhe jeder Gesellschaft und dem ausgewählten Anleger anteilsmäßig",
  "ttRECOVERABLE_AMOUNT": "Der höhere Betrag aus Netto-Verkehrswert und Nutzungswert des Objekts",
  "ttRENT_VARIATION": "Das Verhältnis der Vertragsmiete pro m² des aktuellen Mietvertrags zur Vertragsmiete pro m² des vorherigen Mietvertrags, prozentual ausgedrückt",
  "ttRENTED_AREA": "Die gesamte vermietete Fläche der zum Stichtag vermieteten Mieteinheiten",
  "ttReturnOnEquityLevered": "Das Verhältnis des Gewinns oder Verlusts nach Steuern der ausgewählten Gesellschaft zur gesamten Finanzierung der Gesellschaft (Eigenkapital und Fremdkapital), prozentual ausgedrückt",
  "ttReturnOnEquityUnlevered": "Das Verhältnis des Gewinns oder Verlusts nach Steuern der ausgewählten Gesellschaft zur von Anleger eingesetzten Kapitaleinlage (inklusive Gesellschafterdarlehen), prozentual ausgedrückt",
  "ttREVERSIONARY_POTENTIAL": "Das Verhältnis auf Mietvertragsebene der Marktmiete der vermieteten Mieteinheiten des Mietvertrags zur Vertragsmiete, prozentual ausgedrückt",
  "ttShareAcquisitionPrice": "Ausgabepreis (Kaufpreis) aller von aller Anlegern oder vom ausgewählten Anleger gehaltenen Anteile",
  "ttSimplifiedPAndL": "Die Gewinn-und Verlustrechnung der ausgewählten Gesellschaft und alle ihre Tochtergesellschaften, die entsprechend der Beteiligungshöhe jeder Gesellschaft und dem ausgewählten Anleger anteilsmäßig wird",
  "ttTOTAL_INVESTMENT_VALUE": "Die Summe aus dem Nettoinventarwert der vom ausgewählten Anleger gehaltenen Anteile und dem vom Anleger gewährten Gesellschafterdarlehen",
  "ttTOTAL_RETURN": "Die Summe von Einkommensrendite und Wertänderungsrendite",
  "ttTOTAL_UNREAL_GAINS_LOSSES": "Summe aller unrealisierten Gewinne/Verluste aus direkt gehaltenen Objekten und Beteiligungen an Tochtergesellschaften",
  "ttTotalInvestmentValue": "Die Summe aus dem Nettoinventarwert der vom ausgewählten Anleger gehaltenen Anteile und dem vom Anleger gewährten Gesellschafterdarlehen, falls ein Anleger ausgewählt wird",
  "ttUNIT_TURNOVER_RATE": "Die Summe von Neuvermietungsquote (Mieteinheiten) und Kündigungsquote (Mieteinheiten) dividiert durch 2",
  "ttUNITS_AREA_TURNOVER_RATE": "Die Summe von Neuvermietungsquote (vermietbare Fläche) und Kündigungsquote (vermietbare Fläche) dividiert durch 2",
  "ttUNITS_NUMBER": "Die zum Stichtag Gesamtanzahl von Mieteinheiten",
  "ttUNREAL_GAINS_LOSSES_ON_PROP": "Die Differenz zwischen Erzielbarer Wert und Netto-Buchwert nach benutzerdefinierter außerplanmäßigen Abschreibung gemäß dem Bericht Wertberichtigung der Objekte",
  "ttUNREAL_GAINS_LOSSES_ON_SHARES": "Summe des unrealisierten Gewinn/Verlust aus vom ausgewählten Anleger gehaltenen Anteilen",
  "ttUNREAL_GAINS_LOSSES_ON_SPV": "Die Differenz zwischen Nettoinventarwert und Netto-Buchwert der Beteiligungen an Tochtergesellschaften",
  "ttUNREALIZED_GAINS_AND_LOSSES": "Die Differenz zwischen Erzielbarer Wert und Netto-Buchwert unter Berücksichtigung von benutzerdefinierter außerplanmäßigen Abschreibung",
  "ttUnrealizedGainsLossesOnShares": "Die Differenz zwischen Nettoinventarwert und Netto-Buchwert der Beteiligungen an Tochtergesellschaften",
  "ttUnrealizedInvestmentPropertyGainLoss": "Gewinn-oder Verlustpotential bei Verkauf aller durch die Gesellschaft direkt gehaltenen Objekte, d.h. die Differenz zwischen Netto-Verkehrswert und Netto-Buchwert aller Objekte",
  "ttVACANCY_DURATION": "Die Laufzeit vom Leerstandsbeginn der Mieteinheit bis zum Stichtag, in Jahren-Monaten-Tagen ausgedrückt",
  "ttVACANT_AREA": "Die gesamte Mietfläche der zum Stichtag leer stehenden Mieteinheiten",
  "ttVALUE_IN_USE": "Das Verhältnis auf Objektebene der Marktmiete gemäß dem Gutachter zur Zielrendite für die Objekt-Nutzungsart und das Marktsegment",
  "ttWEIGHTED_CONTRIB_TOTAL_RETURN": "Der Beitrag jedes einzelnes Objekt zur Gesamtrendite aller ausgewählten Objekte, prozentual ausgedrückt",
  "txbAnalysisDate_mismatch": "Zeitpunkt ungültig",
  "txbAnalysisDate_required": "Zeitpunkt erforderlich",
  "txbDateEnd_mismatch": "Enddatum ungültig",
  "txbDateEnd_required": "Enddatum erforderlich",
  "txbDateErv": "Die Wertstellung eingeben",
  "txbDateErv_mismatch": "Falsche Wertstellung",
  "txbDateErv_required": "Wertstellung erforderlich",
  "txbDateFundOwnership": "Bitte nehmen Sie ein Aktualisierungsdatum ein",
  "txbDateFundOwnership_mismatch": "Aktualisierungsdatum ungültig",
  "txbDateFundOwnership_required": "Aktualisierungsdatum erforderlich",
  "txbDateNetBookValue": "Bitte nehmen Sie einen Abschlussstichtag ein",
  "txbDateNetBookValue_mismatch": "Abschlussstichtag ungültig",
  "txbDateNetBookValue_required": "Abschlussstichtag erforderlich",
  "txbDateStart_mismatch": "Beginndatum ungültig",
  "txbDateStart_required": "Beginndatum erforderlich",
  "txbDateStartEnd_mismatch": "Enddatum muss nach dem Startdatum liegen",
  "txbDateValuation": "Bitte nehmen Sie einen Bewertungsstichtag ein",
  "txbDateValuation_mismatch": "Bewertungsstichtag ungültig",
  "txbDateValuation_required": "Bewertungsstichtag erforderlich",
  "txbDateYield": "Bitte nehmen Sie einen Wertstellungsstichtag ein",
  "txbDateYield_mismatch": "Falsche Wertstellung",
  "txbDateYield_required": "Wertstellung erforderlich",
  "txtCacheCleared": "(de) The cache of the browser is empty",
  "txtCacheNotCleared": "(de) The cache of the browser is not empty",
  "txtChooseReportModel": "(de) Choisir un rapport personnalisé",
  "txtMenuAdditionalField": "In diesem Bereich können Sie auf Objekte, Gebaüde, Einheit oder Mietvertrag-Level Daten in Alhambra hochladen und aktualisieren. Die Datenthemen werden im Menü \"Themen verwalten\" frei definiert.",
  "txtMenuErv": "Dieses Menü benutzen Sie, um Marktmiete in Alhambra hochzuladen und zu aktualisieren. Marktmiete können Sie auf verschiedene Ebene hochladen. Die werden dann von der höchsten bis zur niedrigsten Mieteinheit-Ebene übernommen.<BR/>  <BR/>  Marktmiete können Sie auf die folgenden Ebene hochladen:<BR/>  - Auf Mieteinheit-Ebene für jedes einzelnes Objekt<BR/>  - Auf Mieteinheit-Nutzungsart-Ebene für jedes einzelnes Objekt<BR/>  - Auf Mieteinheit-Nutzungsart-Ebene für jedes einzelnes Objekt<BR/>  - Auf Mieteinheit-Nutzungsart-Ebene für jedes einzelnes Marktsegment<BR/>",
  "txtMenuFundOwnership": "Dieses Menü benutzen Sie, um anlegerbezogene Daten in Alhambra hochzuladen und zu aktualisieren. Unter dem begriff Anleger ist jede Gesellschaft oder Vehikel zu verstehen, die Kapitalanteile an einer bestehenden Gesellschaft oder Vehikel (Fonds, Tochtergesellschaft, usw.) hält.",
  "txtMenuLeaseRepository": "Dieses Menü benutzen Sie, um Mietvertragsdaten hochzuladen und zu aktualisieren.<BR/>  VORSICHT: Kommentäre sind auf 2000 Zeichen beschränkt.",
  "txtMenuNetBookValue": "Dieses Menü benutzen Sie, um erforderliche Daten zur Berechnung des Netto-Buchwerts für jedes Objekt in Alhambra hochzuladen und zu aktualisieren.",
  "txtMenuValuation": "Dieses Menü benutzen Sie, um Bewertung-Daten (Verkehrswerte und Marktmiete) in Alhambra hochzuladen und zu aktualisieren.",
  "txtMenuYield": "Dieses Menü benutzen Sie, um Zielrenditen für Investoren in Alhambra hochzuladen und zu aktualisieren. Zielrenditen werden zur Berechnung des Nutzungswerts benutzt. Es ist deswegen empfohlen, sie jedes Jahr zu aktualisieren.",
  "txtModelDateImportFile": "d.h. den Zeitpunkt ab wann die Daten Gültigkeit haben.",
  "txtModelFileAdditionalField": "Geben Sie die Werte und ihre Anfangsdaten ein; wenn es keine Werte gibt, lassen Sie die Zeile leer oder lösche.",
  "txtModelFileErvBuildingUnit": "Möchten Sie keinen Wert ansetzen, geben Sie bitte '0' ein oder löschen Sie die Linien.",
  "txtModelFileErvBuildingUnitType": "Möchten Sie keinen Wert ansetzen, geben Sie bitte '0' ein oder löschen Sie die Linien.",
  "txtModelFileErvBuildingUnitTypeRoom": "Möchten Sie keinen Wert ansetzen, geben Sie bitte '0' ein oder löschen Sie die Linien.",
  "txtModelFileErvSectorUnitType": "Möchten Sie keinen Wert ansetzen, geben Sie bitte '0' ein oder löschen Sie die Linien.",
  "txtModelFileFundOwnership": "Die Vorlagedatei untenstehend benutzen Sie, um die folgenden anlegerbezogenen Daten hochzuladen und zu aktualisieren:<BR/>  - Ausgabepreis<BR/>  - Zugesagtes Eigenkapital<BR/>  - Abgerufenes Eigenkapital<BR/>  <BR/>  Die Vorlagedatei enthält auch die folgenden nicht veränderbaren Datenpunkte zur Information und Kontrolle:<BR/>  - ISIN-Code der gehaltenen Gesellschaft/Vehikels<BR/>  - Anzahl der gehaltenen Anteile<BR/>  <BR/>  Vorsicht: Nur die zum Abschlussstichtag bestehenden Gesellschaften/Vehikel und Anleger werden aktualisiert. Alle Daten, die für aufgelöste Gesellschaften/Vehikel gelten, werden abgelehnt.",
  "txtModelFileLeaseRepository": "Möchten Sie keinen Wert ansetzen, geben Sie bitte '0' ein oder löschen Sie die Linien.",
  "txtModelFileNetBookValue": "Möchten Sie keinen Wert ansetzen, geben Sie bitte '0' ein oder löschen Sie die Linien.",
  "txtModelFileValuation": "Möchten Sie keinen Wert ansetzen, geben Sie bitte '0' ein oder löschen Sie die Linien.",
  "txtModelFileYield": "Möchten Sie keinen Wert ansetzen, geben Sie bitte '0' ein oder löschen Sie die Linien.",
  "txtModelImportFile": "Damit diese Daten sofort berücksichtigt werden :</br> • Einstellungen / Daten neu zu laden</br> • Klicken Sie bitte auf <i>Teilweise neu laden</i>.",
  "txtNextInterface": "Es werden keine Änderungen gespeichert, sind Sie sicher ?",
  "txtNotWithCents": "(de) Sans centimes",
  "txtSearch": "Objekt suchen",
  "txtSearchBuildingOrFund": "Objekt oder Gesellschaft ?",
  "txtSearchCodeOrLabel": "Eine Nummer oder Bezeichnung suchen",
  "txtSearchFunds": "Fonds suchen",
  "txtSearchProvider": "Anbieter suchen",
  "txtSearchReportTemplate": "Bericht oder Vorlage suchen",
  "txtServerCacheCleared": "(de) The server cache has been cleared",
  "txtServerCacheNotCleared": "(de) The server cache has not been cleared",
  "txtUpdateAdditionalFieldOk": "Zusätzliche Daten erfolgreich aktualisiert.",
  "txtUpdateErvOk": "Die Marktmieten wurden erfolgreich aktualisiert.",
  "txtUpdateFundOwnershipOk": "Die anlegerbezogenen Daten wurden erfolgreich aktualisiert.",
  "txtUpdateLeaseRepositoryOk": "Mietvertragsdaten wurden erfolgreich aktualisiert",
  "txtUpdateNetBookValueOk": "Die Netto-Buchwert-bezogenen Daten wurden erfolgreich aktualisiert.",
  "txtUpdateValuationOk": "Die Bewertung-Daten wurden erfolgreich aktualisiert.",
  "txtUpdateYieldOk": "Die Zielrenditen wurden erfolgreich aktualisiert.",
  "txtVacancyUnit": "Monatliche Entwicklung des Leerstands in :",
  "txtWithCents": "(de) Avec centimes",
  "TYPOLOGIE_VENTILATION_COMPTABLE": "Typologie ventilation comptable",
  "UNALLOCATED SHARES": "Anzahl unverteilter Anteile (kalkuliert)",
  "UNAVAILABLE_FOR_LETTING_AREA": "Nicht vermietbare Fläche",
  "UNCALLED CAPITAL": "Capital souscrit non appelé (€)",
  "UNCALLED_CAPITAL": "Nicht abgerufenes Kapital (€)",
  "UNDEFINED": "Nicht Definiert",
  "UNDER_LITIGATION": "Rechtsstreit betroffene",
  "UNDERLYING IMMOBILIER": "Immobilienportfolio",
  "UNDERLYING VEHICLES": "Beteiligung(en)",
  "unhandledErrorMsg": "Der Fehler wurde durch den Anwendung-Code nicht behandelt.",
  "UNIT": "Mieteinheit",
  "UNIT AREA SQM": "Mietfläche (m²)",
  "UNIT DETAILED TENANCY SCHEDULE": "Mieterliste detailliert nach Mieteinheit zum",
  "UNIT DETAILED TENANCY SCHEDULE SHEET": "Mieterliste detailliert nach Mieteinheit",
  "UNIT NATURE LABEL": "Mieteinheit-Nutzungsart",
  "UNIT NUMBER": "Mieteinheit-Nummer",
  "UNIT TENANCY SCHEDULE": "Mieterliste nach Mieteinheit zum",
  "UNIT TENANCY SCHEDULE SHEET": "Mieterliste nach Mieteinheit",
  "UNIT TURNOVER RATE": "Umschlagsquote (Mieteinheiten)",
  "UNIT TYPE CODE": "Mieteinheit-Nutzungsart-Nummer",
  "UNIT TYPE LABEL": "Mieteinheit-Nutzungsart",
  "UNIT TYPE TENANCY SCHEDULE": "Mieterliste nach Mieteinheit-Nutzungsart zum",
  "UNIT TYPE TENANCY SCHEDULE SHEET": "Mieterliste nach Mieteinheit-Nutzungsart",
  "UNIT_AREA_SQM": "Mietfläche (m²)",
  "UNIT_CODE": "Mieteinheit",
  "UNIT_END_MANAGEMENT_DATE": "Betriebsende",
  "UNIT_LABEL": "(de) Unit label",
  "UNIT_LEASE": "(de) Unit Lease Link",
  "UNIT_NATURE_LABEL": "Mieteinheit-Nutzungsart",
  "UNIT_NUMBER": "Mieteinheit-Nummer",
  "UNIT_START_MANAGEMENT_DATE": "Betriebsbeginn",
  "UNIT_TURNOVER_RATE": "Umschlagsquote (Mieteinheiten)",
  "UNIT_TYPE_CODE": "(de) Unit Type Code",
  "UNIT_TYPE_LABEL": "Mieteinheit-Nutzungsart",
  "UNIT_UTILISATION_END_DATE": "(de)Unit Date Used from",
  "UNIT_UTILISATION_START_DATE": "(de)Unit Date Used from",
  "Unit1": "€/Jahr",
  "Unit2": "m²/Jahr",
  "Unit3": "€/m²/Jahr",
  "Unit4": "€",
  "Unit5": "m²",
  "UNITS AREA TURNOVER RATE": "Umschlagsquote (vermietbare Fläche)",
  "UNITS COUNT": "Anzahl von Mieteinheiten",
  "UNITS NUMBER AT DATE": "Anzahl von Mieteinheiten zum",
  "UNITS PERCENT": "% der gesamten Anzahl von Mieteinheiten",
  "UNITS TOTAL AREA SQM": "Gesamtmietfläche (m²)",
  "UNITS_AREA_TURNOVER_RATE": "Umschlagsquote (vermietbare Fläche)",
  "UNITS_COUNT": "Anzahl von Mieteinheiten",
  "UNITS_NUMBER": "Anzahl von Mieteinheiten",
  "UNITS_NUMBER_AT_DATE": "Anzahl von Mieteinheiten zum DD/MM/YYYY",
  "UNITS_PERCENT": "% der gesamten Anzahl von Mieteinheiten",
  "UNITS_TOTAL_AREA_SQM": "Gesamtmietfläche (m²)",
  "UNPAID INVOICED INCL TAX AMOUNT": "Unbezahlter Rechnungsbetrag (€)",
  "UNPAID_INVOICED_INCL_TAX_AMOUNT": "Unbezahlter Rechnungsbetrag (€)",
  "UNREAL_GAINS_LOSSES_ON_PROP": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Objekten (€)",
  "UNREAL_GAINS_LOSSES_ON_SHARES": "Unrealisierter Gewinn/Verlust aus gehaltenen Anteilen (€)",
  "UNREAL_GAINS_LOSSES_ON_SPV": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Beteiligungen (€)",
  "UNREALIZED GAINS AND LOSSES": "Unrealisierter Gewinn/Verlust nach benutzerdefinierter außerplanmäßigen Abschreibung (€)",
  "UNREALIZED GAINS AND LOSSES ON PROPERTIES": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Objekten (€)",
  "UNREALIZED GAINS AND LOSSES ON SHARES": "Unrealisierter Gewinn/Verlust aus gehaltenen Anteilen (€)",
  "UNREALIZED GAINS AND LOSSES ON SPV AND HOLDCO": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Beteiligungen (€)",
  "UNREALIZED GAINS LOSSES ON SHARES": "Unrealisierter Gewinn/Verlust aus gehaltenen Anteilen (€)",
  "UNREALIZED INVESTMENT PROPERTY GAIN LOSS": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Objekten (€)",
  "UNREALIZED NON PROPERTY GAIN LOSS": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Beteiligungen (€)",
  "UNREALIZED_GAINS_AND_LOSSES": "Unrealisierter Gewinn/Verlust nach benutzerdefinierter außerplanmäßigen Abschreibung (€)",
  "UNREALIZED_GAINS_AND_LOSSES_ON_PROPERTIES": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Objekten (€)",
  "UNREALIZED_GAINS_AND_LOSSES_ON_SHARES": "Unrealisierter Gewinn/Verlust aus gehaltenen Anteilen (€)",
  "UNREALIZED_GAINS_AND_LOSSES_ON_SPV_AND_HOLDCO": "Unrealisierter Gewinn/Verlust aus direkt gehaltenen Beteiligungen (€)",
  "UNRECOVERABLE": "(de) Not Recoverable",
  "UNRECOVERED EXPENSES": "Nicht umgelegte Bewirtschaftungskosten (€/Jahr)",
  "UNRECOVERED EXPENSES WOU": "Nicht umgelegte Bewirtschaftungskosten",
  "UPCOMING LEASE EVENTS": "Nächstmögliche Kündigungstermine",
  "UploadStatusError": "Status des Hochladens : Die Datei kann nicht hochgeladen werden. Während des Hochladens ist ein Fehler aufgetreten :",
  "UploadStatusFileTooBig": "Status des Hochladens : Die Dateigrosse soll unter 1 Mb liegen!",
  "UploadStatusOnlyJPEG": "Status des Hochladens : Nur JPEG Dateien sind zulässig !",
  "UploadStatusPropertyNotFound": "Status des Hochladens : Das Objekt kann nicht gefunden werden !",
  "USABLE FLOOR AREA": "Nutzfläche (m²)",
  "USABLE FLOOR AREA WOU": "Nutzfläche",
  "VACANCY": "Leerstand",
  "VACANCY AREA": "Leerstandsfläche (m²)",
  "VACANCY AREA SHEET": "Leerstandsfläche",
  "VACANCY BREAKDOWN": "Leerstandsaufteilung nach Laufzeit",
  "VACANCY BREAKDOWN BY NATURE": "Leerstandsverteilung nach Art zum",
  "VACANCY BREAKDOWN BY NATURE SHEET": "Leerstandsaufteilung nach Art",
  "VACANCY DURATION": "Leerstandslaufzeit (J-M-T)",
  "VACANCY FOR SALE": "Struktureller Leerstand (nach Verkauf)",
  "VACANCY FOR WORKS": "Struktureller Leerstand (nach Sanierung)",
  "VACANCY START DATE": "Leerstandsbeginn",
  "VACANCY SUMMARY": "Vermietungsstand zum",
  "VACANCY TYPE": "Mieteinheiten-Nutzungsart",
  "VACANCY_DURATION": "Leerstandslaufzeit (J-M-T)",
  "VACANCY_RATE_CURRENT_MONTH": "Physische Leerstandsquote aktueller Monat",
  "VACANCY_RATE_MINUS_01_MONTHS": "Physische Leerstandsquote 1 Monat zuvor",
  "VACANCY_RATE_MINUS_02_MONTHS": "Physische Leerstandsquote 2 Monaten zuvor",
  "VACANCY_RATE_MINUS_03_MONTHS": "Physische Leerstandsquote 3 Monaten zuvor",
  "VACANCY_RATE_MINUS_04_MONTHS": "Physische Leerstandsquote 4 Monaten zuvor",
  "VACANCY_RATE_MINUS_05_MONTHS": "Physische Leerstandsquote 5 Monaten zuvor",
  "VACANCY_RATE_MINUS_06_MONTHS": "Physische Leerstandsquote 6 Monaten zuvor",
  "VACANCY_RATE_MINUS_07_MONTHS": "Physische Leerstandsquote 7 Monaten zuvor",
  "VACANCY_RATE_MINUS_08_MONTHS": "Physische Leerstandsquote 8 Monaten zuvor",
  "VACANCY_RATE_MINUS_09_MONTHS": "Physische Leerstandsquote 9 Monaten zuvor",
  "VACANCY_RATE_MINUS_10_MONTHS": "Physische Leerstandsquote 10 Monaten zuvor",
  "VACANCY_RATE_MINUS_11_MONTHS": "Physische Leerstandsquote 11 Monaten zuvor",
  "VACANCY_START_DATE": "Leerstandsbeginn",
  "VACANCY_TYPE": "Leerstandsart",
  "VACANT": "--LEERSTEHEND--",
  "VACANT AREA": "Leerstandsfläche (m²)",
  "VACANT AREA 2 4 MONTHS": "Leerstandsfläche 2-4 Monaten (m²)",
  "VACANT AREA INF 2MONTHS": "Leerstandsfläche < 2 Monaten (m²)",
  "VACANT AREA SUP 4MONTHS": "Leerstandsfläche > 4 Monaten (m²)",
  "VACANT UNITS": "Leerstehende Mieteinheiten zum",
  "VACANT UNITS BY UNIT TYPE": "Aufteilung der leerstehenden Mieteinheiten nach Mieteinheiten-Nutzungsart",
  "VACANT UNITS BY VACANCY TYPE": "Aufteilung der leerstehenden Mieteinheiten nach Leerstandsart",
  "VACANT UNITS SHEET": "Leerstehende Mieteinheiten",
  "VACANT_AREA": "Leerstandsfläche (m²)",
  "VACANT_AREA_1_3_MONTHS": "1-3 monat",
  "VACANT_AREA_2_4_MONTHS": "Leerstandsfläche 2-4 Monaten (m²)",
  "VACANT_AREA_3_6_MONTHS": "3-6 monat",
  "VACANT_AREA_CURRENT_MONTH": "Leerstandsfläche aktueller Monat",
  "VACANT_AREA_INF_1_MONTH": "< 1 monat",
  "VACANT_AREA_INF_2MONTHS": "Leerstandsfläche < 2 Monaten (m²)",
  "VACANT_AREA_MINUS_01_MONTHS": "Leerstandsfläche 1 Monat zuvor",
  "VACANT_AREA_MINUS_02_MONTHS": "Leerstandsfläche 2 Monaten zuvor",
  "VACANT_AREA_MINUS_03_MONTHS": "Leerstandsfläche 3 Monaten zuvor",
  "VACANT_AREA_MINUS_04_MONTHS": "Leerstandsfläche 4 Monaten zuvor",
  "VACANT_AREA_MINUS_05_MONTHS": "Leerstandsfläche 5 Monaten zuvor",
  "VACANT_AREA_MINUS_06_MONTHS": "Leerstandsfläche 6 Monaten zuvor",
  "VACANT_AREA_MINUS_07_MONTHS": "Leerstandsfläche 7 Monaten zuvor",
  "VACANT_AREA_MINUS_08_MONTHS": "Leerstandsfläche 8 Monaten zuvor",
  "VACANT_AREA_MINUS_09_MONTHS": "Leerstandsfläche 9 Monaten zuvor",
  "VACANT_AREA_MINUS_10_MONTHS": "Leerstandsfläche 10 Monaten zuvor",
  "VACANT_AREA_MINUS_11_MONTHS": "Leerstandsfläche 11 Monaten zuvor",
  "VACANT_AREA_SQM": "Leerstandsfläche (m²)",
  "VACANT_AREA_SUP_4MONTHS": "Leerstandsfläche > 4 Monaten (m²)",
  "VACANT_AREA_SUP_6_MONTHS": "> 6 monat",
  "VACANT_DISPOSITION_AREA": "Nach Verkauf leerstehende Leerstandfläche",
  "VACANT_INACTIVE_AREA": "Inaktiv",
  "VACANT_LETTING_AREA": "Vermietbare Fläche",
  "VACANT_UNAVAILABLE_AREA": "Nicht vermietbare Fläche",
  "VACANT_UNDEFINED_AREA": "Leerstandfläche",
  "VACANT_WORKS_AREA": "Nach Sanierung leerstehende Leerstandfläche",
  "vacatedTenants": "Gekündigte Mietverträge",
  "VALIDATION_DU_CHANTIER": "Validation du chantier",
  "VALUATION": "Grundstücksbewertung",
  "VALUATION MARKET RENT": "Bewertung-Marktmiete (€)",
  "VALUATION_MARKET_RENT": "Bewertung - Marktmiete (€)",
  "VALUE IN USE": "Nutzungswert (€)",
  "VALUE_IN_USE": "Nutzungswert (€)",
  "VALUES PERCENT": "% des gesamten Netto-Verkehrswerts",
  "VALUES_PERCENT": "% des gesamten Netto-Verkehrswerts",
  "VARIATION 01 N": "(de) Variation January",
  "VARIATION 02 N": "(de) Variation February",
  "VARIATION 03 N": "(de) Variation March",
  "VARIATION 04 N": "(de) Variation April",
  "VARIATION 05 N": "(de) Variation May",
  "VARIATION 06 N": "(de) Variation June",
  "VARIATION 07 N": "(de) Variation July",
  "VARIATION 08 N": "(de) Variation August",
  "VARIATION 09 N": "(de) Variation September",
  "VARIATION 10 N": "(de) Variation October",
  "VARIATION 11 N": "(de) Variation November",
  "VARIATION 12 N": "(de) Variation December",
  "VARIATION BUDGET": "(de) Variation budget",
  "VARIATION BUDGET RATE": "(de) Variation budget rate",
  "VARIATION N": "(de) Variation € budget to date to actual to date",
  "VARIATION RATE 01 N": "Realisiert gegen Budget Anteil Januar",
  "VARIATION RATE 02 N": "Realisiert gegen Budget Anteil Februar",
  "VARIATION RATE 03 N": "Realisiert gegen Budget Anteil März",
  "VARIATION RATE 04 N": "Realisiert gegen Budget Anteil April",
  "VARIATION RATE 05 N": "Realisiert gegen Budget Anteil Mai",
  "VARIATION RATE 06 N": "Realisiert gegen Budget Anteil Juni",
  "VARIATION RATE 07 N": "Realisiert gegen Budget Anteil Juli",
  "VARIATION RATE 08 N": "Realisiert gegen Budget Anteil August",
  "VARIATION RATE 09 N": "Realisiert gegen Budget Anteil September",
  "VARIATION RATE 10 N": "Realisiert gegen Budget Anteil Oktober",
  "VARIATION RATE 11 N": "Realisiert gegen Budget Anteil November",
  "VARIATION RATE 12 N": "Realisiert gegen Budget Anteil Dezember",
  "VARIATION RATE N": "Realisiert gegen Budget Anteil",
  "VARIATION RATE T1 N": "Realisiert gegen Budget Anteil V1",
  "VARIATION RATE T2 N": "Realisiert gegen Budget Anteil V2",
  "VARIATION RATE T3 N": "Realisiert gegen Budget Anteil V3",
  "VARIATION RATE T4 N": "Realisiert gegen Budget Anteil V4",
  "VARIATION RATE TODATE N": "(de) Variation rate",
  "VARIATION T1 N": "(de) Variation V1",
  "VARIATION T2 N": "(de) Variation V2",
  "VARIATION T3 N": "(de) Variation V3",
  "VARIATION T4 N": "(de) Variation V4",
  "VARIATION TODATE N": "(de) Variation",
  "VARIATION_01_N": "(de) Variation January YYYY",
  "VARIATION_02_N": "(de) Variation February YYYY",
  "VARIATION_03_N": "(de) Variation March YYYY",
  "VARIATION_04_N": "(de) Variation April YYYY",
  "VARIATION_05_N": "(de) Variation May YYYY",
  "VARIATION_06_N": "(de) Variation June YYYY",
  "VARIATION_07_N": "(de) Variation July YYYY",
  "VARIATION_08_N": "(de) Variation August YYYY",
  "VARIATION_09_N": "(de) Variation September YYYY",
  "VARIATION_10_N": "(de) Variation October YYYY",
  "VARIATION_11_N": "(de) Variation November YYYY",
  "VARIATION_12_N": "(de) Variation December YYYY",
  "VARIATION_BUDGET": "(de) Variation budget",
  "VARIATION_BUDGET_RATE": "(de) Variation budget rate",
  "VARIATION_N": "(de) Variation € budget to date to actual to date",
  "VARIATION_RATE_01_N": "Realisiert gegen Budget Anteil Januar YYYY",
  "VARIATION_RATE_02_N": "Realisiert gegen Budget Anteil Februar YYYY",
  "VARIATION_RATE_03_N": "Realisiert gegen Budget Anteil März YYYY",
  "VARIATION_RATE_04_N": "Realisiert gegen Budget Anteil April YYYY",
  "VARIATION_RATE_05_N": "Realisiert gegen Budget Anteil Mai YYYY",
  "VARIATION_RATE_06_N": "Realisiert gegen Budget Anteil Juni YYYY",
  "VARIATION_RATE_07_N": "Realisiert gegen Budget Anteil Juli YYYY",
  "VARIATION_RATE_08_N": "Realisiert gegen Budget Anteil August YYYY",
  "VARIATION_RATE_09_N": "Realisiert gegen Budget Anteil Oktober YYYY",
  "VARIATION_RATE_10_N": "Realisiert gegen Budget Anteil Oktober YYYY",
  "VARIATION_RATE_11_N": "Realisiert gegen Budget Anteil November YYYY",
  "VARIATION_RATE_12_N": "Realisiert gegen Budget Anteil Dezember YYYY",
  "VARIATION_RATE_N": "Realisiert gegen Budget Anteil YYYY",
  "VARIATION_RATE_T1_N": "Realisiert gegen Budget Anteil V1 YYYY",
  "VARIATION_RATE_T2_N": "Realisiert gegen Budget Anteil V2 YYYY",
  "VARIATION_RATE_T3_N": "Realisiert gegen Budget Anteil V3 YYYY",
  "VARIATION_RATE_T4_N": "Realisiert gegen Budget Anteil V4 YYYY",
  "VARIATION_RATE_TODATE_N": "(de) Variation rate DDMMYYYY",
  "VARIATION_T1_N": "(de) Variation V1 YYYY",
  "VARIATION_T2_N": "(de) Variation V2 YYYY",
  "VARIATION_T3_N": "(de) Variation V3 YYYY",
  "VARIATION_T4_N": "(de) Variation V4 YYYY",
  "VARIATION_TODATE_N": "(de) Variation DDMMYYYY",
  "VAT": "(de) VAT",
  "VAT AMOUNT": "Mehrwertsteuer",
  "VAT AMOUNT TOTAL": "Mehrwertsteuerbetrag",
  "VAT_AMOUNT": "Mehrwertsteuer",
  "VAT_AMOUNT_TOTAL": "Mehrwertsteuerbetrag",
  "VAT_RATE_CODE": "(de) VAT Rate Code",
  "VEHICLE FORMATION DATE": "Datum der Auflegung",
  "VEHICLE NET ASSET VALUE": "Fonds-Nettoinventarwert (100%)",
  "VEHICLE TERM": "Laufzeit",
  "VEHICLE_FORMATION_DATE": "Datum der Auflegung",
  "VEHICLE_TERM": "Laufzeit",
  "VERSIONS_DU_PPAT": "Versions du PPAT",
  "VILLE": "Ville",
  "WEIGHTED CONTRIB TOTAL RETURN": "Objektbeitrag zum Portfolioreturn",
  "WEIGHTED USABLE AREA": "Gewichtete Nutzfläche (m²)",
  "WEIGHTED USABLE AREA WOU": "Gewichtete Nutzfläche",
  "WEIGHTED_CONTRIB_TOTAL_RETURN": "Objektbeitrag zum Portfolioreturn",
  "WEIGHTED_USABLE_AREA_SQM": "Gewichtete Nutzfläche (m²)",
  "WORK CAMPAIGN CODE": "(de) Work Campaign Code",
  "WORK CAMPAIGN LABEL": "(de) Work Campaign Label",
  "WORK_CAMPAIGN_CODE": "(de) Work Campaign Code",
  "WORK_CAMPAIGN_LABEL": "(de) Work Campaign Label",
  "WORKING CAPITAL CALLS": "(de) Working capital calls (€)",
  "WORKING CAPITAL RECEIVABLE": "(de) Working capital receivable (€)",
  "WORKING CAPITAL REFUNDS": "(de) Working capital refunds (€)",
  "WORKING_CAPITAL_CALLS": "(de) Working capital calls (€)",
  "WORKING_CAPITAL_RECEIVABLE": "(de) Working capital receivable (€)",
  "WORKING_CAPITAL_REFUNDS": "(de) Working capital refunds (€)",
  "WORKS END DATE": "Enddatum der Renovierung",
  "WORKS VACANT AREA": "Nach Sanierung leerstehende Leerstandsfläche (m²)",
  "WORKS VACANT AREA RATE": "% des gesamten Leerstands",
  "WORKS_END_DATE": "Enddatum der Renovierung",
  "WORKS_END_DATE_mismatch": "Falsches Enddatum der Renovierung",
  "WORKS_VACANT_AREA": "Nach Sanierung leerstehende Leerstandsfläche (m²)",
  "WORKS_VACANT_AREA_RATE": "% des gesamten Leerstands (%)",
  "WORKSHOP AREA": "Praxisfläche (m²)",
  "WORKSHOP_AREA": "Praxisfläche (m²)",
  "YEAR MONTH": "Jahre / Monat",
  "YEAR TO DATE SALES VOLUME": "Umsatz seit Jahresbeginn (€)",
  "YEAR_MONTH": "Jahr / Monat",
  "YEAR_NUMBER": "Jahr",
  "YEAR_TO_DATE_SALES_VOLUME": "Umsatz seit Jahresbeginn (€)",
  "YEARS": "Jahre",
  "YES": "Ja",
  "YIELD MONITORING": "Rendite-Analyse zum",
  "YIELD MONITORING REPORT": "Rendite-Analyse",
  "YOY ANNUAL SALES GROWTH": "Umsatzentwicklung",
  "YOY MONTHLY SALES GROWTH": "Umsatzentwicklung",
  "YOY_ANNUAL_SALES_GROWTH": "Umsatzentwicklung im Jahren YYYY-1 ggü. dem Vorjahr YYYY-2",
  "YOY_MONTHLY_SALES_GROWTH": "Umsatzentwicklung im Monat MM/YYYY ggü. dem Vorjahresmonat MM/YYYY-1",
  "lbl-connection": "Verbindung",
  "lbl-language": "Sprache",
  "lbl-username": "Nutzername",
  "lbl-password": "Passwort",
  "lbl-login": "sich anmelden",
  "en-GB": "Englisch",
  "fr-FR": "Französisch",
  "de-DE": "Deutsch",
  "lblFixedLeftColumns": "Anzahl der festen Spalten auf der linken Seite",
  "lblFixedRightColumns": "Anzahl der festen Spalten auf der rechten Seite",
  "id_report_template": "Vorlagenkennung",
  "report_code": "Berichtscode",
  "report_name": "Standardbericht Entsprechend",
  "template_name": "Vorlagenname",
  "share_with_other_users": "Mit Anderen Nutzern Geteilt",
  "updated_at": "Geändert am",
  "nb_interfaces": "Anzahl der Verknüpften Schnittstellen",
  "is_access_managed_by_building": "Auf Alle Eigenschaften Anwenden",
  "Actions": "Maßnahmen",
  "lblNoDataAvailable": "Es sind keine Daten verfügbar",
  "lblViewReport": "Standardbericht ansehen",
  "lblPreferenceUpdated": "Alle Einstellungen wurden verarbeitet. {{params1}} Präferenzen wurden erfolgreich aktualisiert.",
  "lblNoPreferenceUpdated": "Es wurden keine Präferenzen geändert.",
  "lblMsqErrorPassword2": "Das Passwort muss mindestens {{params1}} Zeichen enthalten, einschließlich eines Großbuchstabens, eines Kleinbuchstabens, einer Zahl und eines Sonderzeichens !*@#$%^+=",
  "lblDatePasswordLeastDays": "Sie können Ihr Passwort nur {{params1}} Tage nach der letzten Aktualisierung ändern.",
  "lblCurrentPasswordError": "Das Passwort kann nicht geändert werden. Das alte Passwort ist falsch.",
  "ITEMS_PER_PAGE": "Elemente pro Seite:",
  "NEXT_PAGE": "Nächste",
  "PREVIOUS_PAGE": "Vorherige",
  "FIRST_PAGE": "Erste Seite",
  "LAST_PAGE": "Letzte Seite",
  "RANGE_PAGE_LABEL": "{{startIndex}} - {{endIndex}} von {{length}}",
  "RANGE_PAGE_LABEL_ZERO": "0 von {{length}}",
  "menuAssetMonitoring": "Anlagenüberwachung",
  "NO_FILTER": "Kein Filter",
  "TENANCY_SCHEDULES": "Mieterlisten",
  "RENTAL_MONITORING": "Verfolgung von Vermietungen",
  "EXPENSES_MONITORING": "Ausgaben Verfolgen",
  "lblReportLaunch": "Der Bericht wurde veröffentlicht",
  "DETAILED": "Formatierte",
  "CONNECTION_FAILED": "Die Anmeldung ist fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.",
  "CONNECTION_ERROR": "Fehler beim Einloggen",
  "UNKNOWN_ERROR": "Unbekannter Fehler",
  "SESSION_EXPIRED": "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
  "OTHER_OCCUPIED_AREA_SQM": "(de) Other - Occupied Area (m²)",
  "OTHER_TOTAL_AREA_SQM": "(de) Other - Total Area (m²)",
  "OTHER_VACANT_AREA_SQM": "(de) Other - Vacant Area (m²)"
}

